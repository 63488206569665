import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';

import CustomCheckbox from 'common/CustomCheckbox';
import { RegularMediumText } from 'common/texts';
import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function Checkbox({ metaPath, answerField }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const choices = useQuiz((current) => get(current, `${metaPath}.choices`, ''), [metaPath]);
  const checkedList = useQuiz(
    (current) => get(current, `content.answers.${answerField}.values`, []),
    [answerField],
  );

  const changeHandler = useSetQuiz(
    (prev, value) => {
      let newCheckedList = [...get(prev, `content.answers.${answerField}.values`, [])];
      if (newCheckedList.includes(value)) {
        return set(
          cloneDeep(prev),
          `content.answers.${answerField}.values`,
          newCheckedList.filter((item) => item !== value),
        );
      }
      newCheckedList.push(value);
      return set(cloneDeep(prev), `content.answers.${answerField}.values`, newCheckedList);
    },
    [answerField],
  );

  const parsedOptions = React.useMemo(() => {
    return choices.map(({ value, text }) => {
      return (
        <CustomCheckbox
          key={value}
          className="g-mt-5"
          label={text}
          checked={checkedList.includes(value)}
          changeHandler={() => changeHandler(value)}
        />
      );
    });
  }, [choices, checkedList, changeHandler]);

  return (
    <div className="g-mt-20">
      <RegularMediumText className="g-mb-15">{title}</RegularMediumText>

      {parsedOptions}
    </div>
  );
});
