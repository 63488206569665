import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { useConsultation } from 'hooks/useAppointments';

import SubVideoComponent from './SubVideoComponent';

export default React.memo(function SubVideoComponentLoader() {
  const room = useConsultation((current) => get(current, 'room'));

  if (!room) {
    return null;
  }

  return (
    <StyledWrapper>
      <SubVideoComponent />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: absolute;
  right: 3rem;
  bottom: 3.5rem;
  width: 15rem;
  height: 15rem;
  @media screen and (max-width: 480px) {
    right: 1rem;
  }
`;
