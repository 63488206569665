import React from 'react';

import InputWithLabel from 'common/InputWithLabel';
import { useSetMedicationProperty } from 'hooks/usePatients';

export default React.memo(function Dosage({ className = '', index, dosage, validate }) {
  const setMedicationDosage = useSetMedicationProperty({ index, field: 'dosage' });
  return (
    <InputWithLabel
      className={className}
      label="Dosage"
      type="number"
      placeholder="0"
      isRequred
      isValid={!validate || dosage}
      value={dosage}
      changeHandler={setMedicationDosage}
    />
  );
});
