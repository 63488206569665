import React, { FC, useCallback, useEffect, useState } from 'react';

import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { DietEdit } from './DietSectionEdit';
import { DietView } from './DietSectionView';

type DietSectionProps = {
  tasks?: TaskType<'diet'>[];
  isPreview?: boolean;
  onChange?: (currentTasks: TaskType<'diet'>[]) => void;
  onAddNew?: () => void;
};

export const DietSection: FC<DietSectionProps> = ({ tasks, isPreview, onChange, onAddNew }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTasks, setCurrentTasks] = useState(tasks || []);

  useEffect(() => {
    setCurrentTasks(tasks || []);

    const maybeHasNew = tasks?.some((task) => task.isNew);

    if (maybeHasNew) {
      setIsEditing(true);
    }
  }, [tasks]);

  const toggleEdit = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const save = useCallback((tasks: TaskType<'diet'>[]) => {
    onChange?.(tasks.map((t) => (t.isNew !== undefined ? { ...t, isNew: false } : t)));
    toggleEdit();
  }, []);

  const deleteTasks = useCallback(() => {
    setCurrentTasks([]);
    onChange?.([]);
    toggleEdit();
  }, []);

  const handleCancel = useCallback(() => {
    onChange?.(tasks?.filter((t) => t.id) || []);
    toggleEdit();
  }, [tasks]);

  const deleteSection = useCallback(
    (index: number) => {
      const newTasks = currentTasks.filter((t, i) => {
        return i !== index;
      });
      onChange?.(newTasks);
    },
    [currentTasks],
  );

  return isEditing && !isPreview ? (
    <DietEdit
      onDeleteSection={deleteSection}
      onSave={save}
      onDelete={deleteTasks}
      onCancel={handleCancel}
      onAddNew={onAddNew}
      tasks={currentTasks}
    />
  ) : (
    <DietView
      tasks={currentTasks}
      isPreview={isPreview}
      onDelete={deleteTasks}
      onEdit={toggleEdit}
    />
  );
};
