import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import LinkButton from 'common/LinkButton';
import { PATIENTS, DETAILS, HEALTH_PLANS, EDIT } from 'constants/routes';

export default React.memo(function EditHealthPlanButton({ healthPlanID }) {
  const { patientId } = useParams();
  const history = useHistory();

  const editHealthPlan = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}/${healthPlanID}/${EDIT}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${HEALTH_PLANS}` },
    });
  }, [history, patientId]);

  return (
    <LinkButton className="view-button" onClick={editHealthPlan}>
      Edit
    </LinkButton>
  );
});
