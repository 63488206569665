import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState';

export const useGenders = createUseGlobalState('genders');
export const useSetGenders = createUseSetGlobalState('genders');

export const useStates = createUseGlobalState('states');
export const useSetStates = createUseSetGlobalState('states');

export const useProfile = createUseGlobalState('profile');
export const useSetProfile = createUseSetGlobalState('profile');

export const useLocalProfile = createUseGlobalState('localProfile');
export const useSetLocalProfile = createUseSetGlobalState('localProfile');

export const useUploadedAvatar = createUseGlobalState('uploadedAvatar');
export const useSetUploadedAvatar = createUseSetGlobalState('uploadedAvatar');

export const useSymptomsList = createUseGlobalState('symptomsList');
export const useSetSymptomsList = createUseSetGlobalState('symptomsList');

export const useAvailability = createUseGlobalState('availability');
export const useSetAvailability = createUseSetGlobalState('availability');

export const useVacationPeriod = createUseGlobalState('vacationPeriod');
export const useSetVacationPeriod = createUseSetGlobalState('vacationPeriod');

export const useSelectedTimeTab = createUseGlobalState('selectedTimeTab');
export const useSetSelectedTimeTab = createUseSetGlobalState('selectedTimeTab');

export const useTimes = createUseGlobalState('times');
export const useSetTimes = createUseSetGlobalState('times');

function withoutValidation() {
  return true;
}

function existValidation(value) {
  return !!value;
}

function zipValidation(value) {
  return value !== null && value?.trim() !== '';
}

function birthDateValidation(value) {
  return !value?.split('-').includes('');
}

function phoneValidation(value) {
  return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value) || /^\d{7}$/.test(value);
}

function countryValidation(value) {
  return ['UAE', 'USA'].includes(value);
}

function summaryValidation(value) {
  if (!value) {
    return false;
  }
  if (value.length > 180) {
    return false;
  }
  return true;
}

function experienceValidation(value) {
  if (!value) {
    return false;
  }
  return Number.isInteger(+value);
}

export const VALIDATION_RULES = {
  email: existValidation,
  first_name: existValidation,
  last_name: existValidation,
  phone: phoneValidation,
  zip: zipValidation,
  gender: existValidation,
  state: existValidation,
  country: countryValidation,
  city: existValidation,
  birth_date: birthDateValidation,
  address_1: withoutValidation,
  address_2: withoutValidation,
  biography: existValidation,
  summary: summaryValidation,
  experience: experienceValidation,
  education: existValidation,
  certifications: existValidation,
  practices: existValidation,
};

export function useMissedFields() {
  const localProfile = useLocalProfile();
  return React.useMemo(() => {
    return Object.keys(VALIDATION_RULES).filter((field) => {
      const validationCb = VALIDATION_RULES[field];
      if (validationCb && localProfile && !validationCb(localProfile[field])) {
        return true;
      }
      return false;
    });
  }, [localProfile]);
}

export const WEEKS_LIST = [
  moment()
    .add(0 * 7, 'days')
    .startOf('isoWeek'),
  moment()
    .add(1 * 7, 'days')
    .startOf('isoWeek'),
  moment()
    .add(2 * 7, 'days')
    .startOf('isoWeek'),
  moment()
    .add(3 * 7, 'days')
    .startOf('isoWeek'),
  moment()
    .add(4 * 7, 'days')
    .startOf('isoWeek'),
];

export function useWeeksTabsConfig(id) {
  return useTimes(
    (current) => {
      const config = WEEKS_LIST.map((day) => {
        const week = day.clone().week();
        const currentSchedule = get(current, week, []);
        return { id: week, label: `Week ${week}`, isRequred: currentSchedule.length === 0, day };
      });
      if (typeof id !== 'undefined') {
        return config.find((item) => item.id === id);
      }
      return config;
    },
    [id],
  );
}
