import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';

import InfoForPatient from './InfoForPatient';

export default function PrescriptionsESend({
  name = '',
  directions = '',
  dispence = '',
  note = '',
  schedule = '',
  prescibedBy = '',
  covIns = '',
}) {
  return (
    <StyledWrapper>
      <div className="pseudo-textarea">
        <RegularText className="textarea-label">{name}</RegularText>
        {directions && <InfoForPatient label="Directions:" value={directions} />}
        {dispence && <InfoForPatient label="Dispence:" value={dispence} />}
        {note && <InfoForPatient label="Note:" value={note} />}
        {schedule && <InfoForPatient label="Schedule:" value={schedule} />}
        {prescibedBy && <InfoForPatient label="Prescibed by:" value={prescibedBy} />}
        {covIns && <InfoForPatient label="Cov/Ins:" value={covIns} />}
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .textarea-label {
    text-decoration: underline;
  }
  .pseudo-textarea {
    background: #ffffff;
    padding: 1rem 1.5rem;
    border: solid 1px #e5e5e5;
    font-size: 1.6rem;
  }
`;
