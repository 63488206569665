import classNames from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { CKEditor } from 'common/CKEditor';
import { FilesResult, useAttachFiles } from 'hooks/useAttachFiles';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import createNotification from '../../../../../../utils/createNotification';
import { DnDFiles } from '../../../../../ui2.0/DnDFiles';
import { InputField } from '../../../../../ui2.0/InputField';
import { TreatmentPlanFiles } from '../../../Files/TreatmentPlanFiles';
import { LibraryArticleList } from '../../LibraryArticle/LibraryArticleList';

type ActivityEditProps = {
  task: TaskType<'activity'>;
  hasDeleteSection?: boolean;
  errors: Partial<Record<keyof TaskType<'activity'>['details'], string>>;
  onChange: (task: TaskType<'activity'>) => void;
  onDeleteSection: () => void;
};

export const ActivityEditItem: FC<ActivityEditProps> = ({
  task,
  errors,
  onChange,
  onDeleteSection,
  hasDeleteSection,
}) => {
  const [currentTask, setCurrentTask] = useState(task);

  const handleAddFile = useCallback((files: FilesResult) => {
    const newFiles = files.uploaded.map((file) => ({
      id: file.id || '',
      fileName: file.originalFileName || '',
      fileType: file.fileType || 'application/pdf',
    }));

    if (files.failed.length > 0) {
      files.failed.forEach((file) => {
        createNotification({
          message: `${file.originalFileName} - ${file.message}`,
          type: 'error',
        });
      });
    }

    setCurrentTask((p) => ({ ...p, files: [...(p.files || []), ...newFiles] }));
  }, []);

  const { onAttachFiles, uploadingFiles } = useAttachFiles({
    onSuccess: handleAddFile,
  });

  const handleDeleteFile = useCallback((id) => {
    setCurrentTask((p) => ({ ...p, files: p.files?.filter((file) => file.id !== id) }));
  }, []);

  const handleAddMaterial = useCallback((material: TaskType['materials']['0']) => {
    setCurrentTask((p) => ({
      ...p,
      materials: [...(p.materials || []).filter((m) => m.id !== material.id), material],
    }));
  }, []);

  const handleDeleteMaterial = useCallback((id) => {
    setCurrentTask((p) => ({
      ...p,
      materials: p.materials?.filter((material) => material.id !== id),
    }));
  }, []);

  const handleChangeVal = useCallback(
    (name: 'summary' | 'duration' | 'description') => (val: string | number) => {
      setCurrentTask((p) => ({
        ...p,
        details: { ...p.details, [name]: name === 'duration' ? Number(val) : val },
      }));
    },
    [],
  );

  useEffect(() => {
    if (JSON.stringify(currentTask) !== JSON.stringify(task)) {
      onChange(currentTask);
    }
  }, [JSON.stringify(currentTask), JSON.stringify(task)]);

  return (
    <DnDFiles onFilesAdded={onAttachFiles}>
      {(isDragActive) => (
        <div className="relative">
          <div
            className={classNames(
              'text-california text-2020 font-ambit font-semibold top-[50%] left-[50%] -mt-8 -ml-[7rem] absolute z-10',
              {
                flex: isDragActive,
                hidden: !isDragActive,
              },
            )}
          >
            Drop Files Here
          </div>
          <div
            className={classNames('border-2 border-california rounded-main p-4 mb-6', {
              'border-dashed': isDragActive,
            })}
          >
            <div className={classNames({ 'opacity-30': isDragActive })}>
              {hasDeleteSection && (
                <div className="flex justify-end">
                  <div
                    onClick={onDeleteSection}
                    className="ml-3 cursor-pointer h-6 w-6 rounded-full bg-catskillWhite flex justify-center items-center"
                  >
                    <TrashIcon className="stroke-manatee" />
                  </div>
                </div>
              )}
              <div className="flex justify-between mb-4">
                <div className="w-full mr-2.5">
                  <InputField
                    error={errors.summary}
                    value={currentTask.details.summary}
                    label="Summary (short-description)"
                    isRequired
                    onChange={handleChangeVal('summary')}
                  />
                </div>
                <div className="w-full">
                  <InputField
                    type="number"
                    error={errors.duration}
                    value={currentTask.details.duration + ''}
                    label="Duration (days)"
                    isRequired
                    onChange={handleChangeVal('duration')}
                  />
                </div>
              </div>
              <div className="ck-short-editor mb-6">
                <CKEditor
                  error={errors.description}
                  label="Additional details (long description)"
                  isRequired
                  data={currentTask.details.description}
                  onChange={handleChangeVal('description')}
                />
              </div>
              <LibraryArticleList
                color="text-california"
                onAdd={handleAddMaterial}
                materials={currentTask.materials}
                onDelete={handleDeleteMaterial}
              />
            </div>
          </div>
          <TreatmentPlanFiles
            uploadingFiles={uploadingFiles}
            onAttach={onAttachFiles}
            files={currentTask.files}
            onDelete={handleDeleteFile}
          />
        </div>
      )}
    </DnDFiles>
  );
};
