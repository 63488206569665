import React from 'react';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import CategorySelect from 'components/patients/medications/common/CategorySelect';
import Medications from 'components/patients/medications/common/medications';
import CreateButton from 'components/patients/medications/create-medications/CreateButton';
import Header from 'components/patients/medications/create-medications/Header';
import MedicationPreview from 'components/patients/medications/create-medications/MedicationPreview';
import { useSetPatientMedications } from 'hooks/usePatients';
import { useFetchMedicationsMeasures, useFetchMedicationsFrequencies } from 'requests/options';

export default function PatientCreateMedications() {
  const fetchMedicationsMeasures = useFetchMedicationsMeasures();
  const fetchMedicationsFrequencies = useFetchMedicationsFrequencies();
  const setDefaultPatientMedications = useSetPatientMedications(() => ({
    medications: [{ icon: 'capsule' }],
  }));
  const clearPatientMedications = useSetPatientMedications(() => null);
  const [validate, setValidate] = React.useState(false);

  React.useEffect(() => {
    fetchMedicationsMeasures();
    fetchMedicationsFrequencies();
    setDefaultPatientMedications();
    return clearPatientMedications;
    // eslint-disable-next-line
  }, [])

  return (
    <RowPlate>
      <Header />
      <PlateContent>
        <CategorySelect validate={validate} />

        <Medications MedicationPreview={MedicationPreview} validate={validate} />

        <CreateButton setValidate={setValidate} />
      </PlateContent>
    </RowPlate>
  );
}
