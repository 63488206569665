import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';

import { useUpdateProgram } from '../../requests/programs';

export default React.memo(function UpdateButton({ isLoading }) {
  const updateProgram = useUpdateProgram();
  return (
    <StyledCustomButton disabled={false} clickHandler={updateProgram} isLoading={isLoading}>
      Update
    </StyledCustomButton>
  );
});

const StyledCustomButton = styled(CustomButton)`
  margin-top: 3.6rem;
`;
