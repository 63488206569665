import React from 'react';

import { MediumText } from 'common/texts';
import AdminNotesLoader from 'components/patients/admin-notes/AdminNotesLoader';
import NewAdminNoteButton from 'components/patients/admin-notes/NewAdminNoteButton';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { ADMIN_NOTES } from 'constants/routes';
import { useSetPatientAdminNote } from 'hooks/usePatients';
import { useFetchAdminNotes } from 'requests/patients';

import { useUserContext } from '../../../context/userContext';
import { permissions } from '../../../permissions';

export default function PatientAdminNotes() {
  const { can } = useUserContext();
  const fetchAdminNotes = useFetchAdminNotes();
  const clearPatientAdminNotes = useSetPatientAdminNote(() => null, []);

  React.useEffect(() => {
    fetchAdminNotes();
    return clearPatientAdminNotes;
  }, []);

  return (
    <DetailsTemplate currentTab={ADMIN_NOTES}>
      <MediumText className="g-mb-10">Admin notes</MediumText>
      <AdminNotesLoader />
      {can(permissions.patient.canAddAdminNotes) && <NewAdminNoteButton />}
    </DetailsTemplate>
  );
}
