import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import React from 'react';

import CustomButton from 'common/CustomButton';
import CustomCheckbox from 'common/CustomCheckbox';
import InputWithLabel from 'common/InputWithLabel';
import { FlexLine } from 'common/plate/styles';
import { RegularText, MediumText } from 'common/texts';
import {
  usePatientInsurance,
  useLocalPatientInsurance,
  useSetLocalPatientInsurance,
} from 'hooks/usePatients';
import { useUpdatePatientInsurance } from 'requests/patients/patients';

export default function Insurance() {
  const patientInsurance = usePatientInsurance();
  const localPatientInsurance = useLocalPatientInsurance();
  const updatePatientInsurance = useUpdatePatientInsurance();

  const withoutInsurance = get(localPatientInsurance, 'no_insurance', false) || false;
  const payedInsurance = get(localPatientInsurance, 'payed', false) || false;

  const primaryGroupId = get(localPatientInsurance, 'insurances.primary.group', '') || '';
  const primaryMemeberId = get(localPatientInsurance, 'insurances.primary.member', '') || '';
  const primaryCompany = get(localPatientInsurance, 'insurances.primary.company', '') || '';

  const secondaryGroupId = get(localPatientInsurance, 'insurances.secondary.group', '') || '';
  const secondaryMemeberId = get(localPatientInsurance, 'insurances.secondary.member', '') || '';
  const secondaryCompany = get(localPatientInsurance, 'insurances.secondary.company', '') || '';

  const updateCondition = React.useMemo(() => {
    if (!patientInsurance || !localPatientInsurance) {
      return false;
    }
    if (isEqual(patientInsurance, localPatientInsurance)) {
      return false;
    }
    return true;
  }, [patientInsurance, localPatientInsurance]);

  return (
    <>
      <CustomCheckbox
        label="Patient don`t have insurance"
        checked={withoutInsurance}
        changeHandler={useSetLocalPatientInsurance((prev, event) => ({
          ...prev,
          no_insurance: event.target.checked,
        }))}
      />
      <MediumText className="g-mt-15">Update your insurance information</MediumText>

      <RegularText className="g-mt-5">
        Current insurance information allows us to properly process lab work or prescriptions
        ordered by your provider.
      </RegularText>

      <MediumText className="g-mt-20">Primary insurance</MediumText>

      <FlexLine>
        <InputWithLabel
          className="g-mt-10 g-mr-30"
          label="Group ID"
          isLoading={!patientInsurance}
          value={primaryGroupId}
          changeHandler={useSetLocalPatientInsurance((prev, group) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.primary.group', group);
            return newPrev;
          })}
          placeholder="Your Group ID"
          disabled={withoutInsurance}
        />
        <InputWithLabel
          className="g-mt-10 g-mr-30"
          label="Memeber ID"
          isLoading={!patientInsurance}
          value={primaryMemeberId}
          changeHandler={useSetLocalPatientInsurance((prev, member) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.primary.member', member);
            return newPrev;
          })}
          placeholder="Your Memeber ID"
          disabled={withoutInsurance}
        />
        <InputWithLabel
          className="g-mt-10 g-mr-15"
          label="Insurance company"
          isLoading={!patientInsurance}
          value={primaryCompany}
          changeHandler={useSetLocalPatientInsurance((prev, company) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.primary.company', company);
            return newPrev;
          })}
          placeholder="Your Insurance Company"
          disabled={withoutInsurance}
        />
      </FlexLine>

      <MediumText className="g-mt-25">Secondary insurance</MediumText>

      <FlexLine>
        <InputWithLabel
          className="g-mt-10 g-mr-30"
          label="Group ID"
          isLoading={!patientInsurance}
          value={secondaryGroupId}
          changeHandler={useSetLocalPatientInsurance((prev, group) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.secondary.group', group);
            return newPrev;
          })}
          placeholder="Your Group ID"
          disabled={withoutInsurance}
        />
        <InputWithLabel
          className="g-mt-10 g-mr-30"
          label="Memeber ID"
          isLoading={!patientInsurance}
          value={secondaryMemeberId}
          changeHandler={useSetLocalPatientInsurance((prev, member) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.secondary.member', member);
            return newPrev;
          })}
          placeholder="Your Memeber ID"
          disabled={withoutInsurance}
        />
        <InputWithLabel
          className="g-mt-10 g-mr-15"
          label="Insurance company"
          isLoading={!patientInsurance}
          value={secondaryCompany}
          changeHandler={useSetLocalPatientInsurance((prev, company) => {
            const newPrev = cloneDeep(prev);
            set(newPrev, 'insurances.secondary.company', company);
            return newPrev;
          })}
          placeholder="Your Insurance Company"
          disabled={withoutInsurance}
        />
      </FlexLine>

      <CustomCheckbox
        className="g-mt-20"
        label="Patient won`t be paying for labs or prescription with insurance"
        checked={payedInsurance}
        changeHandler={useSetLocalPatientInsurance((prev, event) => ({
          ...prev,
          payed: event.target.checked,
        }))}
      />

      <RegularText className="g-mt-20 g-mb-30">
        A note about insurance: You can use your insurance for any prescriptions or lab tests
        ordered by Ciba Health Inc, just as you would at another primary care doctor’s office. We
        don’t take insurance for our membership fee, but if you have a FSA, HSA, or out-of-network
        benefits, it could cover your care at Ciba Health Inc.
      </RegularText>

      <CustomButton
        className="g-mt-auto"
        disabled={!updateCondition}
        clickHandler={updatePatientInsurance}
      >
        Update
      </CustomButton>
    </>
  );
}
