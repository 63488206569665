import moment from 'moment';
import React, { FC } from 'react';

import { ReactComponent as DateIcon } from 'assets/date.svg';
import { ReactComponent as LabsIcon } from 'assets/labs.svg';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { AttachedFile } from '../../AttachedFile';
import { LibraryArticle } from '../../LibraryArticle/LibraryArticle';
import { SectionHeader } from '../../SectionHeader';

type LabsViewProps = {
  tasks?: TaskType<'labs'>[];
  isPreview?: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

export const LabsView: FC<LabsViewProps> = ({ tasks, isPreview, onEdit, onDelete }) => {
  return (
    <div className="shadow-row p-6 border rounded-main mb-6">
      <div className="pb-6">
        <SectionHeader
          title="Labs"
          icon={<LabsIcon />}
          onEdit={onEdit}
          isPreview={isPreview}
          onDelete={onDelete}
        />
      </div>
      {tasks?.map((task, di) => {
        return (
          <React.Fragment key={task.id || 'lab' + di}>
            {di > 0 && <div className="border-t w-full h-px my-6"></div>}
            <div className="">
              <div className="">
                <div className="flex justify-between mb-2">
                  <div className="text-1822 font-ambit text-main font-semibold">
                    {task.details.summary}
                  </div>
                  {task.endDate && (
                    <div className="flex ">
                      <DateIcon className="w-[2rem] h-[2rem]" />
                      <div className="ml-2 text-1420 font-ambit text-main font-semibold">
                        {moment(task.endDate).format('MM.DD.YYYY')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!!task.materials.length && <div className="border-t w-full h-px my-6"></div>}
              <div className="">
                {task.materials?.map((m, mi) => (
                  <LibraryArticle
                    key={m.id || 'lab' + mi}
                    materialId={m.materialVersion.materialId}
                    isPreview={isPreview}
                    title={m.materialVersion.title}
                    category="Labs"
                  />
                ))}
              </div>
              {!!task.files.length && <div className="border-t w-full h-px my-6"></div>}
              <div className="flex">
                {task.files?.map((file, fi) => (
                  <AttachedFile
                    id={file.id}
                    key={file.id || 'file' + fi}
                    className="mr-4"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type={file.fileType as any}
                    filename={file.fileName}
                  />
                ))}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
