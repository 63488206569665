import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomTextarea from 'common/CustomTextarea';
import { MediumText } from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { ADMIN_NOTES } from 'constants/routes';
import { usePatientAdminNote } from 'hooks/usePatients';
import { useFetchPatientAdminNote } from 'requests/patients';

export default function PatientAdminNotePreview() {
  const note = usePatientAdminNote((current) => get(current, 'note'));
  const fetchPatientAdminNote = useFetchPatientAdminNote();

  React.useEffect(() => {
    fetchPatientAdminNote();
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={ADMIN_NOTES}>
      <MediumText className="g-mb-10">Admin notes</MediumText>
      <StyledCustomTextarea value={note} isLoading={!note} readOnly />
    </DetailsTemplate>
  );
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 40rem;
  }
`;
