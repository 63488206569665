import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';

import LinkButton from 'common/LinkButton';
import { useSetQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function AddRow({ metaPath, text = 'Add row' }) {
  const addRowHandler = useSetQuiz(
    (prev) => {
      const currentRowCount = get(prev, `${metaPath}.rowCount`);
      return set(cloneDeep(prev), `${metaPath}.rowCount`, currentRowCount + 1);
    },
    [metaPath],
  );
  return (
    <LinkButton className="g-mt-20" onClick={addRowHandler}>
      {text}
    </LinkButton>
  );
});
