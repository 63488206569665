import React from 'react';
import styled from 'styled-components';

import CustomCheckbox from 'common/CustomCheckbox';
import { globalContext } from 'hooks/useGlobalState';
import request from 'utils/request';

const NotificationsSwitcher = React.memo(function NotificationsSwitcher() {
  const { user } = React.useContext(globalContext);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const providerId = user.value.id;
    request({
      method: 'get',
      url: `/providers/${providerId}/availability-notification`,
    })
      .then(({ data: { data } }) => setChecked(data.availability_notification))
      .catch((error) => console.info(error));
    // eslint-disable-next-line
  }, [])

  const toggleCheckbox = React.useCallback(() => {
    const providerId = user.value.id;
    request({
      method: 'post',
      url: `/providers/${providerId}/availability-notification`,
      data: {
        availability_notification: !checked,
      },
    })
      .then(({ data: { data } }) => setChecked(data.availability_notification))
      .catch((error) => console.info(error));
  }, [user, checked, setChecked]);

  return (
    <StyledCustomCheckbox
      className="g-mt-25"
      label="Receive notifications to fill out availability page"
      checked={checked}
      changeHandler={toggleCheckbox}
    />
  );
});

const StyledCustomCheckbox = styled(CustomCheckbox)`
  display: inline-block;
`;

export default NotificationsSwitcher;
