import React from 'react';
import styled from 'styled-components';

import { FlexLine } from '../../common/plate/styles';
import { BoldBigText } from '../../common/texts';
import getResponsibilityIconComponent from '../../utils/getResponsibilityIconComponent';

export default React.memo(function ProgramVisits({ visits }) {
  const parsedVisits = React.useMemo(() => {
    return visits.map((visit) => {
      const IconComponent = getResponsibilityIconComponent(visit.qualification);
      return (
        <FlexLine
          key={visit.qualification}
          className="flex w-full items-center gap-[1rem] pt-[3rem] pb-[3rem] pr-[10rem] first:border-t-2 border-b-2"
        >
          <IconComponent className="min-w-16" />
          <StyledP>{visit.visit_title}</StyledP>
          <StyledPNum>{visit.available}</StyledPNum>
        </FlexLine>
      );
    });
  });

  return (
    <>
      <BoldBigText className="g-mt-20 g-mb-20 pt-[5rem]">Available Visits</BoldBigText>
      <div>{parsedVisits}</div>
    </>
  );
});

const StyledP = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
`;
const StyledPNum = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
  margin-left: auto;
`;
