import React from 'react';
import { useHistory } from 'react-router-dom';

import LinkButton from 'common/LinkButton';
import { RegularText } from 'common/texts';
import { APPOINTMENTS_ADMIN, RESCHEDULE } from 'constants/routes';

export default function RescheduleButton({ status, id }) {
  const history = useHistory();

  const navigateToReschedule = React.useCallback(() => {
    history.push(`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${id}`);
  }, [history, id]);

  if (status !== 'PLANED') {
    return null;
  }

  return (
    <div className="g-d-flex g-justify-between g-align-center g-mt-20">
      <RegularText>Select another time and date</RegularText>
      <LinkButton className="button" onClick={navigateToReschedule}>
        Reschedule
      </LinkButton>
    </div>
  );
}
