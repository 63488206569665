import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LibraryMaterialType } from 'requests/graphql/patient/queries/getLibraryMaterials';

import { MaterialListItem, MaterialListItemSkeleton } from './MaterialListItem';

type MaterialsTableProps = {
  loading?: boolean;
  hasMore?: boolean;
  itemsPerPage: number;
  notFound?: boolean;
  loadingMore?: boolean;
  canSeeStatus?: boolean;
  materials: LibraryMaterialType[];
  onEdit: (materialId: string) => void;
  loadMore: () => void;
  onView: (materialId: string) => void;
  onDuplicate: (materialId: string) => void;
  onPublish: (materialId: string) => void;
  onUnpublishMaterial: (materialId: string) => void;
  onDelete: (materialId: string) => void;
};

export const MaterialsTable: FC<MaterialsTableProps> = ({
  loading,
  materials,
  canSeeStatus,
  notFound,
  loadMore,
  hasMore,
  loadingMore,
  itemsPerPage,
  onEdit,
  onDuplicate,
  onPublish,
  onDelete,
  onView,
  onUnpublishMaterial,
}) => {
  const deleteMaterial = (materialId: string) => () => onDelete(materialId);
  const editMaterial = (materialId: string) => () => onEdit(materialId);
  const viewMaterial = (materialId: string) => () => onView(materialId);
  const duplicateMaterial = (materialId: string) => () => onDuplicate(materialId);
  const publishMaterial = (materialId: string) => () => onPublish(materialId);
  const unpublishMaterial = (materialId: string) => () => onUnpublishMaterial(materialId);

  return (
    <div className="table mt-2.5">
      <header className="tableheader flex bg-catskillWhite rounded-main h-[4rem] items-center px-5 lg:ml-2 lg:mr-6">
        <div className="col w-[20rem] lg:w-[40%] text-independence font-semibold font-ambit text-1220 lg:text-1420">
          Name
        </div>
        <div className="col w-[5rem] lg:w-[30%] text-independence font-semibold font-ambit text-1220 lg:text-1420">
          Author
        </div>
        <div className="col flex-1 text-independence font-semibold font-ambit text-1220 lg:text-1420">
          Date
        </div>
        {canSeeStatus && (
          <div className="col pl-2 flex-1 text-independence font-semibold font-ambit text-1220 lg:text-1420">
            Status
          </div>
        )}
        <div className="col flex-1 text-independence font-semibold font-ambit text-1220 lg:text-1420 flex justify-end">
          Actions
        </div>
      </header>
      {loading && (
        <div className="lg:mr-4">
          {Array(itemsPerPage)
            .fill(0)
            .map((_, index) => (
              <MaterialListItemSkeleton key={index + 'skeleton'} />
            ))}
        </div>
      )}
      {!(loading || loadingMore) && notFound && (
        <div
          className={
            'flex rounded-main h-[6.2rem] items-center justify-center text-manatee text-1220 lg:text-1622 font-ambit font-semibold px-5 shadow-row py-2 my-2 lg:ml-2 lg:mr-6'
          }
        >
          No materials found that match search criteria
        </div>
      )}

      <InfiniteScroll
        hasMore={!!hasMore}
        dataLength={materials.length}
        className="flex flex-col"
        height={62 * itemsPerPage}
        scrollableTarget="scrollable-materials"
        loader={Array(itemsPerPage)
          .fill(0)
          .map((_, index) => (
            <MaterialListItemSkeleton key={index + 'loader-paginator'} />
          ))}
        next={loadMore}
      >
        {materials.map((material, index) => (
          <MaterialListItem
            key={material.materialId}
            material={material}
            index={index}
            canSeeStatus={canSeeStatus}
            itemsPerPage={itemsPerPage}
            length={materials.length}
            onDelete={deleteMaterial(material.materialId)}
            onEdit={editMaterial(material.materialId)}
            onDuplicate={duplicateMaterial(material.materialId)}
            onPublish={publishMaterial(material.materialId)}
            onUnpublish={unpublishMaterial(material.materialId)}
            onView={viewMaterial(material.materialId)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
