import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { APPOINTMENTS, APPOINTMENT_INFO, MONTH } from 'constants/routes';
import { useScheduledAppointments } from 'hooks/useAppointments';

export default React.memo(function DataCell({ date }) {
  const history = useHistory();
  const scheduledAppointments = useScheduledAppointments();

  const appointments = scheduledAppointments.filter((item) => {
    return (
      moment.utc(item.started_at).local().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
    );
  });

  const parsedAppointments = appointments.map((appointment) => {
    const id = get(appointment, 'id');
    const fullName = get(appointment, 'patient.full_name');
    return (
      <StyledLine
        key={id}
        onClick={() => {
          history.push({
            pathname: `/${APPOINTMENTS}/${APPOINTMENT_INFO}/${id}`,
            state: { referrer: `/${APPOINTMENTS}/${MONTH}` },
          });
        }}
      >
        {fullName}
      </StyledLine>
    );
  });
  return (
    <StyledWrapper>
      <div className="date">
        {date.format('D') === '1' ? date.format('D MMM') : date.format('D')}
      </div>
      {parsedAppointments}
    </StyledWrapper>
  );
});

const StyledLine = styled.div`
  font-size: 1.3rem;
  color: #fff;
  background: #16123f;
  padding: 0.4rem 0.5rem;
  margin-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const StyledWrapper = styled.div`
  width: ${100 / 7}%;
  border-right: solid 1px #f1f5f8;
  border-bottom: dashed 1px #a2c0d4;
  min-height: 10rem;
  .date {
    font-size: 1.3rem;
    text-align: right;
    padding: 0.5rem 1rem 0 0;
  }
`;
