import React from 'react';
import styled from 'styled-components';

export default React.memo(function Bar({ index, height, numCells, color }) {
  return (
    <StyledBar className="bar" index={index} numCells={numCells} height={height} color={color} />
  );
});

const StyledBar = styled.div`
  position: absolute;
  bottom: 0;
  left: ${({ index, numCells }) => (100 / numCells) * index + 100 / numCells / 2}%;
  margin: 0 auto;
  width: 5%;
  height: ${({ height }) => height || 0}%;
  background: ${({ color }) => color};
  transform: translate(-50%, 0);
  transition: all 0.2s;
`;
