import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { initialUserState, setQualificationIds, setSymptoms } from '../profile/qualificationStores';
import { UserQualifications, Qualification } from '../types/qualification';

type ProviderQualificationsState = {
  providerQualifications: UserQualifications | null;
  setProviderQualifications: (q: Partial<UserQualifications> | null) => void;
  setSymptoms: (newSymptom: string) => void;
  setQualificationIds: (qualificationsOptions: Qualification[], value: number) => void;
};

const initialState = {
  providerQualifications: null,
};

export const useProviderQualificationsStore = create<ProviderQualificationsState>()(
  devtools(
    immer((set) => ({
      ...initialState,
      setProviderQualifications: (providerQualifications) =>
        set(
          (state) => {
            state.providerQualifications = {
              ...(state.providerQualifications as UserQualifications),
              ...providerQualifications,
            };
          },
          false,
          'setProviderQualifications',
        ),
      setQualificationIds: (qualificationsOptions, value) => {
        set(
          (state) => {
            state.providerQualifications = state.providerQualifications || initialUserState;
            state.providerQualifications.qualifications = setQualificationIds(
              state.providerQualifications,
              qualificationsOptions,
              value,
            );
          },
          false,
          'setQualificationIds',
        );
      },
      setSymptoms: (newSymptom) => {
        set(
          (state) => {
            state.providerQualifications = state.providerQualifications || initialUserState;
            state.providerQualifications.symptoms = setSymptoms(
              state.providerQualifications.symptoms,
              newSymptom,
            );
          },
          false,
          'setSymptoms',
        );
      },
    })),

    {
      name: 'provider--qualifications-storage',
    },
  ),
);
