import React, { FC, JSX } from 'react';

import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/trash.svg';

type SectionHeaderProps = {
  title: string;
  isPreview?: boolean;
  icon: JSX.Element;
  isEditing?: boolean;
  onEdit?: () => void;
  onDelete: () => void;
};

export const SectionHeader: FC<SectionHeaderProps> = ({
  icon,
  title,
  isEditing,
  isPreview,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="flex justify-between pb-2">
      <div className="flex items-center">
        {icon}
        <div className="text-2020 font-bold font-ambit ml-2">{title}</div>
      </div>
      {!isPreview && (
        <div className="flex">
          {!isEditing && (
            <div
              onClick={onEdit}
              className="cursor-pointer h-6 w-6 rounded-full bg-catskillWhite flex justify-center items-center"
            >
              <EditIcon />
            </div>
          )}
          <div
            onClick={onDelete}
            className="ml-3 cursor-pointer h-6 w-6 rounded-full bg-catskillWhite flex justify-center items-center"
          >
            <TrashIcon className="stroke-manatee" />
          </div>
        </div>
      )}
    </div>
  );
};
