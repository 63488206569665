import moment from 'moment';

export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTERLY = 'quarterly';

export const weekStart = moment().startOf('isoWeek');
export const weekEnd = moment().endOf('isoWeek');

export const monthStart = moment().startOf('month');
export const monthEnd = moment().endOf('month');

export const quarterStart = moment().subtract(1, 'months').startOf('month');
export const quarterEnd = moment().add(1, 'months').endOf('month');

export const progressTabsConfig = [
  {
    id: WEEK,
    label: 'Week',
    from: weekStart.format('YYYY-MM-DD'),
    to: weekEnd.format('YYYY-MM-DD'),
  },
  {
    id: MONTH,
    label: 'Month',
    from: monthStart.format('YYYY-MM-DD'),
    to: monthEnd.format('YYYY-MM-DD'),
  },
  {
    id: QUARTERLY,
    label: 'Quarterly',
    from: quarterStart.format('YYYY-MM-DD'),
    to: quarterEnd.format('YYYY-MM-DD'),
  },
];

export const reductionTabsConfig = [
  {
    id: MONTH,
    label: 'Month',
    from: monthStart.format('YYYY-MM-DD'),
    to: monthEnd.format('YYYY-MM-DD'),
  },
  {
    id: QUARTERLY,
    label: 'Quarterly',
    from: quarterStart.format('YYYY-MM-DD'),
    to: quarterEnd.format('YYYY-MM-DD'),
  },
];
