import React from 'react';
import { useParams } from 'react-router-dom';

import { useFlatMedicationsList } from 'hooks/patient/useMedications';

import MedicationPreview from './MedicationPreview';

export default function MedicationsList() {
  const { medicationId } = useParams();
  const flatMedicationsList = useFlatMedicationsList();

  const parsedMedicationsList = React.useMemo(() => {
    const selectedMedication = flatMedicationsList.find(
      (medication) => +medicationId === +medication.id,
    );
    if (selectedMedication) {
      return <MedicationPreview medication={selectedMedication} />;
    }

    return flatMedicationsList.map((medication, i) => {
      return <MedicationPreview key={i} medication={medication} />;
    });
  }, [medicationId, flatMedicationsList]);

  return <div className="g-mt-15">{parsedMedicationsList}</div>;
}
