import React from 'react';

import { useProviderAppointments } from 'hooks/useProviders';

import AppointmentsList from './AppointmentsList';
import AppointmentsPlaceholder from './AppointmentsPlaceholder';

export default React.memo(function AppointmentsLoader() {
  const providerAppointments = useProviderAppointments();

  if (!providerAppointments) {
    return <AppointmentsPlaceholder />;
  }

  if (providerAppointments && providerAppointments.length === 0) {
    return null;
  }

  return <AppointmentsList />;
});
