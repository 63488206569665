import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import {
  PreFormatting,
  RegularMediumText,
  UppercaseText,
  RegularText,
  BoldText,
  HintText,
} from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { PATIENTS, DETAILS, DOCTOR_NOTES, EDIT, PREVIEW } from 'constants/routes';
import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients';
import { useFetchDoctorNote, useUpdateDoctorNote } from 'requests/patients';

const MedicalRecordNumber = React.memo(function MedicalRecordNumber() {
  const medicalRecord = usePatinetDoctorNote((current) => get(current, 'medicalRecord', ''), []);
  if (!medicalRecord) {
    return null;
  }
  return (
    <>
      <UppercaseText className="g-mt-20">medical record number</UppercaseText>
      <RegularText>{medicalRecord}</RegularText>
    </>
  );
});

const IllnessHistory = React.memo(function IllnessHistory() {
  const illnessHistory = usePatinetDoctorNote((current) => get(current, 'illnessHistory', ''), []);
  if (!illnessHistory) {
    return null;
  }
  return (
    <>
      <RegularMediumText className="g-mt-20">History of Present Illness</RegularMediumText>
      <PreFormatting>
        <RegularText>{illnessHistory}</RegularText>
      </PreFormatting>
    </>
  );
});

const ReviewOfSystems = React.memo(function ReviewOfSystems() {
  const general = usePatinetDoctorNote((current) => get(current, 'general', ''), []);
  const symptoms = usePatinetDoctorNote((current) => get(current, 'symptoms', []), []);

  const parsedGeneral = React.useMemo(() => {
    if (general) {
      return (
        <>
          <UppercaseText className="g-mt-5">General</UppercaseText>
          <RegularText>{general}</RegularText>
        </>
      );
    }
    return null;
  }, [general]);

  const parsedSymptoms = React.useMemo(() => {
    return symptoms.map(({ label, text }, i) => {
      return (
        <div key={i}>
          <BoldText className="g-mt-20">{label}</BoldText>
          <RegularText>{text}</RegularText>
        </div>
      );
    });
  }, [symptoms]);

  if (!parsedGeneral && parsedSymptoms.length === 0) {
    return null;
  }

  return (
    <>
      <RegularMediumText className="g-mt-20">Review of Systems</RegularMediumText>
      {parsedGeneral}
      {parsedSymptoms}
    </>
  );
});

const Assessment = React.memo(function Assessment() {
  const assessment = usePatinetDoctorNote((current) => get(current, 'assessment', ''), []);
  return (
    <>
      <UppercaseText className="g-mt-20">Patient assessment</UppercaseText>
      <PreFormatting>
        <RegularText className="g-mb-45">{assessment}</RegularText>
      </PreFormatting>
    </>
  );
});

export default function PatientEditNotePreview() {
  const { patientId, noteId } = useParams();
  const history = useHistory();
  const draft = usePatinetDoctorNote((current) => get(current, 'draft'), []);

  const fetchDoctorNote = useFetchDoctorNote();
  const updateDoctorNote = useUpdateDoctorNote();

  const isLoadedNote = usePatinetDoctorNote();

  const setCurrentPage = useSetPatinetDoctorNote((prev) => ({ ...prev, draftPage: PREVIEW }), []);

  React.useEffect(() => {
    if (!isLoadedNote) {
      fetchDoctorNote();
    }
    // eslint-disable-next-line
  }, [isLoadedNote])

  React.useEffect(() => {
    setCurrentPage();
    // eslint-disable-next-line
  }, [])

  const toPrevStep = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${noteId}/${EDIT}/3`);
  }, [history, patientId, noteId]);

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>Provider notes {draft && '(Draft)'}</RegularMediumText>

      <MedicalRecordNumber />

      <IllnessHistory />

      <ReviewOfSystems />

      <Assessment />

      <StyledButtonGroup>
        <CustomButton clickHandler={updateDoctorNote}>{draft ? 'Publish' : 'Submit'}</CustomButton>

        <HintText className="prev-button g-ml-30" onClick={toPrevStep}>
          Previous
        </HintText>
      </StyledButtonGroup>
    </DetailsTemplate>
  );
}

const StyledButtonGroup = styled.div`
  margin: auto auto 0 0;
  display: flex;
  align-items: center;
  .prev-button {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
