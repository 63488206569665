import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Arrow } from 'assets/arrow-down.svg';
import LinkButton from 'common/LinkButton';
import { MESSAGES } from 'constants/routes';
import { useChatChannels } from 'hooks/useMessages';
import { useChangeChatName } from 'requests/messages';
import createNotification from 'utils/createNotification';

import { StyledLinePlaceholder } from '../styles';

export default React.memo(function RenameHeader() {
  const { channelSID } = useParams();
  const history = useHistory();
  const { isLoading, channel } = useChatChannels(
    (channels) => {
      if (!channels) {
        return { isLoading: true };
      }
      const currentChannel = channels.find((channel) => channel.sid === channelSID);
      return { channel: currentChannel };
    },
    [channelSID],
  );

  const changeChatName = useChangeChatName(get(channel, 'state.attributes.type'));
  const [name, setName] = React.useState('');

  const backHandler = React.useCallback(
    () => history.push(`/${MESSAGES}/${channelSID}`),
    [history, channelSID],
  );

  const changeNameHandler = React.useCallback(async () => {
    const parsedName = name.trim();
    if (!parsedName) {
      return createNotification({ message: 'Invalid name', type: 'error' });
    }
    const isSuccess = await changeChatName(parsedName);
    if (isSuccess) {
      history.push(`/${MESSAGES}/${channelSID}`);
    }
  }, [name, changeChatName, history, channelSID]);

  if (isLoading) {
    return (
      <StyledWrapper>
        <StyledArrow onClick={backHandler} />
        <StyledLinePlaceholder />
      </StyledWrapper>
    );
  }

  if (!channel) {
    return (
      <StyledWrapper>
        <StyledArrow onClick={backHandler} />
        {/*eslint-disable-next-line react/no-unescaped-entities*/}
        <span className="input-label">Chat doesn't exist or you don't have a permission</span>
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <StyledArrow onClick={backHandler} />
      <span className="input-label">Chat name:</span>
      <input
        className="input"
        placeholder="Enter group chat name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <LinkButton disabled={!name} onClick={changeNameHandler}>
        Rename
      </LinkButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  height: 7rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .input-label {
    white-space: nowrap;
  }
  .input {
    display: block;
    width: 100%;
    margin: 0 0 0 1rem;
    border: none;
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    ::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
    }
  }
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  flex-shrink: 0;
  width: 3rem;
  height: 2.6rem;
  margin-bottom: 1px;
  padding: 1rem;
  margin-left: -1rem;
`;
