import React from 'react';
import { useParams } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default React.memo(function EmailVerification() {
  const { patientId } = useParams();

  const verificationHandler = React.useCallback(() => {
    request({
      method: 'put',
      url: `/admins/patients/${patientId}/verify`,
    })
      .then(() => {
        createNotification({ message: 'Email successfully verified', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [patientId]);

  return (
    <CustomButton className="g-mt-5" clickHandler={verificationHandler}>
      Verify email
    </CustomButton>
  );
});
