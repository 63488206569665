import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import Insurance from 'components/patients/insurance';
import { INSURANCE } from 'constants/routes';
import { useSetPatientInsurance, useSetLocalPatientInsurance } from 'hooks/usePatients';
import { useFetchPatientInsurance } from 'requests/patients/patients';

export default function PatientInsurance() {
  const fetchPatientInsurance = useFetchPatientInsurance();
  const clearPatientInsurance = useSetPatientInsurance(() => null, []);
  const clearLocalPatientInsurance = useSetLocalPatientInsurance(() => null, []);

  React.useEffect(() => {
    fetchPatientInsurance();
    return () => {
      clearPatientInsurance();
      clearLocalPatientInsurance();
    };
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={INSURANCE}>
      <Insurance />
    </DetailsTemplate>
  );
}
