import { Hub } from 'aws-amplify';
import get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Spinner from 'common/loaders/Spinner';
import { DASHBOARD, LOGOUT } from 'constants/routes';
import { useAuth, useSetAuth } from 'hooks/useGlobalState';
import { useFetchProfile } from 'requests/profile';
import { useFetchUser } from 'requests/user';
import useTranslations from 'translations';
import createNotification from 'utils/createNotification';

import LoginForm from './components/LoginForm';
import TwoFAPage from './TwoFAPage';
import { Cognito } from '../../libs/cognito';

const cognito = Cognito.getInstance();

export default React.memo(function LoginPage() {
  const fetchUser = useFetchUser();
  const fetchProfile = useFetchProfile();
  const { login, password } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState({
    newPasswordRequired: null,
    MFARequired: false,
    error: null,
    loading: false,
    logining: false,
    newPassword: null,
    confirmPassword: null,
  });

  const setLogin = useSetAuth((prev, next) => ({ ...prev, login: next }), []);
  const setPassword = useSetAuth((prev, next) => ({ ...prev, password: next }), []);

  const { from } = location.state || { from: { pathname: `/${DASHBOARD}` } };

  const {
    title,
    loginPlaceholder,
    passwordPlaceholder,
    forgotLink,
    submitButton,
    pleaseSetNewPassword = 'Please set a new password',
    pleaseInputMFACode = 'Please input MFA code',
    noConnection = 'no-connection-to-the-server-try-again-later',
  } = useTranslations().login;

  const validationSchema = Yup.object().shape({
    login: Yup.string().required('Login is required').email('Invalid email address'),
    password: Yup.string().required('Password is required'),
  });

  const signIn = async () => {
    try {
      updateState({ error: null });
      await validationSchema.validate({ login, password }, { abortEarly: false });

      updateState({ logining: true });
      const result = await cognito.signInWithEmail(
        login.trim().toLocaleLowerCase(),
        password.trim(),
      );

      // Handle different authentication challenges
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        createNotification({ message: pleaseSetNewPassword, type: 'warning' });
        updateState({ newPasswordRequired: result.challengeParam });
      } else if (result.challengeName === 'SOFTWARE_TOKEN_MFA') {
        createNotification({ message: pleaseInputMFACode, type: 'warning' });
        updateState({ MFARequired: true });
      } else {
        await handleSuccessfulLogin();
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((validationError) => {
          createNotification({ message: validationError.message, type: 'error' });
        });
      } else {
        handleError(error);
      }
    } finally {
      updateState({ logining: false });
    }
  };

  const handleSuccessfulLogin = async () => {
    // Fetch user and profile data
    await cognito.getUser();
    await cognito.getSession();
    try {
      await fetchUser();
      await fetchProfile();
      updateState({ logining: false });
      localStorage.setItem('passwordHasBeenChanged', 0);
      history.push(from);
    } catch (err) {
      updateState({ logining: false });
      console.info(err);
      cognito
        .getSession()
        .then((session) => {
          const exp = get(session, 'idToken.payload.exp');

          if (exp * 1000 > Date.now()) {
            createNotification({
              message: 'The session has expired. Please log in again.',
              type: 'error',
            });
          }
        })
        .finally(() => {
          cognito.signOut();
          history.push(`/${LOGOUT}`);
        });
    }
  };

  const handleError = (error) => {
    if (error.message === 'Network error') {
      return createNotification({ message: noConnection, type: 'error' });
    } else if (error.code === 'NotAuthorizedException') {
      return createNotification({
        message: 'Login failed. Please reset your password or contact us at support@cibahealth.com',
        type: 'error',
      });
    } else if (error.code === 'UserNotConfirmedException') {
      return createNotification({
        message:
          'Your account has not been verified, please verify your email or contact us support@cibahealth.com',
        type: 'error',
      });
    } else {
      return createNotification({
        message: 'Oops, something went wrong!',
        type: 'error',
      });
    }
  };

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const newPasswordClicked = async () => {
    updateState({ error: null });
    if (state.newPassword !== state.confirmPassword) {
      return createNotification({
        message: 'Passwords do not match',
        type: 'error',
      });
    }

    try {
      await cognito.completeNewPassword(state.newPassword);
      history.push(from);
    } catch (err) {
      updateState({ error: err.message });
    }
  };

  const handleSetNewPassword = (newPassword) => {
    updateState({ newPassword });
  };

  const handleSetConfirmPassword = (confirmPassword) => {
    updateState({ confirmPassword });
  };

  //Looks like it's not used
  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event } }) => {
      if (['cognitoHostedUI'].includes(event)) {
        updateState({ loading: true });
        await cognito.getUser();
        await cognito.getSession();
        await fetchUser();
        await fetchProfile();
        updateState({ loading: false });
        history.push(from);
      }
    });
  }, []);
  //Looks like it's not used
  useEffect(() => {
    const searchParam = new URLSearchParams(location.search);
    const code = searchParam.get('code') || '';
    const error = searchParam.get('error_description') || '';
    if (/USER_PASSWORD_FIRST/.test(error)) {
      setTimeout(() => {
        createNotification({
          message: 'To active your account please use your email and password',
          type: 'error',
        });
      }, 0);
    }
    if (/NOT_FOUND/.test(error)) {
      setTimeout(() => {
        createNotification({
          message: 'These credentials do not match our records.',
          type: 'error',
        });
      }, 0);
    }
    if (/Already found an entry for username/.test(error)) {
      cognito.loginWithGoogle();
    }
    if (code) {
      updateState({ loading: true });
    }
  }, [location]);

  if (state.loading) {
    return <Spinner className="loader-container" />;
  }

  if (state.newPasswordRequired) {
    return (
      <LoginForm
        title="New Password Required"
        loginValue={state.newPassword}
        passwordValue={state.confirmPassword}
        onLoginChange={handleSetNewPassword}
        onPasswordChange={handleSetConfirmPassword}
        onSubmit={newPasswordClicked}
        submitButtonLabel="Save"
        loginPlaceholder="New Password"
        passwordPlaceholder="Confirm Password"
      />
    );
  }
  if (state.MFARequired) {
    return <TwoFAPage />;
  }

  return (
    <LoginForm
      title={title}
      loginValue={login}
      passwordValue={password}
      onLoginChange={setLogin}
      onPasswordChange={setPassword}
      loginPlaceholder={loginPlaceholder}
      passwordPlaceholder={passwordPlaceholder}
      onSubmit={signIn}
      disabled={state.logining}
      submitButtonLabel={submitButton}
      forgotLink={forgotLink}
    />
  );
});
