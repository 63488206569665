import get from 'lodash/get';
import React from 'react';

import NotFound from './NotFound';
import SearchItem from './SearchItem';

export default React.memo(function SearchItemsList({ users, setValue, onSelect }) {
  return React.useMemo(() => {
    if (users.length === 0) {
      return <NotFound />;
    }
    return users.map((user) => {
      const id = get(user, 'id', '');
      return <SearchItem key={id} user={user} setValue={setValue} onSelect={onSelect} />;
    });
  }, [users, setValue, onSelect]);
});
