import React from 'react';
import styled from 'styled-components';

import Tab from './Tab';

export default React.memo(function SubTabsSwitcher({
  className = '',
  tabsConfig = [],
  currentTab,
  setCurrentTab,
}) {
  const tabs = React.useMemo(() => {
    return tabsConfig.map((tab) => {
      return <Tab key={tab.id} tab={tab} currentTab={currentTab} setCurrentTab={setCurrentTab} />;
    });
  }, [tabsConfig, currentTab, setCurrentTab]);
  return <StyledWrapper className={className}>{tabs}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  display: flex;
  border-bottom: solid 1px #e9ebef;
`;
