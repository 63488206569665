import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';
import { useMissedFields } from 'hooks/useProfile';

const config = {
  email: 'Email',
  first_name: 'First name',
  last_name: 'Last name',
  phone: 'Phone',
  zip: 'ZIP',
  gender: 'Gender',
  state: 'State',
  city: 'City',
  birth_date: 'Birth date',
  biography: 'BIO',
  summary: 'Short summary',
  experience: 'Years of experience',
  education: 'Education',
  certifications: 'Certifications & Licensure',
  practices: 'Practices & Training',
};

export default React.memo(function Hint() {
  const missedFields = useMissedFields();
  const missedlabels = React.useMemo(() => {
    return missedFields.map((field) => config[field]).join(', ');
  }, [missedFields]);

  if (missedFields.length === 0) {
    return null;
  }

  return (
    <StyledWrapper>
      <RegularText>Please add: {missedlabels}</RegularText>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  margin-bottom: 1.5rem;
  padding: 2rem;
  border-radius: 0.8rem;
  background: #f1f5f8;
`;
