import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Clock } from '../../../assets/clock.svg';
import { ReactComponent as ReadCheck } from '../../../assets/readCheckMark.svg';

export default React.memo(function OwnMessage({
  message,
  lastConsumedMessageIndex,
  lastOpponentMessage,
}) {
  const timestamp = get(message, 'state.timestamp', '');
  const body = get(message, 'body', '');
  return (
    !message.state.attributes.notification && (
      <StyledOwnWrapper>
        <div className="time g-mb-5">{moment.utc(timestamp).local().format('h:mm A')}</div>
        <div className={'body ' + (message.index <= lastOpponentMessage ? 'readStatus' : '')}>
          {body}
          <div className="status">
            {message.index <= lastConsumedMessageIndex ? (
              <>
                <ReadCheck />
                <ReadCheck />
              </>
            ) : (
              <Clock />
            )}
          </div>
        </div>
      </StyledOwnWrapper>
    )
  );
});

const StyledOwnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  margin-left: auto;
  max-width: 90%;
  .time {
    text-align: right;
    opacity: 0.3;
  }
  .body {
    display: inline-block;
    margin-left: auto;
    padding: 0.8rem;
    word-break: break-word;
    border-radius: 0.8rem;
    background: #deeeff;
    .status {
      display: inline-block;
      margin-left: 5px;
      margin-right: 6px;
      justify-content: center;
      align-items: center;
      margin-bottom: 3px;
    }
    svg {
      display: inline-block;
      margin-right: -3px;
    }
  }
  .readStatus {
    svg path {
      stroke: green;
    }
  }
`;
