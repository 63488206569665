import get from 'lodash/get';
import React from 'react';

import { useQuiz, useElementsResultMapper } from 'hooks/patient/useQuestionnaires';

import ScaleResults from './ScaleResults';

export default function JsonFormResults() {
  const parsedElements = useElementsResultMapper();
  const withResults = useQuiz((current) => get(current, 'content.withResults'));
  return (
    <>
      {parsedElements}
      {withResults && <ScaleResults />}
    </>
  );
}
