import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomSelect from 'common/CustomSelect';
import InputWithLabel from 'common/InputWithLabel';
import { FlexLine } from 'common/plate/styles';
import { RegularBigText } from 'common/texts';
import { useIsPatientProfileLoading, VALIDATION_RULES } from 'hooks/patient/useProfile';
import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients';
import { useGenders } from 'hooks/useProfile';

import BirthDate from './BirthDate';

export default React.memo(function PersonalInformation() {
  const isLoading = useIsPatientProfileLoading();
  const localPatientProfile = useLocalPatientProfile();
  const genders = useGenders() || [];
  const firstName = get(localPatientProfile, 'first_name', '');
  const lastName = get(localPatientProfile, 'last_name', '');
  const gender = get(localPatientProfile, 'gender', '');

  const isFirstNameValid = VALIDATION_RULES['first_name'](firstName);
  const isLastNameValid = VALIDATION_RULES['last_name'](lastName);
  const isGenderValid = VALIDATION_RULES['gender'](gender);

  return (
    <StyledWrapper>
      <RegularBigText className="g-mt-10">Personal information</RegularBigText>
      <FlexLine>
        <InputWithLabel
          className="g-mt-20 g-mr-25"
          label="First name"
          placeholder="Your First name"
          isRequred
          isValid={isFirstNameValid}
          value={firstName}
          changeHandler={useSetLocalPatientProfile(
            (prev, firstName) => ({ ...prev, first_name: firstName }),
            [],
          )}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="g-mt-20"
          label="Last name"
          placeholder="Your Last name"
          isRequred
          isValid={isLastNameValid}
          value={lastName}
          changeHandler={useSetLocalPatientProfile(
            (prev, lastName) => ({ ...prev, last_name: lastName }),
            [],
          )}
          isLoading={isLoading}
        />
      </FlexLine>
      <FlexLine>
        <CustomSelect
          className="g-mt-20 g-mr-25"
          label="Gender"
          placeholder="Your gender"
          isRequred
          isValid={isGenderValid}
          value={gender}
          options={genders}
          changeHandler={useSetLocalPatientProfile((prev, gender) => ({ ...prev, gender }), [])}
          isLoading={isLoading}
        />
        <BirthDate isLoading={isLoading} />
      </FlexLine>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .birthday {
    display: flex;
  }
  .year {
    width: 130px;
    margin-right: 1rem;
  }
  .day {
    width: 85px;
    margin-right: 1rem;
    align-self: flex-end;
  }
  .month {
    width: 100px;
    align-self: flex-end;
  }
`;
