import React from 'react';
import styled from 'styled-components';

import { UppercaseText, RegularMediumText } from 'common/texts';

import DonutChart from './DonutChart';

export default function SleepPreview({
  widgetId,
  sleepEfficiency = 0,
  totalSleepTime,
  totalBedTime,
  restingHeartRate,
}) {
  return (
    <StyledWrapper className="g-d-flex g-align-center g-w-100 g-mt-30">
      <div className="efficiency g-w-50 g-text-align-center">
        <DonutChart className="efficiency-chart" widgetId={widgetId} value={sleepEfficiency} />
        <div className="efficiency-value">
          <p>{sleepEfficiency ? `${sleepEfficiency}%` : '0'}</p>
          <p>Efficiency</p>
        </div>
      </div>
      <div className="g-d-flex g-fw-w g-w-50">
        <div className="g-d-flex g-align-center g-w-100">
          <UppercaseText>Total sleep time:</UppercaseText>
          {totalSleepTime && (
            <RegularMediumText className="g-ml-10">{totalSleepTime}</RegularMediumText>
          )}
        </div>
        <div className="g-d-flex g-align-center g-w-100 g-mt-5">
          <UppercaseText>Total Bed time:</UppercaseText>
          {totalBedTime && (
            <RegularMediumText className="g-ml-10">{totalBedTime}</RegularMediumText>
          )}
        </div>
        <div className="g-d-flex g-align-center g-w-100 g-mt-5">
          <UppercaseText>Resting heart rate:</UppercaseText>
          {restingHeartRate && (
            <RegularMediumText className="g-ml-10">{restingHeartRate}</RegularMediumText>
          )}
        </div>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .efficiency {
    position: relative;
  }
  .efficiency-chart {
    max-width: 16rem;
    margin: 0 auto;
  }
  .efficiency-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p:first-child {
      font-size: 2rem;
      line-height: 1.2;
    }
    p:last-child {
      text-transform: uppercase;
    }
  }
`;
