import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SubTabsSwitcher from 'common/sub-tabs-switcher';
import {
  PRE_ASSESSMENT,
  PATIENT_MEDICAL_HISTORY,
  MENTAL_HEALTH,
  PATIENT_FORMS,
  DIGESTIVE_HEALTH,
} from 'constants/quiz-types';
import { PATIENTS, DETAILS, QUESTIONNAIRES } from 'constants/routes';
import { useQuizzesData } from 'hooks/patient/useQuestionnaires';

const order = [
  PATIENT_FORMS,
  PRE_ASSESSMENT,
  PATIENT_MEDICAL_HISTORY,
  MENTAL_HEALTH,
  DIGESTIVE_HEALTH,
];

export default React.memo(function NavigationTabs() {
  const { patientId, type } = useParams();
  const history = useHistory();
  const tabsConfig = useQuizzesData((current) => {
    if (!current) {
      return null;
    }
    return order
      .filter((key) => {
        const data = current[key];
        return data && data.count > 0;
      })
      .map((key) => {
        const data = current[key];
        const { label, count, done } = data;
        return {
          id: key,
          label,
          count,
          done,
        };
      });
  });

  const setCurrentTab = React.useCallback(
    (type) => {
      history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${type}`);
    },
    [history, patientId],
  );

  if (!tabsConfig) {
    return <StyledPlaceholder />;
  }

  return (
    <SubTabsSwitcher tabsConfig={tabsConfig} currentTab={type} setCurrentTab={setCurrentTab} />
  );
});

const StyledPlaceholder = styled.div`
  width: 55%;
  height: 3.9rem;
  background: #e9ebef;
  border-radius: 4px;
`;
