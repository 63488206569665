import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import DataCell from './DataCell';

export default React.memo(function MonthGrid({ currentMonth }) {
  const content = React.useMemo(() => {
    const initialDate = moment().month(currentMonth);
    const firstMonthDay = initialDate.clone().startOf('month');
    const extraDaysBefore = firstMonthDay.day();
    const firstGridDay = firstMonthDay.clone().add(-extraDaysBefore, 'days');
    const daysInMonth = initialDate.daysInMonth();
    const rowsCount = Math.ceil((extraDaysBefore + daysInMonth) / 7);
    const cellsCount = rowsCount * 7;

    return new Array(cellsCount).fill(null).map((item, i) => {
      return <DataCell key={i} date={firstGridDay.clone().add(i, 'days')} />;
    });
  }, [currentMonth]);

  return <StyledWrapper className="g-mb-30">{content}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-left: solid 1px #f1f5f8;
  max-height: 53rem;
  overflow-y: auto;
`;
