import React from 'react';

import { usePatientMedicationsList, useSetPatientMedicationsList } from 'hooks/usePatients';
import { useFetchPatientMedicationsList } from 'requests/patients/medication';

import EmptyMedicationsList from './EmptyMedicationsList';
import MedicationsList from './MedicationsList';
import MedicationsListPlaceholder from './MedicationsListPlaceholder';

export default function MedicationsListLoader() {
  const fetchPatientMedicationsList = useFetchPatientMedicationsList();
  const patientMedicationsList = usePatientMedicationsList();
  const clearPatientMedicationsList = useSetPatientMedicationsList(() => null);

  React.useEffect(() => {
    fetchPatientMedicationsList();
    return clearPatientMedicationsList;
    // eslint-disable-next-line
  }, [])

  if (!patientMedicationsList) {
    return <MedicationsListPlaceholder />;
  }

  if (patientMedicationsList.length === 0) {
    return <EmptyMedicationsList />;
  }

  return <MedicationsList />;
}
