import React from 'react';
import styled from 'styled-components';

import { HintText, RegularText } from 'common/texts';

export default React.memo(function PropertyValue({
  className = '',
  isLoading,
  property = '',
  value = '',
  actionText,
  onClick = () => {},
}) {
  if (isLoading) {
    return (
      <StyledWrapper className={className}>
        <div className="loading-left" />
        <div className="loading-right" />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper className={className}>
      <HintText className="left-side">{property}</HintText>
      <RegularText className="g-d-flex">
        {value || 'n/a'}
        {actionText && (
          <HintText className="action g-ml-10" onClick={onClick}>
            {actionText}
          </HintText>
        )}
      </RegularText>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  .left-side {
    width: 17.5rem;
  }
  .loading-left {
    width: 10rem;
    margin: 0.4rem 7.5rem 0.4rem 0;
    height: 1.6rem;
    background: #e9ebef;
  }
  .loading-right {
    width: 10rem;
    height: 1.6rem;
    background: #e9ebef;
  }
  .action {
    text-decoration: underline;
    cursor: pointer;
  }
`;
