import React from 'react';
import styled from 'styled-components';

import CalendarHeader from '../CalendarHeader';

export default React.memo(function WeekHeader({ weekStart, setWeekStart }) {
  const content = React.useMemo(() => {
    return new Array(7).fill(null).map((item, i) => {
      const currentDay = weekStart.clone().add(i, 'days');
      return (
        <div key={i} className="day-container">
          <div className="day">{currentDay.format('DD')}</div>
          <div className="week-day">{currentDay.format('ddd')}</div>
        </div>
      );
    });
  }, [weekStart]);
  return (
    <CalendarHeader
      onPrev={React.useCallback(
        () => setWeekStart(weekStart.clone().add(-7, 'days')),
        [weekStart, setWeekStart],
      )}
      onNext={React.useCallback(
        () => setWeekStart(weekStart.clone().add(7, 'days')),
        [weekStart, setWeekStart],
      )}
    >
      <StyledWrapper>{content}</StyledWrapper>
    </CalendarHeader>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  margin: 0 7rem;
  width: 100%;
  .day-container {
    width: ${100 / 7}%;
    text-align: center;
    .day {
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 600;
    }
    .week-day {
      font-size: 1.3rem;
      line-height: 1.5rem;
    }
  }
`;
