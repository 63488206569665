import React, { FC } from 'react';

import { useSwitch } from 'common/Switch';
import { TaskTypeName } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { treatmentPlanTabItems, Tabs } from './Tabs';
import { TasksSticks } from '../TasksSticks';

type SidebarProps = {
  loading?: boolean;
  onSelectTaskItem: (val: TaskTypeName) => void;
};

export const Sidebar: FC<SidebarProps> = ({ onSelectTaskItem, loading }) => {
  const [activePlanTab, setActivePlanTab] = useSwitch({
    items: treatmentPlanTabItems,
    defaultItem: treatmentPlanTabItems[1],
  });
  return (
    <div className="pl-6">
      <div className="w-full mb-6">
        <Tabs
          loading={loading}
          items={treatmentPlanTabItems}
          setActiveItem={setActivePlanTab}
          activeItem={activePlanTab}
        />
      </div>
      {loading && <div className="animate-pulse rounded-main bg-catskillWhite w-full h-[40rem]" />}
      {!loading && activePlanTab?.value === 'tasks' && (
        <TasksSticks onSelectTaskItem={onSelectTaskItem} />
      )}
    </div>
  );
};
