import React from 'react';

import { RegularMediumText, SmallText } from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import LabsLoader from 'components/patients/labs/LabsLoader';
import UploadButton from 'components/patients/labs/UploadButton';
import { LABS } from 'constants/routes';

export default function PatientLabs() {
  return (
    <DetailsTemplate currentTab={LABS}>
      <RegularMediumText>Login to labolatories</RegularMediumText>

      <SmallText className="g-mt-10">
        <a
          href="https://www.evexiadiagnostics.com/Account/Logon"
          rel="noopener noreferrer"
          target="_blank"
        >
          Evexia diagnostics
        </a>
      </SmallText>
      <SmallText>
        <a
          href="https://www.vibrant-america.com/secure/login.jsp"
          rel="noopener noreferrer"
          target="_blank"
        >
          Vibrant-America
        </a>
      </SmallText>
      <SmallText>
        <a href="https://www.rupahealth.com" rel="noopener noreferrer" target="_blank">
          Rupa Health
        </a>
      </SmallText>
      <SmallText>
        <a href="http://fullscript.com" rel="noopener noreferrer" target="_blank">
          Fullscript
        </a>
      </SmallText>
      <SmallText>
        <a href="https://www.wellsrx.com" rel="noopener noreferrer" target="_blank">
          Wells Pharmacy
        </a>
      </SmallText>

      <LabsLoader />

      <UploadButton />
    </DetailsTemplate>
  );
}
