import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';

import InputWithLabel from 'common/InputWithLabel';
import { RegularMediumText } from 'common/texts';
import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires';

const RowElement = React.memo(function RowElement({ className = '', rootName, name, title }) {
  const value = useQuiz((current) => get(current, `content.answers.${rootName}.${name}`, ''));
  const answerHandler = useSetQuiz(
    (prev, value) => {
      return set(cloneDeep(prev), `content.answers.${rootName}.${name}`, value);
    },
    [rootName, name],
  );
  return (
    <InputWithLabel
      className={className}
      label={title}
      value={value}
      changeHandler={answerHandler}
    />
  );
});

export default React.memo(function MultipleText({ metaPath }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const rootName = useQuiz((current) => get(current, `${metaPath}.name`, ''), [metaPath]);
  const items = useQuiz((current) => get(current, `${metaPath}.items`, []), [metaPath]);
  const colCount = useQuiz((current) => get(current, `${metaPath}.colCount`, 1), [metaPath]);

  const parsedItems = React.useMemo(() => {
    const groupedItems = items.reduce(
      (a, c) => {
        if (a[a.length - 1].length === +colCount) {
          a.push([]);
        }
        a[a.length - 1].push(c);
        return a;
      },
      [[]],
    );

    return groupedItems.map((arr, i) => {
      const currentItems = arr.map(({ name, title }, j) => {
        return (
          <RowElement
            key={name}
            className={j ? 'g-ml-30' : ''}
            rootName={rootName}
            name={name}
            title={title}
          />
        );
      });
      return (
        <div key={i} className="g-mt-20 g-d-flex">
          {currentItems}
        </div>
      );
    });
  }, [rootName, items, colCount]);

  return (
    <div className="g-mt-20">
      <RegularMediumText>{title}</RegularMediumText>

      {parsedItems}
    </div>
  );
});
