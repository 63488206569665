import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState';

export const usePatientFullName = createUseGlobalState('patientFullName');
export const useSetPatientFullName = createUseSetGlobalState('patientFullName');

export const usePatinets = createUseGlobalState('patinets');
export const useSetPatinets = createUseSetGlobalState('patinets');

export const usePatinetsPagination = createUseGlobalState('patinetsPagination');
export const useSetPatinetsPagination = createUseSetGlobalState('patinetsPagination');

export const usePatinetsFilter = createUseGlobalState('patinetsFilter');
export const useSetPatinetsFilter = createUseSetGlobalState('patinetsFilter');

export const usePatientOverview = createUseGlobalState('patientOverview');
export const useSetPatientOverview = createUseSetGlobalState('patientOverview');

export const usePatinetSummary = createUseGlobalState('patinetSummary');
export const useSetPatinetSummary = createUseSetGlobalState('patinetSummary');

export const usePatientTestState = createUseGlobalState('patientTestState');
export const useSetPatientTestState = createUseSetGlobalState('patientTestState');

export const usePatinetProfile = createUseGlobalState('patinetProfile');
export const useSetPatinetProfile = createUseSetGlobalState('patinetProfile');

export const useLocalPatientProfile = createUseGlobalState('localPatientProfile');
export const useSetLocalPatientProfile = createUseSetGlobalState('localPatientProfile');

export const usePatinetDoctorNotes = createUseGlobalState('patientDoctorNotes');
export const useSetPatinetDoctorNotes = createUseSetGlobalState('patientDoctorNotes');

export const usePatinetDoctorNote = createUseGlobalState('patientDoctorNote');
export const useSetPatinetDoctorNote = createUseSetGlobalState('patientDoctorNote');

export const usePatientSymptomsList = createUseGlobalState('patientSymptomsList');
export const useSetPatientSymptomsList = createUseSetGlobalState('patientSymptomsList');

export const usePatientHealthPlans = createUseGlobalState('patientHealthPlans');
export const useSetPatientHealthPlans = createUseSetGlobalState('patientHealthPlans');

export const usePatientHealthPlan = createUseGlobalState('patientHealthPlan');
export const useSetPatientHealthPlan = createUseSetGlobalState('patientHealthPlan');

export const usePatientLabs = createUseGlobalState('patientLabs');
export const useSetPatientLabs = createUseSetGlobalState('patientLabs');

export const usePatientDocuments = createUseGlobalState('patientDocuments');
export const useSetPatientDocuments = createUseSetGlobalState('patientDocuments');

export const usePatientAdminNotes = createUseGlobalState('patientAdminNotes');
export const useSetPatientAdminNotes = createUseSetGlobalState('patientAdminNotes');

export const usePatientAdminNote = createUseGlobalState('patientAdminNote');
export const useSetPatientAdminNote = createUseSetGlobalState('patientAdminNote');
export const usePatientInsurance = createUseGlobalState('patientInsurance');
export const useSetPatientInsurance = createUseSetGlobalState('patientInsurance');

export const useLocalPatientInsurance = createUseGlobalState('localPatientInsurance');
export const useSetLocalPatientInsurance = createUseSetGlobalState('localPatientInsurance');

export const usePatientMedicationsList = createUseGlobalState('patientMedicationsList');
export const useSetPatientMedicationsList = createUseSetGlobalState('patientMedicationsList');

export const usePatientMedications = createUseGlobalState('patientMedications');
export const useSetPatientMedications = createUseSetGlobalState('patientMedications');

export function useSetMedicationProperty({ index, field }) {
  return useSetPatientMedications(
    (prev, next) => {
      const newPrev = cloneDeep(prev);
      set(newPrev, `medications[${index}][${field}]`, next);
      return newPrev;
    },
    [index, field],
  );
}

export function useAddMedicationRow() {
  return useSetPatientMedications((prev) => {
    const newPrev = cloneDeep(prev);
    newPrev.medications.push({ icon: 'capsule' });
    return newPrev;
  });
}

export function useDeleteMedicationRow(index) {
  return useSetPatientMedications(
    (prev) => {
      const newPrev = cloneDeep(prev);
      newPrev.medications = newPrev.medications.filter((item, i) => i !== index);
      return newPrev;
    },
    [index],
  );
}

export function useIsMedicationValid() {
  return usePatientMedications((current) => {
    if (!current) {
      return false;
    }
    if (!current.category_id) {
      return false;
    }
    return !current.medications.find((medication) => {
      if (!medication.medication) {
        return true;
      }
      if (!medication.dosage) {
        return true;
      }
      if (!medication.measure_id) {
        return true;
      }
      if (!medication.frequency_id) {
        return true;
      }
      if (!medication.started_at) {
        return true;
      }
      if (!medication.ended_at) {
        return true;
      }
      if (+new Date(medication.ended_at) < +new Date(medication.started_at)) {
        return true;
      }
      return false;
    });
  });
}
