import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import InputWithLabel from 'common/InputWithLabel';
import { RegularMediumText, RegularText } from 'common/texts';
import { Cognito } from 'libs/cognito';
import { useFetchUser } from 'requests/user';
import createNotification from 'utils/createNotification';

const cognito = Cognito.getInstance();

export default React.memo(function ConfirmRemoving() {
  const fetchUser = useFetchUser();
  const [code, setCode] = React.useState('');

  const handleSubmit = React.useCallback(() => {
    cognito
      .disableMultiFactorAuthOneTimePassword()
      .then(() => {
        createNotification({
          message: 'Two-step authentication disabled. Please Login again.',
          type: 'success',
        });
      })
      .catch((error) => console.info(error));
  }, [code, fetchUser]);

  return (
    <>
      <RegularMediumText className="g-mt-25">
        You are going to turn off two-step authentication
      </RegularMediumText>
      <RegularText>We do not recommend doing this.</RegularText>

      <RegularText className="g-mt-25">Enter the verification code from your device</RegularText>
      <InputWithLabel
        placeholder="Verification Code (6 digits)"
        value={code}
        changeHandler={setCode}
      />

      <StyledCustomButton
        className="g-mt-15"
        disabled={code.length !== 6}
        clickHandler={handleSubmit}
      >
        Turn off
      </StyledCustomButton>
    </>
  );
});

const StyledCustomButton = styled(CustomButton)`
  margin: auto auto 0 0;
`;
