import React from 'react';
import styled from 'styled-components';

import onlyPositive from 'utils/onlyPositive';
import toHM from 'utils/toHM';

import StatsItem from '../../common/StatsItem';

export default function SleepStats({ data }) {
  const sleepTime = toHM(onlyPositive(data.total));
  const bedTime = toHM(onlyPositive(data.duration));
  const sleepEfficiency = onlyPositive(data.efficiency);
  const averageHR = Math.round(onlyPositive(data.hr_average));

  return (
    <StyledWrapper className="g-d-flex g-fw-w g-justify-between g-mb-30">
      <StatsItem
        className="col g-d-flex g-fw-w g-align-center"
        statsTitle="Total sleep time:"
        statsData={sleepTime}
      />
      <StatsItem
        className="col g-d-flex g-fw-w g-align-center"
        statsTitle="Total bed time:"
        statsData={bedTime}
      />
      <StatsItem
        className="col g-d-flex g-fw-w g-align-center"
        statsTitle="Resting heart rate:"
        statsData={`${averageHR} bpm`}
      />
      <StatsItem
        className="col g-d-flex g-fw-w g-align-center"
        statsTitle="Sleep efficiency:"
        statsData={`${sleepEfficiency}%`}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .col {
    max-width: 24.9%;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 49%;
    }
    @media screen and (max-width: 480px) {
      display: block;
    }
  }
`;
