import React from 'react';

import { useLogs } from 'hooks/useLogs';

import EmptyLogs from './EmptyLogs';
import LogsList from './LogsList';
import LogsPlaceholder from './LogsPlaceholder';

export default function LogsLoader() {
  const logs = useLogs();

  if (!logs) {
    return <LogsPlaceholder />;
  }

  if (logs.length === 0) {
    return <EmptyLogs />;
  }

  return (
    <div className="g-mt-10">
      <LogsList />
    </div>
  );
}
