import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import CustomSelect from 'common/CustomSelect';
import { PATIENTS, DETAILS, MEDICATIONS, PROGRESS } from 'constants/routes';
import { useFlatMedicationsList } from 'hooks/patient/useMedications';

export default React.memo(function MedicationSelect() {
  const { patientId, medicationId } = useParams();
  const history = useHistory();
  const flatMedicationsList = useFlatMedicationsList();

  const options = React.useMemo(() => {
    if (!flatMedicationsList) {
      return null;
    }
    return [
      { value: 'all', label: 'View all' },
      ...flatMedicationsList.map(({ id, medication }) => ({
        value: id.toString(),
        label: medication,
      })),
    ];
  }, [flatMedicationsList]);

  React.useEffect(() => {
    if (options) {
      const option = options.find(({ value }) => +value === +medicationId);
      if (!option) {
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${PROGRESS}/all`);
      }
    }
  }, [options, medicationId, history, patientId]);

  const handleSelect = React.useCallback(
    (id) => {
      history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${PROGRESS}/${id}`);
    },
    [history, patientId],
  );

  return (
    <CustomSelect
      className="g-mt-15"
      label="Medication"
      isLoading={!options}
      options={options}
      value={medicationId}
      changeHandler={handleSelect}
    />
  );
});
