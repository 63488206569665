import _ from 'lodash';

import { $ } from 'zeus-graphql/participant/zeus';

import { GQLQuery } from './client';
import { GET_PARTICIPANTS } from './constants';

export const getParticipants = async (ids = []) => {
  const result = await GQLQuery(
    {
      getParticipants: [
        {
          page: 1,
          perPage: 100,
          filters: {
            ids: $`ids`,
          },
        },
        {
          items: {
            chatIdentity: true,
            id: true,
            firstName: true,
            lastName: true,
          },
        },
      ],
    },
    {
      operationName: GET_PARTICIPANTS,
      variables: {
        ids,
      },
    },
  );

  return _.get(result, 'data.data.getParticipants.items', []);
};
