import React from 'react';

import CustomButton from 'common/CustomButton';
import { useIsMedicationValid } from 'hooks/usePatients';
import { useCreatePatientMedications } from 'requests/patients/medication';

export default function CreateButton({ setValidate }) {
  const isMedicationValid = useIsMedicationValid();
  const createPatientMedications = useCreatePatientMedications();
  const changeHandler = React.useCallback(() => {
    setValidate(true);
    if (isMedicationValid) {
      createPatientMedications();
    }
  }, [isMedicationValid, setValidate, createPatientMedications]);

  return (
    <CustomButton className="g-mt-auto" clickHandler={changeHandler}>
      Create
    </CustomButton>
  );
}
