import React from 'react';

import { PlateContent } from 'common/plate/styles';

import { WideRowPlate } from '../../common/plate/plates';
import ProgramsHeader from '../../components/programs/programs/ProgramsHeader';
import ProgramsLoader from '../../components/programs/programs-loader';

export default function Programs() {
  return (
    <WideRowPlate>
      <ProgramsHeader />
      <PlateContent>
        <ProgramsLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
