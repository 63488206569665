import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { RegularText } from 'common/texts';
import { ENVIRONMENT_CHECK, CAMERA } from 'constants/routes';

export default function Introduction() {
  const history = useHistory();

  const navigateToCameraCheck = React.useCallback(() => {
    history.push(`/${ENVIRONMENT_CHECK}/${CAMERA}`);
  }, [history]);

  return (
    <WideRowPlate>
      <PlateHeader>Video session test</PlateHeader>

      <PlateContent>
        <RegularText>Please click the button below to start the Video Session Test.</RegularText>
        <RegularText>
          Your browser may ask you to grant this site access to your camera and microphone.
        </RegularText>

        <CustomButton className="g-mt-20" clickHandler={navigateToCameraCheck}>
          Start video test
        </CustomButton>
      </PlateContent>
    </WideRowPlate>
  );
}
