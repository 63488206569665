import get from 'lodash/get';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ADMIN, GROUP, PRIVATE } from 'constants/messages';
import { MESSAGES } from 'constants/routes';

import AdminItem from './AdminItem';
import GroupItem from './GroupItem';
import PrivateItem from './PrivateItem';

const itemsConfig = {
  [ADMIN]: AdminItem,
  [GROUP]: GroupItem,
  [PRIVATE]: PrivateItem,
};

export default React.memo(function ChatListItem({ isActive, channel }) {
  const history = useHistory();
  const sid = get(channel, 'sid');
  const type = get(channel, 'state.attributes.type');
  const [newMessagesCount, setNewMessagesCount] = React.useState(
    get(channel, 'lastMessage.index', 0) - get(channel, 'lastConsumedMessageIndex', 0),
  );

  React.useEffect(() => {
    function countHandler() {
      const index = get(channel, 'lastMessage.index');
      const consumedIndex = get(channel, 'lastConsumedMessageIndex');
      let nextMessagesCount = 0;
      if (index !== undefined && consumedIndex === null) {
        nextMessagesCount = index + 1;
      } else {
        nextMessagesCount = (index || 0) - (consumedIndex || 0);
      }
      if (isActive) {
        return setNewMessagesCount(0);
      }
      if (nextMessagesCount < 0) {
        return setNewMessagesCount(0);
      }
      setNewMessagesCount(nextMessagesCount);
    }
    countHandler();
    channel.channelClass.on('messageAdded', countHandler);

    return () => {
      channel.channelClass.removeListener('messageAdded', countHandler);
    };
  }, [channel, isActive]);

  const navigateToChat = React.useCallback(() => {
    history.push(`/${MESSAGES}/${sid}`);
  }, [history, sid]);

  const parsedComponent = React.useMemo(() => {
    const Component = itemsConfig[type];
    if (!Component) {
      console.warn(`Channel: ${sid} with type: ${type} not implemented.`);
      return null;
    }
    return (
      <Component
        channel={channel}
        isActive={isActive}
        newMessagesCount={newMessagesCount}
        navigateToChat={navigateToChat}
      />
    );
  }, [sid, type, channel, isActive, newMessagesCount, navigateToChat]);

  return parsedComponent;
});
