import moment from 'moment-timezone';
import React from 'react';
import tz from 'timezones-list';

import { HintText } from 'common/texts';

import { getTzAbbr } from '../../../../utils/tz';

export default React.memo(function TimeZone({ iana = '' }) {
  const zone = tz.find((z) => z.tzCode === iana);

  if (!iana || !zone) {
    return (
      <div>
        <HintText>
          Your timezone is{' '}
          <span style={{ color: 'black' }}>
            {getTzAbbr(new Date())} {moment().format(' ([GMT]ZZ)')}
          </span>
        </HintText>
      </div>
    );
  }
  return (
    <div>
      <HintText>
        Your timezone is{' '}
        <span style={{ color: 'black' }}>
          {moment.tz.zone(zone.tzCode).abbr(new Date().valueOf())}
          {moment().tz(zone.tzCode).format(' ([GMT]ZZ)')}
        </span>
      </HintText>
      {!zone.label.includes(moment().format('Z')) && (
        <div>
          <b style={{ color: 'red' }}>WARNING</b>{' '}
          <span style={{ color: 'red' }}>
            Your computer timezone is detected as different - {getTzAbbr(new Date())}{' '}
            {moment().format(' ([GMT]ZZ)')}
          </span>
        </div>
      )}
    </div>
  );
});
