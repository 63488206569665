import React from 'react';
import styled from 'styled-components';

import CustomCheckbox from 'common/CustomCheckbox';
import { RegularText } from 'common/texts';

import { TimeRanges, defaultRange } from './TimeRanges';

export const DayAvailability = ({ label, ranges, onRangesChange, onHasError }) => {
  const checked = ranges && ranges.length > 0;

  const toggle = React.useCallback(() => {
    onRangesChange && onRangesChange(checked ? [] : [defaultRange]);
  }, [onRangesChange, checked]);

  return (
    <StyledDayAvailability>
      <CustomCheckbox checked={checked} label={label} changeHandler={toggle} />
      {checked && (
        <TimeRanges ranges={ranges} onRangesChange={onRangesChange} onHasError={onHasError} />
      )}
      {!checked && <RegularText>Day off</RegularText>}
    </StyledDayAvailability>
  );
};

const StyledDayAvailability = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .day-section {
    display: flex;
    .day-name {
      font-size: 13px;
      line-height: 120%;
    }
  }
`;
