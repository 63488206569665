import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { GOOGLE_CALENDAR_CLIENT_ID, SENTRY } from 'env';

import './index.css';

import './datepicker.css';

const isSentry = SENTRY;

if (isSentry === 'production') {
  Sentry.init({
    dsn: 'https://fb8142b1c8bc8f17c5a619255fa16339@o4504128864845824.ingest.us.sentry.io/4507062047277056',
    integrations: [Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <GoogleOAuthProvider clientId={GOOGLE_CALENDAR_CLIENT_ID}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);

if (module.hot) {
  // this is necessary for HMR, the only thing we need for the whole app which is nice
  module.hot.accept('App', () => {
    const NextRootContainer = require('App').default;
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(<NextRootContainer />, document.getElementById('root'));
  });
}
