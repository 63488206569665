import get from 'lodash/get';
import React from 'react';

import { ADMIN, GROUP, PRIVATE } from 'constants/messages';
import {
  createUseGlobalState,
  createUseSetGlobalState,
  globalContext,
  useUser,
} from 'hooks/useGlobalState';

export const useChatTokenData = createUseGlobalState('chatTokenData');
export const useSetChatTokenData = createUseSetGlobalState('chatTokenData');

export const useChatClient = createUseGlobalState('chatClient');
export const useSetChatClient = createUseSetGlobalState('chatClient');

export const useChatChannels = createUseGlobalState('chatChannels');
export const useSetChatChannels = createUseSetGlobalState('chatChannels');

export const useChatUsers = createUseGlobalState('chatUsers');
export const useSetChatUsers = createUseSetGlobalState('chatUsers');

export const useMessageCounter = createUseGlobalState('messageCounter');
export const useSetMessageCounter = createUseSetGlobalState('messageCounter');

/* ---------- ChatUserData ---------- */
function parseChatUserData(user, users) {
  if (!user) {
    return null;
  }
  return users.find((item) => item.chat_identity === user.chat_identity);
}
export function useChatUserData(user) {
  return useChatUsers((users) => parseChatUserData(user, users), [user]);
}
export function useGetChatUserData() {
  const { chatUsers } = React.useContext(globalContext);
  return React.useCallback(
    (user) => {
      return parseChatUserData(user, chatUsers.value);
    },
    [chatUsers],
  );
}
/* ---------- ChatUserData ---------- */

/* ---------- AdminChannelData ---------- */
function parseAdminChannelPatient(channel) {
  const chatIdentity = get(channel, 'state.attributes.channel_for');
  return { chat_identity: chatIdentity };
}
export function useAdminChannelData(channel) {
  return useChatUserData(parseAdminChannelPatient(channel));
}
export function useGetAdminChannelData() {
  const getChatUserData = useGetChatUserData();
  return React.useCallback(
    (channel) => {
      return getChatUserData(parseAdminChannelPatient(channel));
    },
    [getChatUserData],
  );
}
/* ---------- AdminChannelData ---------- */

/* ---------- GroupChannelData ---------- */
export function useGroupChannelData(channel) {
  return get(channel, 'state.attributes');
}
export function useGetGroupChannelData() {
  return React.useCallback((channel) => {
    return get(channel, 'state.attributes');
  }, []);
}
/* ---------- GroupChannelData ---------- */

/* ---------- PrivateChannelData ---------- */
function getPrivateChannelOpponent(channel, userId) {
  const members = get(channel, 'state.attributes.members', []);
  const opponent = members.find((member) => member.id !== userId);
  if (!opponent) {
    console.warn(`Opponent not found in private chat: ${channel.sid}`);
  }
  return opponent;
}
export function usePrivateChannelData(channel) {
  const userId = useUser((current) => get(current, 'id'));
  return useChatUserData(getPrivateChannelOpponent(channel, userId));
}
export function useGetPrivateChannelData() {
  const { user } = React.useContext(globalContext);
  const getChatUserData = useGetChatUserData();
  return React.useCallback(
    (channel) => {
      return getChatUserData(getPrivateChannelOpponent(channel, user.value.id));
    },
    [getChatUserData, user],
  );
}
/* ---------- PrivateChannelData ---------- */

export function useGetAdminChannelName() {
  const getAdminChannelData = useGetAdminChannelData();
  return React.useCallback(
    (channel) => {
      const channelData = getAdminChannelData(channel);
      if (!channelData) {
        return '';
      }
      return channelData.full_name;
    },
    [getAdminChannelData],
  );
}

export function useGetGroupChannelName() {
  const getGroupChannelData = useGetGroupChannelData();
  const getChatUserData = useGetChatUserData();
  return React.useCallback(
    (channel) => {
      const channelData = getGroupChannelData(channel);
      if (!channelData) {
        return '';
      }
      const channelFor = channelData.channel_for;
      if (channelFor) {
        const userData = getChatUserData({ chat_identity: channelFor });
        if (userData) {
          return `${userData.full_name} (TC)`;
        }
      }
      return channelData.name;
    },
    [getGroupChannelData, getChatUserData],
  );
}

export function useGetPrivateChannelName() {
  const getPrivateChannelData = useGetPrivateChannelData();
  return React.useCallback(
    (channel) => {
      const channelData = getPrivateChannelData(channel);
      if (!channelData) {
        return '';
      }
      return channelData.full_name;
    },
    [getPrivateChannelData],
  );
}

export function useGetChannelName() {
  const getAdminChannelName = useGetAdminChannelName();
  const getGroupChannelName = useGetGroupChannelName();
  const getPrivateChannelName = useGetPrivateChannelName();
  const channelsConfig = {
    [ADMIN]: getAdminChannelName,
    [GROUP]: getGroupChannelName,
    [PRIVATE]: getPrivateChannelName,
  };
  return React.useCallback(
    (channel) => {
      const type = get(channel, 'state.attributes.type');
      const handler = channelsConfig[type];
      if (!handler) {
        return null;
      }
      return handler(channel);
    },
    [channelsConfig],
  );
}
