import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import Modal from 'common/Modal';
import { RegularText } from 'common/texts';
import { SETTINGS, PASSWORD } from 'constants/routes';

export default React.memo(function PasswordChange({ closeModal }) {
  const history = useHistory();

  const settingsUrl = `/${SETTINGS}/${PASSWORD}`;
  const toPasswordChange = React.useCallback(() => {
    history.push(settingsUrl);
    closeModal();
  }, [history, settingsUrl, closeModal]);

  return (
    <Modal
      title="Please change your password"
      minWidth="40"
      maxWidth="46"
      withSeparator
      closeModal={closeModal}
    >
      <RegularText className="g-mt-20 g-mb-20">
        We ask our patients to change their password every 90 days. We do this to ensure maximum
        security and HIPAA compliance when using your platform. Thank you!
      </RegularText>
      <CustomButton clickHandler={toPasswordChange}>Change password</CustomButton>
    </Modal>
  );
});
