import React from 'react';
import styled from 'styled-components';

import ChangeVisitsModal from './ChangeVisitsModal';

export default function ChangeVisitsButton({ id, visitType, name = '', used, amount }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <>
      <StyledWrapper>
        <span>
          {used} / {amount}
        </span>
        <span className="edit" onClick={openModal}>
          Edit
        </span>
      </StyledWrapper>

      {isOpen && (
        <ChangeVisitsModal
          closeModal={closeModal}
          id={id}
          visitType={visitType}
          name={name}
          used={used}
          amount={amount}
        />
      )}
    </>
  );
}

const StyledWrapper = styled.span`
  .edit {
    color: rgba(34, 50, 50, 0.5);
    margin-left: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`;
