import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import ConfirmationModal from 'common/ConfirmationModal';
import LinkButton from 'common/LinkButton';
import { UppercaseText, RegularText } from 'common/texts';
import { useModalHandler } from 'hooks/useUtils';
import { useDownloadPatinetLab, useFetchPatinetLabs } from 'requests/patients';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default React.memo(function LabPreview({ id, name, uploaded, uploadedBy, isOwner }) {
  const downloadPatinetLab = useDownloadPatinetLab(id);
  const fetchPatinetLabs = useFetchPatinetLabs(id);

  const [isOpen, openModal, closeModal] = useModalHandler();

  const deleteDocument = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/labs/${id}`,
    })
      .then(() => {
        fetchPatinetLabs();
        createNotification({ message: 'Document successfully deleted', type: 'success' });
      })
      .catch((error) => console.info(error))
      .finally(closeModal);
  }, [id, fetchPatinetLabs, closeModal]);

  return (
    <StyledWrapper>
      <div className="mrn g-mt-5 g-mb-5">
        <UppercaseText>document name</UppercaseText>
        <RegularText className="name">{name}</RegularText>
      </div>

      <div className="author g-mt-5 g-mb-5">
        <UppercaseText>author</UppercaseText>
        <RegularText>{uploadedBy}</RegularText>
      </div>

      <div className="uploaded g-mt-5 g-mb-5">
        <UppercaseText>uploaded</UppercaseText>
        <RegularText>{moment.utc(uploaded).local().fromNow()}</RegularText>
      </div>

      <LinkButton className="view" onClick={downloadPatinetLab}>
        View lab
      </LinkButton>
      {isOwner && (
        <LinkButton className="delete" onClick={openModal}>
          Delete
        </LinkButton>
      )}
      {isOpen && <ConfirmationModal closeModal={closeModal} confirmHandler={deleteDocument} />}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;
  display: flex;
  .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mrn {
    width: 35%;
  }
  .author {
    width: 20%;
  }
  .uploaded {
    width: 20%;
  }
  .view {
    margin: auto 0 auto 1.5rem;
    white-space: nowrap;
  }
  .delete {
    margin: auto 0 auto 1.5rem;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    .mrn {
      width: 30%;
      text-align: center;
    }
    .author {
      width: 30%;
      text-align: center;
    }
    .uploaded {
      width: 30%;
      text-align: center;
    }
    .view {
      width: 48%;
      margin: 1rem 0 0 0;
    }
    .delete {
      width: 48%;
      margin: 1rem 0 0 0;
    }
  }
`;
