import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Icon } from 'assets/add.svg';

export default function ViewData({ className, clickHandler }) {
  return (
    <StyledWrapper className={className} onClick={clickHandler}>
      <div className="label">View data</div>
      <Icon className="g-ml-10" />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    .label {
      text-decoration: none;
    }
  }
  .label {
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: underline;
    color: #1e31d3;
  }
`;
