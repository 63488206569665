import React from 'react';
import styled from 'styled-components';

import WeightList from './weight-list';
import WeightProportions from './WeightProportions';

export default function BodyComposition() {
  return (
    <StyledWrapper className="g-mt-30">
      <p className="label g-mb-20">Body composition</p>
      <WeightProportions />
      <WeightList />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .label {
    font-size: 1.6rem;
  }
`;
