import React, { FC, useCallback, useState } from 'react';

type DnDFilesProps = {
  children: (isDragActive: boolean) => React.ReactNode;
  onFilesAdded: (files: File[]) => void;
};

export const makeFiles = (files: FileList) => {
  const filesArray: File[] = [];
  for (let i = 0; i < files.length; i++) {
    filesArray.push(files[i]);
  }
  return filesArray;
};

export const DnDFiles: FC<DnDFilesProps> = ({ children, onFilesAdded }) => {
  const [dragActive, setDragActive] = useState(false);
  const handleDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files.length) {
      onFilesAdded(makeFiles(e.dataTransfer.files));
    }
  }, []);
  return (
    <div
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {children(dragActive)}
    </div>
  );
};
