import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PROGRAMS } from 'constants/routes';
import { globalContext } from 'hooks/useGlobalState';
import {
  useLocalOptions,
  useSetLocalOptions,
  useSetLocalProgram,
  useSetOptions,
  useSetProgram,
  useSetPrograms,
  useSetVisits,
} from 'hooks/usePrograms';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export function useFetchPrograms() {
  const { programs } = React.useContext(globalContext);
  const setPrograms = useSetPrograms((prev, next) => next, []);
  return React.useCallback(() => {
    if (!programs.value) {
      request({
        method: 'get',
        url: '/plans',
      })
        .then(
          ({
            data: {
              data: { plans },
            },
          }) => setPrograms(plans),
        )
        .catch((error) => console.info(error));
    }
  }, [programs, setPrograms]);
}

export function useFetchOptions() {
  const { careOptions } = React.useContext(globalContext);
  const setOptions = useSetOptions((prev, next) => next, []);

  return React.useCallback(() => {
    if (!careOptions.value) {
      request({
        method: 'get',
        url: '/options/care-options',
      })
        .then(({ data }) => setOptions(data))
        .catch((error) => console.info(error));
    }
  }, [careOptions, setOptions]);
}

export function useFetchVisits() {
  const { medicalVisits } = React.useContext(globalContext);
  const setVisits = useSetVisits((prev, next) => next, []);
  return React.useCallback(() => {
    if (!medicalVisits.value) {
      request({
        method: 'get',
        url: '/options/qualifications',
      })
        .then(({ data }) => {
          const updatedData = data.map((service) => {
            return { ...service, amount: 0 }; // Spread the existing object and add the new field with its value
          });
          setVisits(updatedData);
        })
        .catch((error) => console.info(error));
    }
  }, [medicalVisits, setVisits]);
}

export function useFetchProgram() {
  const { programId } = useParams();

  const setProgram = useSetProgram((prev, next) => next, []);
  const setLocalProgram = useSetLocalProgram((prev, next) => next, []);
  const setLocalOptions = useSetLocalOptions((prev, next) => next, []);

  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/plans/${programId}`,
    })
      .then(
        ({
          data: {
            data: { plan },
          },
        }) => {
          setProgram(plan);
          let arrayOfOptions = plan.options.map((obj) => obj.id.toString());
          setLocalOptions(arrayOfOptions);
          const { ...modifiedPlan } = plan;
          modifiedPlan.care_options = plan.options;
          setLocalProgram(modifiedPlan);
        },
      )
      .catch((error) => console.info(error));
  }, [programId, setProgram]);
}

export function useUpdateProgram() {
  const { localProgram } = React.useContext(globalContext);
  const setProgram = useSetProgram((prev, next) => next, []);
  const setLocalProgram = useSetLocalProgram((prev, next) => next, []);
  const localOptions = useLocalOptions();

  return React.useCallback(() => {
    const programId = localProgram.value.id;
    const programSnap = localProgram.value;
    programSnap.care_options = localOptions?.map((optId) => ({ id: optId }));
    request({
      method: 'put',
      url: `/plans/${programId}`,
      data: programSnap,
    })
      .then(
        ({
          data: {
            data: { plan },
          },
        }) => {
          setProgram(plan);
          const { ...modifiedPlan } = plan;
          modifiedPlan.care_options = plan.options;
          setLocalProgram(modifiedPlan);
          createNotification({ message: 'Program has been updated', type: 'success' });
        },
      )
      .catch((error) => console.info(error));
  }, [localProgram, setProgram, setLocalProgram, localOptions]);
}

export function useCreateProgram() {
  const history = useHistory();
  const localOptions = useLocalOptions();
  const fetchPrograms = useFetchPrograms();
  const { programForm } = React.useContext(globalContext);
  programForm.value.package_type = 'program';
  programForm.value.doctor_visit = 0;
  programForm.value.coach_visit = 0;
  programForm.value.care_options = localOptions?.map((optId) => ({ id: optId }));
  return React.useCallback(
    (setIsSended) => {
      setIsSended(true);
      request({
        method: 'post',
        url: '/plans',
        data: programForm.value,
      })
        .then(() => {
          createNotification({ message: 'Program successfully created', type: 'success' });
          history.push(`/${PROGRAMS}`);
          fetchPrograms();
        })
        .catch((error) => console.info(error))
        .finally(() => setIsSended(false));
    },
    [programForm, history, localOptions, fetchPrograms],
  );
}
