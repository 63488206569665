import React from 'react';

import { MediumText, RegularText } from 'common/texts';
import { usePatinetDoctorNotes } from 'hooks/usePatients';

import NotesPreview from './notes-preview';

export default React.memo(function NotesList() {
  const patinetDoctorNotes = usePatinetDoctorNotes();
  const draftsNotes = React.useMemo(() => {
    return patinetDoctorNotes.filter((note) => note.draft);
  }, [patinetDoctorNotes]);
  const publishedNotes = React.useMemo(() => {
    return patinetDoctorNotes.filter((note) => !note.draft);
  }, [patinetDoctorNotes]);

  return (
    <>
      {draftsNotes.length !== 0 && (
        <>
          <MediumText>Drafts</MediumText>
          <RegularText className="g-mb-15">Continue editing the draft note</RegularText>
        </>
      )}
      <NotesPreview notes={draftsNotes} />
      {publishedNotes.length !== 0 && (
        <MediumText className="g-mt-10 g-mb-15">Published notes</MediumText>
      )}
      <NotesPreview notes={publishedNotes} />
    </>
  );
});
