import { $, GraphQLTypes, InputType, Selector } from 'zeus-graphql/my-health/zeus';

import { useQuery } from '../client';
import { GET_TREATMENT_PLAN } from '../constants';

const task = Selector('TaskGqlType')({
  __typename: true,
  details: true,
  title: true,
  id: true,
  materials: {
    id: true,
    materialVersion: {
      category: {
        title: true,
      },
      materialId: true,
      id: true,
      title: true,
    },
  },
  type: true,
  endDate: true,
  createdAt: true,
  startDate: true,
  status: true,
  files: {
    fileName: true,
    fileType: true,
    id: true,
  },
});
const provider = Selector('ProviderType')({
  __typename: true,
  email: true,
  profile: {
    avatar: true,
    lName: true,
    avatarUrl: true,
    fName: true,
  },
});

const treatmentPlan = Selector('TreatmentPlanType')({
  __typename: true,
  id: true,
  title: true,
  tasks: task,
  createdBy: provider,
  taskAmount: true,
  createdAt: true,
  summary: true,
  status: true,
  sentAt: true,
});

export type TaskTypeName =
  | 'diet'
  | 'medications'
  | 'supplements'
  | 'labs'
  | 'goals'
  | 'activity'
  | 'other';

export type TaskDietDetails = {
  summary: string;
  duration: number;
  description: string;
  type: TaskTypeName;
};

export type ActivityDietDetails = {
  summary: string;
  duration: number;
  description: string;
  type: TaskTypeName;
};

export type TaskMedicationsDetails = {
  categoryId: number;
  category?: {
    id: number;
    title: string;
  };
  name: string;
  measureId: number;
  measure?: {
    id: number;
    measure: string;
  };
  frequencyId: number;
  frequency?: {
    id: number;
    title: string;
    total_per_day: number;
  };
  quantity: number;
  description: string;
  type: string;
};

export type TaskLabsDetails = {
  summary: string;
};

export type TaskDetails<N = TaskTypeName> = N extends 'diet'
  ? TaskDietDetails
  : N extends 'medications'
    ? TaskMedicationsDetails
    : N extends 'activity'
      ? ActivityDietDetails
      : N extends 'labs'
        ? TaskLabsDetails
        : Record<string, unknown>;

export type ProviderType = InputType<GraphQLTypes['ProviderType'], typeof provider>;

export interface TaskType<N = TaskTypeName>
  extends InputType<GraphQLTypes['TaskGqlType'], typeof task> {
  type: TaskTypeName;
  details: TaskDetails<N>;
  isNew?: boolean;
}

export type TreatmentPlanType = InputType<
  GraphQLTypes['TreatmentPlanType'],
  typeof treatmentPlan
> & {
  tasks: TaskType[];
};
export type TreatmentPlanUpdateInput = GraphQLTypes['TreatmentPlanUpdateInput'];

export const useTreatmentPlan = (treatmentPlanId: string) => {
  const { data, isLoading, error } = useQuery(
    [GET_TREATMENT_PLAN, treatmentPlanId],
    {
      getTreatmentPlan: [
        {
          treatmentPlanId: $`treatmentPlanId`,
        },
        treatmentPlan,
      ],
    },
    {
      operationName: GET_TREATMENT_PLAN,
      variables: {
        treatmentPlanId,
      },
    },
  );

  return {
    plan: data?.getTreatmentPlan,
    isLoading,
    error,
  };
};
