import React from 'react';
import styled from 'styled-components';

export default React.memo(function OverviewPlaceholder() {
  return (
    <StyledWrapper aria-hidden="true">
      <div className="head" />
      <div className="body" />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  width: 28rem;
  height: 29rem;
  background: #f8faff;
  border-radius: 0.6rem;
  @media screen and (max-width: 1024px) {
    max-width: 35rem;
  }
  .head {
    padding: 1.9rem;
    border-bottom: 0.1rem solid rgba(34, 50, 50, 0.1);
    &::before {
      content: '';
      display: block;
      width: 70%;
      height: 2rem;
      background: rgba(34, 50, 50, 0.1);
    }
  }
  .body {
    padding: 2rem;
    &::before,
    &::after {
      content: '';
      display: block;
      height: 1.6rem;
      background: rgba(34, 50, 50, 0.1);
    }
    &::before {
      width: 45%;
    }
    &::after {
      width: 80%;
      margin-top: 1.5rem;
    }
  }
`;
