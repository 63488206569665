import classNames from 'clsx';
import React, { FC, useCallback, useState } from 'react';

import { LibraryArticle } from './LibraryArticle';
import { LibraryArticleSearch } from './LibraryArticleSearch';
import { TaskType } from '../../../../../requests/graphql/my-health/queries/getTreatmentPlan';
import { LibraryMaterialType } from '../../../../../requests/graphql/patient/queries/getLibraryMaterials';

type LibraryArticleListProps = {
  materials: TaskType['materials'];
  color: string;
  onAdd: (material: TaskType['materials']['0']) => void;
  onDelete: (id: string) => void;
};

export const LibraryArticleList: FC<LibraryArticleListProps> = ({
  materials,
  onAdd,
  onDelete,
  color,
}) => {
  const [materialsSearchEnabled, setMaterialsSearchEnabled] = useState(false);
  const handleAddMaterial = useCallback((m: LibraryMaterialType) => {
    const material = {
      id: m.id,
      materialVersion: {
        id: m.id,
        title: m.title,
        materialId: m.materialId,
        category: {
          id: m.category.id,
          title: m.category.title,
        },
      },
    };
    onAdd(material);
    setMaterialsSearchEnabled(false);
  }, []);
  return (
    <div className="">
      {(materialsSearchEnabled || !!materials.length) && (
        <div className="text-1522 font-ambit font-semibold text-main mb-4">
          Articles from Library
        </div>
      )}
      <div className="">
        {materials?.map((material, mi) => (
          <LibraryArticle
            materialId={material.materialVersion.materialId}
            onDelete={() => onDelete(material.id)}
            key={material.id || 'material' + mi}
            title={material.materialVersion.title}
            category={material.materialVersion.category.title}
          />
        ))}
        {materialsSearchEnabled && (
          <div className="">
            <LibraryArticleSearch
              onSelect={handleAddMaterial}
              onDelete={() => setMaterialsSearchEnabled(false)}
            />
          </div>
        )}
      </div>
      {!materialsSearchEnabled && (
        <div
          onClick={() => setMaterialsSearchEnabled(true)}
          className={classNames(
            'cursor-pointer text-1416 font-ambit font-semibold text-right',
            color,
          )}
        >
          + Add related articles from library to help patient with his task
        </div>
      )}
    </div>
  );
};
