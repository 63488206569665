import get from 'lodash/get';
import React from 'react';

import { usePrivateChannelData } from 'hooks/useMessages';

import { StyledWrapper, StyledUserAvatar, StyledDetails, StyledCounter } from './styles';

export default React.memo(function PrivateItem({
  channel,
  isActive,
  newMessagesCount,
  navigateToChat,
}) {
  const opponentData = usePrivateChannelData(channel);

  const avatarUrl = get(opponentData, 'avatar_url');
  const firstName = get(opponentData, 'first_name');
  const lastName = get(opponentData, 'last_name');
  const fullName = get(opponentData, 'full_name');

  return (
    <StyledWrapper isActive={isActive} onClick={navigateToChat}>
      <StyledUserAvatar avatar={avatarUrl} firstName={firstName} lastName={lastName} />

      <StyledDetails className="wrapper g-ml-10">
        <div className="name">{fullName}</div>
        <div className="sub-name">Private chat</div>
      </StyledDetails>

      {!!newMessagesCount && <StyledCounter>{newMessagesCount}</StyledCounter>}
    </StyledWrapper>
  );
});
