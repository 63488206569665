import React from 'react';

import LinkButton from 'common/LinkButton';
import { RegularText } from 'common/texts';

import CancelAppointmentModal from './CancelAppointmentModal';

export default function CancelButton({ status, id, closeMainModal }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  if (status !== 'PLANNED') {
    return null;
  }

  return (
    <>
      <div className="g-d-flex g-justify-between g-align-center g-mt-20">
        <RegularText>Cancel appointment</RegularText>
        <LinkButton className="button" onClick={openModal}>
          Cancel
        </LinkButton>
      </div>

      {isOpen && (
        <CancelAppointmentModal closeModal={closeModal} closeMainModal={closeMainModal} id={id} />
      )}
    </>
  );
}
