import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { UppercaseText, RegularText } from 'common/texts';
import { PATIENTS, DETAILS, ADMIN_NOTES, PREVIEW } from 'constants/routes';
import { useUser } from 'hooks/useGlobalState';
import { useSetPatientAdminNotes } from 'hooks/usePatients';
import { useRemoveAdminNote } from 'requests/patients/patients';

export default function AdminNotePreview({ note }) {
  const { patientId } = useParams();
  const history = useHistory();
  const clearPatientAdminNotes = useSetPatientAdminNotes(() => null, []);

  const id = get(note, 'id');

  const removeAdminNote = useRemoveAdminNote(id);

  const userId = useUser((current) => get(current, 'id'));
  const medicalRecord = get(note, 'patient.medical_record');
  const author = get(note, 'admin.full_name');
  const published = moment
    .utc(get(note, 'published', ''))
    .local()
    .format('D MMMM YYYY h:mm A');
  const adminId = get(note, 'admin.id');

  const navigateToPreview = React.useCallback(() => {
    clearPatientAdminNotes();
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}/${id}/${PREVIEW}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}` },
    });
  }, [history, patientId, id, clearPatientAdminNotes]);

  return (
    <StyledPreview>
      <div className="mrn g-mt-5 g-mb-5">
        <UppercaseText>medical record number</UppercaseText>
        <RegularText>{medicalRecord}</RegularText>
      </div>

      <div className="autor g-mt-5 g-mb-5">
        <UppercaseText>Author</UppercaseText>
        <RegularText>{author}</RegularText>
      </div>

      <div className="published g-mt-5 g-mb-5">
        <UppercaseText>published</UppercaseText>
        <RegularText>{published}</RegularText>
      </div>

      <div className="buttons">
        <LinkButton className="edit-button g-mr-10" onClick={navigateToPreview}>
          View notes
        </LinkButton>
        {userId === adminId && (
          <LinkButton className="view-button" onClick={removeAdminNote}>
            Delete
          </LinkButton>
        )}
      </div>
    </StyledPreview>
  );
}

const StyledPreview = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  margin-bottom: 1.5rem;
  background: #fafbfd;
  border-radius: 0.8rem;
  .mrn {
    width: 25%;
  }
  .autor {
    width: 20%;
  }
  .published {
    width: 25%;
  }
  .buttons {
    display: flex;
    width: 30%;
    justify-content: flex-end;
    margin: auto 0;
  }
  .edit-button {
    padding: 0.6rem 2.5rem;
  }
  .view-button {
    min-width: 11rem;
  }
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    .mrn {
      width: 30%;
      text-align: center;
    }
    .autor {
      width: 30%;
      text-align: center;
    }
    .published {
      width: 30%;
      text-align: center;
    }
    .buttons {
      width: 100%;
      margin: 0.5rem 0;
    }
    .edit-button {
      width: 100%;
    }
    .view-button {
      width: 100%;
    }
  }
`;
