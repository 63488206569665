import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Calendar } from 'assets/calendar.svg';

export default React.memo(function CalendarInput({ isRequred, isValid, value, setIsOpen }) {
  const placeholder = <span className="placeholder">mm.dd.yyyy</span>;
  return (
    <StyledWrapper
      className="calendar-input"
      isRequred={isRequred}
      isValid={isValid}
      onClick={React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen])}
    >
      <span className="label">
        {(value && moment.utc(value).local().format('MM.DD.YYYY')) || placeholder}
      </span>
      <Calendar />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
  padding: 1rem 1.6rem;
  border: solid 1px ${({ isRequred, isValid }) => (isRequred && !isValid ? '#f86060' : '#e9ebef')};
  background: #fff;
  .label {
    font-size: 1.3rem;
    ${({ isRequred, isValid }) => (isRequred && !isValid ? 'color: #f86060' : '')};
    .placeholder {
      font-weight: 300;
      ${({ isRequred, isValid }) =>
        isRequred && !isValid ? 'color: #f86060' : 'rgba(0, 0, 0, 0.5)'};
    }
  }
  cursor: pointer;
  &:hover {
    border: solid 1px ${({ isRequred, isValid }) => (isRequred && !isValid ? '#f86060' : '#ccc')};
  }
  &:active {
    box-shadow: 0 0 0 0.1rem
      ${({ isRequred, isValid }) => (isRequred && !isValid ? '#f86060' : 'rgba(0, 0, 0, 0.25)')};
  }
`;
