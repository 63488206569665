import moment from 'moment';

import {
  TYPE_WEIGHT,
  TYPE_HEIGHT,
  TYPE_FAT_RATIO,
  TYPE_FAT_MASS,
  TYPE_MUSCLE_MASS,
  TYPE_BONE_MASS,
  TYPE_WATER,
  TYPE_DIASTOLIC,
  TYPE_SYSTOLIC,
  TYPE_PULSE,
} from './withings-types';

export const FAT = 'fat';
export const MUSCLE = 'muscle';
export const BONE = 'bone';
export const WATER = 'water';

export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';

export const weekStart = moment().startOf('isoWeek');
export const weekEnd = moment().endOf('isoWeek');

export const monthStart = moment().startOf('month');
export const monthEnd = moment().endOf('month');

export const quarterStart = moment().startOf('quarter');
export const quarterEnd = moment().endOf('quarter');

export const yearStart = moment().startOf('year');
export const yearEnd = moment().endOf('year');

export const typeTabsConfig = [
  { id: TYPE_FAT_RATIO, label: 'Body fat' },
  { id: TYPE_MUSCLE_MASS, label: 'Body  muscle' },
  { id: TYPE_BONE_MASS, label: 'Bone mass' },
  { id: TYPE_WATER, label: 'Water' },
];

export const periodTabsConfig = [
  {
    id: WEEK,
    label: 'Week',
    start: weekStart.format('YYYY-MM-DD'),
    end: weekEnd.format('YYYY-MM-DD'),
  },
  {
    id: MONTH,
    label: 'Month',
    start: monthStart.format('YYYY-MM-DD'),
    end: monthEnd.format('YYYY-MM-DD'),
  },
  {
    id: QUARTER,
    label: 'Quarter',
    start: quarterStart.format('YYYY-MM-DD'),
    end: quarterEnd.format('YYYY-MM-DD'),
  },
  {
    id: YEAR,
    label: 'Year',
    start: yearStart.format('YYYY-MM-DD'),
    end: yearEnd.format('YYYY-MM-DD'),
  },
];

export const typesConfig = {
  [TYPE_WEIGHT]: 'weight',
  [TYPE_HEIGHT]: 'height',
  [TYPE_FAT_RATIO]: 'fatRatio',
  [TYPE_FAT_MASS]: 'fatMass',
  [TYPE_MUSCLE_MASS]: 'muscleMass',
  [TYPE_BONE_MASS]: 'boneMass',
  [TYPE_WATER]: 'water',
  [TYPE_DIASTOLIC]: 'diastolic',
  [TYPE_SYSTOLIC]: 'systolic',
  [TYPE_PULSE]: 'pulse',
};

export const decimalPlaceConfig = {
  [TYPE_WEIGHT]: 1,
  [TYPE_HEIGHT]: 1,
  [TYPE_FAT_RATIO]: 1,
  [TYPE_FAT_MASS]: 1,
  [TYPE_MUSCLE_MASS]: 1,
  [TYPE_BONE_MASS]: 1,
  [TYPE_WATER]: 1,
  [TYPE_DIASTOLIC]: 0,
  [TYPE_SYSTOLIC]: 0,
  [TYPE_PULSE]: 0,
};

export const collorsConfig = {
  [TYPE_FAT_RATIO]: '#38dee8',
  [TYPE_MUSCLE_MASS]: '#0dc3f5',
  [TYPE_BONE_MASS]: '#0a7dc7',
  [TYPE_WATER]: '#4865d7',
};

export const dimensionConfig = {
  [TYPE_FAT_RATIO]: '%',
  [TYPE_MUSCLE_MASS]: 'lb',
  [TYPE_BONE_MASS]: 'lb',
  [TYPE_WATER]: 'lb',
};
