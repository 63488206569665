import React from 'react';

import { useInitialChatData } from 'requests/messages';
import { useInitialFetchUser } from 'requests/user';

export default React.memo(function InitialFetch() {
  const initialFetchUser = useInitialFetchUser();
  const initialChatData = useInitialChatData();
  React.useEffect(() => {
    initialFetchUser();
    initialChatData();
  }, [initialFetchUser, initialChatData]);
  return null;
});
