import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useLibraryMaterialTypes } from 'requests/graphql/patient/queries/getLibraryMaterialTypes';

import { PageTitle } from '../../components/Breadcrumbs';
import {
  LibraryMaterialEdit,
  MaterialData,
} from '../../components/library-material/LibraryMaterialEdit';
import { LIBRARY } from '../../constants/routes';
import { useUpdateLibraryMaterial } from '../../requests/graphql/patient/mutations/updateLibraryMaterial';
import { useLibraryMaterial } from '../../requests/graphql/patient/queries/getLibraryMaterial';
import { useLibraryMaterialCategories } from '../../requests/graphql/patient/queries/getLibraryMaterialCategories';
import { LibraryMaterialStatuses, LibraryMaterialTypes } from '../../zeus-graphql/patient/zeus';

export const EditMaterial = () => {
  const history = useHistory();
  const { materialId } = useParams<{ materialId: string }>();
  const { updateLibrary } = useUpdateLibraryMaterial();
  const { libraryMaterialCategoriesOptions } = useLibraryMaterialCategories();
  const { libraryMaterialTypesOptions } = useLibraryMaterialTypes();

  const { libraryMaterial } = useLibraryMaterial(materialId);

  const item = useMemo((): MaterialData | undefined => {
    const val = libraryMaterial;
    if (!val) {
      return;
    }
    return {
      title: val.title,
      categoryId: val.category.id,
      content: val.content,
      type: val.type,
      description: val.description,
      status: val.status as LibraryMaterialStatuses,
    };
  }, [libraryMaterial]);

  const saveMaterial = async (data: MaterialData) => {
    await updateLibrary({
      materialId,
      data: {
        ...data,
        type: data.type as LibraryMaterialTypes,
      },
    });
    history.push(`/${LIBRARY}`);
  };

  useEffect(() => {
    if (libraryMaterial && (!materialId || !item)) {
      history.push(`/${LIBRARY}`);
    }
  }, [libraryMaterial, item, materialId]);

  return (
    <>
      <PageTitle header="Edit a material" crumbs={[{ title: 'Library', url: `/${LIBRARY}` }]} />
      <LibraryMaterialEdit
        onCancel={() => history.push(`/${LIBRARY}`)}
        data={item}
        libraryMaterialCategoriesOptions={libraryMaterialCategoriesOptions}
        libraryMaterialTypesOptions={libraryMaterialTypesOptions}
        onSave={saveMaterial}
      />
    </>
  );
};
