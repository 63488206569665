import { useMemo } from 'react';

import { GraphQLTypes, InputType, Selector } from 'zeus-graphql/my-health/zeus';

import { sortOptions } from '../../../../utils/sortOptions';
import { useQuery } from '../client';
import { GET_MEDICATION_CATEGORIES } from '../constants';

const medicationCategory = Selector('MedicationCategoryType')({
  __typename: true,
  category: true,
  id: true,
  createdAt: true,
});

export type MedicationCategoriesType = InputType<
  GraphQLTypes['MedicationCategoryType'],
  typeof medicationCategory
>;

export const useMedicationCategories = () => {
  const { data, isLoading } = useQuery(
    [GET_MEDICATION_CATEGORIES],
    {
      getMedicationCategories: medicationCategory,
    },
    {
      operationName: GET_MEDICATION_CATEGORIES,
    },
  );

  const medicationCategories = data?.getMedicationCategories || [];
  const medicationCategoriesOptions = useMemo(
    () =>
      medicationCategories
        .map((category) => ({
          value: category.id + '',
          label: category.category,
        }))
        .sort(sortOptions),
    [medicationCategories],
  );

  const medicationCategoriesFilterOptions = useMemo(
    () => [{ value: 'all', label: 'All' }, ...medicationCategoriesOptions],
    [medicationCategoriesOptions],
  );

  return {
    medicationCategories,
    medicationCategoriesOptions,
    medicationCategoriesFilterOptions,
    isLoading,
  };
};
