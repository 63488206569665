import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Refresh } from 'assets/refresh.svg';

export default React.memo(function ReloadButton({ className = '', clickHandler }) {
  return (
    <StyledWrapper className={className} onClick={clickHandler}>
      <Refresh className="g-mr-5" />
      Reload
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
