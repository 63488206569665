import React from 'react';

import Availability from 'components/providers/details/availability';
import ProviderTemplate from 'components/providers/ProviderTemplate';
import { AVAILABILITY } from 'constants/routes';

export default function ProviderAvailability() {
  return (
    <ProviderTemplate currentTab={AVAILABILITY}>
      <Availability />
    </ProviderTemplate>
  );
}
