import React from 'react';

import { usePatientMedicationsList } from 'hooks/usePatients';

import MedicationsPreview from './medications-preview';

export default function MedicationsList() {
  const patientMedicationsList = usePatientMedicationsList();

  const parsedpatientMedications = React.useMemo(() => {
    return patientMedicationsList.map((medications) => {
      return <MedicationsPreview key={medications.id} medications={medications} />;
    });
  }, [patientMedicationsList]);

  return parsedpatientMedications;
}
