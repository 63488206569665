import React from 'react';
import styled from 'styled-components';

import UpdateMembershipModal from './UpdateMembershipModal';

export default function UpdateMembershipButton({ label }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <>
      <StyledWrapper>
        <span>{label}</span>
        <span className="edit" onClick={openModal}>
          Edit
        </span>
      </StyledWrapper>

      {isOpen && <UpdateMembershipModal closeModal={closeModal} />}
    </>
  );
}

const StyledWrapper = styled.span`
  .edit {
    color: rgba(34, 50, 50, 0.5);
    margin-left: 1.2rem;
    text-decoration: underline;
    cursor: pointer;
    :hover {
      text-decoration: none;
    }
  }
`;
