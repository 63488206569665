import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader } from 'common/plate/styles';
import { MediumText } from 'common/texts';
import DaySelector from 'common/widgets/day-selector';
import ExportData from 'common/widgets/ExportData';
import ActivityData from 'components/patients/widgets/synchronized-widget/oura/activity/activity-contributors/ActivityData';
import ActivityList from 'components/patients/widgets/synchronized-widget/oura/activity/activity-contributors/ActivityList';
import { EqualColumns } from 'components/patients/widgets/synchronized-widget/oura/common/styles/styledColumns';
import { PATIENTS, DETAILS, WIDGETS, OURA, DAILY_MOVEMENT } from 'constants/routes';
import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets';

export default function PatientActivityConrtubutors() {
  const { patientId, widgetId } = useParams();
  const daysData = useDaysData('total');
  const dayData = useDayData();

  const label = React.useMemo(() => {
    const cal = dayData.cal_active;
    if (!cal) {
      return null;
    }
    return `${cal} Cal`;
  }, [dayData]);

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`} />
        Activity
        <ExportData title="Activity score" field="score" />
      </PlateHeader>
      <DaySelector data={daysData} label={label} color="#fbb665" />
      <EqualColumns className="g-d-flex g-fw-w g-w-100">
        <div className="col col-left g-w-50">
          <MediumText className="g-mb-15">Activity conrtubutors</MediumText>
          <ActivityList data={dayData} />
          <StyledLink
            to={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${DAILY_MOVEMENT}`}
          >
            View Daily movement
          </StyledLink>
        </div>
        <div className="col col-right g-w-50">
          <ActivityData data={dayData} />
        </div>
      </EqualColumns>
    </RowPlate>
  );
}

const StyledLink = styled(Link)`
  align-self: flex-start;
  display: inline-block;
  margin-top: 0.5rem;
  color: inherit;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
`;
