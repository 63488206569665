import get from 'lodash/get';
import React from 'react';

import { useConsultation } from 'hooks/useAppointments';

export default React.memo(function SubVideoComponent() {
  const localVideoTracks = useConsultation((current) => get(current, 'localVideoTracks'));
  const localAudioTracks = useConsultation((current) => get(current, 'localAudioTracks'));
  const isHidden = useConsultation((current) => get(current, 'isHidden', false));

  const videoRef = React.useRef();
  const audioRef = React.useRef();

  React.useEffect(() => {
    const videoTrack = localVideoTracks[0];
    if (videoTrack && !isHidden) {
      videoTrack.attach(videoRef.current);
      return () => videoTrack.detach();
    }
  }, [localVideoTracks, isHidden]);

  React.useEffect(() => {
    const audioTrack = localAudioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => audioTrack.detach();
    }
  }, [localAudioTracks]);

  if (!localAudioTracks[0] || isHidden) {
    return (
      <>
        <div className="video-placeholder" />
        <div className="name">You</div>
      </>
    );
  }

  return (
    <>
      <video className="sub-video" ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
      <div className="name">You</div>
    </>
  );
});
