import React from 'react';

import InputWithLabel from 'common/InputWithLabel';
import { useSetMedicationProperty } from 'hooks/usePatients';

export default React.memo(function Name({ className = '', index, medicationName, validate }) {
  const setMedicationName = useSetMedicationProperty({ index, field: 'medication' });
  return (
    <InputWithLabel
      className={className}
      label="Medication name"
      placeholder="Select medication"
      isRequred
      isValid={!validate || medicationName}
      value={medicationName}
      changeHandler={setMedicationName}
    />
  );
});
