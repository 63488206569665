import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import { DASHBOARD } from 'constants/routes';

export default React.memo(function LogoIcon({ className = '' }) {
  return (
    <Link to={`/${DASHBOARD}`}>
      <StyledLogoIcon className={className} />
    </Link>
  );
});

const StyledLogoIcon = styled(LogoIcon)`
  display: block;
`;
