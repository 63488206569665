import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { MediumText, UppercaseText, RegularText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import { useProviderProfile } from 'hooks/useProviders';

export default React.memo(function Info() {
  const providerProfile = useProviderProfile();

  const avatar = get(providerProfile, 'avatar', '');
  const firstName = get(providerProfile, 'first_name', '');
  const lastName = get(providerProfile, 'last_name', '');
  const fullName = get(providerProfile, 'full_name', '');
  const qualificationTitle = get(providerProfile, 'qualification_title', '');

  const summary = get(providerProfile, 'summary', '');

  const experience = get(providerProfile, 'experience', '');
  const experienceUnit = +experience === 1 ? 'year' : 'years';

  return (
    <StyledWrapper>
      <div className="g-d-flex">
        <UserAvatar className="avatar" avatar={avatar} firstName={firstName} lastName={lastName} />

        <div>
          <MediumText>{fullName}</MediumText>
          <UppercaseText>{qualificationTitle}</UppercaseText>
        </div>
      </div>

      <RegularText className="g-mt-10 info">{summary}</RegularText>

      {experience && (
        <RegularText className="g-mt-10">
          Experience:{' '}
          <b>
            {experience} {experienceUnit}
          </b>
        </RegularText>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  background: #fafbfd;
  border: solid 1px #e9ebef;
  padding: 2rem 3rem;
  width: 100%;
  margin-right: 4rem;
  align-self: flex-start;
  .avatar {
    width: 5.3rem;
    height: 5.3rem;
    margin-right: 1.5rem;
  }
  .info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 2.4rem;
    max-height: 4.8rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
