import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { RegularMediumText } from 'common/texts';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function RadioGroup({ metaPath, answerField }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const minWidth = useQuiz((current) => get(current, `${metaPath}.minWidth`, ''), [metaPath]);
  const answer = useQuiz(
    (current) => get(current, `content.answers.${answerField}.value`, ''),
    [answerField],
  );
  const choices = useQuiz((current) => get(current, `${metaPath}.choices`, ''), [metaPath]);

  const parsedOptions = React.useMemo(() => {
    return choices.map(({ value, text }) => {
      if (answer !== value) {
        return null;
      }
      return (
        <div key={value} className="g-mt-5">
          <StyledCustomButton minWidth={minWidth}>{text}</StyledCustomButton>
        </div>
      );
    }, []);
  }, [choices, answer, minWidth]);

  return (
    <div className="g-mt-20">
      <RegularMediumText>{title}</RegularMediumText>

      {parsedOptions}
    </div>
  );
});

const StyledCustomButton = styled(CustomButton)`
  min-width: ${({ minWidth }) => minWidth};
  pointer-events: none;
`;
