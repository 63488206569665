import React from 'react';

import { useGetGroupChannelName } from 'hooks/useMessages';

import { StyledWrapper, StyledGroupChatIcon, StyledDetails, StyledCounter } from './styles';

export default React.memo(function GroupItem({
  channel,
  isActive,
  newMessagesCount,
  navigateToChat,
}) {
  const getGroupChannelName = useGetGroupChannelName(channel);
  const name = getGroupChannelName(channel);
  return (
    <StyledWrapper isActive={isActive} onClick={navigateToChat}>
      <StyledGroupChatIcon />

      <StyledDetails className="wrapper g-ml-10">
        <div className="name">{name}</div>
        <div className="sub-name">Group chat</div>
      </StyledDetails>

      {!!newMessagesCount && <StyledCounter>{newMessagesCount}</StyledCounter>}
    </StyledWrapper>
  );
});
