import React, { FC, useCallback, useEffect, useState } from 'react';

import { useMedicationCategories } from 'requests/graphql/my-health/queries/getMedicationCategories';
import { useMedicationFrequencies } from 'requests/graphql/my-health/queries/getMedicationFrequencies';
import { useMedicationMeasures } from 'requests/graphql/my-health/queries/getMedicationMeasures';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { MedicationsEdit } from './MedicationsSectionEdit';
import { MedicationsView } from './MedicationsSectionView';

type MedicationsSectionProps = {
  tasks?: TaskType<'medications'>[];
  isPreview?: boolean;
  onChange?: (currentTasks: TaskType<'medications'>[]) => void;
  onAddNew?: () => void;
};

export const MedicationsSection: FC<MedicationsSectionProps> = ({
  tasks,
  isPreview,
  onChange,
  onAddNew,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentTasks, setCurrentTasks] = useState(tasks || []);

  const { medicationMeasuresOptions } = useMedicationMeasures();
  const { medicationCategoriesOptions } = useMedicationCategories();
  const { medicationFrequenciesOptions } = useMedicationFrequencies();

  useEffect(() => {
    setCurrentTasks(tasks || []);

    const maybeHasNew = tasks?.some((task) => task.isNew);

    if (maybeHasNew) {
      setIsEditing(true);
    }
  }, [tasks]);

  const toggleEdit = useCallback(() => {
    setIsEditing((prev) => !prev);
  }, []);

  const save = useCallback((tasks: TaskType<'medications'>[]) => {
    onChange?.(tasks.map((t) => (t.isNew !== undefined ? { ...t, isNew: false } : t)));
    toggleEdit();
  }, []);

  const deleteTasks = useCallback(() => {
    setCurrentTasks([]);
    onChange?.([]);
    toggleEdit();
  }, []);

  const handleCancel = useCallback(() => {
    onChange?.(tasks?.filter((d) => d.id) || []);
    toggleEdit();
  }, [tasks]);

  const deleteSection = useCallback(
    (index: number) => {
      const newTasks = currentTasks.filter((d, i) => i !== index);
      onChange?.(newTasks);
    },
    [currentTasks],
  );

  return isEditing && !isPreview ? (
    <MedicationsEdit
      medicationMeasuresOptions={medicationMeasuresOptions}
      medicationCategoriesOptions={medicationCategoriesOptions}
      medicationFrequenciesOptions={medicationFrequenciesOptions}
      onDeleteSection={deleteSection}
      onSave={save}
      onDelete={deleteTasks}
      onCancel={handleCancel}
      onAddNew={onAddNew}
      tasks={currentTasks}
    />
  ) : (
    <MedicationsView
      medicationMeasuresOptions={medicationMeasuresOptions}
      medicationCategoriesOptions={medicationCategoriesOptions}
      medicationFrequenciesOptions={medicationFrequenciesOptions}
      tasks={currentTasks}
      isPreview={isPreview}
      onDelete={deleteTasks}
      onEdit={toggleEdit}
    />
  );
};
