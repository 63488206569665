import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import LocalSwitcher from 'common/local-tabs/LocalSwitcher';
import BackButton from 'common/plate/BackButton';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import AppointmentsFilters from 'components/appointmentsAdmin/appointments-create/appointments-filters';
import { APPOINTMENTS_ADMIN, CREATE, DASHBOARD } from 'constants/routes';

const tabsConfig = [
  { id: `/${APPOINTMENTS_ADMIN}`, label: 'View appointment' },
  { id: `/${APPOINTMENTS_ADMIN}/${CREATE}`, label: 'Create appointment' },
];

export default function AppointmentsCreateAdmin() {
  const history = useHistory();
  const { pathname } = useLocation();

  const tabsHandler = React.useCallback(
    (value) => {
      history.push(value);
    },
    [history],
  );

  return (
    <WideRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Appointments
      </PlateHeader>

      <PlateContent>
        <div className="g-mt-15 g-mb-20">
          <LocalSwitcher
            tabsConfig={tabsConfig}
            currentTab={pathname}
            setCurrentTab={tabsHandler}
          />
        </div>

        <AppointmentsFilters />
      </PlateContent>
    </WideRowPlate>
  );
}
