import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import Contactinformation from 'components/patients/profile/Contactinformation';
import EmergencyInformation from 'components/patients/profile/EmergencyInformation';
import PersonalInformation from 'components/patients/profile/PersonalInformation';
import PrimaryProvider from 'components/patients/profile/PrimaryProvider';
import { PROFILE } from 'constants/routes';
import { useSetPatinetProfile } from 'hooks/usePatients';
import { useFetchPatinetProfile } from 'requests/patients';

import { useUserContext } from '../../../context/userContext';
import { permissions } from '../../../permissions';
import LinkToEdit from './../../../components/patients/profile/LinkToEdit';

export default function PatientProfile() {
  const { can } = useUserContext();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const clearPatinetProfile = useSetPatinetProfile(() => null, []);

  React.useEffect(() => {
    fetchPatinetProfile();
    return clearPatinetProfile;
  }, []);

  return (
    <DetailsTemplate currentTab={PROFILE}>
      <PersonalInformation />

      <Contactinformation />

      <EmergencyInformation />

      <PrimaryProvider />
      {can(permissions.patient.canEditProfile) && <LinkToEdit />}
    </DetailsTemplate>
  );
}
