import React from 'react';

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import { usePatientHealthPlans } from 'hooks/usePatients';

import PlansList from './PlansList';

export default React.memo(function HealthPlans() {
  const patientHealthPlans = usePatientHealthPlans();

  const isLoading = React.useMemo(() => !patientHealthPlans, [patientHealthPlans]);

  if (isLoading) {
    return <ListPreviewPlaceholder />;
  }

  return <PlansList />;
});
