import React from 'react';
import styled from 'styled-components';

export default React.memo(function ProgressBar({ progress }) {
  return (
    <StyledBar progress={progress}>
      <div className="indicator" />
    </StyledBar>
  );
});

const StyledBar = styled.div`
  width: 10rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  background: #e4eaf0;
  margin: 1rem 0;
  .indicator {
    width: ${({ progress }) => progress}%;
    height: 100%;
    background: #16123f;
    border-radius: 0.2rem;
  }
`;
