import React from 'react';

import CustomButton from 'common/CustomButton';
import { HintText } from 'common/texts';
import { useUploadPatinetLab } from 'requests/patients';

export default React.memo(function UploadButton() {
  const [isSended, setIsSended] = React.useState(false);
  const uploadPatinetLab = useUploadPatinetLab();

  const changeHandler = React.useCallback(
    (e) => {
      uploadPatinetLab({
        document: e.target.files[0],
        setIsSended,
      });
      e.target.value = '';
    },
    [uploadPatinetLab],
  );

  return (
    <div className="g-d-flex g-align-center g-mt-20">
      <label>
        <CustomButton className="g-mr-25" disabled={isSended}>
          Upload
        </CustomButton>
        <input
          className="g-d-none"
          type="file"
          accept=".doc, .docx, .pdf"
          onChange={changeHandler}
        />
      </label>
      <HintText>Supported formats PDF, Word</HintText>
    </div>
  );
});
