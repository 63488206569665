import React from 'react';

import LinkButton from 'common/LinkButton';
import { useDeleteMedicationRow } from 'hooks/usePatients';

export default React.memo(function RemoveButton({ className = '', index, isLast }) {
  const deleteMedicationRow = useDeleteMedicationRow(index);
  return (
    <LinkButton className={className} disabled={isLast} onClick={deleteMedicationRow}>
      Delete
    </LinkButton>
  );
});
