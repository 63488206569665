import get from 'lodash/get';
import React from 'react';

import { ReactComponent as MuteOffIcon } from 'assets/video/mic-off.svg';
import { ReactComponent as MuteIcon } from 'assets/video/mic.svg';
import { useConsultation, useSetConsultation } from 'hooks/useAppointments';

import { IconWrapper } from './style';

export default React.memo(function AudioHandler() {
  const isMuted = useConsultation((current) => get(current, 'isMuted'));
  const toggleMuted = useSetConsultation((prev) => {
    const isMuted = get(prev, 'isMuted');
    const localAudioTracks = get(prev, 'localAudioTracks');
    if (isMuted) {
      localAudioTracks.forEach((track) => track.enable());
    } else {
      localAudioTracks.forEach((track) => track.disable());
    }
    return { ...prev, isMuted: !isMuted };
  });

  return (
    <IconWrapper onClick={toggleMuted}>{isMuted ? <MuteOffIcon /> : <MuteIcon />}</IconWrapper>
  );
});
