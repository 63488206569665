import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { MediumText, UppercaseText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';
import { APPOINTMENTS_ADMIN, RESCHEDULE, CHANGE_PROVIDER } from 'constants/routes';
import { useAppointment } from 'hooks/useAppointments';

export default React.memo(function ProviderPicker() {
  const appointment = useAppointment();
  const { appointmentId } = useParams();
  const history = useHistory();

  const avatar = get(appointment, 'provider.avatar', '');
  const firstName = get(appointment, 'provider.first_name', '');
  const lastName = get(appointment, 'provider.last_name', '');
  const fullName = get(appointment, 'provider.full_name', '');
  const qualificationTitle = get(appointment, 'provider.qualification_title', '');

  const navigateToProviderPicker = React.useCallback(() => {
    history.push(`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/:appointmentId/${CHANGE_PROVIDER}`);
  }, [history, appointmentId]);

  return (
    <StyledWrapper>
      <div className="g-d-flex">
        <UserAvatar
          className="avatar g-mr-15"
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
        />
        <div>
          <MediumText>{fullName}</MediumText>
          <UppercaseText>{qualificationTitle}</UppercaseText>
        </div>
      </div>

      <LinkButton onClick={navigateToProviderPicker}>Change provider</LinkButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  border-bottom: solid 1px #e9ebef;
  .avatar {
    width: 5.5rem;
    height: 5.5rem;
  }
`;
