import React from 'react';

import { FlexLine } from 'common/plate/styles';
import ChatsList from 'components/messages/ChatsList';
import RenameGroupContent from 'components/messages/rename-group-content copy';

export default function RenameGroup() {
  return (
    <FlexLine>
      <ChatsList withHidden />

      <RenameGroupContent />
    </FlexLine>
  );
}
