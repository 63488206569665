import moment from 'moment';
import React from 'react';

import AppointmentsTemplate from 'components/appointmentsProvider/AppointmentsTemplate';
import WorkWeekGrid from 'components/appointmentsProvider/work-week/WorkWeekGrid';
import WorkWeekHeader from 'components/appointmentsProvider/work-week/WorkWeekHeader';
import { WORK_WEEK } from 'constants/routes';
import { useSetScheduledAppointments } from 'hooks/useAppointments';
import { useFetchScheduledAppointments } from 'requests/appointments';

export default function WorkWeekAppoitments() {
  const [workWeekStart, setWorkWeekStart] = React.useState(moment().startOf('isoWeek'));
  const fetchScheduledAppointments = useFetchScheduledAppointments();
  const clearScheduledAppointments = useSetScheduledAppointments(() => [], []);

  React.useEffect(() => {
    fetchScheduledAppointments({
      period: 'work-week',
      period_start: workWeekStart.format('YYYY-MM-DD'),
    });
    return clearScheduledAppointments;
    // eslint-disable-next-line
  }, [workWeekStart])

  return (
    <AppointmentsTemplate currentTab={WORK_WEEK}>
      <WorkWeekHeader workWeekStart={workWeekStart} setWorkWeekStart={setWorkWeekStart} />

      <WorkWeekGrid workWeekStart={workWeekStart} />
    </AppointmentsTemplate>
  );
}
