import styled from 'styled-components';

export const PlateHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem 2.5rem;
  border-bottom: solid 1px #e9ebef;
`;

export const PlateContent = styled.div`
  padding: 1.2rem 2.5rem 3rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FlexLine = styled.div`
  display: flex;
  @media only screen and (max-width: 480px) {
    display: block;
  }
`;

export const PlateSeparator = styled.div`
  min-width: 3rem;
  @media only screen and (max-width: 480px) {
    min-height: 3rem;
  }
`;

export const PlateFooter = styled.div`
  margin-bottom: 4rem;
  height: 1px;
  background: #e9ebef;
`;
