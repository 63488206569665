import { GraphQLTypes, $, ValueTypes } from 'zeus-graphql/patient/zeus';

import { GQLArgs, useMutation } from '../client';
import { UPDATE_LIBRARY_MATERIAL } from '../constants';

type MutationResponse = Record<
  'updateLibraryMaterial',
  GraphQLTypes['LibraryMaterialType'] | GraphQLTypes['MaterialDuplicatedError']
>;

type Vars = Required<ValueTypes['MainMutation']>['updateLibraryMaterial'][0];

type Args = GQLArgs<MutationResponse>;

export const useUpdateLibraryMaterial = (args?: Args) => {
  const instance = useMutation(
    [],
    {
      updateLibraryMaterial: [
        {
          materialId: $`materialId`,
          data: $`data`,
        },
        {
          '...on LibraryMaterialType': {
            materialId: true,
          },
          '...on MaterialDuplicatedError': {
            __typename: true,
            message: true,
          },
        },
      ],
    },
    {
      errorTypes: [
        {
          __typename: 'MaterialDuplicatedError',
          entityName: 'updateLibraryMaterial',
        },
      ],
      operationName: UPDATE_LIBRARY_MATERIAL,
    },
    args,
  );

  const updateLibrary = instance.mutateAsync as (args: Vars) => Promise<MutationResponse>;

  return { ...instance, updateLibrary };
};
