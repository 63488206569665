import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import DaySelector from 'common/widgets/day-selector';
import DurationData from 'components/patients/widgets/synchronized-widget/oura/common/DurationData';
import StatsItem from 'components/patients/widgets/synchronized-widget/oura/common/StatsItem';
import { UnequalColumns } from 'components/patients/widgets/synchronized-widget/oura/common/styles/styledColumns';
import { PATIENTS, DETAILS, WIDGETS, OURA, SLEEP_CONTRIBUTORS } from 'constants/routes';
import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets';
import onlyPositive from 'utils/onlyPositive';
import toHM from 'utils/toHM';
import toRelative from 'utils/toRelative';

export default function PatientSleepStages() {
  const { patientId, widgetId } = useParams();
  const daysData = useDaysData('total');
  const dayData = useDayData();

  const label = React.useMemo(() => {
    const seconds = dayData.total;
    if (!seconds) {
      return null;
    }
    return toHM(seconds);
  }, [dayData]);

  const normalizedAverageHR = Math.round(onlyPositive(dayData.hr_average));
  const normalizedHRV = Math.round(onlyPositive(dayData.rmssd));
  const normalizedTemperature = ((onlyPositive(dayData.temperature_delta) * 9) / 5 + 32).toFixed(2);
  const normalizedBreath = Math.round(onlyPositive(dayData.breath_average));
  const normalizedSleepTime = onlyPositive(dayData.total);
  const normalizedRem = onlyPositive(dayData.rem);
  const normalizedDeep = onlyPositive(dayData.deep);
  const normalizedLight = onlyPositive(dayData.light);
  const normalizedAwake = onlyPositive(dayData.awake);

  const normalizedRemDate = toHM(normalizedRem);
  const normalizedDeepDate = toHM(normalizedDeep);
  const normalizedLightDate = toHM(normalizedLight);
  const normalizedAwakeDate = toHM(normalizedAwake);

  const remOfTotal = toRelative(normalizedRem, normalizedSleepTime);
  const deepOfTotal = toRelative(normalizedDeep, normalizedSleepTime);
  const lightOfTotal = toRelative(normalizedLight, normalizedSleepTime);
  const awakeOfTotal = toRelative(normalizedAwake, normalizedSleepTime);

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton
          href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${SLEEP_CONTRIBUTORS}`}
        />
        Sleep
      </PlateHeader>
      <DaySelector data={daysData} label={label} color="#6fcbff" />
      <StyledWrapper className="g-d-flex g-fw-w g-w-100">
        <div className="col col-left g-w-50">
          <StatsItem statsTitle="Resting heart rate:" statsData={`${normalizedAverageHR} bpm`} />
        </div>
        <div className="col col-right g-w-50">
          <StatsItem statsTitle="Heart rate variability:" statsData={`${normalizedHRV} ms`} />
        </div>
        <div className="col col-left g-w-50">
          <StatsItem statsTitle="Body temperature:" statsData={`${normalizedTemperature} F`} />
        </div>
        <div className="col col-right g-w-50">
          <StatsItem statsTitle="Respiratory rate:" statsData={`${normalizedBreath} / min`} />
        </div>
      </StyledWrapper>
      <UnequalColumns className="g-d-flex g-justify-between g-mb-30">
        <div className="col col-left">
          <DurationData
            textTitle="Awake"
            lineBg="#c2e1ff"
            durationPercentage={awakeOfTotal}
            durationData={normalizedAwakeDate}
          />
          <DurationData
            textTitle="REM"
            lineBg="#01dcf1"
            durationPercentage={remOfTotal}
            durationData={`${normalizedRemDate}, ${remOfTotal}%`}
          />
          <DurationData
            textTitle="Light"
            lineBg="#3aa8b2"
            durationPercentage={lightOfTotal}
            durationData={`${normalizedLightDate}, ${lightOfTotal}%`}
          />
          <DurationData
            textTitle="Deep"
            lineBg="#276f81"
            durationPercentage={deepOfTotal}
            durationData={`${normalizedDeepDate}, ${deepOfTotal}%`}
          />
        </div>
        <div className="col col-right g-d-flex" />
      </UnequalColumns>
      <StyledPlateContent className="g-d-flex g-justify-between">
        <Link
          className="link"
          to={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${SLEEP_CONTRIBUTORS}`}
        >
          View sleep contrubutors
        </Link>
      </StyledPlateContent>
    </RowPlate>
  );
}

const StyledPlateContent = styled(PlateContent)`
  flex-direction: row;
  .link {
    align-self: flex-start;
    color: inherit;
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-transform: uppercase;
  }
`;

const StyledWrapper = styled.div`
  border-top: 0.1rem solid #e9ebef;
  @media screen and (max-width: 768px) {
    border-top: none;
  }
  .col {
    padding: 1rem 3rem;
    &:nth-child(n + 3) {
      border-top: 0.1rem solid #e9ebef;
    }
    &:nth-child(n + 5) {
      padding-top: 2.5rem;
      padding-bottom: 3rem;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &:not(:nth-child(n + 3)) {
      @media screen and (max-width: 768px) {
        border-top: none;
      }
    }
    &:first-child {
      @media screen and (max-width: 768px) {
        border-right: 0.1rem solid #e9ebef;
      }
    }
  }
  .col-right {
    padding-left: 4rem;
    border-left: 0.1rem solid #e9ebef;
    @media screen and (max-width: 768px) {
      padding-left: 3rem;
      border-top: 0.1rem solid #e9ebef;
      border-left: none;
    }
  }
  .call-to-action {
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }
`;
