import React from 'react';
import { useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { PlateHeader } from 'common/plate/styles';
import { PATIENTS, DETAILS, MEDICATIONS } from 'constants/routes';

export default function Header() {
  const { patientId } = useParams();

  return (
    <PlateHeader>
      <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`} />
      Patient progress
    </PlateHeader>
  );
}
