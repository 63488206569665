import get from 'lodash/get';
import React from 'react';

import CustomButton from 'common/CustomButton';
import { useQuiz, useQuizStep, useSetQuizStep } from 'hooks/patient/useQuestionnaires';

export default React.memo(function NextButton() {
  const step = useQuizStep();
  const withNext = useQuiz(
    (current) => get(current, `content.pages[${step}].withNext`, ''),
    [step],
  );
  const nextHandler = useSetQuizStep((prev) => +prev + 1, []);

  if (!withNext) {
    return null;
  }

  return <CustomButton clickHandler={nextHandler}>Next</CustomButton>;
});
