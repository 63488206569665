import React from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import styled from 'styled-components';

import { ReactComponent as FB } from 'assets/referral/fb.svg';
import { ReactComponent as IN } from 'assets/referral/in.svg';
import { ReactComponent as TW } from 'assets/referral/tw.svg';

export default function ShareBySocial({ link }) {
  return (
    <StyledWrapper className="g-mt-20">
      <FacebookShareButton url={link}>
        <div className="icon-wrapper">
          <FB className="fb" />
        </div>
      </FacebookShareButton>
      <TwitterShareButton className="g-ml-25" url={link}>
        <div className="icon-wrapper">
          <TW className="tw" />
        </div>
      </TwitterShareButton>
      <LinkedinShareButton className="g-ml-25" url={link}>
        <div className="icon-wrapper">
          <IN className="in" />
        </div>
      </LinkedinShareButton>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  button {
    outline: none;
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 4.5rem;
    background: #d8d8d8;
    border-radius: 50%;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:active {
      box-shadow: 0 0 0 0.3rem rgba(22, 18, 63, 0.25);
    }
    .fb {
      width: 1.3rem;
      height: 2.5rem;
    }
    .tw {
      width: 2.5rem;
      height: 2rem;
    }
    .in {
      width: 2.1rem;
      height: 2.1rem;
    }
  }
`;
