import React, { FC, useCallback } from 'react';
type ToggleProps = {
  checked: boolean;
  onChange: (checked: boolean, e?: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  color?: 'green' | 'main';
};
export const Toggle: FC<ToggleProps> = ({ checked, onChange, label, color = 'green' }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(!checked, e);
    },
    [checked],
  );

  const colorClass = color === 'green' ? 'peer-checked:bg-success' : 'peer-checked:bg-main';
  return (
    <div className="flex items-center">
      <div className="mr-2 text-1413 font-regular text-manatee font-ambit">{label}</div>

      <label className="relative inline-flex items-center cursor-pointer">
        <input onChange={handleChange} type="checkbox" checked={checked} className="sr-only peer" />
        <div
          className={
            //eslint-disable-next-line quotes
            "w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 " +
            colorClass
          }
        ></div>
      </label>
    </div>
  );
};
