import React from 'react';
import { useParams } from 'react-router-dom';

import ConfirmationModal from 'common/ConfirmationModal';
import LinkButton from 'common/LinkButton';
import { useSetPatientHealthPlan } from 'hooks/usePatients';
import { useModalHandler } from 'hooks/useUtils';
import { useFetchPatinetHealthPlans } from 'requests/patients';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default React.memo(function DeleteHealthPlanButton({ healthPlanID }) {
  const { patientId } = useParams();
  const fetchPatinetHealthPlans = useFetchPatinetHealthPlans();
  const clearPatientHealthPlan = useSetPatientHealthPlan(() => null, []);

  const [isOpen, openModal, closeModal] = useModalHandler();
  const deleteHealthPlan = React.useCallback(() => {
    request({
      method: 'delete',
      url: `/health-plan/${healthPlanID}`,
    })
      .then(() => {
        fetchPatinetHealthPlans();
        createNotification({ message: 'Document successfully deleted', type: 'success' });
      })
      .catch((error) => console.info(error))
      .finally(closeModal);
  }, [patientId, clearPatientHealthPlan, closeModal, fetchPatinetHealthPlans]);

  return (
    <>
      <LinkButton className="delete view-button" onClick={openModal}>
        Delete
      </LinkButton>
      {isOpen && <ConfirmationModal closeModal={closeModal} confirmHandler={deleteHealthPlan} />}
    </>
  );
});
