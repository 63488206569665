import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Link, useLocation } from 'react-router-dom';

import { Title } from './HeaderTitle';

export const BreadcrumbsContainer: FC = () => {
  return (
    <div className="my-2">
      <span className="font-montserrat font-semibold" id="header-breadcrumbs-container" />
    </div>
  );
};

export const BreadcrumbsContent: FC<{ children: ReactNode }> = ({ children }) => {
  const node = document.getElementById('header-breadcrumbs-container');

  return node ? ReactDOM.createPortal(children, node) : <></>;
};

type PageTitleCrumb = {
  title?: React.ReactNode;
  url: string;
};

type PageTitleProps = {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  crumbs?: PageTitleCrumb[];
};

const useCrumbs = ({ header, subHeader, crumbs }: PageTitleProps) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  const result = [...(crumbs ?? [])];
  subHeader && result.push({ title: subHeader, url: '' });
  !subHeader && subHeader !== false && header && result.push({ title: header, url: currentPath });

  return result;
};

const BreadcrumbArrow: React.FC<{ last: boolean }> = () => {
  return (
    <svg
      className="mx-1"
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3125 0.625L4.6875 4L1.3125 7.375"
        stroke="#8B899F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Breadcrumbs: React.FC<{ crumbs: PageTitleCrumb[] }> = ({ crumbs }) => {
  return (
    <div className="flex items-center gap-x-1 h-3">
      {crumbs.map((crumb, index) => {
        const last = index === crumbs.length - 1;

        return (
          <React.Fragment key={crumb.url}>
            {index !== 0 && <BreadcrumbArrow last={last} />}
            {crumb.url ? (
              <Link
                to={crumb.url}
                className={clsx('cursor-pointer font-normal font-ambit text-1416', {
                  'text-[#8B899F]': last,
                })}
              >
                <>{crumb.title}</>
              </Link>
            ) : (
              crumb.title
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const PageTitle: React.FC<PageTitleProps> = ({ header, subHeader, crumbs }) => {
  const theseCrumbs = useCrumbs({ header, subHeader, crumbs });

  return (
    <>
      {!!header && <Title pageTitle={header} />}
      <BreadcrumbsContent>
        <Breadcrumbs crumbs={theseCrumbs} />
      </BreadcrumbsContent>
    </>
  );
};
