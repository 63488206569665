import React from 'react';
import styled from 'styled-components';

import { TODAY } from 'constants/dates-options';

export default React.memo(function DateSeparator({ parsedTimestamp }) {
  return (
    <StyledWrapper>
      <span>{parsedTimestamp === TODAY ? 'TODAY' : parsedTimestamp}</span>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #a2c0d4;
  text-align: center;
  height: 1rem;
  margin-bottom: 1rem;
  span {
    background: #fff;
    padding: 0 0.5rem;
  }
`;
