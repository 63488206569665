import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import ChatBody from 'common/chat/ChatBody';
import ChatInput from 'common/chat/ChatInput';
import ChatSimpleHeader from 'common/chat/ChatSimpleHeader';
import ChatWrongHeader from 'common/chat/ChatWrongHeader';
import { ColumnPlate } from 'common/plate/plates';
import { useConsultation, useSetConsultation } from 'hooks/useAppointments';
import { globalContext } from 'hooks/useGlobalState';
import { useChatTokenData, useChatChannels } from 'hooks/useMessages';
import { parseMessages, addMessage, updateMessages, removeMessage } from 'utils/messages';
import request from 'utils/request';

const ChatContent = React.memo(function ChatContent({ channel }) {
  const channelClass = channel.channelClass;
  const userIdentity = useChatTokenData((current) => get(current, 'chat_identity'), []);
  const [messages, setMessages] = React.useState(null);

  React.useEffect(() => {
    channelClass.getMessages().then(({ items }) => setMessages(parseMessages(items)));

    channelClass.setAllMessagesConsumed();

    function handleAddMessage(newMessage) {
      setMessages((current) => addMessage(current, newMessage));
      channelClass.setAllMessagesConsumed();
    }

    function handleUpdated({ message }) {
      setMessages((current) => updateMessages(current, message));
    }

    function handleRemoveMessage(removedMessage) {
      setMessages((current) => removeMessage(current, removedMessage));
      channelClass.setAllMessagesConsumed();
    }

    channelClass.on('messageAdded', handleAddMessage);
    channelClass.on('messageUpdated', handleUpdated);
    channelClass.on('messageRemoved', handleRemoveMessage);
    return () => {
      setMessages([]);
      channelClass.removeListener('messageAdded', handleAddMessage);
      channelClass.removeListener('messageUpdated', handleUpdated);
      channelClass.removeListener('messageRemoved', handleRemoveMessage);
    };
    // eslint-disable-next-line
  }, [channelClass])

  React.useEffect(() => {
    return () => setMessages([]);
  }, [setMessages]);

  return (
    <ColumnPlate>
      <ChatSimpleHeader />

      {messages && <ChatBody channel={channel} userIdentity={userIdentity} messages={messages} />}

      <ChatInput channel={channel} />
    </ColumnPlate>
  );
});

export default React.memo(function ChatContentLoader() {
  const { appointmentId } = useParams();
  const { chatChannels } = React.useContext(globalContext);
  const isChannelsLoaded = useChatChannels((current) => !!current);
  const setChatChannel = useSetConsultation((prev, chatChannel) => ({ ...prev, chatChannel }));
  const channel = useConsultation((current) => get(current, 'chatChannel'));
  const [unicChatName, setUnicChatName] = React.useState(null);

  React.useEffect(() => {
    request({
      method: 'get',
      url: `/appointments/${appointmentId}/messages-token`,
    })
      .then(({ data: { data } }) => setUnicChatName(data.channel))
      .catch((error) => console.info(error));
    // eslint-disable-next-line
  }, [appointmentId, setUnicChatName])

  React.useEffect(() => {
    if (isChannelsLoaded && unicChatName) {
      const chatChannelsSnap = chatChannels.value;
      setChatChannel(chatChannelsSnap.find((item) => item.uniqueName === unicChatName));
    }
  }, [isChannelsLoaded, unicChatName, chatChannels, setChatChannel]);

  if (!isChannelsLoaded || !unicChatName) {
    return (
      <ColumnPlate>
        <ChatSimpleHeader />
      </ColumnPlate>
    );
  }

  if (!channel) {
    return (
      <ColumnPlate>
        <ChatWrongHeader />
      </ColumnPlate>
    );
  }

  return <ChatContent channel={channel} />;
});
