import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';

export default function List({ appointments = [] }) {
  return (
    <StyledList>
      {appointments.map((appointment, i) => {
        const id = get(appointment, 'id', i);
        const startedAt = get(appointment, 'started_at', '');
        const formattedDate = moment(startedAt).format('MM.DD.YYYY h:mma');
        const providerName = get(appointment, 'provider.full_name', '');
        const status = get(appointment, 'status', '');

        return (
          <li key={id} className="g-mt-15">
            <p className="date g-mb-5">{moment(startedAt).isValid() && formattedDate}</p>
            <p className="bottom g-d-flex g-justify-between">
              <span className="provider">
                Provider: <RegularText as="span">{providerName}</RegularText>
              </span>
              <span className="status">{status}</span>
            </p>
          </li>
        );
      })}
    </StyledList>
  );
}

const StyledList = styled.ul`
  list-style: none;
  padding: 0 2rem 3.5rem;
  p {
    line-height: 1.3;
  }
  li {
    padding: 0 0 1rem;
    &:not(:last-child) {
      border-bottom: 0.1rem solid rgba(34, 50, 50, 0.1);
    }
  }
  .date {
    color: rgba(0, 0, 0, 0.5);
  }
  .bottom {
    align-items: baseline;
  }
  .provider {
    color: rgba(0, 0, 0, 0.5);
  }
  .status {
    padding-left: 0.2rem;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }
`;
