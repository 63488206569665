import React from 'react';
import styled from 'styled-components';

export default React.memo(function CustomRadio({
  className = '',
  value,
  label,
  checked,
  changeHandler,
}) {
  return (
    <StyledLabel
      className={className}
      checked={checked}
      onClick={React.useCallback(() => changeHandler(value), [changeHandler, value])}
    >
      {label}
      <span className="checkmark" />
    </StyledLabel>
  );
});

const StyledLabel = styled.label`
  display: block;
  position: relative;
  font-size: 1.3rem;
  line-height: 3rem;
  padding-left: 2.5rem;
  cursor: pointer;
  &:hover {
    .checkmark {
      ${({ checked }) =>
        !checked &&
        `
        border: solid 1px #979797;
      `}
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 1.6rem;
    width: 1.6rem;
    background: #fff;
    border: solid 1px #ccc;
    border-radius: 50%;
    ${({ checked }) =>
      checked &&
      `
      border: solid 0.5rem #16123f;
    `}
  }
`;
