export const PATIENT_FORMS = 'patient-forms';
export const PRE_ASSESSMENT = 'pre-assessment';
export const PATIENT_MEDICAL_HISTORY = 'patient-medical-history';
export const MENTAL_HEALTH = 'mental-health';
export const DIGESTIVE_HEALTH = 'digestive-health';

export const SURVEY = 'survey';
export const JSON_FORM = 'json-form';
export const CONSENT_FORM = 'consent-form';
export const GLOBAL_PHYSICAL_HEALTH = 'globalPhysicalHealth';
export const GLOBAL_MENTAL_HEALTH = 'globalMentalHealth';
export const RAW_SCORE = 'RawScore';
export const T_SCORE = 'TScore';
export const METRIC_RULES = 'MetricRules';
