import React from 'react';

import { SquarePlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { MediumText } from 'common/texts';

export default React.memo(function EmptyUpcomingList({ className = '' }) {
  return (
    <SquarePlate className={className}>
      <PlateHeader>Upcoming</PlateHeader>

      <PlateContent>
        <MediumText className="g-mt-5">You have no upcoming events</MediumText>
      </PlateContent>
    </SquarePlate>
  );
});
