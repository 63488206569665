import get from 'lodash/get';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PATIENTS, DETAILS, OVERVIEW, SUMMARY, PROVIDERS } from 'constants/routes';
import { useUser } from 'hooks/useGlobalState';
import { useChatUserData } from 'hooks/useMessages';

import Menu from './Menu';
import { useUserContext } from '../../../../../context/userContext';
import { StyledUserAvatar } from '../../styles';

export default React.memo(function MemberListItem({ channelType, owner, member }) {
  const history = useHistory();
  const isOwner = useUser((current) => get(current, 'id') === owner, [owner]);
  const { isAdmin } = useUserContext();
  const memberData = useChatUserData(member);
  const type = get(memberData, 'type');
  const avatarUrl = get(memberData, 'avatar_url');
  const firstName = get(memberData, 'first_name');
  const lastName = get(memberData, 'last_name');
  const fullName = get(memberData, 'full_name');
  const qualification = get(memberData, 'qualification');

  const navigateToUser = React.useCallback(() => {
    if (type === 'patient') {
      return history.push(`/${PATIENTS}/${DETAILS}/${member.id}/${OVERVIEW}`);
    }

    if (isAdmin) {
      if (type === 'provider') {
        return history.push(`/${PROVIDERS}/${member.id}/${SUMMARY}`);
      }
      if (type === 'admin') {
        return history.push(`/${PROVIDERS}/${member.id}/${SUMMARY}`);
      }
    }
  }, [type, history, isAdmin, member]);

  const subName = React.useMemo(() => {
    if (type === 'patient') {
      return 'patient';
    }
    return qualification;
  }, [type, qualification]);

  const menu = React.useMemo(() => {
    if (member.id === owner) {
      return <div className="sub-name g-ml-auto">Owner</div>;
    }
    if (!isOwner && !isAdmin) {
      return null;
    }
    return <Menu channelType={channelType} owner={owner} type={type} member={member} />;
  }, [type, channelType, member, owner, isOwner]);

  return (
    <StyledWrapper>
      <StyledUserAvatar
        className="g-mr-10"
        firstName={firstName}
        lastName={lastName}
        avatar={avatarUrl}
        onClick={memberData && navigateToUser}
      />

      <div>
        <div className="name">{fullName}</div>
        <div className="sub-name">{subName}</div>
      </div>

      {menu}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 30rem;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0rem;
  }
  .name {
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 500;
  }
  .sub-name {
    font-size: 1rem;
    font-weight: 500;
    color: #bbc0c0;
    text-transform: uppercase;
  }
`;
