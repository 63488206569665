import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButton from './CustomButton';
import Modal from './Modal';
import { RegularText } from './texts';

const text = 'You have unsaved changes. Are you sure want to leave this page ?';

export default function RouterPrompt({ when }) {
  const history = useHistory();
  const unblockRef = React.useRef();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('');

  React.useEffect(() => {
    function beforeunloadFunc(event) {
      event.returnValue = text;
      return 'true';
    }
    if (when) {
      window.addEventListener('beforeunload', beforeunloadFunc);
    } else {
      window.removeEventListener('beforeunload', beforeunloadFunc);
    }
    return () => window.removeEventListener('beforeunload', beforeunloadFunc);
  }, [when]);

  React.useEffect(() => {
    if (when) {
      unblockRef.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    }
  }, [when, unblockRef, history]);

  React.useEffect(() => {
    if (!when && unblockRef.current) {
      unblockRef.current();
    }
  }, [when, unblockRef]);

  const handleOK = React.useCallback(() => {
    unblockRef.current && unblockRef.current();
    history.push(currentPath);
  }, [currentPath, history]);

  const handleCancel = React.useCallback(() => {
    setShowPrompt(false);
  }, [setShowPrompt]);

  if (!showPrompt) {
    return null;
  }

  return (
    <Modal title="You have unsaved changes" minWidth={44} maxWidth={44} closeModal={handleCancel}>
      <RegularText className="g-mt-20 g-text-center">
        Hey there! The changes you made will be lost if you navigate away from this page.
      </RegularText>
      <RegularText className="g-mt-10  g-text-center">
        Are you sure you want to leave this page?
      </RegularText>
      <div className="g-d-flex g-mt-20">
        <CustomButton className="g-w-50 g-mr-10" colorScheme="decline" clickHandler={handleOK}>
          Leave
        </CustomButton>
        <CustomButton inversion className="g-w-50 g-ml-10" clickHandler={handleCancel}>
          Cancel
        </CustomButton>
      </div>
    </Modal>
  );
}
