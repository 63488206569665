import get from 'lodash/get';
import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { FlexLine, PlateContent } from 'common/plate/styles';
import ProgramHeader from 'components/programs/ProgramHeader';
import {
  useLocalProgram,
  useProgram,
  useSetLocalOptions,
  useSetLocalProgram,
  useSetProgram,
} from 'hooks/usePrograms';
import { useFetchOptions, useFetchProgram } from 'requests/programs';

import ProgramFeatures from './ProgramFeatures';
import ProgramVisits from './ProgramVisits';
import CustomSelect from '../../common/CustomSelect';
import CustomTextarea from '../../common/CustomTextarea';
import InputWithLabel from '../../common/InputWithLabel';
import { BoldBigText } from '../../common/texts';
import UpdateButton from '../../components/programs/UpdateButton';

export default function Program() {
  const fetchProgram = useFetchProgram();
  const clearProgram = useSetProgram(() => null, []);

  const fetchOptions = useFetchOptions();
  const clearLocalOptions = useSetLocalOptions(() => null, []);

  React.useEffect(() => {
    fetchProgram();
    return clearProgram;
  }, []);

  React.useEffect(() => {
    fetchOptions();
    return clearLocalOptions;
  }, []);

  const monthes = [
    { label: '3 month', value: '3' },
    { label: '6 month', value: '6' },
    { label: '9 month', value: '9' },
    { label: '12 month', value: '12' },
  ];

  const name = useProgram((current) => get(current, 'name', ''), []);

  const months_of_care = useLocalProgram((current) => get(current, 'months_of_care', ''), []);
  const one_time_fee = useLocalProgram((current) => get(current, 'one_time_fee', ''), []);
  const price = useLocalProgram((current) => get(current, 'price', ''), []);
  const paypal_id = useLocalProgram((current) => get(current, 'paypal_id', ''), []);
  const description = useLocalProgram((current) => get(current, 'description', ''), []);
  const billed = useLocalProgram((current) => get(current, 'billed', ''), []);
  const discount = useLocalProgram((current) => get(current, 'discount', ''), []);

  const qualification_visits = useLocalProgram(
    (current) => get(current, 'qualification_visits', ''),
    [],
  );
  const options = useProgram((current) => get(current, 'options', ''), []);

  const setPrice = useSetLocalProgram((prev, next) => {
    const validNextValue = next >= 0 ? next : prev.price;
    return { ...prev, price: validNextValue };
  }, []);
  return (
    <WideRowPlate>
      <ProgramHeader />
      <PlateContent>
        <BoldBigText className="g-mb-20 pt-[2rem]">About program</BoldBigText>
        <FlexLine>
          <InputWithLabel
            className="g-mt-20 g-mr-25"
            label="Program name"
            placeholder={name}
            isValid={true}
            value={name}
            changeHandler={useSetLocalProgram((prev, name) => ({ ...prev, name }), [])}
            isLoading={false}
            disabled
          />
        </FlexLine>
        <FlexLine>
          <InputWithLabel
            className="g-mt-20 g-mr-25"
            label="Billed"
            placeholder={billed}
            isValid={true}
            value={billed}
            isLoading={false}
            disabled
          />
          <CustomSelect
            className="g-mt-20 g-mr-25"
            label="Duration"
            placeholder={months_of_care}
            isValid={true}
            value={months_of_care}
            options={monthes}
            changeHandler={useSetLocalProgram(
              (prev, months_of_care) => ({ ...prev, months_of_care }),
              [],
            )}
            isLoading={false}
            disabled
          />
        </FlexLine>
        <FlexLine>
          <InputWithLabel
            className="g-mt-20 g-mr-25"
            label="Pay per month"
            placeholder={price}
            isValid={true}
            value={price}
            changeHandler={setPrice}
            isLoading={false}
          />
          <InputWithLabel
            className="g-mt-20 g-mr-25"
            label="Discount"
            placeholder={one_time_fee}
            isValid={true}
            value={discount}
            changeHandler={useSetLocalProgram((prev, discount) => ({ ...prev, discount }), [])}
            isLoading={false}
          />
        </FlexLine>
        <FlexLine>
          <InputWithLabel
            className="g-mt-20 g-mr-25"
            label="Paypal subscription plan ID"
            placeholder={paypal_id}
            isValid={true}
            value={paypal_id}
            changeHandler={useSetLocalProgram((prev, paypal_id) => ({ ...prev, paypal_id }), [])}
            isLoading={false}
          />
        </FlexLine>
        <FlexLine>
          <CustomTextarea
            className="g-mt-15"
            label="Program Description"
            placeholder={description}
            isRequred
            changeHandler={useSetLocalProgram(
              (prev, description) => ({ ...prev, description }),
              [],
            )}
            isValid={true}
            value={description}
          />
        </FlexLine>

        {qualification_visits && <ProgramVisits visits={qualification_visits} />}

        {options && <ProgramFeatures />}

        <UpdateButton />
      </PlateContent>
    </WideRowPlate>
  );
}
