import React from 'react';

import { FlexLine } from 'common/plate/styles';
import ChatContent from 'components/messages/ChatContent';
import ChatsList from 'components/messages/ChatsList';

export default function Messages() {
  return (
    <FlexLine>
      <ChatsList />

      <ChatContent />
    </FlexLine>
  );
}
