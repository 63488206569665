import React from 'react';
import styled from 'styled-components';

import CalendarHeader from '../CalendarHeader';

export default React.memo(function DayHeader({ date, setDate }) {
  return (
    <CalendarHeader
      onPrev={React.useCallback(() => setDate(date.clone().add(-1, 'days')), [date, setDate])}
      onNext={React.useCallback(() => setDate(date.clone().add(1, 'days')), [date, setDate])}
    >
      <StyledWrapper>
        <div className="day">{date.format('DD')}</div>
        <div className="week-day">{date.format('dddd')}</div>
      </StyledWrapper>
    </CalendarHeader>
  );
});

const StyledWrapper = styled.div`
  text-align: center;
  .day {
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 600;
  }
  .week-day {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;
