import get from 'lodash/get';
import React from 'react';

import CustomTextarea from 'common/CustomTextarea';
import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients';

export default React.memo(function SymptomsDescriptionList({ symptoms }) {
  const symptomsIds = React.useMemo(() => {
    return symptoms.map((item) => item.value);
  }, [symptoms]);
  const groupSymptoms = usePatinetDoctorNote((current) => {
    const currentSymptoms = get(current, 'symptoms', []);
    return currentSymptoms.filter((item) => symptomsIds.includes(item.value));
  });
  const updateText = useSetPatinetDoctorNote((prev, value, newText) => {
    const newPrev = { ...prev };
    const currentSymptoms = get(prev, 'symptoms', []);
    newPrev.symptoms = currentSymptoms.map((item) => {
      if (item.value === value) {
        return { ...item, text: newText };
      }
      return item;
    });
    return newPrev;
  }, []);

  return React.useMemo(() => {
    return groupSymptoms.map((symptom) => {
      const isText = get(symptom, 'has_free_text');
      if (!isText) {
        return null;
      }
      const label = get(symptom, 'label');
      const value = get(symptom, 'value');
      const text = get(symptom, 'text');
      return (
        <CustomTextarea
          key={value}
          className="g-mt-20"
          label={label}
          placeholder="Free text"
          value={text}
          changeHandler={(newText) => updateText(value, newText)}
        />
      );
    });
  }, [groupSymptoms, updateText]);
});
