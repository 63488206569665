import get from 'lodash/get';
import React from 'react';

import { MEDICATIONS_NAME, SUPPLEMENTS_NAME } from 'constants/patient-overview';
import { usePatientOverview } from 'hooks/usePatients';

import Medicines from './Medicines';
import Questionnaires from './Questionnaires';

export default function QuestionnairesOverview() {
  const patientOverview = usePatientOverview();
  const questionnaires = get(patientOverview, 'questionnaires', []);

  if (questionnaires.length === 0) {
    return null;
  }

  return questionnaires.map((questionnaire) => {
    const data = get(questionnaire, 'answer', null);
    if (!data) {
      return null;
    }
    let parsedData = {};
    try {
      parsedData = JSON.parse(data);
    } catch {
      return null;
    }
    const id = get(questionnaire, 'id');
    const name = get(questionnaire, 'name', '');
    const pages = get(parsedData, 'pages', []);
    const answers = get(parsedData, 'answers', {});

    if (MEDICATIONS_NAME in answers || SUPPLEMENTS_NAME in answers) {
      return <Medicines key={id} title={name} answers={answers} />;
    }

    return <Questionnaires key={id} title={name} answers={answers} pages={pages} />;
  });
}
