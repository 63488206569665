import get from 'lodash/get';
import React from 'react';

import Spinner from 'common/loaders/Spinner';
import { useConsultation } from 'hooks/useAppointments';

import FullIconComponent from './FullIconComponent';
import MainVideoComponent from './MainVideoComponent';

export default React.memo(function MainVideoComponentLoader({ placeholder = '' }) {
  const room = useConsultation((current) => get(current, 'room'));
  const participant = useConsultation((current) => get(current, 'participants[0]'));

  if (!room) {
    return <Spinner className="loader" />;
  }

  if (!participant) {
    return <div className="not-connected">{placeholder}</div>;
  }

  return (
    <>
      <MainVideoComponent participant={participant} />
      <FullIconComponent />
    </>
  );
});
