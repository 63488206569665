import React from 'react';
import styled from 'styled-components';

export default React.memo(function AppointmentsPlaceholder() {
  return (
    <StyledWrapper>
      <div className="placeholder" />
      <div className="placeholder" />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .placeholder {
    background: #fafbfd;
    height: 8.5rem;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 480px) {
      height: 16rem;
    }
  }
`;
