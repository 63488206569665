import React from 'react';

import { PlateHeader } from 'common/plate/styles';

import BackHandler from './BackHandler';

export default React.memo(function QuizMetricsHeader() {
  return (
    <PlateHeader>
      <BackHandler />
      Metrics
    </PlateHeader>
  );
});
