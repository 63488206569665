import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { MESSAGES, INVITE } from 'constants/routes';

export default React.memo(function InviteUsers() {
  const history = useHistory();
  const { channelSID } = useParams();

  return (
    <StyledWrapper
      className="g-mt-10"
      onClick={() => history.push(`/${MESSAGES}/${channelSID}/${INVITE}`)}
    >
      + Invite the provider or patient
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  font-size: 1rem;
  color: #bbc0c0;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
