import React from 'react';
import { useParams, Redirect } from 'react-router-dom';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import NavigationTabs from 'components/patients/questionnaires/questionnaires/NavigationTabs';
import QuizzesList from 'components/patients/questionnaires/questionnaires/quizzes-list';
import { PRE_ASSESSMENT } from 'constants/quiz-types';
import { PATIENTS, DETAILS, QUESTIONNAIRES } from 'constants/routes';
import { useQuizzesData } from 'hooks/patient/useQuestionnaires';
import { useFetchQuizesData } from 'requests/patients/questionnaires';

export default function PatientQuestionnaires() {
  const { patientId, type } = useParams();
  const fetchQuizesData = useFetchQuizesData();
  const { isLoading, quizGroup } = useQuizzesData(
    (current) => {
      if (!type) {
        return { isLoading: false, quizGroup: null };
      }
      if (!current) {
        return { isLoading: true, quizGroup: null };
      }
      const quizGroup = current[type];
      if (!quizGroup) {
        return { isLoading: false, quizGroup: null };
      }
      return { isLoading: false, quizGroup };
    },
    [type],
  );

  React.useEffect(() => {
    fetchQuizesData();
    // eslint-disable-next-line
  }, [type])

  if (!isLoading && !quizGroup) {
    return (
      <Redirect to={`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${PRE_ASSESSMENT}`} />
    );
  }

  return (
    <DetailsTemplate currentTab={QUESTIONNAIRES}>
      <NavigationTabs />
      <QuizzesList />
    </DetailsTemplate>
  );
}
