import React from 'react';
import styled from 'styled-components';

import TimeLine from '../TimeLine';
import DataLine from './DataLine';

const TIME_LINE = 'time-line';
const DAYS_CONTAINER = 'days-container';

const HOURS = [
  { value: '00:00:00', label: '' },
  { value: '01:00:00', label: '01:00 AM' },
  { value: '02:00:00', label: '02:00 AM' },
  { value: '03:00:00', label: '03:00 AM' },
  { value: '04:00:00', label: '04:00 AM' },
  { value: '05:00:00', label: '05:00 AM' },
  { value: '06:00:00', label: '06:00 AM' },
  { value: '07:00:00', label: '07:00 AM' },
  { value: '08:00:00', label: '08:00 AM' },
  { value: '09:00:00', label: '09:00 AM' },
  { value: '10:00:00', label: '10:00 AM' },
  { value: '11:00:00', label: '11:00 AM' },
  { value: '12:00:00', label: '12:00 PM' },
  { value: '13:00:00', label: '01:00 PM' },
  { value: '14:00:00', label: '02:00 PM' },
  { value: '15:00:00', label: '03:00 PM' },
  { value: '16:00:00', label: '04:00 PM' },
  { value: '17:00:00', label: '05:00 PM' },
  { value: '18:00:00', label: '06:00 PM' },
  { value: '19:00:00', label: '07:00 PM' },
  { value: '20:00:00', label: '08:00 PM' },
  { value: '21:00:00', label: '09:00 PM' },
  { value: '22:00:00', label: '10:00 PM' },
  { value: '23:00:00', label: '11:00 PM' },
];

export default React.memo(function WorkWeekGrid({ workWeekStart }) {
  const content = React.useMemo(() => {
    const gridConfig = [];

    HOURS.forEach((item) => {
      gridConfig.push({ type: TIME_LINE, ...item });
      const days = new Array(5).fill(null).map((item, i) => workWeekStart.clone().add(i, 'days'));
      gridConfig.push({ type: DAYS_CONTAINER, ...item, days });
    });

    gridConfig.push({ type: TIME_LINE, value: '24:00:00', label: '' });

    return gridConfig.map(({ type, value, label, days }, i) => {
      if (type === TIME_LINE) {
        return <TimeLine key={i} label={label} />;
      }
      if (type === DAYS_CONTAINER) {
        return <DataLine key={i} hour={value} days={days} />;
      }
      return null;
    });
  }, [workWeekStart]);

  return <StyledWrapper className="g-mb-30">{content}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  max-height: 53rem;
  overflow-y: auto;
`;
