export default {
  login: {
    title: 'Provider login',
    loginPlaceholder: 'Login or email',
    passwordPlaceholder: 'Password',
    submitButton: 'Login',
    forgotLink: 'Forgot password?',
  },
  twoFALogin: {
    title: 'Two-factor authentication',
  },
  forgotPassword: {
    title: 'Restore your password',
    hint: 'Your new password will be sent to this email',
    emailPlaceholder: 'Enter email',
    submitButton: 'Restore',
    signInLink: 'Sign in',
  },
  footer: {
    settings: 'Settings',
    privacy: 'Privacy',
    help: 'Help',
    refer: 'Refer a patient',
    logout: 'Logout',
  },
  sidebar: {
    dashboard: 'Dashboard',
    patients: 'Patients',
    companies: 'Companies',
    providers: 'Providers',
    appointments: 'Appointments',
    messages: 'Messages',
    reports: 'Reports',
    library: 'Library',
    programs: 'Programs',
  },
};
