import get from 'lodash/get';

import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState';

export const useWidgetsList = createUseGlobalState('widgetsList');
export const useSetWidgetsList = createUseSetGlobalState('widgetsList');

export const useWidgetFilters = createUseGlobalState('widgetFilters');
export const useSetWidgetFilters = createUseSetGlobalState('widgetFilters');

export const useWidget = createUseGlobalState('widget');
export const useSetWidget = createUseSetGlobalState('widget');

export function useDaysData(field) {
  return useWidget(
    (current) => {
      const data = get(current, 'data', []);
      return data.map((item) => {
        return {
          date: item.summaryDate,
          value: item[field],
        };
      });
    },
    [field],
  );
}

export function useDayData(field) {
  const selectedDay = useWidgetFilters((current) => current.selectedDay);
  return useWidget(
    (current) => {
      const data = get(current, 'data', []);
      const day = data.find((item) => item.summaryDate === selectedDay) || {};
      if (field) {
        return day[field];
      }
      return day;
    },
    [selectedDay, field],
  );
}
