import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import LinkButton from 'common/LinkButton';
import Modal from 'common/Modal';
import { RegularText } from 'common/texts';

import { LOGOUT } from '../../constants/routes';

export default React.memo(function ExpirationModal({ closeModal }) {
  const history = useHistory();
  const [counter, setCounter] = React.useState(60);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCounter((current) => current - 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [])

  const logout = React.useCallback(() => history.push(`/${LOGOUT}`), [history]);

  React.useEffect(() => {
    if (counter <= 0) {
      logout();
    }
  }, [counter, logout]);

  return (
    <Modal
      title="Your session will expire!"
      background="rgb(241, 245, 248)"
      minWidth="40"
      maxWidth={40}
      closeModal={closeModal}
    >
      <RegularText className="g-mt-20">You will be logged out in {counter} seconds.</RegularText>
      <RegularText className="g-mt-20">Click “Continue” if you`d like to stay.</RegularText>

      <StyledWrapper>
        <CustomButton clickHandler={closeModal}>Continue</CustomButton>
        <LinkButton className="logout" onClick={logout}>
          Logout
        </LinkButton>
      </StyledWrapper>
    </Modal>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .logout {
    padding: 0.9rem 5rem;
  }
`;
