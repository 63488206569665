import React from 'react';
import styled from 'styled-components';

import { MediumText } from 'common/texts';

export default React.memo(function CardTemplate({ children, title = '' }) {
  return (
    <StyledWrapper>
      <MediumText className="title">{title}</MediumText>
      {children}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 28rem;
  margin-bottom: 2.5rem;
  background: #f8faff;
  border-radius: 0.6rem;
  break-inside: avoid;
  @media screen and (max-width: 1024px) {
    max-width: 35rem;
    margin-bottom: 2rem;
  }
  .title {
    padding: 1.4rem 2rem;
    border-bottom: 0.1rem solid rgba(34, 50, 50, 0.1);
  }
`;
