import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomCheckbox from 'common/CustomCheckbox';
import CustomSelect from 'common/CustomSelect';
import LocalSwitcher from 'common/local-tabs/LocalSwitcher';
import { PATIENTS, ACTIVE, INACTIVE } from 'constants/routes';
import { useCompanies } from 'hooks/useCompanies';
import { usePatinetsFilter, useSetPatinetsFilter } from 'hooks/usePatients';

import PatientSelect from './PatientSelect';

const MY = 'my';
const ALL = 'all';
const typesConfig = [MY, ALL];

const defaultOptions = [
  { value: MY, label: 'My patients' },
  { value: ALL, label: 'All patients' },
];

const tabsConfig = [
  { id: ACTIVE, label: 'Active' },
  { id: INACTIVE, label: 'Inactive' },
];

export default React.memo(function PatinetsNavigation() {
  const { status } = useParams();
  const history = useHistory();
  const selectedOption = usePatinetsFilter((current) => {
    const byCompany = get(current, 'by_company');
    if (byCompany) {
      return byCompany;
    }
    const type = get(current, 'type', MY);
    return type;
  }, []);
  const searchText = usePatinetsFilter((current) => get(current, 'search', ''), []);

  const [searchLocalValue, setSearchLocalValue] = React.useState(searchText);

  const followUpRequired = usePatinetsFilter((current) => {
    return get(current, 'follow_up_required', 0);
  }, []);

  const setFilterByType = useSetPatinetsFilter(
    (prev, type) => ({ ...prev, type, by_company: '' }),
    [],
  );
  const setFilterByCompany = useSetPatinetsFilter(
    (prev, byCompany) => ({ ...prev, type: ALL, by_company: byCompany }),
    [],
  );
  const setSearch = useSetPatinetsFilter((prev, search) => ({ ...prev, search }), []);

  const companies = useCompanies();

  const options = React.useMemo(() => {
    let companiesOptions = [];
    if (companies) {
      companiesOptions = companies.map((company) => ({ value: company.id, label: company.name }));
    }
    return [...defaultOptions, ...companiesOptions];
  }, [companies]);

  const statusHandler = React.useCallback(
    (value) => {
      history.push(`/${PATIENTS}/${value}`);
    },
    [history],
  );

  const setFilterByFollowUpRequired = useSetPatinetsFilter(
    (prev) => ({
      ...prev,
      type: prev.follow_up_required ? prev.type : ALL,
      follow_up_required: prev.follow_up_required ? 0 : 1,
    }),
    [],
  );

  const searchHandler = React.useCallback(
    debounce((value) => {
      const search = new URLSearchParams(history.location.search);
      if (search.get('page')) {
        search.delete('page');
        localStorage.removeItem('search');
        history.push(`/${PATIENTS}/${status}?${search.toString()}`);
      }
      setSearch(value);
    }, 800),
    [history],
  );

  const typeHandler = React.useCallback(
    (value) => {
      const search = new URLSearchParams(history.location.search);
      search.delete('page');
      localStorage.removeItem('search');
      if (typesConfig.includes(value)) {
        setFilterByType(value);
      } else {
        setFilterByCompany(value);
      }
      history.push(`/${PATIENTS}/${status}?${search.toString()}`);
    },
    [setFilterByType, setFilterByCompany, history, status],
  );

  React.useEffect(() => {
    searchHandler(searchLocalValue);
  }, [searchLocalValue]);
  return (
    <StyledWrapper>
      <div className="panel">
        <LocalSwitcher tabsConfig={tabsConfig} currentTab={status} setCurrentTab={statusHandler} />
        <div className="filter-wrapper g-d-flex">
          <PatientSelect
            onClear={() => setSearchLocalValue('')}
            value={searchLocalValue}
            onChange={setSearchLocalValue}
          />

          <CustomSelect
            className="select"
            options={options}
            isLoading={!companies}
            value={selectedOption}
            changeHandler={typeHandler}
          />
        </div>
      </div>
      <div className="follow-up">
        {status === ACTIVE ? (
          <CustomCheckbox
            label="Follow-up required"
            checked={followUpRequired}
            changeHandler={() => setFilterByFollowUpRequired()}
          />
        ) : (
          ''
        )}
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      display: block;
    }
    .filter-wrapper {
      @media screen and (max-width: 768px) {
        margin-top: 2rem;
      }
      .select {
        width: 20rem;
        margin-left: 2rem;
        @media screen and (max-width: 480px) {
          max-width: 20rem;
          margin-left: auto;
        }
        .loader-select {
          height: 100%;
        }
      }
    }
  }
  .follow-up {
    margin-top: 0.5rem;
    align-self: flex-end;
  }
  margin: 2rem 0 4.5rem 0;
`;
