import React from 'react';
import styled, { css } from 'styled-components';

import { MediumText } from 'common/texts';

import Icon from '../common/Icon';

export default function MedicationHeader({ icon, medicationName, description }) {
  return (
    <StyledWrapper className="g-d-flex g-align-center">
      <Icon icon={icon} />
      <MediumText className="name g-ml-15">
        {medicationName}
        {description && <span className="description">{description}</span>}
      </MediumText>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: ${({ description }) => (description ? '40' : '37')}%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .name {
    line-height: 1.5;
  }
  ${({ description }) =>
    !description &&
    css`
      .description {
        display: block;
        font-size: 1.2rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    `}
`;
