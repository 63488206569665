import get from 'lodash/get';

export default function getMedicines({ title, medicines }) {
  return { title, values: getValues(medicines) };
}

function getValues(data) {
  return data
    .map((item) => {
      let values = [];
      item.forEach((item) => {
        const value = get(item, 'value', null);
        if (value) {
          values.push(value);
        }
      });
      return values;
    })
    .map((value) => {
      if (value.length > 1) {
        value[0] = value[0] + ' -';
        return value.join(' ');
      }
      return value;
    });
}
