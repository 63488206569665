import styled from 'styled-components';

export const StyledButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 3rem;
  font-size: 1.3rem;
  text-align: center;
  border: solid 1px #fff;
  border-radius: 0.8rem;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  cursor: pointer;
  :hover {
    border: solid 1px #16123f;
  }
  :active {
    background: #16123f;
    color: #fff;
    .icon {
      path {
        fill: #fff;
      }
    }
  }
  .icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
    path {
      transition: all 0.2s;
    }
  }
`;
