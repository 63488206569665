import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ADMIN, GROUP, PRIVATE } from 'constants/messages';
import { useChatChannels } from 'hooks/useMessages';

import AdminHeader from './AdminHeader';
import GroupHeader from './GroupHeader';
import PrivateHeader from './PrivateHeader';

const itemsConfig = {
  [ADMIN]: AdminHeader,
  [GROUP]: GroupHeader,
  [PRIVATE]: PrivateHeader,
};

export default React.memo(function ChatHeader() {
  const { channelSID } = useParams();
  const channel = useChatChannels(
    (current) => current && current.find(({ sid }) => sid === channelSID),
    [channelSID],
  );
  const type = get(channel, 'state.attributes.type');

  const parsedComponent = React.useMemo(() => {
    if (!channel) {
      return null;
    }
    const Component = itemsConfig[type];
    if (!Component) {
      return console.warn(`Channel: ${channel.sid} with type: ${type} not implemented.`);
    }
    return <Component channel={channel} />;
  }, [type, channel]);

  return parsedComponent;
});
