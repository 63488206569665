import { GraphQLTypes, $, ValueTypes } from 'zeus-graphql/my-health/zeus';

import { GQLArgs, useMutation } from '../client';
import { CREATE_TREATMENT_PLAN } from '../constants';

type MutationResponse = Record<'createTreatmentPlan', GraphQLTypes['TreatmentPlanType']>;

type Vars = {
  data: ValueTypes['TreatmentPlanInput'];
};

type Args = GQLArgs<MutationResponse>;

export const useCreateTreatmentPlan = (args?: Args) => {
  const instance = useMutation(
    [CREATE_TREATMENT_PLAN],
    {
      createTreatmentPlan: [
        {
          data: $`data`,
        },
        {
          id: true,
        },
      ],
    },
    {
      operationName: CREATE_TREATMENT_PLAN,
    },
    args,
  );

  const createTreatmentPlan = instance.mutateAsync as (vars: Vars) => Promise<MutationResponse>;

  return { ...instance, createTreatmentPlan };
};
