import React from 'react';

import AvailableTime from 'components/profile/availability/available-time/index';
import ProfileTemplate from 'components/profile/ProfileTemplate';
import { AVAILABILITY } from 'constants/routes';

export default function Availability() {
  return (
    <ProfileTemplate currentTab={AVAILABILITY}>
      <AvailableTime />
    </ProfileTemplate>
  );
}
