import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ALL, PROVIDERS, ADMIN_NOTES } from 'constants/routes';
import { globalContext } from 'hooks/useGlobalState';
import { useSetTimes } from 'hooks/useProfile';
import {
  useSetProvidersList,
  useSetProviderProfile,
  useSetProviderAppointments,
  useSetProviderAdminNotes,
  useSetProviderAdminNote,
} from 'hooks/useProviders';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

import { useProviderQualificationsStore } from '../stores/provider/qualificationStores';

export function useFetchProviders() {
  const setProvidersList = useSetProvidersList((prev, next) => next, []);
  return React.useCallback(
    ({ qualification, search }) => {
      if (typeof qualification === 'undefined' || typeof search === 'undefined') {
        return;
      }
      const params = {};
      params.qualifications = qualification === ALL ? [] : [qualification];
      if (search) {
        params.search = search;
      }
      request({
        method: 'get',
        url: '/providers',
        params,
      })
        .then(
          ({
            data: {
              data: { providers },
            },
          }) => {
            setProvidersList(providers);
          },
        )
        .catch((error) => console.info(error));
    },
    [setProvidersList],
  );
}

export function useFetchProviderSummary() {
  const { providerId } = useParams();
  const history = useHistory();
  const setProviderProfile = useSetProviderProfile((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/providers/${providerId}/details`,
    })
      .then(
        ({
          data: {
            data: { profile },
          },
        }) => {
          setProviderProfile(profile);
        },
      )
      .catch((error) => {
        console.info(error);
        history.push(`/${PROVIDERS}`);
      });
  }, [providerId, setProviderProfile, history]);
}

export function useFetchProviderAppointments() {
  const { providerId } = useParams();
  const history = useHistory();
  const setProviderAppointments = useSetProviderAppointments((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/appointments',
      params: {
        provider: providerId,
        orderBy: 'started_at',
        sortedBy: 'desc',
      },
    })
      .then(
        ({
          data: {
            data: { appointments },
          },
        }) => {
          setProviderAppointments(appointments);
        },
      )
      .catch((error) => {
        console.info(error);
        history.push(`/${PROVIDERS}`);
      });
  }, [providerId, setProviderAppointments, history]);
}

export function useFetchProviderInformation() {
  const { providerId } = useParams();
  const history = useHistory();
  const setProviderProfile = useSetProviderProfile((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/providers/${providerId}`,
    })
      .then(
        ({
          data: {
            data: { profile },
          },
        }) => {
          setProviderProfile(profile);
        },
      )
      .catch((error) => {
        console.info(error);
        history.push(`/${PROVIDERS}`);
      });
  }, [providerId, setProviderProfile, history]);
}

export function useFetchProviderQualification() {
  const { providerId } = useParams();
  const history = useHistory();
  const setProviderQualification = useProviderQualificationsStore(
    (s) => s.setProviderQualifications,
  );
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/providers/${providerId}/qualification`,
    })
      .then(({ data: { data } }) => {
        setProviderQualification(data);
      })
      .catch((error) => {
        console.info(error);
        history.push(`/${PROVIDERS}`);
      });
  }, [providerId, setProviderQualification, history]);
}

export function useUpdateProviderQualification() {
  const { providerId } = useParams();

  const qualification = useProviderQualificationsStore((s) => s.providerQualifications);
  const setQualification = useProviderQualificationsStore((s) => s.setProviderQualifications);

  return React.useCallback(() => {
    request({
      method: 'post',
      url: `/providers/${providerId}/qualification`,
      data: qualification,
    })
      .then(({ data: { data } }) => {
        setQualification(data);
        createNotification({
          // eslint-disable-next-line quotes
          message: "Provider's Qualification updated",
          type: 'success',
        });
      })
      .catch((error) => console.info(error));
  }, [qualification, setQualification]);
}

export function useFetchProviderTimes() {
  const { providerId } = useParams();
  const history = useHistory();
  const setTimesLoading = useSetTimes((prev, loading) => ({ ...prev, loading }), []);
  const setTimes = useSetTimes(
    (prev, work_time, timezone, loading) => ({
      ...prev,
      loading,
      timezone,
      work_time,
      original: work_time,
    }),
    [],
  );
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/providers/${providerId}/times`,
    })
      .then(
        ({
          data: {
            data: { work_time, timezone },
          },
        }) => {
          setTimes(work_time, timezone, false);
        },
      )
      .catch((error) => {
        console.info(error);
        setTimesLoading(false);
      });
  }, [providerId, setTimes, history]);
}

export function useUpdateProviderTimes() {
  const { providerId } = useParams();
  const { user, times } = React.useContext(globalContext);
  const setTimesLoading = useSetTimes((prev, isLoading) => ({ ...prev, isLoading }), []);
  const setTimes = useSetTimes(
    (prev, work_time, loading) => ({
      ...prev,
      loading,
      work_time,
      original: work_time,
    }),
    [],
  );
  return React.useCallback(() => {
    setTimesLoading(true);

    const data = times.value.work_time;
    request({
      method: 'post',
      url: `/providers/${providerId}/work_time`,
      data,
    })
      .then(() => {
        setTimes(data, false);
        createNotification({
          message: 'Availability updated',
          type: 'success',
        });
      })
      .catch((error) => {
        console.info(error);
        setTimesLoading(false);
      });
  }, [user, setTimes, times]);
}

export function useFetchAdminNotes() {
  const { providerId } = useParams();
  const setProviderAdminNotes = useSetProviderAdminNotes((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/admins/notes',
      params: {
        provider: providerId,
      },
    })
      .then(
        ({
          data: {
            data: { adminNotes },
          },
        }) => {
          setProviderAdminNotes(adminNotes);
        },
      )
      .catch((error) => console.info(error));
  }, [providerId, setProviderAdminNotes]);
}

export function useCreateProviderAdminNote() {
  const history = useHistory();
  const { providerId } = useParams();
  const { providerAdminNote } = React.useContext(globalContext);
  return React.useCallback(() => {
    request({
      method: 'post',
      url: '/admins/notes',
      data: {
        provider: providerId,
        note: providerAdminNote.value.note,
      },
    })
      .then(() => {
        history.push(`/${PROVIDERS}/${providerId}/${ADMIN_NOTES}`);
      })
      .catch((error) => console.info(error));
  }, [providerId, providerAdminNote, history]);
}

export function useFetchProviderAdminNote() {
  const { noteId } = useParams();
  const setProviderAdminNote = useSetProviderAdminNote((prev, next) => next);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/admins/notes/${noteId}`,
    })
      .then(
        ({
          data: {
            data: { adminNote },
          },
        }) => {
          setProviderAdminNote(adminNote);
        },
      )
      .catch((error) => console.info(error));
  }, [noteId, setProviderAdminNote]);
}

export function useRemoveAdminNote(noteId) {
  const fetchAdminNotes = useFetchAdminNotes();
  return React.useCallback(() => {
    request({
      method: 'delete',
      url: `/admins/notes/${noteId}`,
    })
      .then(() => {
        fetchAdminNotes();
        createNotification({
          message: 'Administrative note removed',
          type: 'success',
        });
      })
      .catch((error) => console.info(error));
  }, [noteId, fetchAdminNotes]);
}
