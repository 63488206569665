import React from 'react';
import { useParams } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import { useIsQuizChanged } from 'hooks/patient/useQuestionnaires';
import { useSaveQuiz } from 'requests/patients/questionnaires';

export default React.memo(function SaveHandler() {
  const { quizId } = useParams();
  const saveQuiz = useSaveQuiz();
  const isChanged = useIsQuizChanged();

  const handleSave = React.useCallback(() => {
    saveQuiz({ quizId });
  }, [quizId, saveQuiz]);

  return (
    <CustomButton className="g-ml-15" disabled={!isChanged} inversion clickHandler={handleSave}>
      Save
    </CustomButton>
  );
});
