import React from 'react';

import { ReactComponent as HealthCoach } from 'assets/responsibilities/health-coach.svg';
import { ReactComponent as LifestyleHealth } from 'assets/responsibilities/lifestyle-health.svg';
import { ReactComponent as MensHealth } from 'assets/responsibilities/mens-health.svg';
import { ReactComponent as MentalHealth } from 'assets/responsibilities/mental-health.svg';
import { ReactComponent as NutritionAndWellness } from 'assets/responsibilities/nutrition-and-wellness.svg';
import { ReactComponent as WomensHealth } from 'assets/responsibilities/womens-health.svg';

const ACCEPTED_ICONS = {
  'mental-health': MentalHealth,
  'womens-health': WomensHealth,
  'mens-health': MensHealth,
  'lifestyle-health': LifestyleHealth,
  'nutrition-and-wellness': NutritionAndWellness,
  'health-coach': HealthCoach,
};

export default function getResponsibilityIconComponent(icon) {
  return ACCEPTED_ICONS[icon] || (() => <div className="icon-placeholder" />);
}
