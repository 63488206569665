import classNames from 'clsx';
import React, { FC } from 'react';

import { ReactComponent as EyeIcon } from 'assets/eye.svg';

import { CreateOutlineIcon } from '../../../../common/icons/create-outline';
import { Switch, Option } from '../../../../common/Switch';

export const viewItems: Option[] = [
  {
    label: 'Builder',
    value: 'builder',
    icon: ({ isActive }) => <CreateOutlineIcon mainColor={isActive ? 'white' : 'main'} />,
  },
  {
    label: 'Preview',
    value: 'preview',
    icon: ({ isActive }) => <EyeIcon className={isActive ? 'fill-white' : 'fill-main'} />,
  },
];

type ViewSwitchProps = {
  items: Option[];
  loading?: boolean;
  activeItem?: Option;
  setActiveItem: (item: Option) => void;
};

export const ViewSwitch: FC<ViewSwitchProps> = ({ items, activeItem, loading, setActiveItem }) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <div className="animate-pulse bg-catskillWhite w-32 h-8 rounded-md" />
        <div className="animate-pulse ml-4 bg-catskillWhite w-32 h-8 rounded-md" />
      </div>
    );
  }
  return (
    <Switch
      className="flex"
      items={items}
      activeItem={activeItem}
      onChange={setActiveItem}
      element={({ option, isActive, index, icon }) => (
        <div
          className={classNames('flex items-center cursor-pointer px-4 py-2 w-fit rounded-md', {
            'bg-manatee': isActive,
            'bg-white': !isActive,
            'ml-4': index !== 0,
          })}
        >
          {icon}
          <div
            className={classNames(' ml-3 font-ambit text-1416', {
              'text-white': isActive,
              'text-main': !isActive,
            })}
          >
            {option.label}
          </div>
        </div>
      )}
    />
  );
};
