import classNames from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';

import BackButton from '../../../../common/plate/BackButton';
import { DETAILS, PATIENTS, TREATMENT_PLANS } from '../../../../constants/routes';
import { InputField } from '../../../ui2.0/InputField';

type TreatmentPlanHeaderProps = {
  title?: string;
  loading?: boolean;
  isPreview?: boolean;
  isSaveActive?: boolean;
  patientId: string;
  isDirty?: boolean;
  onChangeTitle?: (str: string) => void;
  onSaveAndPush?: () => void;
  onReset?: () => void;
  onSave?: () => void;
};

export const TreatmentPlanHeader: FC<TreatmentPlanHeaderProps> = ({
  title,
  loading,
  patientId,
  isPreview,
  isSaveActive,
  isDirty,
  onReset,
  onChangeTitle,
  onSave,
  onSaveAndPush,
}) => {
  const [currentTitle, setCurrentTitle] = useState(title || '');
  const [isEditing, setIsEditing] = useState(false);

  const updateTitle = () => {
    onChangeTitle?.(currentTitle);
    setIsEditing(false);
  };

  useEffect(() => {
    setCurrentTitle(title || '');
  }, [title]);

  if (loading) {
    return (
      <div className="flex justify-between py-4 px-4">
        <div className="flex items-center">
          <div className="h-[3rem] w-[30rem] bg-catskillWhite animate-pulse"></div>
          <div className="ml-2 h-[3rem] w-[3rem] bg-catskillWhite animate-pulse"></div>
        </div>
        <div className="flex">
          <div className="h-[4rem] w-[15rem] bg-catskillWhite rounded-[2rem] animate-pulse"></div>
          <div className="h-[4rem] w-[18rem] bg-catskillWhite rounded-[2rem] animate-pulse ml-4"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between py-4 px-4">
      {!isEditing && (
        <div className="pl-6 flex items-center">
          <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`} />
          <div className="text-2430 font-bold text-main font-ambit mr-2">{currentTitle}</div>
          {!isPreview && (
            <div onClick={() => setIsEditing(true)} className="cursor-pointer">
              <EditIcon />
            </div>
          )}
        </div>
      )}
      {isEditing && (
        <div className="pl-6 flex items-center w-[30%]">
          <div className="text-2430 font-bold text-main font-ambit mr-1 w-full">
            <InputField
              className="text-2430 font-bold text-main font-ambit"
              value={currentTitle}
              onEnter={updateTitle}
              onChange={setCurrentTitle}
            />
          </div>
          <div onClick={updateTitle} className="cursor-pointer">
            <CheckmarkIcon />
          </div>
        </div>
      )}

      <div className="flex">
        {isDirty && (
          <div
            onClick={onReset}
            className="text-1423 font-bold font-ambit cursor-pointer uppercase tracking-[.3rem] rounded-[2rem] py-2 px-4 bg-errorBG text-error border border-error h-10 mr-4"
          >
            Reset Changes
          </div>
        )}
        <div
          onClick={onSave}
          className="text-1423 font-bold font-ambit cursor-pointer uppercase tracking-[.3rem] rounded-[2rem] py-2 px-4 bg-white text-main border border-main h-10"
        >
          Save Draft
        </div>
        <div
          onClick={onSaveAndPush}
          className={classNames(
            'ml-4 text-1423 font-bold font-ambit cursor-pointer uppercase tracking-[.3rem] rounded-[2rem] py-2 px-4 bg-main text-white border border-main h-10',
            {
              'opacity-20': !isSaveActive,
            },
          )}
        >
          Save and Send
        </div>
      </div>
    </div>
  );
};
