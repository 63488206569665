import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';

import { useMedicationsTab, useMedicationsSeries } from 'hooks/patient/useMedications';

import TooltipRow from '../grid/TooltipRow';

export default React.memo(function QuarterView() {
  const { from, to } = useMedicationsTab();
  const series = useMedicationsSeries();

  const options = React.useMemo(() => {
    return {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      markers: {
        size: 3,
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 10,
        min: +moment(from),
        max: +moment(to),
        labels: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ seriesIndex, dataPointIndex }) {
          return renderToString(
            <StyledTooltipWrapper>
              <TooltipRow
                medicationName={series[seriesIndex].name}
                progress={series[seriesIndex].data[dataPointIndex][1]}
              />
            </StyledTooltipWrapper>,
          );
        },
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              width: 1200,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 1000,
            },
          },
        },
      ],
    };
  }, [from, to, series]);

  return (
    <div className="g-flex-grow-1">
      <Chart type="line" options={options} series={series} width="100%" height={315} />
    </div>
  );
});

const StyledTooltipWrapper = styled.div`
  width: 20rem;
  padding: 1.5rem;
  background: #fff;
`;
