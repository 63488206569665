import React from 'react';
import styled from 'styled-components';

export default function DonutChart({ widgetId, className = '', value = 0 }) {
  const strokeColor = React.useMemo(() => {
    if (+value >= 70) {
      return '0, 147, 162';
    }
    if (+value > 40) {
      return '255, 199, 0';
    }
    if (!value || value <= 0) {
      return '150, 150, 150';
    }
    return '255, 107, 106';
  }, [value]);
  return (
    <div className={className}>
      <StyledSVG width="100%" height="100%" viewBox="0 0 42 42">
        <defs>
          <linearGradient id={`gradient-chart-${widgetId}`} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="25%" stopColor={'rgb(' + strokeColor + ')'} />
            <stop offset="100%" stopColor={'rgba(' + strokeColor + ', 0.2)'} />
          </linearGradient>
        </defs>
        <circle
          cx="21"
          cy="21"
          r="15.91549430918954"
          fill="none"
          stroke={value > 0 ? `url(#gradient-chart-${widgetId})` : 'rgb(150, 150, 150)'}
          strokeWidth="3"
          strokeDasharray={`${value > 0 ? value : '100'} 100`}
          strokeDashoffset="0"
        />
      </StyledSVG>
    </div>
  );
}

const StyledSVG = styled.svg`
  transform: rotate(-90deg);
`;
