import React from 'react';
import styled from 'styled-components';

import CustomTextarea from '../../common/CustomTextarea';
import InputWithLabel from '../../common/InputWithLabel';
import { FlexLine } from '../../common/plate/styles';
import { BoldBigText } from '../../common/texts';
import SelectOptionsButton from '../../components/programs/SelectOptionsButton';
import { Button } from '../../components/ui2.0/Button';
import { useLocalOptions, useOptions, useSetLocalOptions } from '../../hooks/usePrograms';

export default React.memo(function ProgramFeatures() {
  const commonOptions = useOptions();
  const careOptions = useLocalOptions();
  const deleteOption = useSetLocalOptions((prev, idToRemove) => {
    return Array.isArray(prev) ? prev.filter((id) => id !== idToRemove) : [];
  });

  const uniqueOptions = Array.from(new Set(careOptions));
  const parsedFeatures = React.useMemo(() => {
    return uniqueOptions?.map((optionId) => {
      const option = commonOptions?.find((opt) => opt.id.toString() === optionId);
      if (option) {
        return (
          <FlexLine key={option.id}>
            <InputWithLabel
              className="g-mt-20 g-mr-25"
              label="Feature"
              placeholder={option.name}
              isValid={true}
              value={option.name}
              changeHandler={() => console.info('changed')}
              isLoading={false}
              disabled
            />
            <CustomTextarea
              className="g-mt-20 g-mr-25"
              label="Description"
              placeholder={option.description ? option.description : ''}
              isValid={true}
              value={option.description ? option.description : ''}
              changeHandler={() => console.info('changed')}
              isLoading={false}
              readOnly
            />
            <Button className="g-mt-20" onClick={() => deleteOption(String(option.id))}>
              X
            </Button>
          </FlexLine>
        );
      }
      return (
        <div key={optionId}>
          <p>Option with ID {optionId} not found</p>
        </div>
      );
    });
  });

  return (
    <>
      <StyledBig className="g-mt-20 g-mb-20 pt-[5rem]">Program features</StyledBig>

      {parsedFeatures}

      <SelectOptionsButton commonOptions={commonOptions} />
    </>
  );
});

const StyledBig = styled(BoldBigText)`
  .required {
    color: #ff0000;
  }
`;
