import React from 'react';

import ChatInput from 'common/chat/ChatInput';
import { ColumnWidePlate } from 'common/plate/plates';

import RenameHeader from './RenameHeader';

export default React.memo(function InviteGroupContent() {
  return (
    <ColumnWidePlate>
      <RenameHeader />

      <ChatInput />
    </ColumnWidePlate>
  );
});
