import React from 'react';
import styled from 'styled-components';

import ChangePasswordModal from './ChangePasswordModal';

export default function ChangePasswordButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <>
      <StyledWrapper onClick={openModal}>Change password</StyledWrapper>
      {isOpen && <ChangePasswordModal closeModal={closeModal} />}
    </>
  );
}

const StyledWrapper = styled.span`
  color: rgba(34, 50, 50, 0.5);
  text-decoration: underline;
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`;
