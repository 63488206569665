import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { MediumText } from 'common/texts';
import ButtonGroup from 'components/environment-check/microphone/ButtonGroup';
import VolumeAnalyzer from 'components/environment-check/microphone/VolumeAnalyzer';
import VolumeVisualization from 'components/environment-check/microphone/VolumeVisualization';

export default function Microphone() {
  const [volume, setVolume] = React.useState(0);

  return (
    <WideRowPlate>
      <PlateHeader>Step 2 of 4 - Microphone</PlateHeader>

      <PlateContent>
        <VolumeAnalyzer setVolume={setVolume} />
        <VolumeVisualization volume={volume} />

        <MediumText className="g-mt-30">Do the gray bars turn green when you talk?</MediumText>

        <ButtonGroup />
      </PlateContent>
    </WideRowPlate>
  );
}
