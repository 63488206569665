import React from 'react';
import styled from 'styled-components';

export default React.memo(function IconAnchor({
  value,
  href,
  BeforeIcon,
  AfterIcon,
  isActive = '',
  className = '',
  target,
  clickHandler = () => {},
}) {
  if (!value || !href) {
    return null;
  }
  return (
    <StyledLink
      onClick={clickHandler}
      className={className}
      href={href}
      isactive={isActive.toString()}
      target={target}
    >
      {BeforeIcon && <BeforeIcon className="before" />}
      {value}
      {AfterIcon && <AfterIcon className="after" />}
    </StyledLink>
  );
});

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  font-size: 1.3rem;
  line-height: 2.4rem;
  opacity: ${({ isactive }) => (isactive === 'true' ? 1 : 0.5)};
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  .before {
    margin-right: 1.4rem;
  }
  .after {
    margin-left: 0.5rem;
  }
`;
