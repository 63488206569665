import React from 'react';

import CustomSelect from 'common/CustomSelect';
import { useMedicationsMeasures } from 'hooks/useOptions';
import { useSetMedicationProperty } from 'hooks/usePatients';

export default React.memo(function Measure({ className = '', index, measureId, validate }) {
  const medicationsMeasures = useMedicationsMeasures();
  const setMedicationMeasureId = useSetMedicationProperty({ index, field: 'measure_id' });
  return (
    <CustomSelect
      className={className}
      label="Measure"
      placeholder="Tablets"
      isLoading={!medicationsMeasures}
      isRequred
      isValid={!validate || measureId}
      options={medicationsMeasures}
      value={measureId}
      changeHandler={setMedicationMeasureId}
    />
  );
});
