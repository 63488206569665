import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FullIcon } from 'assets/video/full-icon.svg';

function goToFullScreen() {
  const element = document.getElementById('video-wrapper');
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
}

function cancelFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  }
}

export default React.memo(function FullIconComponent() {
  const toggleFullScreen = React.useCallback(() => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      return cancelFullScreen();
    }
    goToFullScreen();
  }, []);

  return <StyledFullIcon onClick={toggleFullScreen} />;
});

export const StyledFullIcon = styled(FullIcon)`
  position: absolute;
  left: 2rem;
  bottom: 3.5rem;
  z-index: 10;
  width: 4.8rem;
  height: 4.8rem;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    left: 1rem;
  }
  :hover {
    opacity: 0.5;
  }
  :active {
    opacity: 0.75;
  }
`;
