import React from 'react';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { RegularMediumText } from 'common/texts';
import Footer from 'components/dashboard/activity-log/Footer';
import LogsFilters from 'components/dashboard/activity-log/logs-filters';
import LogsLoader from 'components/dashboard/LogsLoader';
import { DASHBOARD } from 'constants/routes';
import { useLogsFilter } from 'hooks/useLogs';
import { useFetchLogs } from 'requests/logs';

export default function ActivityLog() {
  const logsFilter = useLogsFilter();
  const fetchLogs = useFetchLogs();

  React.useEffect(() => {
    fetchLogs(logsFilter);
    // eslint-disable-next-line
  }, [logsFilter])

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Activity log
      </PlateHeader>

      <PlateContent>
        <RegularMediumText className="g-mb-5">Select user and activity period</RegularMediumText>

        <LogsFilters />

        <LogsLoader />

        <Footer />
      </PlateContent>
    </RowPlate>
  );
}
