import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageTitle } from 'components/Breadcrumbs';
import { DETAILS, PATIENTS, TREATMENT_PLANS } from 'constants/routes';
import { usePatinetSummary, usePatinetProfile, usePatientFullName } from 'hooks/usePatients';
import { useProfile } from 'hooks/useProfile';
import { useFetchPatinetSummary, useFetchPatinetProfile } from 'requests/patients';

import {
  PatientDetails,
  ProviderDetails,
} from '../../../components/patients/treatment-plans/TreatmentPlanEdit/sections/SummarySection/SummarySectionView';
import { TreatmentPlanView } from '../../../components/patients/treatment-plans/TreatmentPlanEdit/TreatmentPlanView';
import {
  TreatmentPlanType,
  useTreatmentPlan,
} from '../../../requests/graphql/my-health/queries/getTreatmentPlan';

export default function TreatmentPlanViewContainer() {
  const { patientId, planId } = useParams<{ patientId: string; planId: string }>();
  const fetchPatinetSummary = useFetchPatinetSummary();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const patientSummary = usePatinetSummary();
  const patientProfile = usePatinetProfile();
  const providerProfile = useProfile();
  const [loading, setLoading] = useState(false);

  const patientFullName = usePatientFullName();

  const { plan, isLoading } = useTreatmentPlan(planId);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPatinetProfile(), fetchPatinetSummary()]).finally(() => setLoading(false));
  }, []);

  const patientInfo: PatientDetails = {
    avatarUrl: patientProfile?.avatar_url,
    fName: patientProfile?.first_name,
    lName: patientProfile?.last_name,
    mrn: patientSummary?.patient.medical_record,
  };

  const providerInfo: ProviderDetails = {
    avatarUrl: providerProfile?.avatar_url || providerProfile?.avatar,
    fName: providerProfile?.first_name,
    lName: providerProfile?.last_name,
    avatar: providerProfile?.avatar,
  };

  return (
    <>
      <PageTitle
        header={patientFullName ? `Treatment plan for ${patientFullName}` : ''}
        crumbs={[
          {
            title: 'Treatment plans',
            url: `/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`,
          },
        ]}
      />
      <TreatmentPlanView
        plan={plan as TreatmentPlanType}
        provider={providerInfo}
        patientId={patientId}
        patient={patientInfo}
        loading={isLoading || loading}
      />
    </>
  );
}
