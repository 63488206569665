import get from 'lodash/get';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as ChatIcon } from 'assets/video/chat-icon.svg';
import { APPOINTMENTS, CONSULTATION } from 'constants/routes';
import { useConsultation } from 'hooks/useAppointments';

import { IconWrapper } from './style';

export default React.memo(function ChatHandler() {
  const history = useHistory();
  const chatChannel = useConsultation((current) => get(current, 'chatChannel'));
  const appointmentId = useConsultation((current) => get(current, 'appointmentId'));
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    function handleAddMessage() {
      setCounter((current) => current + 1);
    }

    if (chatChannel) {
      chatChannel.channelClass.on('messageAdded', handleAddMessage);

      return () => {
        chatChannel.channelClass.removeListener('messageAdded', handleAddMessage);
      };
    }
  }, [chatChannel]);

  const navigateToConsultation = React.useCallback(() => {
    history.push(`/${APPOINTMENTS}/${CONSULTATION}/${appointmentId}`);
  }, [history, appointmentId]);

  return (
    <StyledIconWrapper onClick={navigateToConsultation}>
      <ChatIcon />
      {!!counter && <div className="counter">{counter}</div>}
    </StyledIconWrapper>
  );
});

const StyledIconWrapper = styled(IconWrapper)`
  position: relative;
  .counter {
    position: absolute;
    bottom: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-left: 1px;
    font-size: 0.6rem;
    font-weight: 500;
    color: #fff;
    background: #f86060;
    width: 1.2rem;
    height: 1.2rem;
    border: solid 1px #fff;
    border-radius: 50%;
  }
`;
