import React from 'react';
import styled from 'styled-components';

export default React.memo(function ChatSimpleHeader() {
  return <StyledWrapper>Send message</StyledWrapper>;
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 2rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
`;
