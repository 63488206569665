import React from 'react';
import styled from 'styled-components';

import { ReactComponent as PassedIcon } from 'assets/passed.svg';
import { MediumText } from 'common/texts';

export default React.memo(function Name({ total, step, name }) {
  const isCompleted = total === step;
  return (
    <StyledWrapper isCompleted={isCompleted}>
      {isCompleted && <PassedIcon className="icon" />}
      <MediumText className="label">{name}</MediumText>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 1.5rem;
  }
  .label {
    ${({ isCompleted }) => isCompleted && 'opacity: 0.5'};
  }
`;
