import find from 'lodash/find';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomCheckbox from 'common/CustomCheckbox';
import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients';

import SymptomsDescriptionList from './SymptomsDescriptionList';

export default React.memo(function SymptomsList({ className, symptoms }) {
  const currentSymptoms = usePatinetDoctorNote((current) => get(current, 'symptoms'), []);
  const handleChange = useSetPatinetDoctorNote((prev, symptom) => {
    const newPrev = { ...prev };
    const currentSymptoms = get(prev, 'symptoms', []);
    if (find(currentSymptoms, (item) => item.value === symptom.value)) {
      newPrev.symptoms = currentSymptoms.filter((item) => item.value !== symptom.value);
    } else {
      const newSymptoms = [...currentSymptoms];
      newSymptoms.push({ ...symptom, text: '' });
      newPrev.symptoms = newSymptoms;
    }
    return newPrev;
  }, []);

  const parsedSymptoms = React.useMemo(() => {
    return symptoms.map((symptom) => {
      const checked = find(currentSymptoms, (item) => item.value === symptom.value);
      return (
        <CustomCheckbox
          className="checkbox"
          key={symptom.value}
          label={symptom.label}
          checked={!!checked}
          changeHandler={() => handleChange(symptom)}
        />
      );
    });
  }, [symptoms, currentSymptoms, handleChange]);

  return (
    <StyledWrapper className={className}>
      {parsedSymptoms}
      <SymptomsDescriptionList symptoms={symptoms} />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  .checkbox {
    width: 50%;
    display: flex;
    align-items: center;
  }
`;
