import debounce from 'lodash/debounce';
import React, { FC, useEffect, useState } from 'react';
import * as yup from 'yup';

import { CKEditor } from 'common/CKEditor';

import { Hint } from './Hint';
import { SectionFooter } from '../../SectionFooter';

type SummarySectionEditProps = {
  value?: string;
  onCancel: () => void;
  onSave: (val: string) => void;
};

const schema = yup.string().max(1000, 'Limit of character is reached');

export const SummarySectionEdit: FC<SummarySectionEditProps> = ({ value, onCancel, onSave }) => {
  const [summary, setSummary] = useState(value || '');
  const [error, setError] = useState('');

  const save = () => {
    schema
      .validate(summary || '', { abortEarly: false })
      .then(() => {
        setError('');
        onSave(summary);
      })
      .catch((err: yup.ValidationError) => {
        setError(err.message);
      });
  };

  const handleChange = (val: string) => {
    setSummary(val);
    if (error) {
      schema
        .validate(val || '', { abortEarly: false })
        .then(() => {
          setError('');
        })
        .catch((err: yup.ValidationError) => {
          setError(err.message);
        });
    }
  };

  useEffect(() => {
    setSummary(value || '');
  }, [value]);

  return (
    <div className="shadow-row bg-catskillWhite p-6 border rounded-main my-6">
      <div className="pb-6">
        <div className="flex justify-between pb-2">
          <div className="text-2020 font-bold font-ambit">Summary</div>
        </div>
        <Hint />
      </div>

      <div className="">
        <div className="ck-short-editor bg-white border-[.2rem] rounded-main border-manatee p-4 max-w-[110rem]">
          <div className=" text-1324 font-poppins mb-1 text-[#2232327f]">Description</div>
          <CKEditor error={error} data={summary} onChange={debounce(handleChange, 400)} />
        </div>
        <SectionFooter onCancel={onCancel} onSave={save} />
      </div>
    </div>
  );
};
