import moment from 'moment';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { APPOINTMENTS, CONSULTATION } from 'constants/routes';

const timeGap = 60 * 60 * 1000; // 1 hour

export default React.memo(function JoinButton({ date }) {
  const { appointmentId } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = React.useState(
    +moment.utc(date).local() - moment() - timeGap > 0,
  );

  React.useEffect(() => {
    const timeDiff = +moment.utc(date).local() - moment() - timeGap;
    let timeoutId;
    if (timeDiff > 0) {
      timeoutId = setTimeout(() => setDisabled(false), timeDiff);
    }

    return () => clearTimeout(timeoutId);
  }, [date, setDisabled]);

  const clickHandler = React.useCallback(() => {
    history.push(`/${APPOINTMENTS}/${CONSULTATION}/${appointmentId}`);
  }, [history, appointmentId]);

  return (
    <StyledCustomButton disabled={disabled} clickHandler={clickHandler}>
      Join
    </StyledCustomButton>
  );
});

const StyledCustomButton = styled(CustomButton)`
  margin: 2rem 0 2.6rem 2.5rem;
`;
