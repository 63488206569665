import React from 'react';
import { useParams } from 'react-router-dom';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import QuizResultsBody from 'components/patients/questionnaires/quiz-results/quiz-results-body';
import QuizResultsHeader from 'components/patients/questionnaires/quiz-results/quiz-results-header';
import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires';
import { useFetchQuiz } from 'requests/patients/questionnaires';

export default function PatientQuizResults() {
  const { quizId } = useParams();
  const fetchQuiz = useFetchQuiz();
  const isQuizLoaded = useQuiz((current) => !!current);
  const clearQuiz = useSetQuiz(() => null, []);

  React.useEffect(() => {
    fetchQuiz(quizId);
    return clearQuiz;
    // eslint-disable-next-line
  }, [quizId])

  if (!isQuizLoaded) {
    return null;
  }

  return (
    <RowPlate>
      <QuizResultsHeader />

      <PlateContent>
        <QuizResultsBody />
      </PlateContent>
    </RowPlate>
  );
}
