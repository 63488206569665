import get from 'lodash/get';
import React from 'react';

import { SURVEY, JSON_FORM } from 'constants/quiz-types';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

import JsonFormResults from './json-form-results';
import SurveyResults from './survey-results';

const resultsConfig = {
  [SURVEY]: SurveyResults,
  [JSON_FORM]: JsonFormResults,
};

export default React.memo(function QuizResultsBody() {
  const type = useQuiz((current) => get(current, 'type'));

  const ButtonComponent = React.useMemo(() => {
    const Component = resultsConfig[type];
    if (!Component) {
      console.warn(`Quiz with type: ${type} not implemented`);
      return () => null;
    }
    return Component;
  }, [type]);

  return <ButtonComponent />;
});
