import get from 'lodash/get';
import React from 'react';

import { useMedicationsProgressTooltip } from 'hooks/patient/useMedications';

import TooltipRow from './TooltipRow';

export default React.memo(function Tooltip({ time }) {
  const medications = useMedicationsProgressTooltip(time);

  const parsedTooltipRows = React.useMemo(() => {
    return medications.map((medication, i) => {
      // if (medication.hideTooltip) return null
      const medicationName = get(medication, 'medication');
      const progress = get(medication, 'progress') || 0;
      return <TooltipRow key={i} medicationName={medicationName} progress={progress} />;
    });
  }, [medications]);

  return parsedTooltipRows;
});
