import React from 'react';

import { useElementsMapper, useQuizStep, useSetQuizStep } from 'hooks/patient/useQuestionnaires';

export default React.memo(function QuizBody() {
  const step = useQuizStep();
  const stepRef = React.useRef(step);
  const handleNavigation = useSetQuizStep((prev, next) => +prev + next, []);

  const parsedElements = useElementsMapper();

  React.useEffect(() => {
    if (parsedElements && parsedElements.length === 0) {
      handleNavigation(stepRef.current < step ? 1 : -1);
    }
    stepRef.current = step;
    // eslint-disable-next-line
  }, [parsedElements, stepRef])

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (!parsedElements) {
    return null;
  }

  return parsedElements;
});
