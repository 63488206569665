import get from 'lodash/get';
import React from 'react';

import { useReferralList } from 'hooks/useReferral';

import EmptyReferral from './EmptyReferral';
import ReferralList from './ReferralList';
import ReferralPlaceholder from './ReferralPlaceholder';

export default function ReferralLoader() {
  const referralList = useReferralList((current) => get(current, 'list'));

  if (!referralList) {
    return <ReferralPlaceholder />;
  }

  if (referralList.length === 0) {
    return <EmptyReferral />;
  }

  return <ReferralList />;
}
