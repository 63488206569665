import get from 'lodash/get';
import React from 'react';

import { useCompanyPlansMembers } from 'hooks/useCompanies';

import PatientPreview from './PatientPreview';

export default function PatientsList() {
  const companyPlansMembers = useCompanyPlansMembers();

  const parsedPlansMembers = React.useMemo(() => {
    return companyPlansMembers.map((patient) => {
      const id = get(patient, 'id');
      return <PatientPreview key={id} patient={patient} />;
    });
  }, [companyPlansMembers]);

  return parsedPlansMembers;
}
