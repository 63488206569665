import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { MESSAGES, RENAME } from 'constants/routes';

export default React.memo(function RenameChat() {
  const history = useHistory();
  const { channelSID } = useParams();

  return (
    <StyledWrapper
      className="g-mt-30"
      onClick={() => history.push(`/${MESSAGES}/${channelSID}/${RENAME}`)}
    >
      Change group chat name
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  font-size: 1rem;
  color: #bbc0c0;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
