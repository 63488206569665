import React from 'react';
import styled, { css } from 'styled-components';

export default function CircularIndicator({ goalProgress = 0, currentCalories = 0 }) {
  const calories = React.useMemo(() => {
    if (currentCalories && !isNaN(currentCalories) && !(currentCalories < 0)) {
      return +currentCalories;
    } else {
      return 0;
    }
  }, [currentCalories]);

  return (
    <StyledWrapper className="g-d-flex" goalProgress={goalProgress}>
      <div className="indicator g-d-flex g-text-aling-center g-w-100">
        <p className="text g-w-100">{calories}</p>
        <div className="indicator-inner g-w-100" aria-hidden="true" />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div.attrs(({ goalProgress }) => {
  let background = '196, 196, 196';
  if (goalProgress > 0) {
    background = '255, 107, 106';
  }
  if (goalProgress > 20) {
    background = '255, 199, 0';
  }
  if (goalProgress >= 40) {
    background = '1, 202, 241';
  }
  return {
    background: `rgba(${background})`,
    borderColor: goalProgress ? '#c2e1ff' : '#969696',
    height: goalProgress || '5',
  };
})`
  width: 12.9rem;
  height: 12rem;
  margin: 0 auto;
  border: 0.9rem solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  .indicator {
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    width: 9.9rem;
    height: 9.4rem;
    margin: auto;
  }
  .indicator-inner {
    position: relative;
    height: ${({ height }) => height}%;
    overflow: hidden;
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 9.9rem;
      height: 9.4rem;
      background: ${({ background }) => background};
      border-radius: 50%;
    }
  }
  .text {
    position: relative;
    z-index: 1;
    font-size: 2rem;
    line-height: 1.2;
    ${({ goalProgress }) =>
      goalProgress >= 60 &&
      css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
      `}
  }
`;
