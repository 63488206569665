import React from 'react';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { BoldText, SmallText, HintText } from 'common/texts';

export default function PrescriptionPreview({ label = '', desc = '', status = '', date = '' }) {
  return (
    <StyledWrapper className="g-d-flex g-justify-between g-align-center" status={status}>
      <div className="head">
        <BoldText className="label">{label}</BoldText>
        <SmallText className="desc">{desc}</SmallText>
      </div>
      <div className="body g-d-flex">
        <div className="body-item">
          <HintText className="hint">Status</HintText>
          <p className="value">{status}</p>
        </div>
        <div className="body-item">
          <HintText className="hint">Date</HintText>
          <p className="value">{date}</p>
        </div>
      </div>
      <div className="footer g-d-flex">
        <LinkButton className="button">{status !== 'pending' ? 'View' : 'Edit'}</LinkButton>
        <LinkButton className="button">Delete</LinkButton>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-bottom: 1.5rem;
  padding: 2.5rem 2.5rem 2.5rem 3rem;
  background: ${({ status }) =>
    // eslint-disable-next-line no-nested-ternary
    status === 'current'
      ? 'rgba(233, 255, 242, 0.7)'
      : status === 'historical'
        ? 'rgba(247, 247, 234, 0.7)'
        : '#f8faff'};
  border-radius: 1rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .head {
    width: 42%;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .label {
    font-size: 1.5rem;
  }
  .desc {
    font-size: 1.1rem;
  }
  .body {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    @media screen and (max-width: 768px) {
      margin-top: auto;
      margin-bottom: 1rem;
      padding-left: 0;
    }
  }
  .body-item {
    &:nth-child(n + 2) {
      margin-left: 2.5rem;
      @media screen and (max-width: 1024px) {
        margin-left: 1.5rem;
      }
    }
  }
  .hint {
    font-size: 1.3rem;
    text-transform: uppercase;
  }
  .value {
    font-size: 1.5rem;
    text-transform: capitalize;
    @media screen and (max-width: 1024px) {
      font-size: 1.3rem;
    }
  }
  .footer {
    @media screen and (max-width: 768px) {
      margin-top: auto;
      margin-bottom: 1rem;
    }
  }
  .button {
    min-width: 9rem;
    @media screen and (max-width: 1024px) {
      min-width: 7rem;
    }
    &:nth-child(n + 2) {
      margin-left: 1rem;
    }
  }
`;
