import React from 'react';

import { globalContext } from 'hooks/useGlobalState';
import request from 'utils/request';

export function useFetchHeadsUpLink() {
  const { user } = React.useContext(globalContext);
  return React.useCallback(() => {
    const userId = user.value.id;
    request({
      method: 'get',
      url: `/providers/${userId}/heads-up-link`,
    })
      .then(
        ({
          data: {
            data: { link },
          },
        }) => {
          const linkElement = window.document.createElement('a');
          linkElement.href = link;
          linkElement.target = '_blank';
          linkElement.rel = 'noopener noreferrer';
          linkElement.click();
        },
      )
      .catch((error) => console.info(error));
  }, [user]);
}
