import produce from 'immer';
import _, { debounce } from 'lodash';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

import { ReactComponent as MedicationsIcon } from 'assets/medications.svg';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';
import { validate } from 'utils/extractErrors';
import { SelectOption } from 'utils/sortOptions';

import { MedicationsEditItem } from './MedicationsSectionEditItem';
import { AddSectionButton } from '../../AddSectionButton';
import { SectionFooter } from '../../SectionFooter';
import { SectionHeader } from '../../SectionHeader';

type MedicationsEditProps = {
  tasks?: TaskType<'medications'>[];
  medicationMeasuresOptions: SelectOption[];
  medicationCategoriesOptions: SelectOption[];
  medicationFrequenciesOptions: SelectOption[];
  onCancel: () => void;
  onDelete: () => void;
  onDeleteSection: (index: number) => void;
  onSave: (val: TaskType<'medications'>[]) => void;
  onAddNew?: () => void;
};

const schema = yup.array().of(
  yup.object().shape({
    startDate: yup.string().required('Start date is required'),
    endDate: yup.string().required('End date is required'),
    details: yup.object().shape({
      name: yup.string().required('Name is required'),
      categoryId: yup
        .number()
        .required('Medication is required')
        .positive('Medication is required'),
      frequencyId: yup.number().required('Frequency is required').positive('Frequency is required'),
      measureId: yup.number().required('Measure is required').positive('Measure is required'),
      quantity: yup.number().required('Quantity is required').positive('Quantity cannot be zero'),
    }),
  }),
);

const convertTasks = (tasks: TaskType<'medications'>[]) =>
  tasks?.map((t) => {
    const d = { ...t.details };
    return {
      ...t,
      details: {
        ..._.omit(t.details, 'category', 'frequency', 'measure', 'start_date', 'end_date'),
        categoryId: d.category?.id || d.categoryId,
        frequencyId: d.frequency?.id || d.frequencyId,
        measureId: d.measure?.id || d.measureId,
      },
    };
  });

export const MedicationsEdit: FC<MedicationsEditProps> = ({
  tasks,
  onAddNew,
  onCancel,
  onSave,
  onDelete,
  onDeleteSection,
  medicationMeasuresOptions,
  medicationCategoriesOptions,
  medicationFrequenciesOptions,
}) => {
  const [currentTasks, setCurrentTasks] = useState(convertTasks(tasks || []));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState<Record<string, any>[]>([]);

  const handleChangeTask = useCallback(
    (task, id) => {
      const newTasks = currentTasks?.map((d, i) => (i === id ? task : d)) || [];

      if (errors.length > 0) {
        validate(schema, newTasks, setErrors, () => setErrors([]));
      }
      setCurrentTasks(newTasks);
    },
    [currentTasks, errors],
  );

  const errorsMessages = (di: number) => ({
    title: errors?.[di]?.title,
    name: errors?.[di]?.name,
    startDate: errors?.[di]?.startDate,
    endDate: errors?.[di]?.endDate,
    categoryId: errors?.[di]?.categoryId,
    frequencyId: errors?.[di]?.frequencyId,
    measureId: errors?.[di]?.measureId,
    quantity: errors?.[di]?.quantity,
  });

  const handleSubmit = (submit: () => void) => {
    return () => {
      validate(schema, currentTasks || [], setErrors, submit);
    };
  };

  const save = useCallback(() => {
    const tasks = currentTasks || [];
    onSave(
      tasks.map((task) =>
        produce(task, (t) => {
          t.startDate = moment(t.startDate).format('YYYY-MM-DD');
          t.endDate = moment(t.endDate).format('YYYY-MM-DD');
          t.details.quantity = Number(t.details.quantity);
        }),
      ),
    );
  }, [currentTasks]);

  useEffect(() => {
    const newTasks = convertTasks(tasks || []);
    setCurrentTasks(newTasks);
  }, [tasks]);

  return (
    <div className="mb-6">
      <div className="shadow-row p-6 border rounded-main">
        <div className="pb-6">
          <SectionHeader
            title="Medications"
            icon={<MedicationsIcon />}
            isEditing={true}
            onDelete={onDelete}
          />
        </div>
        {currentTasks?.map((task, di) => (
          <MedicationsEditItem
            medicationMeasuresOptions={medicationMeasuresOptions}
            medicationCategoriesOptions={medicationCategoriesOptions}
            medicationFrequenciesOptions={medicationFrequenciesOptions}
            hasDeleteSection={di > 0}
            onDeleteSection={() => onDeleteSection(di)}
            errors={errorsMessages(di)}
            key={task.id || 'medication' + di}
            task={task}
            onChange={debounce((d) => handleChangeTask(d, di), 400)}
          />
        ))}
        <SectionFooter onCancel={onCancel} onSave={handleSubmit(save)} />
      </div>
      {onAddNew && <AddSectionButton onClick={onAddNew} />}
    </div>
  );
};
