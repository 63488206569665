import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomTextarea from 'common/CustomTextarea';
import { MediumText } from 'common/texts';
import ProviderTemplate from 'components/providers/ProviderTemplate';
import { ADMIN_NOTES } from 'constants/routes';
import { useProviderAdminNote } from 'hooks/useProviders';
import { useFetchProviderAdminNote } from 'requests/providers';

export default function ProviderAdminNotePreview() {
  const note = useProviderAdminNote((current) => get(current, 'note'));
  const fetchProviderAdminNote = useFetchProviderAdminNote();

  React.useEffect(() => {
    fetchProviderAdminNote();
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={ADMIN_NOTES}>
      <MediumText className="g-mb-10">Admin notes</MediumText>
      <StyledCustomTextarea value={note} isLoading={!note} readOnly />
    </ProviderTemplate>
  );
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 40rem;
  }
`;
