import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';

import CustomSelect from 'common/CustomSelect';
import InputWithLabel from 'common/InputWithLabel';
import { FlexLine } from 'common/plate/styles';
import { RegularBigText } from 'common/texts';
import { useIsPatientProfileLoading, VALIDATION_RULES } from 'hooks/patient/useProfile';
import { useLocalPatientProfile, useSetLocalPatientProfile } from 'hooks/usePatients';

const relationshipOptions = [
  { value: 'father', label: 'Father' },
  { value: 'mother', label: 'Mother' },
  { value: 'brother', label: 'Brother' },
  { value: 'sister', label: 'Sister' },
  { value: 'aunt', label: 'Aunt' },
  { value: 'uncle', label: 'Uncle' },
  { value: 'cousin', label: 'Cousin' },
  { value: 'legal-guardian', label: 'Legal guardian' },
  { value: 'stepmom', label: 'Stepmom' },
  { value: 'stepdad', label: 'Stepdad' },
  { value: 'spouse', label: 'Spouse' },
];

function useSetProfileEmergencyProperty(field) {
  return useSetLocalPatientProfile(
    (prev, next) => {
      const newPrev = cloneDeep(prev);
      set(newPrev, `emergency[${field}]`, next);
      return newPrev;
    },
    [field],
  );
}

export default React.memo(function EmergencyContactInformation() {
  const isLoading = useIsPatientProfileLoading();
  const emergencyName = useLocalPatientProfile((current) => get(current, 'emergency.name', ''));
  const emergencyRelationship = useLocalPatientProfile((current) =>
    get(current, 'emergency.relationship', ''),
  );
  const emergencyPhone = useLocalPatientProfile((current) => get(current, 'emergency.phone', ''));

  const isPhoneValid = VALIDATION_RULES['phone'](emergencyPhone);

  return (
    <div>
      <RegularBigText className="g-mt-45">Emergency contact information</RegularBigText>
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Name"
          placeholder="First and last name"
          value={emergencyName}
          changeHandler={useSetProfileEmergencyProperty('name')}
          isLoading={isLoading}
        />
        <CustomSelect
          className="g-mr-25 g-mt-15"
          label="Relationship"
          placeholder="Your relationship"
          options={relationshipOptions}
          value={emergencyRelationship}
          changeHandler={useSetProfileEmergencyProperty('relationship')}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="g-mt-15"
          label="Phone"
          placeholder="Phone number"
          value={emergencyPhone}
          isValid={!emergencyPhone || isPhoneValid}
          changeHandler={useSetProfileEmergencyProperty('phone')}
          isLoading={isLoading}
        />
      </FlexLine>
    </div>
  );
});
