import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import { PATIENTS, DETAILS, DOCTOR_NOTES, CREATE } from 'constants/routes';
import { useSetPatinetDoctorNote } from 'hooks/usePatients';

export default React.memo(function NewNoteButton() {
  const { patientId } = useParams();
  const history = useHistory();
  const clearPatinetDoctorNote = useSetPatinetDoctorNote(() => null, []);

  const createNewNote = React.useCallback(() => {
    clearPatinetDoctorNote();
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/1`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}` },
    });
  }, [history, patientId, clearPatinetDoctorNote]);

  return (
    <CustomButton className="g-mt-auto" clickHandler={createNewNote}>
      Add new notes
    </CustomButton>
  );
});
