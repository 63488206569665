import moment from 'moment-timezone';
import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import CustomRadio from 'common/CustomRadio';
import StyledCalendar from 'common/styledCalendar';
import { UppercaseText } from 'common/texts';

export const calculateAvailableTime = (datestimes, date) => {
  return datestimes.filter((t) => moment.utc(t).local().isSame(date, 'day'));
};
const tomorrow = new Date();
const today = moment().format('YYYY-MM-DD');
tomorrow.setDate(new Date().getDate() + 1);

export default React.memo(function CalendarWindow({
  allAvailableTime,
  setIsOpen,
  selectedDate,
  setSelectedDate,
}) {
  const [date, setDate] = React.useState(selectedDate ? moment(selectedDate).toDate() : tomorrow);
  const [time, setTime] = React.useState(selectedDate);

  const availableTime = React.useMemo(
    () => calculateAvailableTime(allAvailableTime, date),
    [allAvailableTime, date],
  );

  const handleChange = React.useCallback(
    (value) => {
      setTime(value);
    },
    [setTime],
  );

  const formattedAllAvailableTime = React.useMemo(() => {
    return [
      today,
      ...new Set(
        allAvailableTime.map((date) => {
          return moment.utc(date).local().format('YYYY-MM-DD');
        }),
      ),
    ];
  }, [allAvailableTime]);

  function tileDisabled({ date, view }) {
    if (view === 'month') {
      return !formattedAllAvailableTime.includes(moment(date).format('YYYY-MM-DD'));
    }
  }

  const options = React.useMemo(() => {
    return availableTime.map((value) => {
      return (
        <CustomRadio
          key={value}
          className="g-mr-15"
          value={value}
          label={moment.utc(value).local().format('h:mm A')}
          checked={value === time}
          changeHandler={handleChange}
        />
      );
    });
  }, [availableTime, handleChange, time]);

  const chooseHandler = React.useCallback(() => {
    setSelectedDate(time);
    setIsOpen(false);
  }, [time, setSelectedDate, setIsOpen]);

  return (
    <StyledWrapper>
      <StyledCalendar
        nextLabel=">>"
        prevLabel="<<"
        minDate={new Date()}
        minDetail="month"
        MonthView
        tileDisabled={tileDisabled}
        onChange={(date) => setDate(date)}
        value={date}
      />
      <UppercaseText className="g-mt-10 g-mb-10">Available time</UppercaseText>
      <div className="checkbox-container">{options}</div>
      <CustomButton className="choose-button" disabled={!time} clickHandler={chooseHandler}>
        Choose
      </CustomButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  width: 40rem;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background: #fff;
  padding: 2rem 3.5rem;
  border: solid 1px #e9ebef;
  box-shadow: 0 20px 60px 0 rgba(163, 171, 186, 0.4);
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    .calendar-checkbox {
      width: 33%;
    }
  }
  .choose-button {
    margin-top: 1rem;
    font-size: 1.3rem;
    padding: 1rem 3rem;
  }
`;
