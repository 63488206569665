import styled from 'styled-components';

const CountButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  background: #f9fafc;
  border: solid 1px #e9ebef;
  border-radius: 2px;
  font-size: 1.3rem;
  transition: all 0.2s;
  :disabled {
    color: #fff;
    background: #979797;
  }
  :hover:enabled {
    border-color: #ccc;
    cursor: pointer;
  }
  :active:enabled {
    box-shadow: 0 0 0 0.3rem rgb(0 0 0 / 25%);
  }
`;
export default CountButton;
