import React from 'react';
import { useHistory } from 'react-router-dom';
export const useSavedSearch = () => {
  const history = useHistory();

  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    if (window.location.search) {
      setSearch(window.location.search);
      localStorage.setItem('search', window.location.search);
    } else {
      const search = localStorage.getItem('search');
      if (search) {
        setSearch(search);
        history.replace({
          search,
        });
      }
    }
  }, [window.location.search]);

  return search;
};
