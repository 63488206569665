import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import StyledCalendar from 'common/styledCalendar';

import { DayAvailability } from './DayAvailability';
import { calculateAvailability, makeRange, workingDaysHighlight } from './helpers';

/**
 * CalendarView
 *
 * @param {Object} p
 * @param {(args: boolean) => void} p.onHasError
 * @param {{ defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}} p.availability
 * @param {(args: { defaults: Object.<string, number[][]>; specials:{from: string, to:string, hoursCouples: number[][]}[]}) => void} p.onChange
 * @param {string[]} p.dirtyDates
 * @returns
 */
export const CalendarView = ({
  availability = { defaults: {}, specials: [] },
  onHasError,
  dirtyDates,
  dirtyDays,
  onChange,
}) => {
  const [ranges, setRanges] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  /**
   * @param {number[][]} ranges
   */
  const changeRanges = (ranges) => {
    const newAvailability = calculateAvailability(availability, selectedDate, ranges);
    onChange(newAvailability);
    setRanges(ranges);
  };

  /**
   * @param {Date} date
   */
  const changeDate = (date) => {
    const { range } = makeRange(availability, date);
    setRanges(range);
    setSelectedDate(date);
  };

  React.useEffect(() => {
    const { range } = makeRange(availability, selectedDate);
    setRanges(range);
  }, [availability]);

  return (
    <Root>
      <div className="calendar">
        <StyledCalendar
          onChange={changeDate}
          minDate={new Date()}
          value={selectedDate}
          tileClassName={({ date, view }) =>
            workingDaysHighlight(availability, dirtyDates, dirtyDays, date, view)
          }
        />
      </div>
      <div className="available-day-time">
        <div className="title">Available time</div>
        <div className="slots">
          <DayAvailability
            label={moment(selectedDate).format('dddd')}
            onHasError={onHasError}
            onRangesChange={changeRanges}
            ranges={ranges}
          />
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  .available-day-time {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }
  .title {
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 500;
  }

  width: 38.3rem;
`;
