import React from 'react';
import { Switch } from 'react-router-dom';

import { useUpdateDoctorNoteDraft } from 'requests/patients';
import onbeforeunloadHandler from 'utils/onbeforeunloadHandler';

export default function PatientEditNoteWrapper({ children }) {
  const updateDoctorNoteDraft = useUpdateDoctorNoteDraft();
  React.useEffect(() => {
    onbeforeunloadHandler({ key: 'updateDoctorNoteDraft', func: updateDoctorNoteDraft });
    return () => {
      onbeforeunloadHandler({ key: 'updateDoctorNoteDraft', func: null });
      updateDoctorNoteDraft();
    };
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    window.addEventListener('blur', updateDoctorNoteDraft);
    return () => window.removeEventListener('blur', updateDoctorNoteDraft);
    // eslint-disable-next-line
  }, [])
  return <Switch>{children}</Switch>;
}
