import React from 'react';

import { RegularMediumText } from 'common/texts';

export default function EmptyLabs() {
  return (
    <RegularMediumText className="g-mt-30">
      Patient do not have any uploaded labs.
    </RegularMediumText>
  );
}
