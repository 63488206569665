import React from 'react';

import { useChatClient, useSetChatChannels } from 'hooks/useMessages';
import { updateChannels } from 'utils/messages';

export default function MessageAdded() {
  const chatClient = useChatClient();
  const messageAdded = useSetChatChannels((prev, message) => {
    return updateChannels(prev, message.channel);
  }, []);

  React.useEffect(() => {
    chatClient.on('messageAdded', messageAdded);

    return () => {
      chatClient.removeListener('messageAdded', messageAdded);
    };
  }, [chatClient, messageAdded]);

  return null;
}
