import debounce from 'lodash/debounce';
import React from 'react';
import styled from 'styled-components';

import request from 'utils/request';

import SearchItemPlaceholder from './SearchItemPlaceholder';
import SearchItemsList from './SearchItemsList';

export default React.memo(function SearchResults({
  value,
  isOpen,
  searchLink,
  params = {},
  searchField,
  setValue,
  onSelect,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const isMounted = React.useRef(false);

  const fetchUsers = React.useCallback(
    (searchText) => {
      setIsLoading(true);
      request({
        method: 'get',
        url: `/${searchLink}`,
        params: {
          ...params,
          search: searchText,
          // ...(isAdmin && !params.type ? { type: 'all' } : {}),
        },
      })
        .then(({ data: { data } }) => {
          if (!isMounted.current) {
            return;
          }
          setIsLoading(false);
          setUsers(data[searchField]);
        })
        .catch((error) => {
          console.info(error);
          if (!isMounted) {
            return;
          }
          setIsLoading(false);
        });
    },
    [params, setIsLoading, setUsers, isMounted, searchLink, searchField],
  );

  const debounced = React.useRef(
    debounce((searchText) => {
      fetchUsers(searchText);
    }, 500),
  );

  React.useEffect(() => {
    isMounted.current = true;
    debounced.current(value);
    return () => (isMounted.current = false);
  }, [value]);

  if (!isOpen) {
    return null;
  }

  if (isLoading) {
    return (
      <StyledWrapper>
        <SearchItemPlaceholder />
        <SearchItemPlaceholder />
      </StyledWrapper>
    );
  }

  return (
    <StyledWrapper>
      <SearchItemsList users={users} setValue={setValue} onSelect={onSelect} />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: absolute;
  top: 100%;
  transform: translate(0, 1rem);
  border: solid 1px #dadada;
  background: #fff;
  width: 100%;
  z-index: 10;
  max-width: 30rem;
  max-height: 30rem;
  overflow-y: auto;
  z-index: 999;
  @media screen and (max-width: 480px) {
    max-width: initial;
  }
`;
