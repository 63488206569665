import React from 'react';

import SearchableSelect from 'common/searchable-select';
import { useSetAppointmentsFilters } from 'hooks/useAppointments';

export default React.memo(function ProviderSelect() {
  const setProvider = useSetAppointmentsFilters(
    (prev, provider) => ({ ...prev, provider: provider.id }),
    [],
  );
  const clearProvider = useSetAppointmentsFilters((prev) => ({ ...prev, provider: '' }), []);
  return (
    <SearchableSelect
      className="provider"
      label="Provider"
      placeholder="Provider"
      searchLink="providers"
      searchField="providers"
      onSelect={setProvider}
      onClear={clearProvider}
    />
  );
});
