import React from 'react';

import CustomButton from 'common/CustomButton';
import AuthInput from 'components/auth/AuthInput';
import AuthLink from 'components/auth/AuthLink';
import AuthTemplate from 'components/auth/AuthTemplate';
import { FORGOT_PASSWORD } from 'constants/routes';

const LoginForm = ({
  title,
  loginValue,
  passwordValue,
  onLoginChange,
  onPasswordChange,
  onSubmit,
  submitButtonLabel,
  disabled,
  loginPlaceholder,
  passwordPlaceholder,
  forgotLink,
}) => {
  return (
    <>
      <AuthTemplate title={title}>
        <AuthInput
          className="g-mb-15"
          value={loginValue}
          onEnter={onSubmit}
          changeHandler={onLoginChange}
          placeholder={loginPlaceholder}
          type="text"
        />
        <AuthInput
          className="g-mb-15"
          value={passwordValue}
          changeHandler={onPasswordChange}
          onEnter={onSubmit}
          placeholder={passwordPlaceholder}
          type="password"
        />
        <CustomButton disabled={disabled} className="g-mb-15 g-w-100" clickHandler={onSubmit}>
          {submitButtonLabel}
        </CustomButton>
        {/* <GoogleLoginButton /> */}
        {forgotLink && <AuthLink value={forgotLink} href={`/${FORGOT_PASSWORD}`} />}
      </AuthTemplate>
    </>
  );
};

export default LoginForm;
