import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import { PATIENTS, DETAILS, ADMIN_NOTES, CREATE } from 'constants/routes';
import { useSetPatientAdminNotes } from 'hooks/usePatients';

export default React.memo(function NewAdminNoteButton() {
  const { patientId } = useParams();
  const history = useHistory();
  const clearPatientAdminNotes = useSetPatientAdminNotes(() => null, []);

  const createNewNote = React.useCallback(() => {
    clearPatientAdminNotes();
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}/${CREATE}`,
      state: { referrer: `/${PATIENTS}/${DETAILS}/${patientId}/${ADMIN_NOTES}` },
    });
  }, [history, patientId, clearPatientAdminNotes]);

  return (
    <CustomButton className="g-mt-auto" clickHandler={createNewNote}>
      Add new notes
    </CustomButton>
  );
});
