import get from 'lodash/get';
import React from 'react';

import { WEEK, MONTH, QUARTERLY } from 'constants/medications-tabs';
import { useMedicationsTab } from 'hooks/patient/useMedications';

import MonthView from './month-view';
import QuarterView from './quarter-view';
import WeekView from './week-view';

const config = {
  [WEEK]: WeekView,
  [MONTH]: MonthView,
  [QUARTERLY]: QuarterView,
};

export default function MedicationsChart() {
  const tabId = useMedicationsTab((current) => get(current, 'id'));
  const chartEl = React.useRef(null);

  const chart = React.useMemo(() => {
    const Component = config[tabId];
    if (!Component) {
      return null;
    }
    return <Component chartEl={chartEl} />;
  }, [tabId]);

  return (
    <div className="g-d-flex g-overflow-x-auto" ref={chartEl}>
      {chart}
    </div>
  );
}
