import classNames from 'clsx';
import React, { FC } from 'react';

import { Switch, Option } from '../../../../../common/Switch';

export const treatmentPlanTabItems: Option[] = [
  {
    label: 'Modules',
    disabled: true,
    value: 'modules',
  },
  {
    label: 'Tasks',
    value: 'tasks',
  },
  {
    label: 'Surveys',
    disabled: true,
    value: 'surveys',
  },
];

type TabsProps = {
  loading?: boolean;
  items: Option[];
  activeItem?: Option;
  setActiveItem: (item: Option) => void;
};

export const Tabs: FC<TabsProps> = ({ items, activeItem, setActiveItem, loading }) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <div className="animate-pulse bg-catskillWhite w-32 h-10 rounded-md" />
        <div className="animate-pulse ml-4 bg-catskillWhite w-32 h-10 rounded-md" />
        <div className="animate-pulse ml-4 bg-catskillWhite w-32 h-10 rounded-md" />
      </div>
    );
  }
  return (
    <Switch
      className="flex"
      itemClassName="flex w-full"
      items={items}
      activeItem={activeItem}
      onChange={(item) => !item.disabled && setActiveItem(item)}
      element={({ option, isActive, disabled }) => (
        <div
          className={classNames(
            'cursor-pointer font-semibold font-ambit text-1622 flex-1 border-b-[.2rem] text-center pb-2',
            {
              'text-main border-main': isActive,
              'opacity-50 cursor-default': disabled,
              'text-manatee': !isActive,
            },
          )}
        >
          {option.label}
        </div>
      )}
    />
  );
};
