import React from 'react';

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import { usePatinetDoctorNotes } from 'hooks/usePatients';

import NotesList from './NotesList';

export default React.memo(function DoctorNotes() {
  const patinetDoctorNotes = usePatinetDoctorNotes();

  const isLoading = React.useMemo(() => !patinetDoctorNotes, [patinetDoctorNotes]);

  if (isLoading) {
    return <ListPreviewPlaceholder />;
  }

  return <NotesList />;
});
