import React from 'react';
import styled from 'styled-components';

import CustomSelect from 'common/CustomSelect';
import CustomTextarea from 'common/CustomTextarea';

export default function PrescriptionRightCol() {
  return (
    <StyledWrapper>
      <CustomSelect className="field" label="Formulary/Ins" placeholder=" " />
      <CustomTextarea className="field g-mt-20" label="Pharmacy Note" placeholder=" " />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: 42%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 2rem;
  }
`;
