import React from 'react';

import SearchableSelect from 'common/searchable-select';
import { useSetAppointmentsFilters } from 'hooks/useAppointments';

export default React.memo(function PatientSelect() {
  const setPatient = useSetAppointmentsFilters(
    (prev, patient) => ({ ...prev, patient: patient.id }),
    [],
  );
  const clearPatient = useSetAppointmentsFilters((prev) => ({ ...prev, patient: '' }), []);
  return (
    <SearchableSelect
      className="patient"
      label="Patient"
      placeholder="Patient"
      searchLink="patients"
      searchField="patients"
      onSelect={setPatient}
      onClear={clearPatient}
    />
  );
});
