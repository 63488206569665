import React from 'react';

import ConfirmationModal from 'common/ConfirmationModal';
import LinkButton from 'common/LinkButton';
import { useModalHandler } from 'hooks/useUtils';
import { useDeletePatientMedication } from 'requests/patients/medication';

export default React.memo(function RemoveButton({ className = '', id, index, isLast }) {
  const deletePatientMedication = useDeletePatientMedication(id, index);
  const [isOpen, openModal, closeModal] = useModalHandler();

  return (
    <>
      <LinkButton className={className} disabled={isLast} onClick={openModal}>
        Delete
      </LinkButton>
      {isOpen && (
        <ConfirmationModal closeModal={closeModal} confirmHandler={deletePatientMedication} />
      )}
    </>
  );
});
