import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import NotificationsCounter from 'common/NotificationsCounter';

export default React.memo(function IconLink({
  value,
  href,
  BeforeIcon,
  AfterIcon,
  isActive = '',
  className = '',
  clickHandler = () => {},
  withCounter,
}) {
  if (!value || !href) {
    return null;
  }
  return (
    <StyledLink
      onClick={clickHandler}
      className={className}
      to={href}
      isactive={isActive.toString()}
    >
      {BeforeIcon && (
        <div className="before">
          <BeforeIcon />
          {withCounter && <NotificationsCounter className="counter" />}
        </div>
      )}
      {value}
      {AfterIcon && <AfterIcon className="after" />}
    </StyledLink>
  );
});

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  font-size: 1.3rem;
  line-height: 2.4rem;
  color: ${({ isactive }) => (isactive === 'true' ? '#000' : 'rgba(0, 0, 0, 0.5)')};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  .before {
    position: relative;
    margin-right: 1.4rem;
    display: flex;
    align-items: center;
    svg {
      opacity: ${({ isactive }) => (isactive === 'true' ? 1 : 0.5)};
    }
  }
  .counter {
    width: 1.5rem;
    height: 1.5rem;
  }
  .after {
    margin-left: 0.5rem;
    opacity: ${({ isactive }) => (isactive === 'true' ? 1 : 0.5)};
  }
`;
