import React from 'react';
import styled from 'styled-components';

import { UppercaseText, RegularText } from 'common/texts';

export default function StatusLine({ label = '', value = '', percentageStatus = '0' }) {
  return (
    <StyledWrapper percentageStatus={percentageStatus}>
      <div className="g-d-flex g-fw-w g-align-center g-justify-between g-w-100 g-mb-10">
        <UppercaseText className="g-mr-5">{label}</UppercaseText>
        <RegularText className="value">{value}</RegularText>
      </div>
      <div className="line" aria-hidden="true" />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  .value {
    color: ${({ percentageStatus }) => percentageStatus < 70 && '#ff8331'};
  }
  .line {
    position: relative;
    width: 100%;
    height: 0.4rem;
    border-radius: 2rem;
    background: #e5e5e5;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ percentageStatus }) => percentageStatus}%;
      height: inherit;
      background: ${({ percentageStatus }) => (percentageStatus < 70 ? '#ff8331' : '#38dee8')};
      border-radius: inherit;
      transition: all 0.2s;
    }
  }
`;
