import React from 'react';
import styled from 'styled-components';

export default React.memo(function InfoPlaceholder() {
  return <StyledWrapper />;
});

const StyledWrapper = styled.div`
  background: #fafbfd;
  border: solid 1px #e9ebef;
  height: 19rem;
  width: 100%;
  margin-right: 4rem;
`;
