import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import styled from 'styled-components';

import InputWithLabel from 'common/InputWithLabel';
import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires';

function normalizeDate(date) {
  const parsedDate = [];
  date
    .replace(/\D/g, '')
    .split('')
    .forEach((item, i) => {
      if (i > 7) {
        return;
      }
      if (i === 2 || i === 4) {
        parsedDate.push('/');
      }
      parsedDate.push(item);
    });
  return parsedDate.join('');
}

const typeConfig = {
  date: normalizeDate,
};

export default function Text({ className = '', metaPath, answerField }) {
  const label = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const inputType = useQuiz((current) => get(current, `${metaPath}.inputType`), [metaPath]);
  const value = useQuiz(
    (current) => get(current, `content.answers.${answerField}.value`, ''),
    [answerField],
  );
  const styles = useQuiz((current) => get(current, `${metaPath}.styles`, ''), [metaPath]);

  const changeHandler = useSetQuiz(
    (prev, value) => {
      let normalizeFunc = typeConfig[inputType];
      if (!normalizeFunc) {
        normalizeFunc = (value) => value;
      }
      return set(cloneDeep(prev), `content.answers.${answerField}.value`, normalizeFunc(value));
    },
    [inputType, answerField],
  );

  return (
    <StyledInputWithLabel
      className={className}
      label={label}
      value={value}
      changeHandler={changeHandler}
      styles={styles}
    />
  );
}

const StyledInputWithLabel = styled(InputWithLabel)`
  ${({ styles }) => styles || ''};
`;
