import get from 'lodash/get';
import React from 'react';

import { PlateHeader } from 'common/plate/styles';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

import BackHandler from './BackHandler';

export default React.memo(function QuizHeader() {
  const title = useQuiz((current) => get(current, 'name', ''));

  return (
    <PlateHeader>
      <BackHandler />
      {title}
    </PlateHeader>
  );
});
