import React from 'react';

import { usePatientLabs } from 'hooks/usePatients';
import { useFetchPatinetLabs } from 'requests/patients';

import EmptyLabs from './EmptyLabs';
import LabsList from './LabsList';
import LabsPlaceholder from './LabsPlaceholder';

export default function LabsLoader() {
  const patientLabs = usePatientLabs();
  const fetchPatinetLabs = useFetchPatinetLabs();

  React.useEffect(() => {
    fetchPatinetLabs();
    // eslint-disable-next-line
  }, [fetchPatinetLabs])

  if (!patientLabs) {
    return <LabsPlaceholder />;
  }

  if (patientLabs && patientLabs.length === 0) {
    return <EmptyLabs />;
  }

  return <LabsList />;
}
