import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import InputWithLabel from 'common/InputWithLabel';
import BackButton from 'common/plate/BackButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { PROVIDERS } from 'constants/routes';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

function existValidation(value) {
  return !!value;
}

export default React.memo(function CreateProvider() {
  const history = useHistory();
  const [isSended, setIsSended] = React.useState(false);
  const [validate, setValidate] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const submitHandler = React.useCallback(() => {
    setValidate(true);
    if (!existValidation(firstName)) {
      return;
    }
    if (!existValidation(lastName)) {
      return;
    }
    if (!existValidation(email)) {
      return;
    }
    setIsSended(true);
    request({
      method: 'post',
      url: '/admins/providers',
      data: {
        first_name: firstName,
        last_name: lastName,
        email: email,
      },
    })
      .then(() => {
        createNotification({ message: 'Provider successfully created', type: 'success' });
        history.push(`/${PROVIDERS}`);
      })
      .catch((error) => console.info(error));
  }, [setValidate, firstName, lastName, email, setIsSended, history]);

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${PROVIDERS}`} />
        Add new provider
      </PlateHeader>

      <PlateContent>
        <InputWithLabel
          className="g-mt-20"
          label="First name"
          placeholder="Enter first name"
          isRequred
          isValid={!validate || existValidation(firstName)}
          value={firstName}
          changeHandler={setFirstName}
        />

        <InputWithLabel
          className="g-mt-20"
          label="Last name"
          placeholder="Enter last name"
          isRequred
          isValid={!validate || existValidation(lastName)}
          value={lastName}
          changeHandler={setLastName}
        />

        <InputWithLabel
          className="g-mt-20"
          label="Email"
          placeholder="Enter email address"
          isRequred
          isValid={!validate || existValidation(email)}
          value={email}
          changeHandler={setEmail}
        />

        <CustomButton className="g-mt-auto" disabled={isSended} clickHandler={submitHandler}>
          Submit
        </CustomButton>
      </PlateContent>
    </ColumnWidePlate>
  );
});
