import React from 'react';

import CustomButton from 'common/CustomButton';
import SubModal from 'common/SubModal';
import { useAppointmentsFilters } from 'hooks/useAppointments';
import { useFetchAppointments } from 'requests/appointments';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default React.memo(function RefundAppointmentModal({ id, closeModal, closeMainModal }) {
  const { period, provider, patient } = useAppointmentsFilters();
  const fetchAppointments = useFetchAppointments();

  const refundlHandler = React.useCallback(() => {
    request({
      method: 'put',
      url: `/admins/appointments/${id}/refunde`,
    })
      .then(() => {
        closeModal();
        closeMainModal();
        createNotification({ message: 'Appointment successfully refunded', type: 'success' });
        fetchAppointments({ period, provider, patient });
      })
      .catch((error) => console.info(error));
  }, [closeModal, closeMainModal, fetchAppointments, id, period, provider, patient]);

  return (
    <SubModal
      title="Are you sure in that?"
      closeModal={closeModal}
      minWidth={44}
      maxWidth={44}
      withSeparator
    >
      <CustomButton className="g-mt-20" clickHandler={refundlHandler}>
        Refund appointment
      </CustomButton>
    </SubModal>
  );
});
