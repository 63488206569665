import { useMemo } from 'react';

import { GraphQLTypes, InputType, Selector } from 'zeus-graphql/my-health/zeus';

import { sortOptions } from '../../../../utils/sortOptions';
import { useQuery } from '../client';
import { GET_MEDICATION_FREQUENCIES } from '../constants';

const medicationFrequency = Selector('MedicationFrequencyType')({
  __typename: true,
  id: true,
  frequency: true,
  totalPerDay: true,
  createdAt: true,
});

export type MedicationFrequenciesType = InputType<
  GraphQLTypes['MedicationFrequencyType'],
  typeof medicationFrequency
>;

export const useMedicationFrequencies = () => {
  const { data, isLoading } = useQuery(
    [GET_MEDICATION_FREQUENCIES],
    {
      getMedicationFrequencies: medicationFrequency,
    },
    {
      operationName: GET_MEDICATION_FREQUENCIES,
    },
  );

  const medicationFrequencies = data?.getMedicationFrequencies || [];
  const medicationFrequenciesOptions = useMemo(
    () =>
      medicationFrequencies
        .map((category) => ({
          value: category.id + '',
          label: category.frequency,
        }))
        .sort(sortOptions),
    [medicationFrequencies],
  );

  const medicationFrequenciesFilterOptions = useMemo(
    () => [{ value: 'all', label: 'All' }, ...medicationFrequenciesOptions],
    [medicationFrequenciesOptions],
  );

  return {
    medicationFrequencies,
    medicationFrequenciesOptions,
    medicationFrequenciesFilterOptions,
    isLoading,
  };
};
