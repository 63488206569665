import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import createNotification from 'utils/createNotification';
import normalizeWidgetData from 'utils/normalizeWidgetData';
import request from 'utils/request';

import {
  useMealNote,
  useSetFoodJournal,
  useUpdateMealNote,
} from '../../hooks/patient/useFoodJournal';

export function useFetchFoodJournal() {
  const { patientId } = useParams();
  const setFoodJournal = useSetFoodJournal((prev, next) => next, []);
  return React.useCallback(() => {
    return request({
      method: 'get',
      url: `/meals?patient=${patientId}`,
    })
      .then(
        ({
          data: {
            data: { meals },
          },
        }) => {
          setFoodJournal(meals.map((meal) => normalizeWidgetData(meal)));
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setFoodJournal]);
}

export function useAddNote() {
  const note = useMealNote();
  const fetchFoodJournal = useFetchFoodJournal();
  return React.useCallback(() => {
    return request({
      method: 'post',
      url: '/meal-notes',
      data: {
        meal_id: note.meal_id,
        description: note.description,
      },
    })
      .then(() => {
        createNotification({ message: 'Note has successfully added', type: 'success' });
        fetchFoodJournal();
      })
      .catch((error) => console.info(error));
  }, [note]);
}

export function useUpdateNote() {
  const note = useUpdateMealNote();
  const noteId = get(note, 'id', '');
  const description = get(note, 'description', '');
  const fetchFoodJournal = useFetchFoodJournal();
  return React.useCallback(() => {
    return request({
      method: 'put',
      url: `/meal-notes/${noteId}`,
      data: {
        description: description,
      },
    })
      .then(() => {
        createNotification({ message: 'Note has successfully updated', type: 'success' });
        fetchFoodJournal();
      })
      .catch((error) => console.info(error));
  }, [note]);
}
