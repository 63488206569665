import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import DayLabel from 'common/widgets/DayLabel';
import HalfCircleIndicator from 'common/widgets/HalfCircleIndicator';
import ViewData from 'common/widgets/ViewData';
import { PATIENTS, DETAILS, WIDGETS, OURA, READINESS_CONTRIBUTORS } from 'constants/routes';
import normalizePercentageValue from 'utils/normalizePercentageValue';

export default function OuraReadinessWidget({ widgetId, lastResult }) {
  const { patientId } = useParams();
  const history = useHistory();
  const { summaryDate, score } = lastResult;

  const normalizedScore = normalizePercentageValue(score);

  const percentageValue = React.useMemo(() => {
    if (!normalizedScore || +normalizedScore <= 0) {
      return 0;
    }
    if (+normalizedScore >= 100) {
      return 100;
    }
    return +normalizedScore;
  }, [normalizedScore]);

  const valueInDeg = React.useMemo(() => {
    return (percentageValue * 180) / 100;
  }, [percentageValue]);

  const navigateToReadinessContributors = React.useCallback(() => {
    history.push(
      `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${READINESS_CONTRIBUTORS}`,
    );
  }, [history, patientId, widgetId]);

  return (
    <>
      <DayLabel date={summaryDate} />
      <StyledWrapper className="g-d-flex g-align-center g-w-100 g-mt-30">
        <HalfCircleIndicator
          value={normalizedScore || 0}
          valueInDeg={valueInDeg}
          isExcellent={normalizedScore >= 80}
        />
      </StyledWrapper>
      <ViewData className="g-mt-45" clickHandler={navigateToReadinessContributors} />
    </>
  );
}

const StyledWrapper = styled.div`
  .value {
    padding-top: 4.5rem;
    font-size: 3rem !important;
  }
`;
