import React from 'react';

import { useProviderProfile } from 'hooks/useProviders';

import Info from './Info';
import InfoPlaceholder from './InfoPlaceholder';

export default React.memo(function ProviderInfo() {
  const providerProfile = useProviderProfile();

  if (!providerProfile) {
    return <InfoPlaceholder />;
  }

  return <Info />;
});
