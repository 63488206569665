import get from 'lodash/get';
import React from 'react';

import CustomButton from 'common/CustomButton';
import { useQuiz, useQuizStep, useSetQuizStep } from 'hooks/patient/useQuestionnaires';

export default React.memo(function SubmitButton() {
  const step = useQuizStep();
  const withSubmit = useQuiz(
    (current) => get(current, `content.pages[${step}].withSubmit`, ''),
    [step],
  );
  const submitHandler = useSetQuizStep((prev) => +prev + 1, []);

  if (!withSubmit) {
    return null;
  }

  return <CustomButton clickHandler={submitHandler}>Submit</CustomButton>;
});
