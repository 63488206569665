import React from 'react';

import { MediumText } from 'common/texts';
import ReferralLoader from 'components/providers/details/referral/referral-list/ReferralLoader';
import Stats from 'components/providers/details/referral/Stats';
import ProviderTemplate from 'components/providers/ProviderTemplate';
import { REFERRAL } from 'constants/routes';
import { useSetReferralList } from 'hooks/useReferral';
import { useFetchProviderReferral } from 'requests/referral';

export default function ProviderReferral() {
  const fetchProviderReferral = useFetchProviderReferral();
  const clearReferral = useSetReferralList(() => null);

  React.useEffect(() => {
    fetchProviderReferral();
    return clearReferral;
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={REFERRAL}>
      <Stats />
      <MediumText className="g-mt-25">List of invited patients</MediumText>
      <ReferralLoader />
    </ProviderTemplate>
  );
}
