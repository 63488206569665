import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomSelect from 'common/CustomSelect';
import InputWithLabel from 'common/InputWithLabel';
import { FlexLine } from 'common/plate/styles';
import { HintText, RegularBigText } from 'common/texts';
import { useLocalProfile, useSetLocalProfile, VALIDATION_RULES } from 'hooks/useProfile';

import { CURRENT_COUNTRY } from '../../../env';
import request from '../../../utils/request';
// import PhoneVerefication from './phone-verefication'

export default React.memo(function ContactInformation({ isLoading }) {
  const [states, setStates] = React.useState([]);

  const countries = [
    { label: 'USA', value: 'USA' },
    { label: 'UAE', value: 'UAE' },
  ];
  const country = useLocalProfile((current) => get(current, 'country'));
  const email = useLocalProfile((current) => get(current, 'email', ''));
  const phone = useLocalProfile((current) => get(current, 'phone', ''));
  const city = useLocalProfile((current) => get(current, 'city', ''));
  const state = useLocalProfile((current) => get(current, 'state', ''));
  const zip = useLocalProfile((current) => get(current, 'zip', ''));
  const zipLabel = country === undefined || country === 'USA' ? 'Zip' : 'P.O. Box';
  const zipPlaceholder =
    country === undefined || country === 'USA' ? 'Your zip code' : 'Your P.O. Box';
  const phonePrefix = country === 'USA' ? '+1' : '+971';

  const isEmailValid = VALIDATION_RULES['email'](email);
  const isPhoneValid = VALIDATION_RULES['phone'](phone);
  const isCityValid = VALIDATION_RULES['city'](city);
  const isStateValid = VALIDATION_RULES['state'](state);
  const isZipValid = VALIDATION_RULES['zip'](zip);
  const isCountryValid = VALIDATION_RULES['country'](country);

  const setCountry = useSetLocalProfile((prev, country) => ({ ...prev, country }), []);

  React.useEffect(() => {
    (async () => {
      const { data } = await request({
        url: `/options/states?country=${country || 'USA'}`,
        method: 'GET',
      });
      setStates(data);
    })();
  }, [country]);
  React.useEffect(() => {
    if (CURRENT_COUNTRY === 'USA') {
      setCountry('USA');
    }
  }, [country]);

  return (
    <StyledWrapper>
      <RegularBigText className="g-mt-15">Personal information</RegularBigText>
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-15"
          label="Email"
          placeholder="Your Email"
          isRequred
          disabled
          readOnly
          isValid={isEmailValid}
          value={email}
          changeHandler={useSetLocalProfile((prev, email) => ({ ...prev, email }), [])}
          isLoading={isLoading}
        />
        <div className="g-d-flex g-w-100">
          <StyledHintText className="g-mt-auto g-mr-10 g-mb-10">{phonePrefix}</StyledHintText>
          <InputWithLabel
            className="g-mt-15"
            label="Phone"
            placeholder="Your phone number"
            isRequred
            isValid={isPhoneValid}
            value={phone}
            changeHandler={useSetLocalProfile((prev, phone) => ({ ...prev, phone }), [])}
            isLoading={isLoading}
          />
          {/* <PhoneVerefication isLoading={isLoading} /> */}
        </div>
      </FlexLine>
      {CURRENT_COUNTRY !== 'USA' && (
        <div className="g-d-flex g-w-100">
          <CustomSelect
            className="g-mr-25 g-mt-20"
            label="Country"
            placeholder="Your country"
            isRequred
            isValid={isCountryValid}
            value={country}
            options={countries}
            changeHandler={setCountry}
            isLoading={isLoading}
          />
        </div>
      )}
      <FlexLine></FlexLine>
      <FlexLine>
        <InputWithLabel
          className="g-mr-25 g-mt-20"
          label="City"
          placeholder="Your city"
          isRequred
          isValid={isCityValid}
          value={city}
          changeHandler={useSetLocalProfile((prev, city) => ({ ...prev, city }), [])}
          isLoading={isLoading}
        />
        <CustomSelect
          className="g-mr-25 g-mt-20"
          label="State"
          placeholder="Your state"
          isRequred
          isValid={isStateValid}
          value={state}
          options={states}
          changeHandler={useSetLocalProfile((prev, state) => ({ ...prev, state }), [])}
          isLoading={isLoading}
        />
        <InputWithLabel
          className="zip g-mt-20"
          label={zipLabel}
          placeholder={zipPlaceholder}
          isRequred
          isValid={isZipValid}
          value={zip}
          changeHandler={useSetLocalProfile((prev, zip) => ({ ...prev, zip }), [])}
          isLoading={isLoading}
        />
      </FlexLine>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .zip {
    width: 145px;
  }
`;
const StyledHintText = styled(HintText)`
  line-height: 2.7rem;
`;
