import React from 'react';

import { RegularText } from 'common/texts';

export default React.memo(function Estimate({ estimate }) {
  return (
    <>
      <RegularText className="g-o-50">Estimated time</RegularText>
      <RegularText>{estimate} mins</RegularText>
    </>
  );
});
