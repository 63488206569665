import React from 'react';
import styled from 'styled-components';

import { MediumText } from 'common/texts';

import AppointmentsLoader from './AppointmentsLoader';

export default React.memo(function ProviderAppointments() {
  return (
    <StyledWrapper>
      <MediumText className="g-mb-20">Appointments history</MediumText>

      <AppointmentsLoader />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 480px) {
    margin-top: 2rem;
  }
`;
