import React from 'react';

import ReferralLoader from 'components/referral/referral-list/ReferralLoader';
import ReferralTemplate from 'components/referral/ReferralTemplate';
import { LIST } from 'constants/routes';

export default function Referral() {
  return (
    <ReferralTemplate currentTab={LIST}>
      <ReferralLoader />
    </ReferralTemplate>
  );
}
