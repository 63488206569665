import React from 'react';
import styled from 'styled-components';

import NextButton from './NextButton';
import Progress from './Progress';
import SaveHandler from './SaveHandler';
import SubmitButton from './SubmitButton';

export default React.memo(function QuizFooter() {
  return (
    <StyledWrapper>
      <div>
        <NextButton />
        <SubmitButton />
        <SaveHandler />
      </div>

      <Progress />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  margin-top: auto;
  padding-top: 3.5rem;
  display: flex;
  justify-content: space-between;
`;
