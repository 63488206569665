import styled from 'styled-components';

import { ReactComponent as GroupChatIcon } from 'assets/group-chat.svg';
import { ReactComponent as GroupLogoIcon } from 'assets/logo-white-icon.svg';
import UserAvatar from 'common/UserAvatar';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.4rem 2.5rem;
  border-bottom: solid 2px #f1f5f8;
  .name {
    max-width: 25rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 500;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
  }
  .sub-name {
    font-size: 1rem;
    font-weight: 500;
    color: #bbc0c0;
    text-transform: uppercase;
  }
`;

export const StyledUserAvatar = styled(UserAvatar)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
`;

export const StyledGroupChatIcon = styled(GroupChatIcon)`
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  cursor: pointer;
`;

export const StyledSubGroupChatIcon = styled(GroupChatIcon)`
  width: 2.2rem;
  min-width: 2.2rem;
  height: 2.2rem;
`;

export const StyledSubLogoChatIcon = styled(GroupLogoIcon)`
  width: 2.2rem;
  min-width: 2.2rem;
  height: 2.2rem;
`;
