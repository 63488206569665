import React from 'react';

import CustomSelect from 'common/CustomSelect';
import InputWithLabel from 'common/InputWithLabel';
import { MediumText } from 'common/texts';
import { useCompanyForm, useSetCompanyForm } from 'hooks/useCompanies';
import { usePlans } from 'hooks/useOptions';
import { useFetchPlans } from 'requests/options';

export default function CompanyForm({ validate }) {
  const fetchPlans = useFetchPlans();
  const plans = usePlans();

  const name = useCompanyForm((current) => current.name || '', []);
  const capacity = useCompanyForm((current) => current.capacity || '', []);
  const planId = useCompanyForm((current) => current.plan_id, []);
  const coupon = useCompanyForm((current) => current.coupon || '', []);
  const setName = useSetCompanyForm((prev, next) => ({ ...prev, name: next }), []);
  const setCapacity = useSetCompanyForm((prev, next) => ({ ...prev, capacity: next }), []);
  const setPlanId = useSetCompanyForm((prev, next) => ({ ...prev, plan_id: next }), []);
  const setCoupon = useSetCompanyForm((prev, next) => ({ ...prev, coupon: next }), []);

  React.useEffect(() => {
    fetchPlans();
    // eslint-disable-next-line
  }, [])

  return (
    <div className="g-mt-15">
      <MediumText>General information</MediumText>

      <InputWithLabel
        className="g-mt-5"
        label="Company name"
        placeholder="Enter name"
        isRequred
        isValid={!validate || name}
        value={name}
        changeHandler={setName}
      />

      <InputWithLabel
        className="g-mt-20"
        label="Capacity"
        placeholder="0"
        isRequred
        isValid={!validate || capacity}
        value={capacity}
        changeHandler={setCapacity}
      />

      <CustomSelect
        className="g-mt-20"
        label="Select Tariff"
        placeholder="Select Tariff"
        isRequred
        isValid={!validate || planId}
        value={planId}
        options={plans || []}
        changeHandler={setPlanId}
      />

      <InputWithLabel
        className="g-mt-20"
        label="Create coupon"
        placeholder="Enter coupon"
        isRequred
        isValid={!validate || coupon}
        value={coupon}
        changeHandler={setCoupon}
      />
    </div>
  );
}
