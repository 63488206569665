import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import MemberListItem from './member-list-item';

export default React.memo(function MembersList({ channelType, channel }) {
  const owner = get(channel, 'state.attributes.owner');
  const members = get(channel, 'state.attributes.members', []);
  const parsedMembers = members
    .sort((member) => (member.id === owner ? -1 : 0))
    .map((member) => {
      return (
        <MemberListItem key={member.id} channelType={channelType} owner={owner} member={member} />
      );
    });

  return <StyledWrapper>{parsedMembers}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  overflow-y: auto;
  max-height: 25rem;
  padding: 1.5rem;
  margin: -1.5rem;
`;
