import React from 'react';
import styled from 'styled-components';

import { useDayData } from 'hooks/patient/widgets/useWidgets';

export default function BMI() {
  const weight = useDayData('weight');
  const height = useDayData('height');
  const bmi = React.useMemo(() => {
    if (!weight || !height) {
      return 0;
    }
    const weightKg = weight / 2.20462;
    return Math.round(weightKg / Math.pow(height, 2));
  }, [weight, height]);

  return (
    <StyledWrapper className="g-d-flex g-justify-between g-mt-20">
      <p className="text">BMI</p>
      <p className="text">{bmi ? bmi.toFixed(1) : 0}</p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #e9ebef;
  p {
    font-size: 2.4rem;
  }
`;
