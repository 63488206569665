import React from 'react';

import { useChatClient, useSetChatChannels } from 'hooks/useMessages';
import { useFetchChatUsersData } from 'requests/messages';
import { parseChannel } from 'utils/messages';

export default function ChannelAdded() {
  const chatClient = useChatClient();
  const fetchChatUsersData = useFetchChatUsersData();

  const addChatChannel = useSetChatChannels(
    (prev, newChannel) => {
      if (prev.find((channel) => channel.sid === newChannel.sid)) {
        return prev;
      }
      fetchChatUsersData([newChannel]);
      return [parseChannel(newChannel), ...prev];
    },
    [fetchChatUsersData],
  );

  React.useEffect(() => {
    chatClient.on('channelAdded', addChatChannel);

    return () => {
      chatClient.removeListener('channelAdded', addChatChannel);
    };
  }, [chatClient, addChatChannel]);

  return null;
}
