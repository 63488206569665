import classNames from 'clsx';
import React, { FC } from 'react';

enum Status {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  REQUEST = 'request',
}

type StatusPillProps = {
  status: string;
};

export const StatusPill: FC<StatusPillProps> = ({ status }) => {
  return (
    <div
      className={classNames(
        ' text-[.9rem] lg:text-1012 uppercase font-bold px-3 py-1 rounded-3xl font-lato',
        {
          'bg-yellow-100 text-yellow-800': status === Status.DRAFT,
          'bg-errorBG text-error': status === Status.REQUEST,
          'bg-successBG text-success': status === Status.PUBLISHED,
          'bg-catskillWhite text-manatee': status === Status.UNPUBLISHED,
        },
      )}
    >
      {status}
    </div>
  );
};
