import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';

export default function ListValues({ values = [] }) {
  return values.map((value, i) => {
    return <StyledRegularText key={i}>{value}</StyledRegularText>;
  });
}

const StyledRegularText = styled(RegularText)`
  word-break: break-word;
`;
