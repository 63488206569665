import get from 'lodash/get';
import React from 'react';

import { useConsultation } from 'hooks/useAppointments';

export default React.memo(function MainVideoComponent() {
  const mainVideoTracks = useConsultation((current) => get(current, 'mainVideoTracks'));
  const mainAudioTracks = useConsultation((current) => get(current, 'mainAudioTracks'));
  const [isVideoOn, setIsVideoOn] = React.useState(true);

  const videoRef = React.useRef();
  const audioRef = React.useRef();

  React.useEffect(() => {
    const videoTrack = mainVideoTracks[0];
    function setOn() {
      setIsVideoOn(true);
    }
    function setOff() {
      setIsVideoOn(false);
    }
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      videoTrack.on('enabled', setOn);
      videoTrack.on('disabled', setOff);

      return () => {
        videoTrack.removeListener('enabled', setOn);
        videoTrack.removeListener('disabled', setOff);
      };
    }
  }, [mainVideoTracks, setIsVideoOn]);

  React.useEffect(() => {
    const audioTrack = mainAudioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
    }
  }, [mainAudioTracks]);

  return (
    <>
      <video style={{ opacity: isVideoOn ? 1 : 0 }} className="video" ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
    </>
  );
});
