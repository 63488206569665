import React from 'react';

import DeletePatientButton from './DeletePatientButton';
import ExportButton from './ExportButton';
import { useUserContext } from '../../../../context/userContext';
import { permissions } from '../../../../permissions';

export default function ActionsButtons() {
  const { can } = useUserContext();
  return (
    <div className="g-d-flex g-justify-between">
      {can(permissions.patient.canExportAllData) && <ExportButton />}
      {can(permissions.patient.canDeletePatient) && <DeletePatientButton />}
    </div>
  );
}
