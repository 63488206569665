import React from 'react';
import styled from 'styled-components';

import onlyPositive from 'utils/onlyPositive';
import toHM from 'utils/toHM';
import valueToLabel from 'utils/widgets/valueToLabel';

import StatusLine from '../../common/StatusLine';

export default function ContributorsList({ data }) {
  const sleepTime = toHM(onlyPositive(data.total));
  const sleepScore = onlyPositive(data.score_total);
  const sleepEfficiency = onlyPositive(data.efficiency);
  const disturbances = onlyPositive(data.score_disturbances);
  const rem = toHM(onlyPositive(data.rem));
  const scoreRem = onlyPositive(data.score_rem);
  const deep = toHM(onlyPositive(data.deep));
  const scoreDeep = onlyPositive(data.score_deep);

  return (
    <StyledWrapper className="g-d-flex g-fw-w g-justify-between g-mb-45 g-mt-20">
      <div className="col">
        <StatusLine label="Total sleep" value={sleepTime} percentageStatus={sleepScore} />
        <StatusLine
          label="Efficiency"
          value={`${sleepEfficiency}%`}
          percentageStatus={sleepEfficiency}
        />
        <StatusLine
          label="Restfulness"
          value={valueToLabel(disturbances)}
          percentageStatus={disturbances}
        />
      </div>
      <div className="col">
        <StatusLine label="Rem sleep" value={`${rem}, ${scoreRem}%`} percentageStatus={scoreRem} />
        <StatusLine
          label="Deep sleep"
          value={`${deep}, ${scoreDeep}%`}
          percentageStatus={scoreDeep}
        />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
  .col {
    width: 43%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &:nth-child(2)::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      bottom: 1rem;
      left: 50%;
      width: 0.1rem;
      background: #e9ebef;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;
