import React from 'react';
import styled from 'styled-components';

export default function MedicationsChartPlaceholder() {
  return <StyledWrapper />;
}

const StyledWrapper = styled.div`
  width: 100%;
  height: 36rem;
  background: #fafbfd;
`;
