import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import PropertyValue from 'common/PropertyValue';
import { MediumText } from 'common/texts';
import { usePatinetSummary } from 'hooks/usePatients';

export default function ProgramDetails({ isLoading }) {
  const patinetSummary = usePatinetSummary();

  const programName = get(patinetSummary, 'patient.program', '');
  const statusPackage = get(patinetSummary, 'patient.status_package', '').toLowerCase();
  const expiresIn = get(patinetSummary, 'patient.active_until_date', '');
  const parsedExpiresIn = expiresIn !== 'n/a' ? moment(expiresIn).fromNow() : expiresIn;
  const nextBillDate = get(patinetSummary, 'patient.next_bill_date', '');
  const parsedNextBillDate =
    nextBillDate !== 'n/a' ? moment(nextBillDate).format('Do MMMM YYYY') : nextBillDate;

  const visits = get(patinetSummary, 'visits', []);
  const parsedVisits = React.useMemo(() => {
    return visits.map((visit) => {
      const property = get(visit, 'name', '');
      const used = get(visit, 'used', '');
      const amount = get(visit, 'amount', '');
      return (
        <PropertyValue
          key={property}
          className="g-mt-5"
          isLoading={isLoading}
          property={property}
          value={`${used} / ${amount}`}
        />
      );
    });
  }, [visits, isLoading]);

  return (
    <>
      <MediumText className="g-mt-20">Program information</MediumText>

      <PropertyValue className="g-mt-5" isLoading={isLoading} property="Plan" value={programName} />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Status"
        value={statusPackage}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Program expires in"
        value={<span className="g-red">{parsedExpiresIn}</span>}
      />
      <PropertyValue
        className="g-mt-5"
        isLoading={isLoading}
        property="Next Bill Date"
        value={parsedNextBillDate}
      />
      {parsedVisits}
    </>
  );
}
