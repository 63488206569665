import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { MediumText } from 'common/texts';
import DaySelector from 'common/widgets/day-selector';
import ExportData from 'common/widgets/ExportData';
import ContributorsList from 'components/patients/widgets/synchronized-widget/oura/sleep/sleep-contributors/ContributorsList';
import SleepStats from 'components/patients/widgets/synchronized-widget/oura/sleep/sleep-contributors/SleepStats';
import { PATIENTS, DETAILS, WIDGETS, OURA, SLEEP_STAGES } from 'constants/routes';
import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets';
import toHM from 'utils/toHM';

export default function PatientSleepConrtubutors() {
  const { patientId, widgetId } = useParams();
  const daysData = useDaysData('total');
  const dayData = useDayData();

  const label = React.useMemo(() => {
    const seconds = dayData.total;
    if (!seconds) {
      return null;
    }
    return toHM(seconds);
  }, [dayData]);

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`} />
        Sleep
        <ExportData title="Sleep score" field="score" />
      </PlateHeader>
      <DaySelector data={daysData} label={label} color="#6fcbff" />
      <PlateContent>
        <SleepStats data={dayData} />
        <MediumText>Sleep contributors</MediumText>
        <ContributorsList data={dayData} />
        <StyledLink
          to={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${SLEEP_STAGES}`}
        >
          View sleep stages
        </StyledLink>
      </PlateContent>
    </RowPlate>
  );
}

const StyledLink = styled(Link)`
  align-self: flex-start;
  margin-left: auto;
  color: inherit;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
`;
