import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { useWidget } from 'hooks/patient/widgets/useWidgets';
import getBoundaryValues from 'utils/widgets/getBoundaryValues';

import PressureValue from './PressureValue';

export default function PressureData() {
  const systolicBoundary = useWidget((current) => {
    const data = get(current, 'data');
    return getBoundaryValues(data, 'systolic');
  });
  const diastolicBoundary = useWidget((current) => {
    const data = get(current, 'data');
    return getBoundaryValues(data, 'diastolic');
  });

  return (
    <StyledWrapper>
      <div className="row-top g-d-flex g-fw-w">
        <PressureValue
          className="col"
          labelClassName="label"
          label="Avg. Systol"
          valueClassName="g-mt-10"
          value={systolicBoundary.avg}
        />
        <PressureValue
          className="col"
          labelClassName="label"
          label="Avg. Diastol"
          valueClassName="g-mt-10"
          value={diastolicBoundary.avg}
        />
      </div>
      <div className="row-bottom g-d-flex g-fw-w g-justify-between">
        <PressureValue className="col" label="Systolic max" value={systolicBoundary.max} />
        <PressureValue className="col" label="Diastolic max" value={diastolicBoundary.max} />
        <PressureValue className="col" label="Systolic min" value={systolicBoundary.min} />
        <PressureValue className="col" label="Diastolic min" value={diastolicBoundary.min} />
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  padding-right: 6.5rem;
  padding-left: 6.5rem;
  @media screen and (max-width: 768px) {
    padding: 0;
    border-top: 0.1rem solid #e9ebef;
    border-bottom: 0.1rem solid #e9ebef;
  }
  .col {
    max-width: 49%;
    @media screen and (max-width: 768px) {
      max-width: 50%;
      width: 50%;
      padding: 2rem 1rem 1.5rem 2rem;
    }
    &:nth-child(even) {
      @media screen and (max-width: 768px) {
        border-left: 0.1rem solid #e9ebef;
      }
    }
  }
  .row-top {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid #e9ebef;
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
    .col {
      margin-right: auto;
      margin-left: auto;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-left: 0;
      }
      span {
        font-size: 2rem;
      }
    }
    .label {
      font-size: 1.5rem;
    }
  }
  .row-bottom {
    padding-top: 1rem;
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
    .col {
      max-width: 24%;
      @media screen and (max-width: 768px) {
        max-width: 50%;
      }
      span {
        font-size: 1.6rem;
      }
    }
  }
`;
