import React from 'react';

import onlyPositive from 'utils/onlyPositive';
import toHM from 'utils/toHM';
import valueToLabel from 'utils/widgets/valueToLabel';

import StatusLine from '../../common/StatusLine';

export default function ActivityList({ data }) {
  const inactive = toHM(onlyPositive(data.inactive), 'minutes');
  const stayActive = onlyPositive(data.score_stay_active);
  const inactivityAlerts = onlyPositive(data.inactivity_alerts);
  const moveEveryHour = onlyPositive(data.score_move_every_hour);
  const meetDailyTargets = onlyPositive(data.score_meet_daily_targets);
  const trainingFrequency = onlyPositive(data.score_training_frequency);
  const trainingVolume = onlyPositive(data.score_training_volume);
  const recoveryTime = onlyPositive(data.score_recovery_time);
  return (
    <>
      <StatusLine
        label="Stay active"
        value={`${inactive} inactive`}
        percentageStatus={stayActive}
      />
      <StatusLine
        label="Move every hour"
        value={`${inactivityAlerts} alerts`}
        percentageStatus={moveEveryHour}
      />
      <StatusLine
        label="Meer daily goals"
        value={valueToLabel(meetDailyTargets)}
        percentageStatus={meetDailyTargets}
      />
      <StatusLine
        label="Training frequency"
        value={valueToLabel(trainingFrequency)}
        percentageStatus={trainingFrequency}
      />
      <StatusLine
        label="Training volume"
        value={valueToLabel(trainingVolume)}
        percentageStatus={trainingVolume}
      />
      <StatusLine
        label="Recovery time"
        value={valueToLabel(recoveryTime)}
        percentageStatus={recoveryTime}
      />
    </>
  );
}
