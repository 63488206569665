import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import CalendarHeader from '../CalendarHeader';

export default React.memo(function MonthHeader({ setCurrentMonth }) {
  const content = React.useMemo(() => {
    return new Array(7).fill(null).map((item, i) => {
      const currentDay = moment().startOf('week').add(i, 'days');
      return (
        <div key={i} className="day-container">
          <div className="week-day">{currentDay.format('ddd')}</div>
        </div>
      );
    });
  }, []);
  return (
    <CalendarHeader
      onPrev={React.useCallback(() => setCurrentMonth((prev) => prev - 1), [setCurrentMonth])}
      onNext={React.useCallback(() => setCurrentMonth((prev) => prev + 1), [setCurrentMonth])}
    >
      <StyledWrapper>{content}</StyledWrapper>
    </CalendarHeader>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  .day-container {
    width: ${100 / 7}%;
    text-align: center;
    @media only screen and (max-width: 480px) {
      :first-child {
        text-align: right;
        padding-right: 1rem;
      }
      :last-child {
        text-align: left;
        padding-left: 1rem;
      }
    }
    .week-day {
      font-size: 1.3rem;
      line-height: 3.5rem;
    }
  }
`;
