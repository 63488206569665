import React from 'react';

import CameraHelp from 'assets/environment-check/camera-help.jpg';
import { MediumText, RegularText } from 'common/texts';

export default function Hint({ isOpen }) {
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <MediumText className="g-mt-40">Help</MediumText>

      <img className="g-align-self-start g-mt-20" src={CameraHelp} alt="camera-help" />

      <RegularText className="g-mt-20">
        • Your browser may ask you if you allow this site access to your camera and microphone.
        Select “Allow.”
      </RegularText>
      <RegularText>• Try using a different web browser.</RegularText>
      <RegularText>• Try restarting your computer.</RegularText>
      <RegularText>
        • Make sure you don’t have Skype, PhotoBooth, Google Hangouts or any other application or
        website open that might be fighting for your access to the webcam or microphone
        capabilities.
      </RegularText>
      <RegularText>
        • You may have to select your camera and microphone from the drop-down lists
      </RegularText>
      <RegularText>
        • Some camera models have an “ON/OFF” switch - make sure that if yours has one - it is set
        to “ON.”
      </RegularText>
    </>
  );
}
