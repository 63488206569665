import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomTextarea from 'common/CustomTextarea';
import RateUs from 'common/RateUs';

export default React.memo(function FeedbackMessage({ message }) {
  const messageRate = get(message, 'state.attributes.rate');
  const messageReview = get(message, 'state.attributes.review');
  return (
    <StyledWrapper>
      <div>Your appointment is complete. Thank you for rating.</div>
      <RateUs className="rate g-mt-10" rate={messageRate} />
      <CustomTextarea
        className="g-mt-10"
        placeholder="Please share your experience"
        value={messageReview}
        readOnly
      />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  textarea {
    height: 8rem;
  }
  .rate {
    justify-content: flex-end;
  }
`;
