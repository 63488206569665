import styled from 'styled-components';

export const StyledLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 2.5rem 2.5rem;
  border-bottom: solid 1px #e9ebef;
  .button {
    width: 13.5rem;
    min-width: 13.5rem;
    margin-left: 1rem;
  }
`;
