import React from 'react';
import styled from 'styled-components';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';
import { DASHBOARD, SETTINGS, PASSWORD, SECURITY } from 'constants/routes';

const tabsConfig = [
  { id: PASSWORD, label: 'Password', href: `/${SETTINGS}/${PASSWORD}` },
  { id: SECURITY, label: 'Security', href: `/${SETTINGS}/${SECURITY}` },
];

export default React.memo(function SettingTemplate({ currentTab, children }) {
  return (
    <StyledRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Settings
      </PlateHeader>
      <PlateContent>
        <TabsSwitcher className="g-mt-20" tabsConfig={tabsConfig} currentTab={currentTab} />
        {children}
      </PlateContent>
    </StyledRowPlate>
  );
});

const StyledRowPlate = styled(RowPlate)`
  min-height: 66rem;
`;
