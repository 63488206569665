import React from 'react';
import styled from 'styled-components';

import { UppercaseText } from 'common/texts';

export default function DurationData({
  textTitle = '',
  lineBg = '',
  durationPercentage = '0',
  durationData = '',
}) {
  return (
    <StyledWrapper
      className="g-d-flex g-fw-w g-align-center"
      lineBg={lineBg}
      durationPercentage={durationPercentage}
    >
      <UppercaseText className="g-w-100 g-mb-5 g-o-50">{textTitle}</UppercaseText>
      <div className="line g-mb-5" aria-hidden="true" />
      <p className="value g-mb-5">{durationData}</p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  &:nth-child(n + 2) {
    margin-top: 1.5rem;
  }
  .line {
    width: ${({ durationPercentage }) => durationPercentage}%;
    height: 1rem;
    border-radius: 2rem;
    background: ${({ lineBg }) => lineBg};
    transition: width 0.2s;
  }
  .value {
    margin-left: ${({ durationPercentage }) => durationPercentage > 0 && '10px'};
    color: rgba(34, 50, 50, 0.5);
  }
`;
