import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { MediumText } from 'common/texts';
import { useQuiz, useComplexElementsResultMapper } from 'hooks/patient/useQuestionnaires';

const ElementsRow = React.memo(function ElementsRow({ rowIndex, metaPath }) {
  const parsedElements = useComplexElementsResultMapper({ metaPath, rowIndex });
  return <StyledWrapper className="g-d-flex g-justify-between">{parsedElements}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export default React.memo(function Matrixdynamic({ metaPath }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`), [metaPath]);
  const rowCount = useQuiz((current) => get(current, `${metaPath}.rowCount`), [metaPath]);

  const parsedTable = React.useMemo(() => {
    return new Array(rowCount).fill(null).map((item, i) => {
      return <ElementsRow key={i} rowIndex={i} metaPath={metaPath} />;
    });
  }, [rowCount, metaPath]);

  return (
    <div>
      {title && <MediumText className="g-mt-20">{title}</MediumText>}

      {parsedTable}
    </div>
  );
});
