import React from 'react';

import BackButton from 'common/plate/BackButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import AppointmentsFilters from 'components/appointmentsProvider/appointments-create/appointments-filters';
import { APPOINTMENTS, MONTH } from 'constants/routes';

export default function AppointmentsCreate() {
  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS}/${MONTH}`} />
        Create appointment
      </PlateHeader>

      <PlateContent>
        <AppointmentsFilters />
      </PlateContent>
    </ColumnWidePlate>
  );
}
