// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC, JSX } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { DASHBOARD, LOGIN } from '../constants/routes';
import { useUserContext } from '../context/userContext';

export type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  disabled?: boolean;
  pageTitle?: string;
  permissions?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  render?: () => JSX.Element;
};

type ComponentWrapperProps = Pick<
  PrivateRouteProps,
  'component' | 'render' | 'permissions' | 'path'
>;

export const ComponentWrapper: FC<ComponentWrapperProps> = ({
  permissions,
  path,
  render,
  component: Component,
  children,
}) => {
  const { can, isInited } = useUserContext();
  const permitted = !permissions || can(permissions);
  const noPermission = isInited && !permitted;

  if (noPermission) {
    return <Redirect to={`/${DASHBOARD}`} />;
  }
  if (permitted) {
    if (render) {
      return render();
    }
    if (Component) {
      return <Component />;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return children as any;
  }

  console.error(`Route component with path "${path}" does not have a render or component props`);

  return (
    <h1>
      Oops, something went wrong. Please contact out support -{' '}
      <a target="_blank" href="mailto:support@cibahealth.com" rel="noreferrer">
        support@cibahealth.com
      </a>
    </h1>
  );
};

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const token = localStorage.getItem('idToken');
  const auth = token ?? false;

  if (!auth) {
    return <Redirect to={`/${LOGIN}`} />;
  }

  return (
    <Route {...props}>
      <ComponentWrapper {...props} />
    </Route>
  );
};
