import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Option } from './types/common';
import { Qualification } from './types/qualification';

export type UserQualifications = {
  qualification_title: string;
  qualifications: number[];
  state_licenses: string[];
  symptoms: number[];
};

type ProfileQualificationsState = {
  symptomsOptions: Option[];
  qualificationsOptions: Qualification[];
  setQualificationsOptions: (q: Qualification[]) => void;
  setSymptomsOptions: (s: Option[]) => void;
};

const initialState = {
  symptomsOptions: [],
  qualificationsOptions: [],
};

export const useDictionaryStore = create<ProfileQualificationsState>()(
  devtools(
    (set) => ({
      ...initialState,
      setQualificationsOptions: (qualificationsOptions: Qualification[]) =>
        set({ qualificationsOptions }, false, 'setQualificationsOptions'),
      setSymptomsOptions: (symptomsOptions: Option[]) =>
        set({ symptomsOptions }, false, 'setSymptomsOptions'),
    }),
    {
      name: 'dictionary-storage',
    },
  ),
);
