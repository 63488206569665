import { UseQueryOptions } from '@tanstack/react-query';
import _ from 'lodash';
import moment from 'moment';

import { PARTICIPANT_API_GRAPHQL_URL } from 'env';
import createNotification from 'utils/createNotification';
import { getResponseError } from 'utils/getErrors';
import request from 'utils/request';
import { useTypedQuery } from 'zeus-graphql/participant/reactQuery';
import {
  Thunder,
  GraphQLTypes,
  InputType,
  OperationOptions,
  ValueTypes,
} from 'zeus-graphql/participant/zeus';
export const GQL = Thunder(async (query, variables) => {
  return request({
    method: 'post',
    baseURL: PARTICIPANT_API_GRAPHQL_URL,
    data: { query, variables },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
});

export const GQLQuery = GQL('query');
export const GQLMutation = GQL('mutation');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const callToast = (error: any) => {
  const message = getResponseError(error);
  if (error) {
    createNotification({ message, type: 'error' });
  }
};

export function useQuery<
  O extends 'Query',
  TData extends ValueTypes[O],
  TResult = InputType<GraphQLTypes[O], TData>,
>(
  queryKey: unknown[],
  query: TData | ValueTypes[O],
  zeusOptions?: OperationOptions & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers?: Record<string, any>;
    errorTypes?: {
      __typename: string;
      message?: string;
      entityName: keyof Partial<InputType<GraphQLTypes[O], TData>>;
    }[];
  },
  options?: Omit<UseQueryOptions<TResult>, 'queryKey' | 'queryFn'>,
) {
  return useTypedQuery(
    queryKey,
    query,
    {
      ...options,
      onSuccess: (data: TResult) => {
        if (data) {
          const errorTag = zeusOptions?.errorTypes?.find(
            (type) => _.get(data, `${type.entityName as string}.__typename`) === type.__typename,
          );

          if (errorTag && _.get(data, `${errorTag.entityName as string}`)) {
            const msg =
              errorTag.message ||
              (_.get(
                data,
                `${errorTag.entityName as string}.message`,
                'Oops, something went wrong',
              ) as string);
            const apolloError = new Error(msg);
            callToast(apolloError);
            options?.onError?.(apolloError);
            return;
          }
        }
        options?.onSuccess?.(data);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        options?.onError?.(error);

        if (error?.message === 'Failed to fetch') {
          createNotification({
            message: 'No connection to the server. Try again later',
            type: 'error',
          });
        } else {
          callToast(error);
        }
      },
    },
    zeusOptions,
    PARTICIPANT_API_GRAPHQL_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        TimeZone: moment().format('ZZ'),
        'X-Auth-Source': localStorage.getItem('role') || 'provider',
        ...zeusOptions?.headers,
      },
    },
  );
}
