import React from 'react';
import styled from 'styled-components';

export default function LabelsList({ dates }) {
  return (
    <StyledWrapper className="g-mt-15">
      {dates.map(({ value }, i) => {
        return (
          <div key={i} className="label">
            {value || ''}
          </div>
        );
      })}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .label {
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
    color: #16123f;
  }
`;
