import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import {
  PreFormatting,
  RegularMediumText,
  UppercaseText,
  RegularText,
  BoldText,
  HintText,
} from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { PATIENTS, DETAILS, DOCTOR_NOTES, CREATE, PREVIEW } from 'constants/routes';
import { usePatinetDoctorNote, useSetPatinetDoctorNote } from 'hooks/usePatients';
import { useCreatePatinetDoctorNote } from 'requests/patients';

const IllnessHistory = React.memo(function IllnessHistory() {
  const illnessHistory = usePatinetDoctorNote((current) => get(current, 'illnessHistory', ''), []);
  if (!illnessHistory) {
    return null;
  }
  return (
    <>
      <RegularMediumText className="g-mt-20">History of Present Illness</RegularMediumText>
      <PreFormatting>
        <RegularText>{illnessHistory}</RegularText>
      </PreFormatting>
    </>
  );
});

const ReviewOfSystems = React.memo(function ReviewOfSystems() {
  const general = usePatinetDoctorNote((current) => get(current, 'general', ''), []);
  const symptoms = usePatinetDoctorNote((current) => get(current, 'symptoms', []), []);

  const parsedGeneral = React.useMemo(() => {
    if (general) {
      return (
        <>
          <UppercaseText className="g-mt-5">General</UppercaseText>
          <RegularText>{general}</RegularText>
        </>
      );
    }
    return null;
  }, [general]);

  const parsedSymptoms = React.useMemo(() => {
    return symptoms.map(({ label, text }, i) => {
      return (
        <div key={i}>
          <BoldText className="g-mt-20">{label}</BoldText>
          <RegularText>{text}</RegularText>
        </div>
      );
    });
  }, [symptoms]);

  if (!parsedGeneral && parsedSymptoms.length === 0) {
    return null;
  }

  return (
    <>
      <RegularMediumText className="g-mt-20">Review of Systems</RegularMediumText>
      {parsedGeneral}
      {parsedSymptoms}
    </>
  );
});

const Assessment = React.memo(function Assessment() {
  const assessment = usePatinetDoctorNote((current) => get(current, 'assessment', ''), []);
  return (
    <>
      <UppercaseText className="g-mt-20">Patient assessment</UppercaseText>
      <PreFormatting>
        <RegularText className="g-mb-45">{assessment}</RegularText>
      </PreFormatting>
    </>
  );
});

export default function PatientCreateNotePreview() {
  const { patientId } = useParams();
  const history = useHistory();

  const assessment = usePatinetDoctorNote((current) => get(current, 'assessment', ''), []);
  const createPatinetDoctorNote = useCreatePatinetDoctorNote();

  const setCurrentPage = useSetPatinetDoctorNote((prev) => ({ ...prev, draftPage: PREVIEW }), []);

  React.useEffect(() => {
    setCurrentPage();
    // eslint-disable-next-line
  }, [])

  const toPrevStep = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/3`);
  }, [history, patientId]);

  if (!assessment) {
    return <Redirect to={`/${PATIENTS}/${DETAILS}/${patientId}/${DOCTOR_NOTES}/${CREATE}/3`} />;
  }

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>Provider notes</RegularMediumText>

      <IllnessHistory />

      <ReviewOfSystems />

      <Assessment />

      <StyledButtonGroup>
        <CustomButton className="next-button" clickHandler={createPatinetDoctorNote}>
          Submit
        </CustomButton>

        <HintText className="prev-button g-ml-30" onClick={toPrevStep}>
          Previous
        </HintText>
      </StyledButtonGroup>
    </DetailsTemplate>
  );
}

const StyledButtonGroup = styled.div`
  margin: auto auto 0 0;
  display: flex;
  align-items: center;
  .next-button {
    padding: 1.6rem 6rem;
  }
  .prev-button {
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
