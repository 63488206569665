import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import styled from 'styled-components';

import CustomTextarea from 'common/CustomTextarea';
import { useQuiz, useSetQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function Comment({ metaPath, answerField }) {
  const label = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const value = useQuiz(
    (current) => get(current, `content.answers.${answerField}.value`, ''),
    [answerField],
  );
  const styles = useQuiz((current) => get(current, `${metaPath}.styles`, ''), [metaPath]);

  const changeHandler = useSetQuiz(
    (prev, value) => {
      return set(cloneDeep(prev), `content.answers.${answerField}.value`, value);
    },
    [answerField],
  );

  return (
    <StyledCustomTextarea
      className="g-mt-20"
      styles={styles}
      label={label}
      value={value}
      changeHandler={changeHandler}
    />
  );
});

const StyledCustomTextarea = styled(CustomTextarea)`
  ${({ styles }) => styles || ''};
`;
