import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import CustomTextarea from 'common/CustomTextarea';
import BackButton from 'common/plate/BackButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import CustomCalendar from 'common/time-calendar';
import ProviderPicker from 'components/appointmentsAdmin/reschedule-appointment/ProviderPicker';
import { useAppointment, useSetAppointment } from 'hooks/useAppointments';
import { useFetchAppointment, useChangeAppointment } from 'requests/appointments';
import request from 'utils/request';

import { APPOINTMENTS_ADMIN, CHANGE_PROVIDER, RESCHEDULE } from '../../../constants/routes';

export default function RescheduleAppointment() {
  const { appointmentId } = useParams();
  const [allAvailableTime, setAllAvailableTime] = React.useState([]);
  const history = useHistory();
  const appointment = useAppointment();
  const setSelectedDate = useSetAppointment((prev, startedAt) => ({
    ...prev,
    started_at: startedAt,
  }));
  const setChangeReason = useSetAppointment((prev, changeReason) => ({ ...prev, changeReason }));
  const clearAppointment = useSetAppointment(() => null, []);
  const fetchAppointment = useFetchAppointment();
  const [isSended, setIsSended] = React.useState();
  const changeAppointment = useChangeAppointment();

  const doctorId = get(appointment, 'provider.id');
  const selectedDate = get(appointment, 'started_at');
  const changeReason = get(appointment, 'changeReason', '');

  React.useEffect(() => {
    if (!appointment) {
      fetchAppointment();
    }
    return () => {
      if (
        history.location.pathname !==
        `/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${appointmentId}/${CHANGE_PROVIDER}`
      ) {
        clearAppointment();
      }
    };
  }, [appointmentId]);

  React.useEffect(() => {
    if (!doctorId) {
      return;
    }
    const timezone = moment().format('ZZ');
    request({
      method: 'get',
      url: `/providers/${doctorId}/all-available-slots`,
      params: {
        timezone,
      },
    })
      .then(({ data: { data } }) => setAllAvailableTime(data))
      .catch((error) => {
        setAllAvailableTime([]);
        console.info(error);
      });
  }, [doctorId]);

  const handleChangeAppointment = React.useCallback(() => {
    changeAppointment(setIsSended);
  }, [changeAppointment, setIsSended]);

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS_ADMIN}`} />
        Reschedule an appointment
      </PlateHeader>

      <ProviderPicker />

      <PlateContent>
        <CustomCalendar
          className="g-mt-10"
          allAva
          label=" Select an available date and time"
          isLoading={!appointment}
          allAvailableTime={allAvailableTime}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <CustomTextarea
          className="g-mt-25"
          label="Indicate the reason"
          value={changeReason}
          changeHandler={setChangeReason}
        />

        <CustomButton
          className="g-mt-auto"
          disabled={!doctorId || !selectedDate || !changeReason || isSended}
          clickHandler={handleChangeAppointment}
        >
          Confirm
        </CustomButton>
      </PlateContent>
    </ColumnWidePlate>
  );
}
