import React from 'react';
import styled from 'styled-components';

import CloseIcon from 'common/CloseIcon';
import ConfirmationModal from 'common/ConfirmationModal';
import { useModalHandler } from 'hooks/useUtils';
import { useDeleteWidget } from 'requests/patients/widgets/widgets';

export default function DeleteWidgetModal({ widgetId }) {
  const [isOpen, openModal, closeModal] = useModalHandler();
  const [disabled, setDisabled] = React.useState(false);
  const deleteWidget = useDeleteWidget(widgetId);

  const deleteHandler = React.useCallback(async () => {
    setDisabled(true);
    const isSuccess = await deleteWidget();
    if (isSuccess) {
      closeModal();
    }
  }, [deleteWidget, closeModal]);

  return (
    <StyledWrapper>
      <CloseIcon className="icon" closeHandler={openModal} />

      {isOpen && (
        <ConfirmationModal
          disabled={disabled}
          closeModal={closeModal}
          confirmHandler={deleteHandler}
        />
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;
