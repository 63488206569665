import get from 'lodash/get';
import React from 'react';

import CustomSelect from 'common/CustomSelect';
import { PERIOD_SELECT } from 'constants/dates-options';
import { useAppointmentsFilters, useSetAppointmentsFilters } from 'hooks/useAppointments';

export default React.memo(function PeriodSelect() {
  const setPeriod = useSetAppointmentsFilters((prev, period) => ({ ...prev, period }), []);
  const period = useAppointmentsFilters((current) => get(current, 'period'));
  return (
    <CustomSelect
      className="period"
      label="Period"
      options={PERIOD_SELECT}
      value={period}
      changeHandler={setPeriod}
    />
  );
});
