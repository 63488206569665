import classNames from 'clsx';
import React, { FC } from 'react';

type ButtonProps = {
  onClick?: () => void;
  type?: 'main' | 'secondary';
  disabled?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  className?: string;
};

export const Button: FC<ButtonProps & { children: React.ReactNode }> = ({
  onClick,
  className,
  children,
  type = 'main',
  buttonType = 'button',
  disabled,
}) => {
  let styles = '';

  if (type === 'main') {
    styles = classNames('bg-main border-main30 text-white', {
      ' hover:bg-independence ': !disabled,
    });
  }
  if (type === 'secondary') {
    styles = 'bg-white border-main30 text-main';
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      className={classNames(
        'flex items-center px-[2.7rem] h-8 rounded-[2.4rem] text-1324',
        styles,
        className,
        {
          'opacity-50': disabled,
        },
      )}
    >
      {children}
    </button>
  );
};
