// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom';

import ScreenWidth from 'common/ScreenWidth';
import PictureInPicture from 'components/appointmentsProvider/consultation/video-component/PictureInPicture';
import BugherdLoader from 'components/BugherdLoader';
import ChatListeners from 'components/chat-listeners';
import InitialFetch from 'components/InitialFetch';
import InterceptorsHandler from 'components/InterceptorsHandler';
import LocalVideoListeners from 'components/LocalVideoListeners';
import MainVideoListeners from 'components/MainVideoListeners';
import OverlayLoader from 'components/OverlayLoader';
import Template from 'components/template';
import AppointmentsAdmin from 'containers/appointmentsAdmin/Appointments';
import AppointmentsCreateAdmin from 'containers/appointmentsAdmin/create/AppointmentsCreate';
import CreateChangeProvider from 'containers/appointmentsAdmin/create/CreateChangeProvider';
import ChangeProvider from 'containers/appointmentsAdmin/reschedule/ChangeProvider';
import RescheduleAppointment from 'containers/appointmentsAdmin/reschedule/RescheduleAppointment';
import RescheduleSuccess from 'containers/appointmentsAdmin/reschedule/RescheduleSuccess';
import AppointmentInfo from 'containers/appointmentsProvider/AppointmentInfo';
import Consultation from 'containers/appointmentsProvider/Consultation';
// eslint-disable-next-line import/no-duplicates
import AppointmentConfigurateAdmin from 'containers/appointmentsProvider/create/AppointmentConfigurate';
// eslint-disable-next-line import/no-duplicates
import AppointmentConfigurate from 'containers/appointmentsProvider/create/AppointmentConfigurate';
import AppointmentsCreate from 'containers/appointmentsProvider/create/AppointmentsCreate';
import DayAppoitments from 'containers/appointmentsProvider/DayAppoitments';
import MonthAppoitments from 'containers/appointmentsProvider/MonthAppoitments';
import WeekAppoitments from 'containers/appointmentsProvider/WeekAppoitments';
import WorkWeekAppoitments from 'containers/appointmentsProvider/WorkWeekAppoitments';
import ForgotPassword from 'containers/auth/ForgotPassword';
import LoginPage from 'containers/auth/LoginPage';
import Logout from 'containers/auth/Logout';
import TwoFAPage from 'containers/auth/TwoFAPage';
import Companies from 'containers/companies/Companies';
import Company from 'containers/companies/Company';
import CompanyPlans from 'containers/companies/CompanyPlans';
import CreateCompany from 'containers/companies/CreateCompany';
import Dashboard from 'containers/Dashboard';
import ActivityLog from 'containers/dashbord/ActivityLog';
import Camera from 'containers/environment-check/Camera';
import Finish from 'containers/environment-check/Finish';
import Introduction from 'containers/environment-check/Introduction';
import Microphone from 'containers/environment-check/Microphone';
import Speakers from 'containers/environment-check/Speakers';
import CreateDirect from 'containers/messages/CreateDirect';
import CreateGroup from 'containers/messages/CreateGroup';
import InviteGroup from 'containers/messages/InviteGroup';
import Messages from 'containers/messages/Messages';
import RenameGroup from 'containers/messages/RenameGroup';
import PatientCreateNotePreview from 'containers/patients/details/create-note/PatientCreateNotePreview';
import PatientCreateNoteStep1 from 'containers/patients/details/create-note/PatientCreateNoteStep1';
import PatientCreateNoteStep2 from 'containers/patients/details/create-note/PatientCreateNoteStep2';
import PatientCreateNoteStep3 from 'containers/patients/details/create-note/PatientCreateNoteStep3';
import PatientCreateNoteWrapper from 'containers/patients/details/create-note/PatientCreateNoteWrapper';
import { PatientDocuments } from 'containers/patients/details/documents';
import PatientEditNotePreview from 'containers/patients/details/edit-note/PatientEditNotePreview';
import PatientEditNoteStep1 from 'containers/patients/details/edit-note/PatientEditNoteStep1';
import PatientEditNoteStep2 from 'containers/patients/details/edit-note/PatientEditNoteStep2';
import PatientEditNoteStep3 from 'containers/patients/details/edit-note/PatientEditNoteStep3';
import PatientEditNoteWrapper from 'containers/patients/details/edit-note/PatientEditNoteWrapper';
import EditHealthPlan from 'containers/patients/details/health-plan/PatientEditHealthPlan';
import PatientCreateMedications from 'containers/patients/details/medications/PatientCreateMedications';
import PatientMedicationsEdit from 'containers/patients/details/medications/PatientMedicationsEdit';
import PatientMedicationsList from 'containers/patients/details/medications/PatientMedicationsList';
import PatientMedicationsProgress from 'containers/patients/details/medications/PatientMedicationsProgress';
import PatientAdminNotePreview from 'containers/patients/details/PatientAdminNotePreview';
import PatientAdminNotes from 'containers/patients/details/PatientAdminNotes';
import PatientCreateAdminNote from 'containers/patients/details/PatientCreateAdminNote';
import PatientCreateHealthPlan from 'containers/patients/details/PatientCreateHealthPlan';
import PatientDoctorNotes from 'containers/patients/details/PatientDoctorNotes';
import PatientEditProfile from 'containers/patients/details/PatientEditProfile';
import PatientHealthPlans from 'containers/patients/details/PatientHealthPlans';
import PatientInsurance from 'containers/patients/details/PatientInsurance';
import PatientInvite from 'containers/patients/details/PatientInvite';
import PatientLabs from 'containers/patients/details/PatientLabs';
import PatientNotePreview from 'containers/patients/details/PatientNotePreview';
import PatientOverview from 'containers/patients/details/PatientOverview';
import PatientPrescriptions from 'containers/patients/details/PatientPrescriptions';
import PatientProfile from 'containers/patients/details/PatientProfile';
import PatientReferral from 'containers/patients/details/PatientReferral';
import PatientSummary from 'containers/patients/details/PatientSummary';
import PatientCreatePrescription from 'containers/patients/details/prescriptions/PatientCreatePrescription';
import PatientPrescriptionPreview from 'containers/patients/details/prescriptions/PatientPrescriptionPreview';
import PatientPrescriptionView from 'containers/patients/details/prescriptions/PatientPrescriptionView';
import PatientQuestionnaires from 'containers/patients/details/questionnaires/PatientQuestionnaires';
import PatientQuiz from 'containers/patients/details/questionnaires/PatientQuiz';
import PatientQuizEdit from 'containers/patients/details/questionnaires/PatientQuizEdit';
import PatientQuizResults from 'containers/patients/details/questionnaires/PatientQuizResults';
import TreatmentPlanEdit from 'containers/patients/details/TreatmentPlanEdit';
import TreatmentPlanList from 'containers/patients/details/TreatmentPlanList';
import TreatmentPlanNew from 'containers/patients/details/TreatmentPlanNew';
import TreatmentPlanView from 'containers/patients/details/TreatmentPlanView';
import PatientActivityContributors from 'containers/patients/details/widgets/oura/PatientActivityContributors';
import PatientDailyMovement from 'containers/patients/details/widgets/oura/PatientDailyMovement';
import PatientRreadinessContributors from 'containers/patients/details/widgets/oura/PatientReadinessContributors';
import PatientSleepContributors from 'containers/patients/details/widgets/oura/PatientSleepContributors';
import PatientSleepStages from 'containers/patients/details/widgets/oura/PatientSleepStages';
import PatientWidgets from 'containers/patients/details/widgets/PatientWidgets';
import PatientWidgetsGroupWrapper from 'containers/patients/details/widgets/PatientWidgetsGroupWrapper';
import PatientPressure from 'containers/patients/details/widgets/withings/PatientPressure';
import PatientPulse from 'containers/patients/details/widgets/withings/PatientPulse';
import PatientWeight from 'containers/patients/details/widgets/withings/PatientWeight';
import PatientWeightTimeline from 'containers/patients/details/widgets/withings/PatientWeightTimeline';
import Patients from 'containers/patients/Patients';
import Availability from 'containers/profile/Availability';
import Information from 'containers/profile/Information';
import Qualification from 'containers/profile/Qualification';
import CreateProgram from 'containers/programs/CreateProgram';
import Program from 'containers/programs/Program';
import Programs from 'containers/programs/Programs';
import CreateProvider from 'containers/providers/CreateProvider';
import ProviderAdminNotePreview from 'containers/providers/ProviderAdminNotePreview';
import ProviderAdminNotes from 'containers/providers/ProviderAdminNotes';
import ProviderAvailability from 'containers/providers/ProviderAvailability';
import ProviderCreateAdminNote from 'containers/providers/ProviderCreateAdminNote';
import ProviderInformation from 'containers/providers/ProviderInformation';
import ProviderQualification from 'containers/providers/ProviderQualification';
import ProviderReferral from 'containers/providers/ProviderReferral';
import Providers from 'containers/providers/Providers';
import ProviderSummary from 'containers/providers/ProviderSummary';
import ReferFriend from 'containers/referral/ReferFriend';
import Referral from 'containers/referral/Referral';
import Password from 'containers/settings/Password';
import Security from 'containers/settings/Security';
import TwoFASuccess from 'containers/settings/TwoFASuccess';
import TrainingAndResources from 'containers/TrainingAndResources';
import { ADMINS_EMAILS } from 'env';
import { useFeatureFlags } from 'hooks/useFeatureFlag';

import { PrivateRoute, PrivateRouteProps } from './components/PrivateRoute';
import {
  ACTIVITY_CONTRIBUTORS,
  ACTIVITY_LOG,
  ADMIN_NOTES,
  APPOINTMENTS,
  APPOINTMENTS_ADMIN,
  APPOINTMENT_INFO,
  AVAILABILITY,
  BLOOD_PRESSURE,
  CAMERA,
  CHANGE_PROVIDER,
  COMPANIES,
  CONFIGURATE,
  CONSULTATION,
  CREATE,
  DAILY_MOVEMENT,
  DASHBOARD,
  DAY,
  DETAILS,
  DIRECT,
  DOCTOR_NOTES,
  DOCUMENTS,
  EDIT,
  ENVIRONMENT_CHECK,
  FINISH,
  FORGOT_PASSWORD,
  FRIEND,
  GROUP,
  HEALTH_PLANS,
  INFORMATION,
  INSURANCE,
  INTRODUCTION,
  INVITE,
  LABS,
  LIBRARY,
  LIST,
  LOGIN,
  LOGOUT,
  MEDICATIONS,
  MESSAGES,
  MICROPHONE,
  MONTH,
  OURA,
  OVERVIEW,
  PASSWORD,
  PATIENTS,
  PRESCRIPTIONS,
  PRESCRIPTION_PREVIEW,
  PRESCRIPTION_VIEW,
  PREVIEW,
  PROFILE,
  PROGRESS,
  PROVIDERS,
  PULSE,
  QUALIFICATION,
  QUESTIONNAIRES,
  QUIZ,
  READINESS_CONTRIBUTORS,
  REFERRAL,
  RENAME,
  RESCHEDULE,
  RESCHEDULE_SUCCESS,
  RESULTS,
  METRICS,
  SECURITY,
  SETTINGS,
  SLEEP_CONTRIBUTORS,
  SLEEP_STAGES,
  SPEAKERS,
  SUMMARY,
  TRAINING_AND_RESOURCES,
  TREATMENT_PLANS,
  TWO_FA_LOGIN,
  TWO_FA_SUCCESS,
  VIEW,
  WEEK,
  WEIGHT,
  WEIGHT_TIMELINE,
  WIDGETS,
  WITHINGS,
  WORK_WEEK,
  PROGRAMS,
  FOOD_JOURNAL,
} from './constants/routes';
import { CreateMaterial } from './containers/library/CreateMaterial';
import { EditMaterial } from './containers/library/EditMaterial';
import { ProgramMaterials } from './containers/library/ProgramMaterials';
import { ViewMaterial } from './containers/library/ViewMaterial';
import FoodJournal from './containers/patients/details/food-journal/FoodJournal';
import PatientQuizMetrics from './containers/patients/details/questionnaires/PatientQuizMetrics';
import { UserContextProvider } from './context/userContext';
import { permissions } from './permissions';

const proId = `/${PROVIDERS}/:providerId`;
const patId = `/${PATIENTS}/${DETAILS}/:patientId`;

type RouteType = {
  path: string;
  disabled?: boolean;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (props: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};
type RedirectType = {
  from: string;
  to: string;
};

function ScrollToTop() {
  const history = useHistory();
  React.useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0));
    return () => unlisten();
  }, []);
  return null;
}

const RedirectIfLoggedIn: FC<RedirectType> = (props) => {
  if (!localStorage.getItem('idToken')) {
    return <Redirect from={props.from} to={`/${LOGIN}`} />;
  }
  return <Redirect from={props.from} to={props.to} />;
};

const RouteIfNotAuth: FC<RouteType> = (props) => {
  if (localStorage.getItem('idToken')) {
    return <Redirect to={`/${DASHBOARD}`} />;
  }
  return <Route {...props} />;
};

const RouteIfNotAdmin: FC<PrivateRouteProps> = (props) => {
  const currentAdmin = localStorage.getItem('email') || '';
  const currentRole = localStorage.getItem('role');
  const adminEmails = ADMINS_EMAILS;
  if (adminEmails && adminEmails.length > 0 && !adminEmails.includes(currentAdmin)) {
    return <Redirect to={`/${DASHBOARD}`} />;
  } else if (0 === adminEmails?.length && 'admin' !== currentRole) {
    return <Redirect to={`/${DASHBOARD}`} />;
  }
  return (
    <UserContextProvider>
      <PrivateRoute {...props} />
    </UserContextProvider>
  );
};

const RouteIfAuth: FC<PrivateRouteProps> = (props) => {
  if (props.disabled) {
    return <Redirect to={`/${DASHBOARD}`} />;
  }
  if (!localStorage.getItem('idToken')) {
    return <Redirect to={`/${LOGIN}`} />;
  }
  return (
    <UserContextProvider>
      <PrivateRoute {...props} />
    </UserContextProvider>
  );
};

function tpl(Component: FC) {
  // eslint-disable-next-line react/display-name
  return () => (
    <Template>
      <Component />
    </Template>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});

export default function Routes() {
  const ff = useFeatureFlags();
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <BrowserRouter getUserConfirmation={() => {}}>
        <InterceptorsHandler />
        <ScreenWidth />
        <ScrollToTop />
        <InitialFetch />
        <ChatListeners />
        <LocalVideoListeners />
        <MainVideoListeners />
        <Switch>
          <RouteIfNotAuth path={`/${LOGIN}`} component={LoginPage} />
          <RouteIfNotAuth path={`/${TWO_FA_LOGIN}`} component={TwoFAPage} />
          <RouteIfNotAuth path={`/${FORGOT_PASSWORD}`} component={ForgotPassword} />
          <RouteIfAuth path={`/${LOGOUT}`} component={Logout} />

          <RouteIfAuth path={`/${DASHBOARD}`} exact render={tpl(Dashboard)} />
          <RouteIfAuth
            permissions={[permissions.activityLog.canViewPage]}
            path={`/${DASHBOARD}/${ACTIVITY_LOG}`}
            render={tpl(ActivityLog)}
          />

          <RouteIfAuth path={`/${PROFILE}/${INFORMATION}`} render={tpl(Information)} />
          <RouteIfAuth path={`/${PROFILE}/${QUALIFICATION}`} render={tpl(Qualification)} />
          <RouteIfAuth path={`/${PROFILE}/${AVAILABILITY}`} render={tpl(Availability)} />

          <RouteIfAuth path={`/${ENVIRONMENT_CHECK}/${INTRODUCTION}`} render={tpl(Introduction)} />
          <RouteIfAuth path={`/${ENVIRONMENT_CHECK}/${CAMERA}`} render={tpl(Camera)} />
          <RouteIfAuth path={`/${ENVIRONMENT_CHECK}/${MICROPHONE}`} render={tpl(Microphone)} />
          <RouteIfAuth path={`/${ENVIRONMENT_CHECK}/${SPEAKERS}`} render={tpl(Speakers)} />
          <RouteIfAuth path={`/${ENVIRONMENT_CHECK}/${FINISH}`} render={tpl(Finish)} />

          <RouteIfAuth path={`/${PATIENTS}/:status`} exact render={tpl(Patients)} />
          <RouteIfAuth path={`${patId}/${OVERVIEW}`} render={tpl(PatientOverview)} />
          <RouteIfAuth path={`${patId}/${SUMMARY}`} render={tpl(PatientSummary)} />
          <RouteIfAuth
            disabled={!ff.treatmentPlan}
            path={`${patId}/${TREATMENT_PLANS}`}
            exact
            render={tpl(TreatmentPlanList)}
          />
          <RouteIfAuth
            disabled={!ff.treatmentPlan}
            path={`${patId}/${TREATMENT_PLANS}/${CREATE}`}
            render={tpl(TreatmentPlanNew)}
          />
          <RouteIfAuth
            disabled={!ff.treatmentPlan}
            path={`${patId}/${TREATMENT_PLANS}/${EDIT}/:planId`}
            render={tpl(TreatmentPlanEdit)}
          />
          <RouteIfAuth
            disabled={!ff.treatmentPlan}
            path={`${patId}/${TREATMENT_PLANS}/${VIEW}/:planId`}
            render={tpl(TreatmentPlanView)}
          />
          <RouteIfAuth path={`${patId}/${PROFILE}`} render={tpl(PatientProfile)} />
          <RouteIfAuth path={`${patId}/${EDIT}`} render={tpl(PatientEditProfile)} />
          <RouteIfAuth
            path={`${patId}/${QUESTIONNAIRES}/${QUIZ}/:quizId/${RESULTS}`}
            render={tpl(PatientQuizResults)}
          />
          <RouteIfAuth
            path={`${patId}/${QUESTIONNAIRES}/${QUIZ}/:quizId/${METRICS}`}
            render={tpl(PatientQuizMetrics)}
          />
          <RouteIfAuth
            path={`${patId}/${QUESTIONNAIRES}/${QUIZ}/:quizId/${EDIT}`}
            render={tpl(PatientQuizEdit)}
          />
          <RouteIfAuth
            path={`${patId}/${QUESTIONNAIRES}/${QUIZ}/:quizId`}
            render={tpl(PatientQuiz)}
          />
          <RouteIfAuth
            path={`${patId}/${QUESTIONNAIRES}/:type`}
            render={tpl(PatientQuestionnaires)}
          />
          <RouteIfAuth
            path={`${patId}/${HEALTH_PLANS}/${CREATE}`}
            render={tpl(PatientCreateHealthPlan)}
          />
          <RouteIfAuth
            path={`${patId}/${HEALTH_PLANS}/:healthPlanID/${EDIT}`}
            render={tpl(EditHealthPlan)}
          />
          <RouteIfAuth path={`${patId}/${HEALTH_PLANS}`} render={tpl(PatientHealthPlans)} />
          <RouteIfAuth path={`${patId}/${DOCTOR_NOTES}/${CREATE}`}>
            <PatientCreateNoteWrapper>
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/${CREATE}/1`}
                render={tpl(PatientCreateNoteStep1)}
              />
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/${CREATE}/2`}
                render={tpl(PatientCreateNoteStep2)}
              />
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/${CREATE}/3`}
                render={tpl(PatientCreateNoteStep3)}
              />
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/${CREATE}/${PREVIEW}`}
                render={tpl(PatientCreateNotePreview)}
              />
            </PatientCreateNoteWrapper>
          </RouteIfAuth>
          <RouteIfAuth
            path={`${patId}/${DOCTOR_NOTES}/:noteId/${EDIT}/${PREVIEW}`}
            render={tpl(PatientEditNotePreview)}
          />
          <RouteIfAuth path={`${patId}/${DOCTOR_NOTES}/:noteId/${EDIT}`}>
            <PatientEditNoteWrapper>
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/:noteId/${EDIT}/1`}
                render={tpl(PatientEditNoteStep1)}
              />
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/:noteId/${EDIT}/2`}
                render={tpl(PatientEditNoteStep2)}
              />
              <RouteIfAuth
                path={`${patId}/${DOCTOR_NOTES}/:noteId/${EDIT}/3`}
                render={tpl(PatientEditNoteStep3)}
              />
            </PatientEditNoteWrapper>
          </RouteIfAuth>
          <RouteIfAuth
            path={`${patId}/${DOCTOR_NOTES}/:noteId/${PREVIEW}`}
            render={tpl(PatientNotePreview)}
          />
          <RouteIfAuth path={`${patId}/${DOCTOR_NOTES}`} render={tpl(PatientDoctorNotes)} />
          <RouteIfAuth path={`${patId}/${LABS}`} render={tpl(PatientLabs)} />
          <RouteIfAuth path={`${patId}/${DOCUMENTS}`} render={tpl(PatientDocuments)} />
          <RouteIfAuth path={`${patId}/${REFERRAL}`} render={tpl(PatientReferral)} />
          <RouteIfAuth
            path={`${patId}/${ADMIN_NOTES}/${CREATE}`}
            render={tpl(PatientCreateAdminNote)}
          />
          <RouteIfAuth
            path={`${patId}/${ADMIN_NOTES}/:noteId/${PREVIEW}`}
            render={tpl(PatientAdminNotePreview)}
          />
          <RouteIfAuth path={`${patId}/${ADMIN_NOTES}`} render={tpl(PatientAdminNotes)} />
          <RouteIfAuth path={`${patId}/${INSURANCE}`} render={tpl(PatientInsurance)} />
          <RouteIfAuth
            path={`${patId}/${PRESCRIPTIONS}/${PRESCRIPTION_VIEW}`}
            render={tpl(PatientPrescriptionView)}
          />
          <RouteIfAuth
            path={`${patId}/${PRESCRIPTIONS}/${PRESCRIPTION_PREVIEW}`}
            render={tpl(PatientPrescriptionPreview)}
          />
          <RouteIfAuth
            path={`${patId}/${PRESCRIPTIONS}/${CREATE}`}
            render={tpl(PatientCreatePrescription)}
          />
          <RouteIfAuth path={`${patId}/${PRESCRIPTIONS}`} render={tpl(PatientPrescriptions)} />
          <RouteIfAuth
            path={`${patId}/${MEDICATIONS}/${CREATE}`}
            render={tpl(PatientCreateMedications)}
          />
          <RouteIfAuth
            path={`${patId}/${MEDICATIONS}/${PROGRESS}/:medicationId`}
            render={tpl(PatientMedicationsProgress)}
          />
          <RouteIfAuth
            path={`${patId}/${MEDICATIONS}/:medicationsId/${EDIT}`}
            render={tpl(PatientMedicationsEdit)}
          />
          <RouteIfAuth path={`${patId}/${MEDICATIONS}`} render={tpl(PatientMedicationsList)} />

          <RouteIfAuth
            path={`${patId}/${WIDGETS}/${WITHINGS}/:widgetId/${WEIGHT_TIMELINE}`}
            render={tpl(PatientWeightTimeline)}
          />
          <RouteIfAuth path={`${patId}/${WIDGETS}/${WITHINGS}/:widgetId`}>
            <PatientWidgetsGroupWrapper>
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${WITHINGS}/:widgetId/${WEIGHT}`}
                render={tpl(PatientWeight)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${WITHINGS}/:widgetId/${PULSE}`}
                render={tpl(PatientPulse)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${WITHINGS}/:widgetId/${BLOOD_PRESSURE}`}
                render={tpl(PatientPressure)}
              />
            </PatientWidgetsGroupWrapper>
          </RouteIfAuth>
          <RouteIfAuth path={`${patId}/${FOOD_JOURNAL}`} render={tpl(FoodJournal)} />

          <RouteIfAuth path={`${patId}/${WIDGETS}/${OURA}/:widgetId`}>
            <PatientWidgetsGroupWrapper>
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${OURA}/:widgetId/${READINESS_CONTRIBUTORS}`}
                render={tpl(PatientRreadinessContributors)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${OURA}/:widgetId/${DAILY_MOVEMENT}`}
                render={tpl(PatientDailyMovement)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${OURA}/:widgetId/${ACTIVITY_CONTRIBUTORS}`}
                render={tpl(PatientActivityContributors)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${OURA}/:widgetId/${SLEEP_STAGES}`}
                render={tpl(PatientSleepStages)}
              />
              <RouteIfAuth
                path={`${patId}/${WIDGETS}/${OURA}/:widgetId/${SLEEP_CONTRIBUTORS}`}
                render={tpl(PatientSleepContributors)}
              />
            </PatientWidgetsGroupWrapper>
          </RouteIfAuth>
          <RouteIfAuth path={`${patId}/${WIDGETS}`} render={tpl(PatientWidgets)} />

          <RouteIfAuth path={`/${COMPANIES}`} exact render={tpl(Companies)} />
          <RouteIfAuth
            permissions={[permissions.companies.canAddNewCompany]}
            path={`/${COMPANIES}/${CREATE}`}
            exact
            render={tpl(CreateCompany)}
          />
          <RouteIfAuth
            permissions={[permissions.companies.canAddNewCompany]}
            path={`/${COMPANIES}/:companyId/${INVITE}`}
            exact
            render={tpl(PatientInvite)}
          />
          <RouteIfAuth path={`/${COMPANIES}/:companyId`} exact render={tpl(Company)} />

          <RouteIfAuth
            path={`/${COMPANIES}/${PROGRAMS}/:subscriptionId`}
            exact
            render={tpl(CompanyPlans)}
          />

          <RouteIfNotAdmin path={`/${PROGRAMS}`} exact render={tpl(Programs)} />
          <RouteIfNotAdmin path={`/${PROGRAMS}/${CREATE}`} exact render={tpl(CreateProgram)} />
          <RouteIfNotAdmin path={`/${PROGRAMS}/:programId`} exact render={tpl(Program)} />

          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`/${PROVIDERS}`}
            exact
            render={tpl(Providers)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`/${PROVIDERS}/${CREATE}`}
            exact
            render={tpl(CreateProvider)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${SUMMARY}`}
            render={tpl(ProviderSummary)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${INFORMATION}`}
            render={tpl(ProviderInformation)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${QUALIFICATION}`}
            render={tpl(ProviderQualification)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${AVAILABILITY}`}
            render={tpl(ProviderAvailability)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${REFERRAL}`}
            render={tpl(ProviderReferral)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${ADMIN_NOTES}/${CREATE}`}
            render={tpl(ProviderCreateAdminNote)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${ADMIN_NOTES}/:noteId/${PREVIEW}`}
            render={tpl(ProviderAdminNotePreview)}
          />
          <RouteIfAuth
            permissions={[permissions.providers.canViewPage]}
            path={`${proId}/${ADMIN_NOTES}`}
            render={tpl(ProviderAdminNotes)}
          />

          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}`}
            exact
            render={tpl(AppointmentsAdmin)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${CREATE}`}
            exact
            render={tpl(AppointmentsCreateAdmin)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${CREATE}/${CONFIGURATE}`}
            exact
            render={tpl(AppointmentConfigurateAdmin)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${CREATE}/${CHANGE_PROVIDER}`}
            exact
            render={tpl(CreateChangeProvider)}
          />

          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/:appointmentId`}
            exact
            render={tpl(AppointmentInfo)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${RESCHEDULE_SUCCESS}`}
            render={tpl(RescheduleSuccess)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/:appointmentId`}
            exact
            render={tpl(RescheduleAppointment)}
          />
          <RouteIfAuth
            permissions={[permissions.appointmentsAdmin.canViewPage]}
            path={`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/:appointmentId/${CHANGE_PROVIDER}`}
            render={tpl(ChangeProvider)}
          />

          <RouteIfAuth path={`/${APPOINTMENTS}/${DAY}`} exact render={tpl(DayAppoitments)} />
          <RouteIfAuth path={`/${APPOINTMENTS}/${WEEK}`} exact render={tpl(WeekAppoitments)} />
          <RouteIfAuth
            path={`/${APPOINTMENTS}/${WORK_WEEK}`}
            exact
            render={tpl(WorkWeekAppoitments)}
          />
          <RouteIfAuth path={`/${APPOINTMENTS}/${MONTH}`} exact render={tpl(MonthAppoitments)} />
          <RouteIfAuth
            path={`/${APPOINTMENTS}/${APPOINTMENT_INFO}/:appointmentId`}
            exact
            render={tpl(AppointmentInfo)}
          />
          <RouteIfAuth
            path={`/${APPOINTMENTS}/${CONSULTATION}/:appointmentId`}
            exact
            render={tpl(Consultation)}
          />
          <RouteIfAuth path={`/${APPOINTMENTS}/${CREATE}`} exact render={tpl(AppointmentsCreate)} />
          <RouteIfAuth
            path={`/${APPOINTMENTS}/${CREATE}/${CONFIGURATE}`}
            exact
            render={tpl(AppointmentConfigurate)}
          />

          <RouteIfAuth path={`/${MESSAGES}/${DIRECT}`} render={tpl(CreateDirect)} />
          <RouteIfAuth path={`/${MESSAGES}/${GROUP}`} render={tpl(CreateGroup)} />
          <RouteIfAuth path={`/${MESSAGES}/:channelSID/${INVITE}`} render={tpl(InviteGroup)} />
          <RouteIfAuth path={`/${MESSAGES}/:channelSID/${RENAME}`} render={tpl(RenameGroup)} />
          <RouteIfAuth path={`/${MESSAGES}/:channelSID`} render={tpl(Messages)} />
          <RouteIfAuth path={`/${MESSAGES}`} render={tpl(Messages)} />

          <RouteIfAuth path={`/${SETTINGS}/${PASSWORD}`} render={tpl(Password)} />
          <RouteIfAuth
            path={`/${SETTINGS}/${SECURITY}/${TWO_FA_SUCCESS}`}
            render={tpl(TwoFASuccess)}
          />
          <RouteIfAuth path={`/${SETTINGS}/${SECURITY}`} render={tpl(Security)} />
          <RouteIfAuth
            disabled={!ff.library}
            path={`/${LIBRARY}`}
            exact
            render={tpl(ProgramMaterials)}
          />
          <RouteIfAuth
            disabled={!ff.library}
            path={`/${LIBRARY}/${CREATE}`}
            render={tpl(CreateMaterial)}
          />
          <RouteIfAuth
            disabled={!ff.library}
            path={`/${LIBRARY}/:materialId/${EDIT}`}
            exact
            render={tpl(EditMaterial)}
          />
          <RouteIfAuth
            disabled={!ff.library}
            path={`/${LIBRARY}/:materialId/${VIEW}`}
            exact
            render={tpl(ViewMaterial)}
          />

          <RouteIfAuth path={`/${TRAINING_AND_RESOURCES}`} render={tpl(TrainingAndResources)} />
          <RouteIfAuth path={`/${REFERRAL}/${FRIEND}`} render={tpl(ReferFriend)} />
          <RouteIfAuth path={`/${REFERRAL}/${LIST}`} render={tpl(Referral)} />

          <RedirectIfLoggedIn from="/" to={`/${DASHBOARD}`} />
        </Switch>
        <Toaster />
        <PictureInPicture />
        <BugherdLoader />
        <OverlayLoader />
      </BrowserRouter>
    </QueryClientProvider>
  );
}
