import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import SwitcherTab from 'common/SwitcherTab';

export default React.memo(function TabsSwitcher({
  className = '',
  role,
  tabsConfig = [],
  currentTab,
  onChange,
}) {
  const tabs = React.useMemo(() => {
    const filtered = tabsConfig.filter((c) => {
      if (role && c.roles) {
        if (!(c.roles instanceof Array)) {
          return true;
        }
        return c.roles.includes(role);
      }
      return true;
    });
    return filtered.map(({ id, label, href }, i) => {
      let isSeparatorNeed = true;
      if (id === currentTab) {
        isSeparatorNeed = false;
      }
      if (get(filtered[i + 1], 'id') === currentTab) {
        isSeparatorNeed = false;
      }
      if (filtered.length === i + 1) {
        isSeparatorNeed = false;
      }
      return (
        <SwitcherTab
          key={id}
          onClick={() => onChange && onChange(id)}
          label={label}
          href={href}
          isActive={id === currentTab}
          isSeparatorNeed={isSeparatorNeed}
        />
      );
    });
  }, [tabsConfig, currentTab]);
  return <StyledWrapper className={className}>{tabs}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  background: #eee;
  border: solid 2px #eee;
  border-radius: 0.8rem;
`;
