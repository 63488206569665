import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { PATIENTS, DETAILS, OVERVIEW, MESSAGES, PROVIDERS, SUMMARY } from 'constants/routes';
import { usePrivateChannelData } from 'hooks/useMessages';
import { useScreenWidth } from 'hooks/useUtils';

import { StyledWrapper, StyledUserAvatar } from './styles';
import { useUserContext } from '../../../context/userContext';
import { permissions } from '../../../permissions';

export default React.memo(function PrivateHeader({ channel }) {
  const { channelSID } = useParams();
  const { can } = useUserContext();
  const history = useHistory();
  const isMobile = useScreenWidth((value) => value <= 480, []);
  const opponentData = usePrivateChannelData(channel);

  const id = get(opponentData, 'id');
  const avatarUrl = get(opponentData, 'avatar_url');
  const firstName = get(opponentData, 'first_name');
  const lastName = get(opponentData, 'last_name');
  const fullName = get(opponentData, 'full_name');
  const type = get(opponentData, 'type');
  const qualification = get(opponentData, 'qualification');

  const navigateCondition = id && type === 'patient';

  const navigateToOpponent = React.useCallback(() => {
    if (type === 'patient') {
      return history.push({
        pathname: `/${PATIENTS}/${DETAILS}/${id}/${OVERVIEW}`,
        state: { referrer: `/${MESSAGES}/${channelSID}` },
      });
    }
    if (id && can(permissions.providers.canViewPage)) {
      return history.push({
        pathname: `/${PROVIDERS}/${id}/${SUMMARY}`,
        state: { referrer: `/${MESSAGES}/${channelSID}` },
      });
    }
  }, [type, history, id, channelSID]);

  const subName = React.useMemo(() => {
    if (type === 'patient') {
      return 'patient';
    }
    return qualification;
  }, [type, qualification]);

  return (
    <StyledWrapper>
      {isMobile && <BackButton href={`/${MESSAGES}`} />}
      <StyledUserAvatar
        className="g-mr-10"
        firstName={firstName}
        lastName={lastName}
        avatar={avatarUrl}
        onClick={navigateCondition ? navigateToOpponent : null}
      />
      <div>
        <div className="name">{fullName}</div>
        <div className="sub-name">{subName}</div>
      </div>
    </StyledWrapper>
  );
});
