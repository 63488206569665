import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { ReactComponent as EmptyImage } from 'assets/empty-image.svg';

import IconButton from './components/UI/IconButton';
import LinkButton from './components/UI/LinkButton';
import { formatDateReadable, capitalizeFirstLetter } from './utils';
import CustomButton from '../../../../common/CustomButton';
import { useSetMealNote, useSetUpdateMealNote } from '../../../../hooks/patient/useFoodJournal';
import { useAddNote, useUpdateNote } from '../../../../requests/patients/food-journal';

export default function MealModal({ closeModal, meal, mealData }) {
  const [state, setState] = useState({
    disabled: true,
    isDisabledNote: true,
    noteDescription: '',
    isNoteValid: true,
    currentNoteId: null,
    errorMessage: '',
    errorNewNoteMessage: '',
  });

  const addNote = useAddNote();
  const updateNote = useUpdateNote();
  const providerId = localStorage.getItem('provider_id');
  const setMealNote = useSetMealNote((prev, description) => ({ ...prev, description }), []);
  const setMealId = useSetMealNote((prev, meal_id) => ({ ...prev, meal_id }), []);

  const setUpdateMealNote = useSetUpdateMealNote(
    (prev, description) => ({ ...prev, description }),
    [],
  );
  const setUpdateMealId = useSetUpdateMealNote((prev, id) => ({ ...prev, id }), []);
  const filteredNotes = mealData.notes.filter((note) => note.provider.id === providerId);
  const filteredNotesReversed = filteredNotes.reverse();

  useEffect(() => {
    clearState();
    setMealId(meal.id);
  }, []);

  const updateState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const clearState = () => {
    updateState({
      disabled: true,
      isDisabledNote: true,
      noteDescription: '',
      isNoteValid: true,
      currentNoteId: null,
      errorMessage: '',
    });
  };

  const handleNewNoteChange = (e) => {
    const input = e.target.value;
    if (input.length >= 5) {
      updateState({ disabled: false, isNoteValid: true, errorNewNoteMessage: '' });
      setMealNote(input);
    } else {
      updateState({
        disabled: true,
        isNoteValid: false,
        errorNewNoteMessage: 'Note must be at least 5 characters long',
      });
    }
  };

  const handleNoteChange = (e) => {
    updateState({ noteDescription: e.target.value });
    if (e.target.value.length >= 5) {
      updateState({ isDisabledNote: false, errorMessage: '' });
      setUpdateMealNote(e.target.value);
    } else {
      updateState({
        isDisabledNote: true,
        errorMessage: 'Note must be at least 5 characters long',
      });
    }
  };

  const handleFocus = (id, value) => {
    clearState();
    setUpdateMealId(id);
    updateState({ currentNoteId: id, noteDescription: value });
  };

  const handleClearTextarea = () => {
    const textarea = document.querySelector('textarea');
    if (textarea) {
      textarea.value = '';
    }
  };

  const handleAddNote = () => {
    addNote().then(() => {
      clearState();
      handleClearTextarea();
    });
  };

  const handleUpdateAddNote = () => {
    updateNote().then(() => {
      clearState();
    });
  };

  return ReactDOM.createPortal(
    <ModalStyledWrapper
      onClick={React.useCallback(
        (e) => {
          if (!closeModal) {
            return null;
          }
          e.stopPropagation();
          clearState();
          closeModal();
        },
        [closeModal, clearState],
      )}
      minWidth={45}
      maxWidth={45}
      background="#fff"
    >
      <div className="container" onClick={React.useCallback((e) => e.stopPropagation(), [])}>
        <div className="header items-center pb-0 md:pb:2">
          {/* <div className="title">Your note to patient</div> */}
          <div className="text-16px24px font-semibold text-main">Your note to patient</div>
          <IconButton onClick={closeModal} />
        </div>
        <hr className="separator" />

        {/* Modal Content */}
        <div className="overflow-y-auto max-h-96">
          <div className="flex w-full justify-between">
            <div className="flex flex-col">
              <div className="flex items-center gap-[5px] my-[5px]">
                <p className="text-main text-14px20px font-semibold">
                  {capitalizeFirstLetter(meal.type)}
                </p>
                <p className="text-manatee text-14px20px font-medium">
                  ({formatDateReadable(meal.date)})
                </p>
              </div>
            </div>
          </div>
          <p className="text-main text-12px18px font-medium mb-0 md:mb:3 text-ellipsis overflow-hidden">
            {capitalizeFirstLetter(meal.description)}
          </p>
          {meal.image_url ? (
            <div>
              <div className="w-full aspect-video">
                <img className="object-cover w-full h-full" src={meal.image_url} alt="Meal" />
              </div>
              <div>
                <LinkButton href={meal.image_url} target="blank">
                  Show full photo
                </LinkButton>
              </div>
            </div>
          ) : (
            <>
              <EmptyImg className="rounded-t-lg flex justify-center items-center w-full">
                <EmptyImage />
              </EmptyImg>
            </>
          )}

          {filteredNotesReversed && <StyledInfo className="mb-2 mt-5">Notes</StyledInfo>}
          <div className="flex flex-col gap-[10px]">
            {filteredNotesReversed &&
              filteredNotesReversed.map((note) => {
                return (
                  <ModalStyledNotes key={note.id}>
                    <div className="flex flex-col w-full">
                      {state.currentNoteId !== note.id ? (
                        <p
                          className="py-4 px-2.5 text-13px16px text-ellipsis overflow-hidden rounded-sm border bg-gray-50 cursor-pointer border-gray-200"
                          onClick={() => handleFocus(note.id, note.description)}
                        >
                          {note.description}
                        </p>
                      ) : null}

                      {state.currentNoteId === note.id ? (
                        <>
                          <textarea
                            placeholder={note.description}
                            rows="3"
                            value={
                              state.currentNoteId === note.id
                                ? state.noteDescription
                                : note.description
                            }
                            onChange={handleNoteChange}
                            onFocus={() => handleFocus(note.id, note.description)}
                            className={`block py-4 px-2.5 rounded-sm w-full min-h-10 text-13px16px border focus:ring-blue-500 focus:border-blue-500 ${
                              state.errorMessage && state.currentNoteId === note.id
                                ? 'border-red-500'
                                : 'border-gray-200'
                            }`}
                          ></textarea>
                          {state.errorMessage && state.currentNoteId === note.id && (
                            <p className="mt-2 text-red-500 text-12px18px">{state.errorMessage}</p>
                          )}
                        </>
                      ) : null}

                      {state.currentNoteId === note.id ? (
                        <div className="flex justify-end">
                          <StyledUpdate
                            disabled={state.currentNoteId === note.id ? state.isDisabledNote : true}
                            clickHandler={handleUpdateAddNote}
                          >
                            Update
                          </StyledUpdate>
                          <StyledUpdate clickHandler={() => clearState()}>Cancel</StyledUpdate>
                        </div>
                      ) : null}
                    </div>
                  </ModalStyledNotes>
                );
              })}
          </div>
        </div>
        {/* Modal Content */}

        <p className="text-12px18px mb-2 mt-4 text-main">Add your note:</p>
        <textarea
          rows="3"
          className={`block py-4 px-2.5 rounded-sm w-full min-h-10 text-13px16px border focus:ring-blue-500 focus:border-blue-500 ${
            state.errorNewNoteMessage && !state.isNoteValid ? 'border-red-500' : 'border-gray-200'
          }`}
          placeholder="Write your thoughts here..."
          onChange={handleNewNoteChange}
        ></textarea>
        {!state.isNoteValid && (
          <p className="mt-2 text-red-500 text-12px18px">{state.errorNewNoteMessage}</p>
        )}
        {/* <StyledInput type="text" placeholder="Take a note" onChange={handleChange} /> */}
        <div className="flex gap-[2rem] mt-0 md:mt-4">
          <CustomButton
            disabled={state.disabled}
            className="g-mt-10 md:g-mt-20 flex-1"
            clickHandler={handleAddNote}
          >
            Add
          </CustomButton>
          <CustomButton
            disabled={false}
            className="g-mt-10 md:g-mt-20 flex-1"
            inversion={true}
            clickHandler={closeModal}
          >
            Cancel
          </CustomButton>
        </div>
      </div>
    </ModalStyledWrapper>,
    document.getElementById('modal'),
  );
}

//MealModal
const ModalStyledWrapper = styled.div`
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 9999;
  /* backdrop-filter: blur(3px); */
  background: rgba(30, 34, 42, 0.4);
  .container {
    margin: auto auto;
    min-width: ${({ minWidth }) => minWidth || 60}rem;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'rem' : 'unset')};
    background: ${({ background }) => background};
    border-radius: 20px;
    padding: 25px;
    padding-top: 20px;
    box-shadow: 0 1px 8px 0 rgba(20, 46, 110, 0.1);
    @media only screen and (max-width: 480px) {
      min-width: unset;
      width: 85%;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      width: 100%;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      text-align: start;
      color: #16123f;
    }
  }
  .separator {
    height: 1px;
    margin: 1.5rem -2.5rem;
    background: #e9ebef;
  }
`;
const StyledInfo = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #223232;
  text-transform: uppercase;
  opacity: 50%;
`;
const StyledUpdate = styled(CustomButton)`
  margin: 5px 0;
  padding: 8px;
  margin-right: 4px;
  border: unset;
  cursor: pointer;
  &:hover {
    background: rgba(22, 18, 63, 0.8);
    color: white;
  }
`;
const ModalStyledNotes = styled.div`
  color: #223232;
  display: flex;
  justify-content: start;
  gap: 10px;
`;

export const EmptyImg = styled.div`
  /* width: 230px; */
  height: 136px;
  /* object-fit: cover; */
  background-color: #f1f5f8;
`;
