import get from 'lodash/get';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PATIENTS, DETAILS, QUESTIONNAIRES, QUIZ, RESULTS } from 'constants/routes';
import { useQuiz, useQuizStep } from 'hooks/patient/useQuestionnaires';
import { useSaveQuiz } from 'requests/patients/questionnaires';

export default React.memo(function SaveListener() {
  const { patientId, quizId } = useParams();
  const history = useHistory();
  const step = useQuizStep();
  const lastPageIndex = useQuiz((current) => get(current, 'content.pages', []).length, []);
  const saveQuiz = useSaveQuiz();

  React.useEffect(() => {
    async function handleSaveQuiz() {
      const isSuccess = await saveQuiz({ quizId });
      if (isSuccess) {
        history.push(
          `/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${QUIZ}/${quizId}/${RESULTS}`,
        );
      }
    }
    if (+step >= +lastPageIndex) {
      handleSaveQuiz();
    }
    // eslint-disable-next-line
  }, [step, lastPageIndex, patientId, quizId])

  return null;
});
