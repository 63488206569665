import moment from 'moment';
import React from 'react';
import { Switch } from 'react-router-dom';

import {
  useWidget,
  useWidgetFilters,
  useSetWidget,
  useSetWidgetFilters,
} from 'hooks/patient/widgets/useWidgets';
import { defaultWidgetFilters } from 'hooks/useGlobalState';
import { useFetchWidget } from 'requests/patients/widgets/widgets';

export default function PatientWidgetsGroupWrapper({ children }) {
  const widgetFilters = useWidgetFilters();
  const fetchWidget = useFetchWidget();
  const clearWidget = useSetWidget(() => null);
  const clearWidgetFilters = useSetWidgetFilters(() => defaultWidgetFilters);
  const initalFetch = React.useRef(false);

  const widget = useWidget();
  const selectDate = useSetWidgetFilters(
    (prev, date) => ({
      ...prev,
      selectedDay: date,
      start: moment(date).subtract(30, 'days').format('YYYY-MM-DD'),
      end: moment(date).add(7, 'days').format('YYYY-MM-DD'),
    }),
    [],
  );

  React.useEffect(() => {
    fetchWidget();
    // eslint-disable-next-line
  }, [widgetFilters])

  React.useEffect(() => {
    if (!initalFetch.current && widget) {
      const lastResult = widget.data[widget.data.length - 1] || {};
      if (lastResult.summaryDate) {
        selectDate(lastResult.summaryDate);
        initalFetch.current = true;
      }
    }
    // eslint-disable-next-line
  }, [initalFetch, widget, selectDate])

  React.useEffect(() => {
    return () => {
      clearWidget();
      clearWidgetFilters();
    };
    // eslint-disable-next-line
  }, [])

  return <Switch>{children}</Switch>;
}
