import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomRadio from 'common/CustomRadio';
import { RegularMediumText } from 'common/texts';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function SwitchGroup({ className = '', metaPath, answerField }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const answer = useQuiz(
    (current) => get(current, `content.answers.${answerField}.value`, ''),
    [answerField],
  );

  const choices = useQuiz((current) => get(current, `${metaPath}.choices`, []), [metaPath]);

  const parsedOptions = React.useMemo(() => {
    return choices.map(({ value, text }, i) => {
      return (
        <CustomRadio
          key={value}
          className={i ? 'custom-input g-ml-20' : 'custom-input'}
          label={text}
          checked={answer === value}
        />
      );
    });
  }, [choices, answer]);

  return (
    <div className={`${className} g-mt-20`}>
      <RegularMediumText>{title}</RegularMediumText>

      <StyledWrapper className="g-mt-10 g-d-flex">{parsedOptions}</StyledWrapper>
    </div>
  );
});

const StyledWrapper = styled.div`
  @media screen and (max-width: 480px) {
    flex-direction: column;
    .custom-input {
      margin-left: 0;
    }
  }
`;
