import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default React.memo(function CustomLink({ value, href }) {
  if (!value || !href) {
    return null;
  }
  return <StyledLink to={href}>{value}</StyledLink>;
});

const StyledLink = styled(Link)`
  font-size: 1.4rem;
`;
