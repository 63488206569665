import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as FinishIcon } from 'assets/video/finish-icon.svg';
import { APPOINTMENTS, APPOINTMENTS_ADMIN, MONTH } from 'constants/routes';
import { useHandleDisconnect } from 'hooks/useAppointments';

import { IconWrapper } from './style';
import { useUserContext } from '../../context/userContext';

export default React.memo(function CancelHandler() {
  const history = useHistory();
  const { isAdmin } = useUserContext();
  const handleDisconnect = useHandleDisconnect();

  const finishHandler = React.useCallback(() => {
    handleDisconnect();
    history.push(isAdmin ? `/${APPOINTMENTS_ADMIN}` : `/${APPOINTMENTS}/${MONTH}`);
  }, [history, handleDisconnect]);

  return (
    <IconWrapper onClick={finishHandler}>
      <FinishIcon />
    </IconWrapper>
  );
});
