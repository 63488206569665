import React from 'react';

import CustomCheckbox from 'common/CustomCheckbox';
import { UppercaseText } from 'common/texts';

type QualificationsListProps = {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  qualifications: any[];
  qualificationIds: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setQualificationIds: (a: any) => any;
};

export default function QualificationsList({
  isLoading,
  qualifications,
  qualificationIds,
  setQualificationIds,
}: QualificationsListProps) {
  const parsedQualificationsList = React.useMemo(() => {
    if (!qualifications) {
      return null;
    }
    return qualifications.map(({ value, label }) => {
      return (
        <CustomCheckbox
          className="checkbox"
          key={value}
          label={label}
          checked={qualificationIds.includes(+value)}
          changeHandler={() => setQualificationIds(+value)}
        />
      );
    });
  }, [qualifications, qualificationIds, setQualificationIds]);

  return (
    <div className="g-mt-25">
      <UppercaseText className="g-mb-10">Your qualification</UppercaseText>

      {!isLoading && parsedQualificationsList}
    </div>
  );
}
