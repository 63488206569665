import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

import LinkButton from 'common/LinkButton';
import { RegularText, MediumText, UppercaseText } from 'common/texts';
import { PATIENTS, DETAILS, OVERVIEW, APPOINTMENTS, APPOINTMENT_INFO } from 'constants/routes';

import { StyledLine } from './styles';

export default React.memo(function PatientInfo({ appointment }) {
  const history = useHistory();

  const appointmentId = get(appointment, 'id');
  const patientId = get(appointment, 'patient.id');
  const firstName = get(appointment, 'patient.first_name', '');
  const lastName = get(appointment, 'patient.last_name', '');
  const age = moment().diff(get(appointment, 'patient.birth_date', ''), 'years');
  const gender = get(appointment, 'patient.gender', '');

  const navigateToPatient = React.useCallback(() => {
    history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${patientId}/${OVERVIEW}`,
      state: { referrer: `/${APPOINTMENTS}/${APPOINTMENT_INFO}/${appointmentId}` },
    });
  }, [history, patientId, appointmentId]);

  return (
    <StyledLine>
      <div>
        <RegularText>Patient Info</RegularText>
        <MediumText onClick={navigateToPatient}>
          {firstName} {lastName}
        </MediumText>
        <div className="g-d-flex">
          <UppercaseText>Age: {age}</UppercaseText>
          <UppercaseText className="g-ml-15">Gender: {gender}</UppercaseText>
        </div>
      </div>

      <LinkButton onClick={navigateToPatient} className="button">
        View info
      </LinkButton>
    </StyledLine>
  );
});
