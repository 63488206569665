import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import MedicationsListLoader from 'components/patients/medications/medications-list/MedicationsListLoader';
import NewMedicationsListButton from 'components/patients/medications/medications-list/NewMedicationsListButton';
import { MEDICATIONS } from 'constants/routes';

export default function PatientMedicationsList() {
  return (
    <DetailsTemplate currentTab={MEDICATIONS}>
      <MedicationsListLoader />

      <NewMedicationsListButton />
    </DetailsTemplate>
  );
}
