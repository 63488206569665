import get from 'lodash/get';
import React from 'react';

import { useQuiz, useQuizStep } from 'hooks/patient/useQuestionnaires';

import ProgressBar from '../../common/ProgressBar';

export default React.memo(function Progress() {
  const withProgress = useQuiz((current) => get(current, 'content.withProgress', ''), []);
  const step = useQuizStep();
  const stepsLength = useQuiz((current) => get(current, 'content.pages.length', 1), []);

  const currentProgress = React.useMemo(() => {
    return Math.round((step * 100) / stepsLength);
  }, [stepsLength, step]);

  if (!withProgress) {
    return null;
  }

  return (
    <div>
      <ProgressBar progress={currentProgress} />
      Progress
    </div>
  );
});
