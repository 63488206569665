import React, { FC, ReactNode, useContext } from 'react';

import { useMe } from './useMe';
import type { UseMeValueType } from './useMe';

const UserContext = React.createContext<UseMeValueType>({
  isInited: false,
  session: null,
  role: 'provider',
  isAdmin: false,
  can: () => false,
});

export const useUserContext = () => useContext<UseMeValueType>(UserContext);

const UserContextProviderComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useMe();

  return <UserContext.Provider value={value as UseMeValueType}>{children}</UserContext.Provider>;
};

export const UserContextProvider = React.memo(
  UserContextProviderComponent,
) as typeof UserContextProviderComponent;
