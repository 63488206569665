import get from 'lodash/get';
import React from 'react';

import createNotification from 'utils/createNotification';

export default React.memo(function RequestCameraPermission({
  currentStream,
  setDeviceList,
  setSelectedCamera,
}) {
  React.useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true }) // Request permition for camera
      .then((stream) => {
        currentStream.current = stream; // save current stream for stoping on willUnmount
        return navigator.mediaDevices.enumerateDevices();
      }) // Get devices list
      .then((devices) => {
        const list = devices.filter(({ kind }) => kind === 'videoinput'); // pick only camera
        setDeviceList(list);
        const selectedDevice = localStorage.getItem('cameraId'); // selected for using device
        const isDeviceExist = devices.find(({ deviceId }) => deviceId === selectedDevice); // check connection to picked device
        setSelectedCamera(isDeviceExist ? selectedDevice : get(list, '[0].deviceId', '')); // use picked device or take first from list
      })
      .catch(() => {
        createNotification({
          title: 'Your camera and microphone are not connected.',
          message: 'Please allow access',
          type: 'error',
        });
      });
    // eslint-disable-next-line
  }, [])
  return null;
});
