import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import DoctorNotes from 'components/patients/doctor-notes/DoctorNotes';
import NewNoteButton from 'components/patients/doctor-notes/NewNoteButton';
import { DOCTOR_NOTES } from 'constants/routes';
import { useFetchPatinetDoctorNotes } from 'requests/patients';

export default function PatientDoctorNotes() {
  const fetchPatinetDoctorNotes = useFetchPatinetDoctorNotes();

  React.useEffect(() => {
    fetchPatinetDoctorNotes();
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <DoctorNotes />

      <NewNoteButton />
    </DetailsTemplate>
  );
}
