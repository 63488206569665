import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import DateSeparator from 'common/DateSeparator';
import DataLine from 'components/dashboard/DataLine';
import { useLogs } from 'hooks/useLogs';

const today = moment().format('MM.DD.YYYY');

export default React.memo(function LogsList() {
  const logs = useLogs();

  const parsedLogs = React.useMemo(() => {
    const logsWithDayBreack = [];
    let lastDate = null;
    logs.forEach((log) => {
      const timestamp = get(log, 'created_at');
      const parsedTimestamp = moment.utc(timestamp).local().format('MM.DD.YYYY');
      if (lastDate !== parsedTimestamp) {
        lastDate = parsedTimestamp;
        const data = {
          type: 'timeSeparator',
          body: parsedTimestamp === today ? 'TODAY' : parsedTimestamp,
        };
        logsWithDayBreack.push(data);
      }
      logsWithDayBreack.push(log);
    });
    return logsWithDayBreack.map((appointment) => {
      const type = get(appointment, 'type');
      if (type === 'timeSeparator') {
        const body = get(appointment, 'body');
        return <StyledDateSeparator key={body} value={body} />;
      }
      const id = get(appointment, 'id');
      const createdAt = get(appointment, 'created_at');
      const text = get(appointment, 'text', '');
      const payload = get(appointment, 'payload', '');
      return <DataLine key={id} id={id} createdAt={createdAt} text={text} payload={payload} />;
    });
  }, [logs]);

  return <StyledWrapper>{parsedLogs}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  overflow-y: auto;
  max-height: 40rem;
  margin-bottom: 2rem;
`;

const StyledDateSeparator = styled(DateSeparator)`
  margin-bottom: 2.5rem;
`;
