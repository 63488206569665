import React from 'react';
import { useParams } from 'react-router-dom';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import PromtListener from 'components/patients/questionnaires/quiz/PromtListener';
import QuizBody from 'components/patients/questionnaires/quiz/quiz-body';
import QuizFooter from 'components/patients/questionnaires/quiz/quiz-footer';
import QuizHeader from 'components/patients/questionnaires/quiz/quiz-header';
import SaveListener from 'components/patients/questionnaires/quiz/SaveListener';
import { useQuiz, useSetQuiz, useSetQuizStep } from 'hooks/patient/useQuestionnaires';
import { useFetchQuiz } from 'requests/patients/questionnaires';

export default function PatientQuizEdit() {
  const { quizId } = useParams();
  const fetchQuiz = useFetchQuiz();
  const isQuizLoaded = useQuiz((current) => !!current);
  const clearQuiz = useSetQuiz(() => null, []);
  const clearQuizStep = useSetQuizStep(() => 0, []);

  React.useEffect(() => {
    fetchQuiz(quizId);
    return clearQuiz;
    // eslint-disable-next-line
  }, [quizId])

  React.useEffect(() => {
    return clearQuizStep;
    // eslint-disable-next-line
  }, [])

  if (!isQuizLoaded) {
    return null;
  }

  return (
    <RowPlate>
      <SaveListener />
      <PromtListener />
      <QuizHeader />

      <PlateContent>
        <QuizBody />
        <QuizFooter />
      </PlateContent>
    </RowPlate>
  );
}
