import React from 'react';

import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import CategorySelect from 'components/patients/medications/common/CategorySelect';
import Medications from 'components/patients/medications/common/medications';
import Header from 'components/patients/medications/edit-medications/Header';
import MedicationPreview from 'components/patients/medications/edit-medications/MedicationPreview';
import UpdateButton from 'components/patients/medications/edit-medications/UpdateButton';
import { useSetPatientMedications } from 'hooks/usePatients';
import { useFetchMedicationsMeasures, useFetchMedicationsFrequencies } from 'requests/options';
import { useFetchPatientMedications } from 'requests/patients/medication';

export default function PatientMedicationsEdit() {
  const fetchMedicationsMeasures = useFetchMedicationsMeasures();
  const fetchMedicationsFrequencies = useFetchMedicationsFrequencies();
  const fetchPatientMedications = useFetchPatientMedications();
  const clearPatientMedications = useSetPatientMedications(() => null);

  React.useEffect(() => {
    fetchMedicationsMeasures();
    fetchMedicationsFrequencies();
    fetchPatientMedications();
    return clearPatientMedications;
    // eslint-disable-next-line
  }, [])

  return (
    <RowPlate>
      <Header />
      <PlateContent>
        <CategorySelect />

        <Medications MedicationPreview={MedicationPreview} />

        <UpdateButton />
      </PlateContent>
    </RowPlate>
  );
}
