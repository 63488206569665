import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import BackButton from 'common/plate/BackButton';
import { PlateHeader } from 'common/plate/styles';
import { UppercaseText, RegularText } from 'common/texts';
import { COMPANIES, INVITE } from 'constants/routes';
import { useCompany } from 'hooks/useCompanies';

import { useUserContext } from '../../../context/userContext';
import { permissions } from '../../../permissions';

export default function CompanyPlansHeader() {
  const { can } = useUserContext();
  const name = useCompany((current) => get(current, 'name', ''), []);
  const count = useCompany((current) => get(current, 'patients_count', 0), []);

  const history = useHistory();
  const { companyId } = useParams();

  const navigateToInvite = React.useCallback(() => {
    history.push(`/${COMPANIES}/${companyId}/${INVITE}`);
  }, [history]);

  return (
    <StyledPlateHeader>
      <BackButton href={`/${COMPANIES}`} />
      <StyledSpan className="company-name">{name}</StyledSpan>
      <div className="counter">
        <UppercaseText className="title">patients</UppercaseText>
        <RegularText>{`${count}`}</RegularText>
      </div>
      <div>
        {can(permissions.companies.canAddNewCompany) && (
          <StyledCustomButton clickHandler={navigateToInvite}>Add patient</StyledCustomButton>
        )}
      </div>
    </StyledPlateHeader>
  );
}

const StyledSpan = styled.span`
  margin-right: auto;
`;

const StyledCustomButton = styled(CustomButton)`
  padding: 1rem 2.5rem;
  margin-left: 2rem;
`;
const StyledPlateHeader = styled(PlateHeader)`
  padding: 1.8rem 2.5rem;
  .coupon {
    max-width: 40%;
    font-weight: 400;
    margin-left: auto;
    padding-left: 0.5rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .counter {
    font-weight: 400;
    margin-left: 4rem;
    .title {
      margin-bottom: 0.6rem;
    }
    p {
      line-height: 1;
    }
  }
`;
