import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import ChartLoader from 'components/patients/medications/medications-progress/ChartLoader';
import Header from 'components/patients/medications/medications-progress/Header';
import MedicationSelect from 'components/patients/medications/medications-progress/MedicationSelect';
import MedicationsLoader from 'components/patients/medications/medications-progress/MedicationsLoader';
import PeriodSelector from 'components/patients/medications/medications-progress/PeriodSelector';

export default function PatientMedicationsProgress() {
  return (
    <WideRowPlate>
      <Header />
      <PlateContent>
        <PeriodSelector />
        <ChartLoader />
        <MedicationSelect />
        <MedicationsLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
