import React from 'react';

import Spinner from 'common/loaders/Spinner';

import { Cognito } from '../../libs/cognito';

export default function Logout() {
  const cognito = Cognito.getInstance();
  React.useEffect(() => {
    cognito.signOut();
    window.location = '/login';
  }, []);

  return <Spinner className="loader-container" />;
}
