import React from 'react';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { RegularMediumText, RegularText } from 'common/texts';

export default React.memo(function AddSecurity({ submitHandler }) {
  return (
    <div>
      <RegularMediumText className="g-mt-25">Two-step authentication</RegularMediumText>
      <RegularText>Keep your account extra secure with a two-factor authentication.</RegularText>

      <StyledLinkButton
        className="g-mt-15"
        onClick={React.useCallback(() => submitHandler(true), [submitHandler])}
      >
        Add authentication
      </StyledLinkButton>
    </div>
  );
});

const StyledLinkButton = styled(LinkButton)`
  align-self: flex-start;
  padding: 0.6rem 3.5rem;
`;
