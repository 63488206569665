import React from 'react';
import styled from 'styled-components';

export default function WeightListItem({ label = '', value = '', bgColor }) {
  return (
    <StyledWrapper className="g-d-flex g-justify-between g-w-100 g-mt-15" bgColor={bgColor}>
      <p className="label g-d-flex g-align-center text">{label}</p>
      <p className="text">{value}</p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  padding-bottom: 15px;
  border-bottom: 0.1rem solid #e9ebef;
  .text {
    font-size: 1.6rem;
  }
  .label {
    color: rgba(34, 50, 50, 0.5);
    &::before {
      content: '';
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 2rem;
      border-radius: 50%;
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
      background: ${({ bgColor }) => bgColor};
    }
  }
`;
