import classNames from 'clsx';
import React, { FC } from 'react';

import { BasicProps, Switch } from '../../common/Switch';

export const tabsConfig = [
  { label: 'Program Materials', value: 'Program Materials' },
  // { label: 'Communication', value: 'Communication' },
];

export const Tabs: FC<BasicProps> = ({ items, activeItem, onChange }) => {
  return (
    <Switch
      items={items}
      activeItem={activeItem}
      className="flex"
      onChange={onChange}
      element={({ option, isActive, index }) => (
        <div
          className={classNames('cursor-pointer', {
            'ml-7.5': index !== 0,
          })}
        >
          <div
            className={classNames(
              'text-1622 transition-all duration-150  mb-1 font-ambit font-semibold',
              {
                'text-main': isActive,
                'text-manatee': !isActive,
              },
            )}
          >
            {option.label}
          </div>
          <div
            className={classNames(
              'rounded-main transition-all duration-150  w-full h-0.5 bg-main opacity-0',
              {
                'opacity-100': isActive,
              },
            )}
          ></div>
        </div>
      )}
    />
  );
};
