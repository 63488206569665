import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { StyledButton } from './styles';

export default React.memo(function ConsentFormCompleted({ message }) {
  const href = get(message, 'state.attributes.href');
  const name = get(message, 'state.attributes.name');
  return (
    <StyledWrapper>
      <p>
        Welcome on board once again, {name}! At Ciba Health, we care about your well-being and life
        quality, and it&#39;s important for our team to know where you started with us. Please fill
        up the PROMIS-10 to start tracking your progress during the program.
      </p>
      <StyledLink
        className="button g-mt-10 g-mb-5"
        as="a"
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        Go to form
      </StyledLink>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .button {
    padding-right: 1rem;
    padding-left: 1rem;
  }
`;

const StyledLink = styled(StyledButton)`
  text-decoration: none;
`;
