import React from 'react';
import { useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader } from 'common/plate/styles';
import { MediumText } from 'common/texts';
import DaySelector from 'common/widgets/day-selector';
import ExportData from 'common/widgets/ExportData';
import { EqualColumns } from 'components/patients/widgets/synchronized-widget/oura/common/styles/styledColumns';
import ReadinessData from 'components/patients/widgets/synchronized-widget/oura/readiness/readiness-contributors/ReadinessData';
import ReadinessList from 'components/patients/widgets/synchronized-widget/oura/readiness/readiness-contributors/ReadinessList';
import { PATIENTS, DETAILS, WIDGETS } from 'constants/routes';
import { useDayData, useDaysData } from 'hooks/patient/widgets/useWidgets';

export default function PatientReadinessConrtubutors() {
  const { patientId } = useParams();
  const daysData = useDaysData('score');
  const dayData = useDayData();

  const label = React.useMemo(() => {
    const score = dayData.score;
    if (!score) {
      return null;
    }
    return score;
  }, [dayData]);

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}`} />
        Readiness
        <ExportData title="Readiness score" field="score" />
      </PlateHeader>
      <DaySelector data={daysData} label={label} color="#448dc2" />
      <EqualColumns className="g-d-flex g-fw-w g-w-100">
        <div className="col col-left g-w-50">
          <MediumText className="g-mb-15">Readiness conrtubutors</MediumText>
          <ReadinessList data={dayData} />
        </div>
        <div className="col col-right g-w-50">
          <ReadinessData data={dayData} />
        </div>
      </EqualColumns>
    </RowPlate>
  );
}
