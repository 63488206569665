import React, { JSX } from 'react';

import { IconSvgProps } from 'components/new/Icon/Icon.types';

export const ArrowForward = ({
  height = 20,
  width = 20,
  strokeWidth = 2,
  className,
}: IconSvgProps): JSX.Element => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrows / Forward">
        <path
          id="Vector"
          d="M8 6L12 10L8 14"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
