import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import LinkButton from 'common/LinkButton';
import BackButton from 'common/plate/BackButton';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import PrescriptionContent from 'components/patients/prescriptions/prescription-create/PrescriptionContent';
import { DASHBOARD } from 'constants/routes';

export default function PatientCreatePrescription() {
  return (
    <WideRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Prescription
      </PlateHeader>
      <PlateContent>
        <PrescriptionContent />
        <StyledBtnsWrapper className="g-d-flex">
          <CustomButton className="button">Save</CustomButton>
          <LinkButton className="button g-ml-30">Preview</LinkButton>
        </StyledBtnsWrapper>
      </PlateContent>
    </WideRowPlate>
  );
}

const StyledBtnsWrapper = styled.div`
  margin-top: 10rem;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
  .button {
    min-width: 13rem;
    &:nth-child(n + 2) {
      @media screen and (max-width: 1024px) {
        margin-left: 0.5rem;
      }
    }
  }
`;
