import classNames from 'clsx';
import React, { FC } from 'react';

import { Switch, BasicProps } from '../../common/Switch';

export const CategoriesPills: FC<BasicProps & { loading?: boolean }> = ({
  items,
  activeItem,
  onChange,
  loading,
}) => {
  if (loading) {
    return (
      <div className="flex">
        <div className="animate-pulse cursor-pointer w-14 bg-catskillWhite px-2 text-main font-ambit text-1220 py-1  rounded-[.4rem]"></div>
        <div className="animate-pulse cursor-pointer w-16 bg-catskillWhite px-2 text-main font-ambit text-1220 py-1  rounded-[.4rem] ml-4"></div>
        <div className="animate-pulse cursor-pointer w-14 bg-catskillWhite px-2 text-main font-ambit text-1220 py-1  rounded-[.4rem] ml-4"></div>
        <div className="animate-pulse cursor-pointer w-18 bg-catskillWhite px-2 text-main font-ambit text-1220 py-1  rounded-[.4rem] ml-4"></div>
      </div>
    );
  }
  return (
    <Switch
      className="flex"
      items={items}
      activeItem={activeItem}
      onChange={onChange}
      element={({ option, isActive, index }) => (
        <div
          className={classNames(
            'cursor-pointer bg-catskillWhite px-2 text-main font-ambit text-1220 py-1 w-fit rounded-[.4rem]',
            {
              'bg-manatee text-white': isActive,
              'ml-4': index !== 0,
            },
          )}
        >
          {option.label}
        </div>
      )}
    />
  );
};
