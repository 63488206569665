import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { TIME_SEPARATOR, OWN_MESSAGE, OPPONENT_MESSAGE, SYSTEM_MESSAGE } from 'constants/messages';
import { useFetchChatUsersData } from 'requests/messages';

import DateSeparator from './messages/DateSeparator';
import OpponentMessage from './messages/OpponentMessage';
import OwnMessage from './messages/OwnMessage';
import SystemMessage from './messages/system-message';

function getMessageType(message, userIdentity) {
  const type = get(message, 'state.attributes.type');
  if (type) {
    return SYSTEM_MESSAGE;
  }
  const isOwner = get(message, 'author') === userIdentity;
  if (isOwner) {
    return OWN_MESSAGE;
  }
  return OPPONENT_MESSAGE;
}

const findMaxIndexWithDifferentAuthor = (array, targetAuthor) =>
  array.reduce(
    (maxIndex, { author, index }) =>
      author !== targetAuthor && index > maxIndex ? index : maxIndex,
    -1,
  );

const messagesConfig = {
  [TIME_SEPARATOR]: DateSeparator,
  [OWN_MESSAGE]: OwnMessage,
  [OPPONENT_MESSAGE]: OpponentMessage,
  [SYSTEM_MESSAGE]: SystemMessage,
};

export default React.memo(function ChatBody({
  userIdentity,
  messages,
  channel = [],
  lastConsumedMessageIndex,
  deliveredMessage,
}) {
  const fetchChatUsersData = useFetchChatUsersData();
  const wrapperRef = React.useRef();

  React.useEffect(() => {
    const wrapperElement = wrapperRef.current;
    wrapperElement.scrollTop = wrapperElement.scrollHeight;
  }, [messages]);

  React.useEffect(() => {
    if (messages && messages.length > 1) {
      const lastMessage = messages.slice(-1)[0];
      if (userIdentity !== lastMessage.author) {
        channel.channelClass.updateLastConsumedMessageIndex(lastMessage.index);
      }
    }
    if (messages) {
      const authors = messages
        .filter((message) => getMessageType(message, userIdentity) === OPPONENT_MESSAGE)
        .map((message) => message.author)
        .filter((author) => author.length === 32);
      const unicAuthors = [...new Set(authors)];
      const members = unicAuthors.map((author) => ({ type: null, chat_identity: author }));
      const channel = {};
      set(channel, 'state.attributes.members', members);
      fetchChatUsersData([channel]);
    }
  }, [messages, userIdentity, lastConsumedMessageIndex]);

  const parsedMessages = React.useMemo(() => {
    const messagesWithDayBreack = [];
    let lastDate = null;

    const lastOpponentMessage = findMaxIndexWithDifferentAuthor(messages, userIdentity);

    messages.forEach((message) => {
      const sid = get(message, 'sid');
      const timestamp = get(message, 'timestamp');
      const parsedTimestamp = moment.utc(timestamp).local().format('MM-DD-YYYY');
      if (lastDate !== parsedTimestamp) {
        lastDate = parsedTimestamp;
        messagesWithDayBreack.push({
          id: parsedTimestamp,
          type: TIME_SEPARATOR,
          parsedTimestamp,
        });
      }
      messagesWithDayBreack.push({
        id: sid,
        type: getMessageType(message, userIdentity),
        message,
      });
    });
    return messagesWithDayBreack.map(({ id, type, parsedTimestamp, message }) => {
      const Component = messagesConfig[type];
      if (!Component) {
        console.warn(`Message with type: ${type}, not implemented`);
        return null;
      }
      return (
        <Component
          key={id}
          parsedTimestamp={parsedTimestamp}
          message={message}
          deliveredMessage={deliveredMessage}
          lastConsumedMessageIndex={lastConsumedMessageIndex}
          lastOpponentMessage={lastOpponentMessage}
        />
      );
    });
  }, [messages, userIdentity, lastConsumedMessageIndex]);

  return <StyledWrapper ref={wrapperRef}>{parsedMessages}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  padding: 1.7rem;
  overflow-y: auto;
  max-height: 47rem;
`;
