import get from 'lodash/get';
import React from 'react';

import { RegularMediumText, RegularText } from 'common/texts';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function Expression({ className = '', metaPath }) {
  const title = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const description = useQuiz((current) => get(current, `${metaPath}.description`, ''), [metaPath]);
  return (
    <>
      <RegularMediumText className={`${className} g-mt-20`}>{title}</RegularMediumText>
      <RegularText>{description}</RegularText>
    </>
  );
});
