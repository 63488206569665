import React from 'react';
import styled from 'styled-components';

import { BoldBigText } from 'common/texts';
import { useAppointmentsProviders } from 'hooks/useAppointments';
import { useFetchAppointmentsProviders } from 'requests/appointments';

import ProvidersList from './ProvidersList';

export default React.memo(function ProviderLoader() {
  const fetchAppointmentsProviders = useFetchAppointmentsProviders();
  const providers = useAppointmentsProviders();

  React.useEffect(() => {
    fetchAppointmentsProviders();
    // eslint-disable-next-line
  }, [])

  const content = React.useMemo(() => {
    if (!providers) {
      return null;
    }
    if (providers.length === 0) {
      return null;
    }
    return <ProvidersList />;
  }, [providers]);

  return (
    <StyledWrapper>
      <BoldBigText className="title">Change a provider</BoldBigText>

      {content}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .title {
    padding: 2rem 3rem;
    border-bottom: solid 1px #e9ebef;
  }
`;
