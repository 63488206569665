export default function normalizeParams(options = {}) {
  const params = {};
  Object.keys(options).forEach((key) => {
    const value = options[key];
    if (value) {
      params[key] = value;
    }
  });
  return params;
}
