import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';

export default React.memo(function SearchItem({ user, setValue, onSelect }) {
  const avatar = get(user, 'avatar', '');
  const firstName = get(user, 'first_name', '');
  const lastName = get(user, 'last_name', '');
  const fullName = get(user, 'full_name', '');

  const selectUser = React.useCallback(() => {
    onSelect && onSelect(user);
    setValue(fullName);
  }, [onSelect, user, setValue, fullName]);

  return (
    <StyledWrapper onClick={selectUser}>
      <UserAvatar className="avatar" avatar={avatar} firstName={firstName} lastName={lastName} />
      <RegularText className="name">{fullName}</RegularText>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  :hover {
    background: #fbfbfb;
  }
  .avatar {
    width: 3.2rem;
    height: 3.2rem;
  }
  .name {
    margin-left: 1rem;
  }
`;
