import React from 'react';
import styled from 'styled-components';

export default React.memo(function Spinner({ className = '' }) {
  return (
    <StyledWrapper className={className}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
