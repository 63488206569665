import get from 'lodash/get';
import React from 'react';

import { UppercaseText, RegularText } from 'common/texts';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

import Scale from '../../../common/Scale';

const textConfig = {
  0: 'Minimal',
  20: 'Mild',
  40: 'Moderate',
  60: 'Moderately Severe',
  80: 'Severe',
};

export default React.memo(function ScaleResults() {
  const name = useQuiz((current) => get(current, 'name', ''));
  const answers = useQuiz((current) => get(current, 'content.answers', {}));
  const labels = useQuiz((current) => get(current, 'content.withResults.lables', []));
  const scaleCount = useQuiz((current) => get(current, 'content.withResults.scaleCount', []));
  const count = Object.values(answers)
    .map(({ value }) => value)
    .reduce(function (a, b) {
      return +a + +b;
    }, 0);
  // Calculate the total count of answers
  const answersCount = Object.keys(answers).length;

  const textResult = React.useMemo(() => {
    const relativeResult = ((count - answersCount) / (scaleCount - answersCount)) * 100;
    let text = 'Minimal';
    Object.keys(textConfig).forEach((key) => {
      if (relativeResult > key) {
        text = textConfig[key];
      }
    });
    return text;
  }, [scaleCount, count]);

  return (
    <>
      <UppercaseText className="g-mt-20 g-mb-10">{name}</UppercaseText>
      {/*  Search all questinaries with name PHQ-8 and set up scale count */}
      {name.includes('PHQ-8') ? (
        <Scale labels={labels} scaleCount="24" value={count - answersCount} />
      ) : (
        <Scale
          labels={labels}
          scaleCount={scaleCount - answersCount}
          value={count - answersCount}
        />
      )}
      <RegularText className="g-mt-70">
        Your result is {textResult}. We recommend that you follow up with one of our psychologists
        or psychiatrists for an evaluation and to discuss what your results mean. We care about your
        mental health - and are here to give you the support you need.
      </RegularText>
    </>
  );
});
