import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';
import { typesConfig, periodTabsConfig, dimensionConfig } from 'constants/withings-tabs';
import { useDaysData } from 'hooks/patient/widgets/useWidgets';

import Bars from './bars';
import DatesList from './dates-list';
import LabelsList from './labels-list';

function Label({ day }) {
  const from = day.startOf('isoWeek').format('D.MM');
  const to = day.endOf('isoWeek').format('D.MM');
  return (
    <div>
      <div>{from}</div>
      <div>{to}</div>
    </div>
  );
}

export default function QuarterView({ currentTab, period }) {
  const type = typesConfig[currentTab];
  const { start, end } = periodTabsConfig.find((item) => item.id === period);
  const daysData = useDaysData(type);

  const firstWeekNum = moment(start).isoWeek();
  const lastWeekNum = moment(end).isoWeek();
  const weeksInQuarter = React.useMemo(() => {
    if (firstWeekNum === 1) {
      return lastWeekNum;
    }
    if (lastWeekNum === 1) {
      return moment(end).subtract(1, 'weeks').isoWeek() - firstWeekNum + 2;
    }
    if (firstWeekNum > lastWeekNum) {
      return lastWeekNum + 1;
    }
    return lastWeekNum - firstWeekNum + 1;
  }, [firstWeekNum, lastWeekNum, end]);

  const dates = React.useMemo(() => {
    return new Array(weeksInQuarter).fill().map((item, i) => {
      const day = moment(start).add(i, 'week');
      const daysValue = new Array(7)
        .fill()
        .map((item, i) => {
          const current = day.clone().add(i, 'days').format('YYYY-MM-DD');
          const { value = 0 } = daysData.find(({ date }) => date === current) || {};
          return value;
        })
        .filter((item) => item);
      const value = daysValue.reduce((a, val) => +a + +val, 0) / daysValue.length || 0;
      return {
        label: <Label day={day} />,
        value: value ? value.toFixed(1) : 0,
      };
    });
  }, [start, daysData, weeksInQuarter]);

  return (
    <StyledWrapper>
      <RegularText>
        {moment(start).isoWeekday(1).format('dddd, D MMMM YYYY')} -{' '}
        {moment(end).isoWeekday(7).format('dddd, D MMMM YYYY')}{' '}
        <b>(in {dimensionConfig[currentTab]})</b>
      </RegularText>
      <LabelsList dates={dates} />
      <Bars currentTab={currentTab} dates={dates} />
      <DatesList dates={dates} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div``;
