import React from 'react';

import DetailsTemplate from 'components/patients/DetailsTemplate';
import OverviewLoader from 'components/patients/overview/OverviewLoader';
import { OVERVIEW } from 'constants/routes';
import { useSetPatientOverview } from 'hooks/usePatients';
import { useFetchPatientOverview } from 'requests/patients';

export default function PatientOverview() {
  const fetchPatientOverview = useFetchPatientOverview();
  const clearPatientOverview = useSetPatientOverview(() => null, []);

  React.useEffect(() => {
    fetchPatientOverview();
    return clearPatientOverview;
  });

  return (
    <DetailsTemplate currentTab={OVERVIEW}>
      <OverviewLoader />
    </DetailsTemplate>
  );
}
