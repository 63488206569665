import React from 'react';
import styled from 'styled-components';

import ConfirmationModal from 'common/ConfirmationModal';
import { useModalHandler } from 'hooks/useUtils';
import { useDeleteChannel } from 'requests/messages';

export default React.memo(function DeleteGroupChat() {
  const deleteChannel = useDeleteChannel();
  const [isOpen, openModal, closeModal] = useModalHandler();

  const deleteHandler = React.useCallback(async () => {
    const isSuccess = await deleteChannel();
    if (isSuccess) {
      closeModal();
    }
  }, [deleteChannel, closeModal]);

  return (
    <StyledWrapper className="g-mt-10">
      <div className="remove-label" onClick={openModal}>
        Delete group chat
      </div>
      {isOpen && <ConfirmationModal closeModal={closeModal} confirmHandler={deleteHandler} />}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .remove-label {
    font-size: 1rem;
    color: #f03e3d;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
