import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TreatmentPlanListItem, TreatmentPlanListItemSkeleton } from './TreatmentPlanListItem';
import { TreatmentPlanType } from '../../../requests/graphql/my-health/queries/getTreatmentPlan';

type TreatmentPlanListProps = {
  treatmentPlans: TreatmentPlanType[];
  hasMore: boolean;
  loadingMore: boolean;
  itemsPerPage: number;
  loading: boolean;
  loadMore: () => void;
  onView: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
};

export const TreatmentPlanList: FC<TreatmentPlanListProps> = ({
  treatmentPlans,
  onEdit,
  onView,
  itemsPerPage,
  loading,
  hasMore,
  loadMore,
  onDelete,
  onDuplicate,
}) => {
  return (
    <div>
      {loading &&
        new Array(itemsPerPage)
          .fill(0)
          .map((_, index) => <TreatmentPlanListItemSkeleton key={index + 'skeleton'} />)}

      <InfiniteScroll
        hasMore={!!hasMore}
        dataLength={treatmentPlans.length}
        className="flex flex-col"
        scrollableTarget="scrollable-treatment-plans"
        height={185 * itemsPerPage}
        loader={Array(itemsPerPage)
          .fill(0)
          .map((_, index) => (
            <TreatmentPlanListItemSkeleton key={index + 'loader-paginator'} />
          ))}
        next={loadMore}
      >
        {treatmentPlans.map((el) => (
          <TreatmentPlanListItem
            onView={onView}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            key={el.id}
            plan={el}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
