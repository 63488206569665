import classNames from 'clsx';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FullPlate } from 'common/plate/plates';

import { CKEditor } from '../../common/CKEditor';
import RouterPrompt from '../../common/RouterPrompt';
import { InputField } from '../../components/ui2.0/InputField';
import { SelectField, Option } from '../../components/ui2.0/SelectField';
import { Toggle } from '../../components/ui2.0/Toggle';
import { LibraryMaterialStatuses } from '../../zeus-graphql/patient/zeus';

export type MaterialData = {
  isNew?: boolean;
  title: string;
  description: string;
  type: string;
  categoryId: string;
  content: string;
  status: LibraryMaterialStatuses;
};

export interface MaterialDataInput extends MaterialData {
  isPublish: boolean;
}

type LibraryMaterialEditProps = {
  onCancel: () => void;
  onSave: (data: MaterialData) => void;
  libraryMaterialTypesOptions: Option[];
  libraryMaterialCategoriesOptions: Option[];
  data?: MaterialData;
};

export const LibraryMaterialEdit: FC<LibraryMaterialEditProps> = ({
  onSave,
  onCancel,
  data,
  libraryMaterialTypesOptions,
  libraryMaterialCategoriesOptions,
}) => {
  const {
    trigger,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<MaterialDataInput>();

  const isDirty = useMemo(() => {
    const formData = getValues();
    const isEQ = isEqual(
      {
        title: data?.title,
        description: data?.description,
        type: data?.type,
        categoryId: data?.categoryId,
        content: data?.content,
        isPublish: data?.status === LibraryMaterialStatuses.published,
        status: data?.status,
      },
      formData,
    );

    if (!data) {
      return Boolean(formData.title || formData.description || formData.content);
    } else {
      return !isEQ;
    }
  }, [data, getValues()]);

  const [isPreview, setIsPreview] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  const saveMaterial = async (data: MaterialDataInput) => {
    const { title, categoryId, content, description, isPublish, type } = data;
    setIsSaved(true);
    onSave({
      title,
      description,
      type,
      categoryId,
      content,
      status: isPublish ? LibraryMaterialStatuses.published : LibraryMaterialStatuses.draft,
    });
  };

  useEffect(() => {
    if (data) {
      setValue('title', data.title);
      setValue('description', data.description);
      setValue('type', data.type);
      setValue('categoryId', data.categoryId);
      setValue('content', data.content);
      setValue('status', data.status);
      setValue('isPublish', data.status === LibraryMaterialStatuses.published);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (!data && libraryMaterialTypesOptions.length && libraryMaterialCategoriesOptions.length) {
      setValue('type', libraryMaterialTypesOptions[0].value);
      setValue('categoryId', libraryMaterialCategoriesOptions[0].value);
      setValue('status', LibraryMaterialStatuses.draft);
      setValue('isPublish', false);
    }
  }, [data, libraryMaterialTypesOptions, libraryMaterialCategoriesOptions]);

  return (
    <form className="flex justify-center" onSubmit={handleSubmit(saveMaterial)}>
      <FullPlate className="self-center w-full max-w-[89.6rem] p-6">
        <RouterPrompt when={isDirty && !isSaved} />
        <div className="p-6 bg-catskillWhite rounded-lg mb-6">
          <div className="text-1822 font-semibold font-ambit border-b pb-6 flex justify-between">
            <div className="">Name and Category</div>
            <div className="">
              <Toggle
                checked={watch('isPublish', false)}
                onChange={(val) => {
                  setValue('isPublish', val);
                }}
                label="Published"
              />
            </div>
          </div>
          <div className="flex mt-6">
            <div className="flex-1 mr-3">
              <SelectField
                label="Type"
                isRequired
                selected={
                  libraryMaterialTypesOptions.find((el) => el.value === watch('type', '')) ||
                  libraryMaterialTypesOptions?.[0]
                }
                error={errors.type?.message}
                onSelect={(val) => {
                  setValue('type', val.value);
                  trigger('type');
                }}
                options={libraryMaterialTypesOptions}
              />
              {errors.type && (
                <div className="text-error text-1214 py-1">{errors.type.message}</div>
              )}{' '}
              {/* Please add few sentences */}
            </div>
            <div className="flex-1 ml-3">
              <SelectField
                label="Category"
                isRequired
                selected={
                  libraryMaterialCategoriesOptions.find(
                    (el) => el.value === watch('categoryId', ''),
                  ) || libraryMaterialCategoriesOptions?.[0]
                }
                error={errors.categoryId?.message}
                onSelect={(val) => {
                  setValue('categoryId', val.value);
                  trigger('categoryId');
                }}
                options={libraryMaterialCategoriesOptions}
              />
            </div>
          </div>
          <div className="mt-6">
            <InputField
              onChange={(val) => {
                setValue('title', val);
                trigger('title');
              }}
              maxLength={255}
              value={watch('title', '')}
              isRequired
              label="Title"
              placeholder="Enter title"
              error={errors.title?.message}
            />
          </div>
          <div className="mt-6">
            <InputField
              maxLength={255}
              onChange={(val) => {
                setValue('description', val);
                trigger('description');
              }}
              isRequired
              value={watch('description')}
              label="Short description"
              placeholder="Enter description"
              error={errors.description?.message}
            />
          </div>
        </div>
        <div className="p-6 bg-catskillWhite rounded-lg">
          <div className="text-1822 font-semibold font-ambit border-b pb-6 flex justify-between">
            <div className="">Content</div>

            <div className="">
              <Toggle checked={isPreview} color="main" onChange={togglePreview} label="Preview" />
            </div>
          </div>
          <div
            className={classNames('mt-6 ck-editor-view', {
              hidden: !isPreview,
            })}
            dangerouslySetInnerHTML={{ __html: watch('content') }}
          ></div>
          <div
            className={classNames('mt-6', {
              hidden: isPreview,
            })}
          >
            <div className="text-1324 text-coil">
              Text Body <span className="text-error">*</span>
            </div>
            <CKEditor
              data={watch('content')}
              onChange={(content) => {
                setValue('content', content);
                trigger('content');
              }}
            />
            {errors.content && (
              <div className="text-error text-1214 py-1">{errors.content.message}</div>
            )}{' '}
            {/* Please add few sentences */}
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <label className="flex items-center">
            {/* <input onChange={(e) => setRequestToPublish(e.target.value === 'on')} className="mr-2" type="checkbox" />{' '}
            <div className="text-independence text-1622">Request to Publish</div> */}
          </label>
          <div className="flex">
            <button
              type="button"
              onClick={onCancel}
              className="mr-6 border group border-main  w-[21rem] h-[4.2rem] hover:bg-independence border-main30 flex justify-center  items-center text-white px-[2.7rem] rounded-[2.4rem] text-1324"
            >
              <div className="text-main group-hover:text-white">Cancel</div>
            </button>
            <button
              type="submit"
              disabled={!isValid || !isDirty}
              className={classNames(
                'bg-main border w-[21rem] h-[4.2rem]  border-main30 flex items-center text-white px-[2.7rem] justify-center rounded-[2.4rem] text-1324',
                {
                  'opacity-50': !isValid || !isDirty,
                  'hover:bg-independence': !(!isValid || !isDirty),
                },
              )}
            >
              <div className="text-white">Save</div>
            </button>
          </div>
        </div>
      </FullPlate>
    </form>
  );
};
