import React from 'react';
import styled from 'styled-components';

import InputWithLabel from 'common/InputWithLabel';
import LinkButton from 'common/LinkButton';

export default function ShareByLink({ link }) {
  const inputRef = React.useRef();

  const copyToClipboard = React.useCallback(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.select();
      document.execCommand('copy');
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      if (document.selection) {
        document.selection.empty();
      }
      inputElement.blur();
    }
  }, [inputRef]);

  return (
    <StyledWrapper className="g-mt-20">
      <InputWithLabel
        inputRef={inputRef}
        label="Copy the link"
        readOnly
        value={link}
        isLoading={!link}
      />
      <LinkButton className="invite-button g-mt-auto g-ml-25" onClick={copyToClipboard}>
        Copy link
      </LinkButton>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  .invite-button {
    padding: 1.1rem 2rem;
    width: 100%;
    max-width: 12rem;
  }
`;
