import get from 'lodash/get';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Pagination from 'common/pagination';
import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import PatientsHeader from 'components/patients/patients/PatientsHeader';
import PatientsList from 'components/patients/patients/PatientsList';
import PatinetsNavigation from 'components/patients/patients/PatinetsNavigation';
import { PATIENTS } from 'constants/routes';
import {
  usePatinets,
  useSetPatinets,
  usePatinetsPagination,
  useSetPatinetsFilter,
} from 'hooks/usePatients';
import { useSavedSearch } from 'hooks/useSavedSearch';
import { useFetchCompanies } from 'requests/companies';
import { useFetchPatinets } from 'requests/patients';

import { useUserContext } from '../../context/userContext';

export default function NewPatients() {
  const { isAdmin } = useUserContext();
  const { status } = useParams();
  const history = useHistory();
  const clearPatinets = useSetPatinets(() => null, []);
  const fetchCompanies = useFetchCompanies();
  const fetchPatinets = useFetchPatinets();
  const patinets = usePatinets();

  const setType = useSetPatinetsFilter((prev, type) => ({ ...prev, type }), []);

  React.useEffect(() => {
    setType(isAdmin ? 'all' : 'my');
  }, [isAdmin]);

  React.useEffect(() => {
    fetchCompanies();
    fetchPatinets();
    return clearPatinets;
  }, [fetchPatinets]);

  const patinetsPagination = usePatinetsPagination();

  const page = get(patinetsPagination, 'current_page');
  const pagesCount = get(patinetsPagination, 'last_page');

  const savedSearch = useSavedSearch();

  const navigateHandler = React.useCallback(
    (value) => {
      const params = new URLSearchParams(savedSearch);

      if (value === 1) {
        params.delete('page');
        localStorage.removeItem('search');
      } else {
        params.set('page', value);
      }

      history.replace(`/${PATIENTS}/${status}?${params.toString()}`);
    },
    [history, status],
  );

  return (
    <RowPlate>
      <PatientsHeader />

      <PlateContent>
        <PatinetsNavigation />

        <PatientsList />

        {patinets && (
          <Pagination page={page} pagesCount={pagesCount} navigateHandler={navigateHandler} />
        )}
      </PlateContent>
    </RowPlate>
  );
}
