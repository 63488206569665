import React from 'react';

import { HintText, RegularMediumText } from 'common/texts';

export default function StatsItem({
  className = '',
  hintClassName = 'g-mr-10',
  statsTitle = '',
  statsData = '',
}) {
  return (
    <div className={className}>
      <HintText className={hintClassName}>{statsTitle}</HintText>
      <RegularMediumText>{statsData}</RegularMediumText>
    </div>
  );
}
