import React from 'react';

import ChatInput from 'common/chat/ChatInput';
import { ColumnWidePlate } from 'common/plate/plates';

import GroupHeader from './GroupHeader';

export default React.memo(function CreateGroupContent() {
  return (
    <ColumnWidePlate>
      <GroupHeader />

      <ChatInput />
    </ColumnWidePlate>
  );
});
