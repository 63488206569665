import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { useScheduledAppointments } from 'hooks/useAppointments';

import TimeLine from '../TimeLine';
import DataLine from './DataLine';

const TIME_LINE = 'time-line';
const TIME_CONTAINR = 'time-containr';

const HOURS = [
  { value: '00:00:00', label: '' },
  { value: '01:00:00', label: '01:00 AM' },
  { value: '02:00:00', label: '02:00 AM' },
  { value: '03:00:00', label: '03:00 AM' },
  { value: '04:00:00', label: '04:00 AM' },
  { value: '05:00:00', label: '05:00 AM' },
  { value: '06:00:00', label: '06:00 AM' },
  { value: '07:00:00', label: '07:00 AM' },
  { value: '08:00:00', label: '08:00 AM' },
  { value: '09:00:00', label: '09:00 AM' },
  { value: '10:00:00', label: '10:00 AM' },
  { value: '11:00:00', label: '11:00 AM' },
  { value: '12:00:00', label: '12:00 PM' },
  { value: '13:00:00', label: '01:00 PM' },
  { value: '14:00:00', label: '02:00 PM' },
  { value: '15:00:00', label: '03:00 PM' },
  { value: '16:00:00', label: '04:00 PM' },
  { value: '17:00:00', label: '05:00 PM' },
  { value: '18:00:00', label: '06:00 PM' },
  { value: '19:00:00', label: '07:00 PM' },
  { value: '20:00:00', label: '08:00 PM' },
  { value: '21:00:00', label: '09:00 PM' },
  { value: '22:00:00', label: '10:00 PM' },
  { value: '23:00:00', label: '11:00 PM' },
];

export default React.memo(function DayGrid() {
  const scheduledAppointments = useScheduledAppointments();
  const content = React.useMemo(() => {
    const gridConfig = [];

    HOURS.forEach(({ value, label }) => {
      gridConfig.push({ type: TIME_LINE, label });
      gridConfig.push({ type: TIME_CONTAINR, value });
    });

    gridConfig.push({ type: TIME_LINE, value: '24:00:00', label: '' });

    return gridConfig.map(({ type, value, label }, i) => {
      if (type === TIME_LINE) {
        return <TimeLine key={i} label={label} />;
      }
      if (type === TIME_CONTAINR) {
        const appointment = scheduledAppointments.find(
          (item) => moment.utc(item.started_at).local().format('HH:mm:ss') === value,
        );
        return <DataLine key={i} appointment={appointment} />;
      }
      return null;
    });
  }, [scheduledAppointments]);

  return <StyledWrapper className="g-mb-30">{content}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  max-height: 53rem;
  overflow-y: auto;
`;
