import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as IconExport } from 'assets/export.svg';
import { HintText } from 'common/texts';
import { useWidget } from 'hooks/patient/widgets/useWidgets';
import { useExportWidgetData } from 'requests/patients/widgets/widgets';

export default function ExportData({ title = 'Score', field = 'score' }) {
  const exportData = useWidget(
    (current) => {
      let widgetData = {};
      const data = get(current, 'data', []);
      if (data.length === 0) {
        return { '': '' };
      }
      data.forEach((item) => {
        widgetData[get(item, 'summaryDate', '')] = get(item, field, '');
      });
      return widgetData;
    },
    [field],
  );
  const exportWidgetData = useExportWidgetData(title, exportData);

  return (
    <StyledHintText as="button" className="g-d-flex g-ml-auto" onClick={exportWidgetData}>
      <IconExport className="g-mr-10" />
      Export
    </StyledHintText>
  );
}

const StyledHintText = styled(HintText)`
  padding: 0;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  svg {
    position: relative;
    top: 0.3rem;
    width: 1.5rem;
    height: 1.5rem;
  }
`;
