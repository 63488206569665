import React from 'react';
import styled from 'styled-components';

import ListPlaceholder from 'common/loaders/ListPlaceholder';
import { useBookedAppointments } from 'hooks/useAppointments';

import AppointmentPreview from './AppointmentPreview';

export default React.memo(function AppointmentsList() {
  const bookedAppointments = useBookedAppointments();

  if (!bookedAppointments) {
    return (
      <>
        <ListPlaceholder />
        <ListPlaceholder />
      </>
    );
  }

  const result = bookedAppointments.map((appointment) => {
    return <AppointmentPreview key={appointment.id} appointment={appointment} />;
  });

  return <StyledWrapper>{result}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  max-height: 48rem;
  overflow-y: auto;
`;
