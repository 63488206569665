import React from 'react';
import styled from 'styled-components';

import { ReactComponent as PaperPlane } from 'assets/paper-plane-solid.svg';
import { useSendMessage } from 'requests/messages';
import isMacOS from 'utils/isMacOS';

const controlKey = isMacOS ? 'metaKey' : 'ctrlKey';

export default React.memo(function ChatInput({ channel }) {
  const sendMessage = useSendMessage(channel);

  const [message, setMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);

  const changeHandler = React.useCallback(
    (e) => {
      setMessage(e.target.value);
    },
    [setMessage],
  );

  const sendHandler = React.useCallback(() => {
    if (!channel) {
      return;
    }

    const sendMessageBack = async () => {
      await channel.channelClass
        .sendMessage(parsedMessage)
        .then(() => {
          setIsError(false);
          setMessage('');
          sendMessage(parsedMessage);
          channel.channelClass.setAllMessagesConsumed();
        })
        .catch((error) => {
          setIsError(true);
          console.info('error ' + error);
        });
    };

    const parsedMessage = message.trim();
    if (!parsedMessage) {
      return;
    }
    sendMessageBack();
  }, [channel, message, sendMessage]);

  const keyHandler = React.useCallback(
    (event) => {
      if (event[controlKey] && event.keyCode === 13) {
        setMessage((current) => current + '\n');
        const element = event.target;
        element.scrollTop = element.scrollHeight;
        return;
      }

      if (event.keyCode === 13) {
        event.preventDefault();
        sendHandler();
      }
    },
    [setMessage, sendHandler],
  );

  return (
    <>
      {isError && <StyledError>Problems with connection. Please, try again later..</StyledError>}
      <StyledWrapper>
        <textarea
          value={message}
          onChange={changeHandler}
          onKeyDown={keyHandler}
          className="text-input"
          placeholder="Start typing here…"
        />

        <div onClick={sendHandler} className="send-button">
          <PaperPlane className="paper-plane" />
        </div>
      </StyledWrapper>
    </>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  border-top: solid 2px #f1f5f8;
  height: 6.3rem;
  .text-input {
    display: block;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    border-bottom-left-radius: 0.8rem;
    padding: 1.8rem 2rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    resize: none;
  }
  .send-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 6rem;
    min-width: 6rem;
    border-left: solid 2px transparent;
    cursor: pointer;
    :hover {
      border-left: solid 2px #f1f5f8;
    }
    :active {
      background: #f1f5f8;
    }
  }
  .paper-plane {
    width: 2rem;
    height: 2rem;
  }
`;
const StyledError = styled.p`
  font-size: 12px;
  margin-left: 2rem;
  color: red;
`;
