import moment from 'moment';
import React from 'react';

import AppointmentsTemplate from 'components/appointmentsProvider/AppointmentsTemplate';
import MonthGrid from 'components/appointmentsProvider/month/MonthGrid';
import MonthHeader from 'components/appointmentsProvider/month/MonthHeader';
import { MONTH } from 'constants/routes';
import { useSetScheduledAppointments } from 'hooks/useAppointments';
import { useFetchScheduledAppointments } from 'requests/appointments';

export default function MonthAppoitments() {
  const [currentMonth, setCurrentMonth] = React.useState(moment().month());
  const fetchScheduledAppointments = useFetchScheduledAppointments();
  const clearScheduledAppointments = useSetScheduledAppointments(() => [], []);

  React.useEffect(() => {
    fetchScheduledAppointments({
      period: 'month',
      period_start: moment().month(currentMonth).startOf('month').format('YYYY-MM-DD'),
    });
    return clearScheduledAppointments;
    // eslint-disable-next-line
  }, [currentMonth])

  return (
    <AppointmentsTemplate currentTab={MONTH}>
      <MonthHeader setCurrentMonth={setCurrentMonth} />

      <MonthGrid currentMonth={currentMonth} />
    </AppointmentsTemplate>
  );
}
