import React from 'react';

import ChatInput from 'common/chat/ChatInput';
import { ColumnWidePlate } from 'common/plate/plates';

import InviteHeader from './InviteHeader';

export default React.memo(function RenameGroupContent() {
  return (
    <ColumnWidePlate>
      <InviteHeader />

      <ChatInput />
    </ColumnWidePlate>
  );
});
