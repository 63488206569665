import React from 'react';

import CustomSelect from 'common/CustomSelect';
import { useSetMedicationProperty } from 'hooks/usePatients';

import iconsConfig from '../../iconsConfig';

export default React.memo(function Icon({ className = '', index, icon, validate }) {
  const setMedicationIcon = useSetMedicationProperty({ index, field: 'icon' });
  return (
    <CustomSelect
      className={className}
      label="Category"
      options={iconsConfig}
      isRequred
      isValid={!validate || icon}
      value={icon}
      changeHandler={setMedicationIcon}
    />
  );
});
