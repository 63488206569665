import React from 'react';
import styled from 'styled-components';

import { HintText, RegularText } from 'common/texts';

export default function InfoForPatient({ label = '', value = '' }) {
  return (
    <StyledWrapper className="g-d-flex">
      <HintText className="label">{label}</HintText>
      <RegularText>{value}</RegularText>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  &:nth-child(n + 2) {
    margin-top: 0.5rem;
  }
  .label {
    min-width: 15rem;
  }
`;
