import get from 'lodash/get';
import React from 'react';

import { useAdminChannelData } from 'hooks/useMessages';

import { StyledWrapper, StyledUserAvatar, StyledDetails, StyledCounter } from './styles';

export default React.memo(function AdminItem({
  channel,
  isActive,
  newMessagesCount,
  navigateToChat,
}) {
  const patientData = useAdminChannelData(channel);

  const avatarUrl = get(patientData, 'avatar_url');
  const firstName = get(patientData, 'first_name');
  const lastName = get(patientData, 'last_name');
  const fullName = get(patientData, 'full_name');

  return (
    patientData !== undefined && (
      <StyledWrapper isActive={isActive} onClick={navigateToChat}>
        <StyledUserAvatar avatar={avatarUrl} firstName={firstName} lastName={lastName} />

        <StyledDetails className="wrapper g-ml-10">
          <div className="name">{fullName}</div>
          <div className="sub-name">Support chat</div>
        </StyledDetails>

        {!!newMessagesCount && <StyledCounter>{newMessagesCount}</StyledCounter>}
      </StyledWrapper>
    )
  );
});
