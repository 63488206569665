import React from 'react';

import { NODE_ENV } from '../env';

export default React.memo(function BugherdLoader() {
  React.useEffect(() => {
    if (NODE_ENV === 'staging') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://www.bugherd.com/sidebarv2.js?apikey=ztexcts6ld1xbm6ar0wbza';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
  return null;
});
