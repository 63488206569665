import React from 'react';
import styled from 'styled-components';

import InputWithLabel from 'common/InputWithLabel';

export default function PrescriptionFields() {
  return (
    <StyledWrapper className="g-d-flex g-justify-between g-mt-10">
      <InputWithLabel className="field" label="Send to Pharmacy" placeholder=" " value="" />
      <InputWithLabel className="field g-ml-30" label="Pharmacy Address" placeholder=" " value="" />
      <InputWithLabel className="field g-ml-30" label="Pharmacy Phone" placeholder=" " value="" />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .field {
    max-width: 100%;
    &:nth-child(n + 2) {
      @media screen and (max-width: 768px) {
        margin-top: 1.5rem;
        margin-left: 0;
      }
    }
    p {
      text-transform: capitalize;
      color: inherit;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`;
