import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { UserQualifications, Qualification } from '../types/qualification';

type ProfileQualificationsState = {
  userQualifications: UserQualifications | null;
  setUserQualifications: (q: Partial<UserQualifications>) => void;
  setSymptoms: (newSymptom: string) => void;
  setQualificationIds: (qualificationsOptions: Qualification[], value: number) => void;
};

export const initialUserState = {
  qualification_title: '',
  qualifications: [],
  state_licenses: [],
  symptoms: [],
};

const initialState = {
  userQualifications: null,
};

export const setQualificationIds = (
  q: UserQualifications | null,
  qualificationsOptions: Qualification[],
  value: number,
) => {
  const qualifications = q?.qualifications || [];
  const currentQualificationIds = q?.qualifications || [];
  // set value if list empty
  if (currentQualificationIds.length === 0) {
    return [value];
  }
  // remove value if exist
  if (currentQualificationIds.includes(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return currentQualificationIds.filter((item: any) => item !== value);
  }
  const newType = qualificationsOptions.find((item) => +item.value === value)?.type;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAnotherType = currentQualificationIds.find((item: any) => {
    const itemType = qualificationsOptions.find((thin) => +thin.value === item)?.type;
    return itemType !== newType;
  });
  // if type not match clear al values
  if (isAnotherType) {
    return [value];
  }
  // set value if type match
  return [...qualifications, value];
};

export const setSymptoms = (s: number[], ns: string) => {
  return s.includes(+ns) ? s.filter((item) => +item !== +ns) : [...s, +ns];
};

export const useProfileQualificationsStore = create<ProfileQualificationsState>()(
  devtools(
    immer((set) => ({
      ...initialState,
      setUserQualifications: (userQualifications) =>
        set(
          (state) => {
            state.userQualifications = {
              ...(state.userQualifications as UserQualifications),
              ...userQualifications,
            };
          },
          false,
          'setUserQualifications',
        ),
      setQualificationIds: (qualificationsOptions, value) => {
        set(
          (state) => {
            state.userQualifications = state.userQualifications || initialUserState;
            state.userQualifications.qualifications = setQualificationIds(
              state.userQualifications,
              qualificationsOptions,
              value,
            );
          },
          false,
          'setQualificationIds',
        );
      },
      setSymptoms: (newSymptom) => {
        set(
          (state) => {
            state.userQualifications = state.userQualifications || initialUserState;
            state.userQualifications.symptoms = setSymptoms(
              state.userQualifications.symptoms,
              newSymptom,
            );
          },
          false,
          'setSymptoms',
        );
      },
    })),
    {
      name: 'profile--qualifications-storage',
    },
  ),
);
