import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { HintText, RegularText } from 'common/texts';

export default React.memo(function DataLine({ createdAt, text, payload }) {
  const time = moment.utc(createdAt).local().format('HH:mm A');
  let parsedText = text;
  const matches = text.match(new RegExp(/{{.*?}}/g));
  if (matches) {
    matches.forEach((key) => {
      const parsedKey = key.replace('{{', '').replace('}}', '');
      parsedText = parsedText.replace(key, `<b>${get(payload, parsedKey)}</b>`);
    });
  }
  return (
    <StyledWrapper>
      <HintText className="time">{time}</HintText>

      <RegularText className="text" dangerouslySetInnerHTML={{ __html: parsedText }} />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .time {
    width: 8.5rem;
    flex-shrink: 0;
  }
  .text {
    line-height: 2rem;
  }
`;
