import { Placement } from '@popperjs/core/lib/enums';
import classNames from 'clsx';
import React, { FC, useEffect, useRef, useState, JSX } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

export type MenuListItem = {
  icon?: JSX.Element;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (item?: any) => any;
};

type DropdownMenuProps = {
  className?: string;
  placement?: Placement;
  list: MenuListItem[];
};

export const DropdownMenuFunc = ({
  children,
  list,
  placement,
  className,
}: DropdownMenuProps & { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<null | HTMLButtonElement>(null);
  const elemRef = useRef<null | HTMLDivElement>(null);
  const { styles, attributes } = usePopper(buttonRef.current, elemRef.current, {
    placement,
  });

  const clickHandler = (handler: () => void) => () => {
    handler();
    setIsOpen(false);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onBodyClick = (event: any) => {
      if (elemRef?.current?.contains(event.target) || buttonRef?.current?.contains(event.target)) {
        return;
      }
      setIsOpen(false);
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  const element = (
    <>
      <button type="button" ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
        {children}
      </button>
      {ReactDOM.createPortal(
        <div className={className} ref={elemRef} style={styles.popper} {...attributes.popper}>
          <ul
            className={classNames(
              'bg-white rounded-main min-w-[15.2rem] py-1 shadow-dropdown transition-all duration-300 ease-out',
              {
                block: isOpen,
                hidden: !isOpen,
              },
            )}
          >
            {list.map(({ icon, text, handler }) => (
              <li
                key={text}
                onClick={clickHandler(handler)}
                className="flex items-center px-5 py-4 h-8 hover:bg-catskillWhite cursor-pointer"
              >
                {icon && <div className="mr-4">{icon}</div>}
                <div className={classNames('text-1324')}>{text}</div>
              </li>
            ))}
          </ul>
        </div>,
        document.body,
      )}
    </>
  );

  return { element, buttonRef, elemRef };
};

export const DropdownMenu: FC<DropdownMenuProps & { children: React.ReactNode }> = ({
  children,
  list,
  placement,
  className,
}) => {
  const { element } = DropdownMenuFunc({ children, list, placement, className });

  return element;
};
