import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';

import { typesConfig, decimalPlaceConfig } from 'constants/withings-tabs';
import {
  TYPE_WEIGHT,
  TYPE_FAT_MASS,
  TYPE_MUSCLE_MASS,
  TYPE_WATER,
  TYPE_BONE_MASS,
} from 'constants/withings-types';
import kilogramsToPounds from 'utils/widgets/kilogramsToPounds';

function ouraAdapter(data) {
  return data.map((item) => ({ ...item, summaryDate: item.summary_date }));
}

function withingsFormatValue(value = 0, type, decimalPlace) {
  const config = [TYPE_WEIGHT, TYPE_FAT_MASS, TYPE_MUSCLE_MASS, TYPE_WATER, TYPE_BONE_MASS];
  if (config.find((item) => item === type)) {
    return kilogramsToPounds(value, decimalPlace);
  }
  return value;
}

function withingsAdapter(data) {
  const measuregrps = get(data, 'measuregrps');
  if (!measuregrps) {
    return [];
  }
  const dataObject = {};
  measuregrps.reverse().forEach((measuregrp) => {
    const day = moment.unix(measuregrp.date).format('YYYY-MM-DD');
    const measures = measuregrp.measures;
    measures.forEach(({ type, value, unit }) => {
      const parsedType = typesConfig[type];
      if (!parsedType) {
        return console.warn(`Withings type: ${type}, not implemented`);
      }
      const parsedDecimalPlace = decimalPlaceConfig[type];
      const parsedValue = (value * Math.pow(10, unit)).toFixed(parsedDecimalPlace);
      const formattedValue = withingsFormatValue(parsedValue, type, parsedDecimalPlace);
      set(dataObject, `${day}.${parsedType}`, formattedValue);
    });
    return day;
  });
  const dataArray = Object.keys(dataObject)
    .map((key) => ({ ...dataObject[key], summaryDate: key }))
    .sort((a, b) => moment(a.summaryDate) - moment(b.summaryDate));
  return dataArray;
}

const adapters = {
  oura: ouraAdapter,
  withings: withingsAdapter,
};

export default function normalizeWidgetData(widget) {
  if (!widget) {
    return widget;
  }
  const { device, data } = widget;
  if (!device) {
    return widget;
  }
  const adapter = adapters[device];
  if (!adapter) {
    console.warn(`Adapter for device: ${device}, not implemented`);
    return widget;
  }
  return {
    ...widget,
    data: adapter(data),
  };
}
