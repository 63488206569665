import React from 'react';

import { MediumBoldText } from 'common/texts';
import { Pagination } from 'components/new/Pagination/Pagination';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { DOCUMENTS } from 'constants/routes';

import styles from './index.module.css';

export const PatientDocuments = () => {
  const renderExternalLogins = () => {
    const logins = [
      ['Evexia diagnostics', 'https://www.evexiadiagnostics.com/Account/Logon'],
      ['Vibrant-America', 'https://www.vibrant-america.com/secure/login.jsp'],
      ['Rupa Health', 'https://www.rupahealth.com'],
      ['Fullscript', 'https://fullscript.com'],
      ['Wells Pharmacy', 'https://www.wellsrx.com'],
    ];
    return logins.map(([name, url]) => (
      <p key={name}>
        <a className={styles.externalLogin} href={url} rel="noopener noreferrer" target="_blank">
          {name}
        </a>
      </p>
    ));
  };

  return (
    <DetailsTemplate currentTab={DOCUMENTS}>
      <div className={styles.section}>
        <MediumBoldText>External logins</MediumBoldText>
        {renderExternalLogins()}
      </div>
      <div className={styles.section}>
        <MediumBoldText>Upload</MediumBoldText>
      </div>
      <div className={styles.section}>
        <MediumBoldText>Uploaded Documents</MediumBoldText>
        <p className={styles.description}>There are no labs uploaded yet</p>
      </div>
      <div>
        <Pagination pageCount={50} onPageChange={() => {}} initialPage={1} />
      </div>
    </DetailsTemplate>
  );
};
