import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';
import { typesConfig, periodTabsConfig, dimensionConfig } from 'constants/withings-tabs';
import { useDaysData } from 'hooks/patient/widgets/useWidgets';

import Bars from './bars';
import DatesList from './dates-list';
import LabelsList from './labels-list';

export default function YearView({ currentTab, period }) {
  const type = typesConfig[currentTab];
  const { start } = periodTabsConfig.find((item) => item.id === period);

  const daysData = useDaysData(type);

  const dates = React.useMemo(() => {
    const monthInYear = 12;
    return new Array(monthInYear).fill().map((item, i) => {
      const day = moment(start).add(i, 'month');
      const daysValue = new Array(day.daysInMonth())
        .fill()
        .map((item, i) => {
          const current = day.clone().add(i, 'days').format('YYYY-MM-DD');
          const { value = 0 } = daysData.find(({ date }) => date === current) || {};
          return value;
        })
        .filter((item) => item);
      const value = daysValue.reduce((a, val) => +a + +val, 0) / daysValue.length || 0;
      return {
        label: day.format('MMM'),
        value: value ? value.toFixed(1) : 0,
      };
    });
  }, [start, daysData]);

  return (
    <StyledWrapper>
      <RegularText>
        {moment(start).format('YYYY')} Year <b>(in {dimensionConfig[currentTab]})</b>
      </RegularText>
      <LabelsList dates={dates} />
      <Bars currentTab={currentTab} dates={dates} />
      <DatesList dates={dates} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div``;
