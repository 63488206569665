import React from 'react';
import styled from 'styled-components';

export default React.memo(function AppointmentsPlaceholder() {
  return (
    <StyledWrapper>
      <div className="placeholder" />
      <div className="placeholder" />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .placeholder {
    background: #fafbfd;
    height: 12rem;
    border-bottom: solid 1px #e9ebef;
  }
`;
