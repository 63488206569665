import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { RegularMediumText, RegularText } from 'common/texts';
import SuccessIcon from 'components/settings/security/SuccessIcon';
import { DASHBOARD } from 'constants/routes';

export default React.memo(function TwoFASuccess() {
  const history = useHistory();

  return (
    <ColumnWidePlate>
      <StyledWrapper>
        <SuccessIcon />

        <p className="congratulation-text">Your profile is safe</p>

        <RegularMediumText className="g-mt-10">Attention!</RegularMediumText>

        <RegularText className="g-mt-10">
          If you lose access to your mobile device or uninstall
        </RegularText>
        <RegularText>
          Authenticator, you will no longer be able to log into your profile.
        </RegularText>
        <RegularText>To avoid this, write down a one-time login code in a safe place.</RegularText>

        <p className="code g-mt-20">4FY0NN0R51</p>

        <CustomButton
          className="link g-mt-20"
          clickHandler={React.useCallback(() => history.push(`/${DASHBOARD}`), [history])}
        >
          I saved the code, continue
        </CustomButton>
      </StyledWrapper>
    </ColumnWidePlate>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .congratulation-text {
    font-size: 3.6rem;
    font-weight: 700;
    margin-top: 4rem;
  }
  .code {
    font-size: 2rem;
    font-weight: 700;
  }
  .link {
    padding: 1.6rem;
  }
`;
