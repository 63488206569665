import React from 'react';
import styled from 'styled-components';

import { useMedicationsTab, useSetMedicationsTab } from 'hooks/patient/useMedications';

export default React.memo(function Tab({ tab }) {
  const isActive = useMedicationsTab((current) => current.id === tab.id, [tab]);
  const setMedicationsTab = useSetMedicationsTab(() => tab, [tab]);
  return (
    <StyledWrapper isActive={isActive} onClick={setMedicationsTab}>
      {tab.label}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  margin-bottom: -2px;
  padding: 1rem 3.5rem;
  font-size: 1.5rem;
  border-bottom: solid 2px ${({ isActive }) => (isActive ? '#000' : 'transparent')};
  transition: all 0.2s;
  cursor: pointer;
  :hover {
    border-bottom: solid 2px #000;
  }
`;
