import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { PlateHeader } from 'common/plate/styles';
import ProviderLoader from 'components/appointmentsAdmin/reschedule-appointment/ProviderLoader';
import ProviderPicker from 'components/appointmentsAdmin/reschedule-appointment/ProviderPicker';
import { useAppointment, useSetAppointment } from 'hooks/useAppointments';
import { useFetchAppointment } from 'requests/appointments';

import { APPOINTMENTS_ADMIN, RESCHEDULE } from '../../../constants/routes';

export default function ChangeProvider() {
  const { appointmentId } = useParams();
  const history = useHistory();
  const appointment = useAppointment();
  const clearAppointment = useSetAppointment(() => null, []);
  const fetchAppointment = useFetchAppointment();

  React.useEffect(() => {
    if (!appointment) {
      fetchAppointment();
    }
    return () => {
      if (history.location.pathname !== `/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${appointmentId}`) {
        clearAppointment();
      }
    };
  }, [appointmentId]);

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS_ADMIN}/${RESCHEDULE}/${appointmentId}`} />
        Reschedule an appointment
      </PlateHeader>

      <ProviderPicker />

      <ProviderLoader />
    </ColumnWidePlate>
  );
}
