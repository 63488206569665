import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from 'hooks/useGlobalState';
import request from 'utils/request';

export default function HitHandler() {
  const { appointmentId } = useParams();
  const userId = useUser((current) => get(current, 'id'));
  React.useEffect(() => {
    if (userId) {
      request({
        method: 'put',
        url: `/appointments/${appointmentId}/markPresence`,
        data: {
          provider: userId,
        },
      });
    }
  }, [appointmentId, userId]);
  return null;
}
