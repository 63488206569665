import { useState } from 'react';

import {
  FailedFile,
  UploadFileInfoType,
  useUploadTaskFiles,
} from '../requests/graphql/my-health/mutations/uploadTaskFiles';

export type FilesResult = { uploaded: UploadFileInfoType[]; failed: FailedFile[] };

type AttachFilesProps = {
  onSuccess?: (result: FilesResult) => void;
  onFail?: (error: Error) => void;
};

export const useAttachFiles = (args?: AttachFilesProps) => {
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);

  const { onSuccess, onFail } = args || {};
  const { uploadTaskFiles, isLoading } = useUploadTaskFiles({
    onSuccess: (data) => {
      if (data.uploadTaskFiles?.files.length) {
        const uploaded = data.uploadTaskFiles.files.filter(
          (file) => file.__typename === 'UploadFileInfoType',
        ) as UploadFileInfoType[];
        const failed = data.uploadTaskFiles.files.filter(
          (file) => file.__typename !== 'UploadFileInfoType',
        ) as FailedFile[];
        onSuccess?.({ uploaded, failed });
      }
    },
  });

  const onAttachFiles = async (files: File | File[]) => {
    let fls = files as File[];
    try {
      if (!Array.isArray(files)) {
        fls = [files];
      }
      setUploadingFiles(fls);
      const data = await uploadTaskFiles({ files: fls });
      setUploadingFiles([]);

      return data;
    } catch (err) {
      setUploadingFiles([]);
      onFail?.(err as Error);
    }
  };

  return { onAttachFiles, uploadingFiles, isLoading };
};
