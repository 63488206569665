import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import CustomTextarea from 'common/CustomTextarea';
import { MediumText } from 'common/texts';
import ProviderTemplate from 'components/providers/ProviderTemplate';
import { ADMIN_NOTES } from 'constants/routes';
import { useProviderAdminNote, useSetProviderAdminNote } from 'hooks/useProviders';
import { useCreateProviderAdminNote } from 'requests/providers';

export default function ProviderCreateAdminNote() {
  const note = useProviderAdminNote((current) => get(current, 'note'));
  const setProviderAdminNote = useSetProviderAdminNote((prev, note) => ({ note }));
  const clearProviderAdminNote = useSetProviderAdminNote(() => null);
  const createProviderAdminNote = useCreateProviderAdminNote();

  React.useEffect(() => {
    return clearProviderAdminNote;
    // eslint-disable-next-line
  }, [])

  return (
    <ProviderTemplate currentTab={ADMIN_NOTES}>
      <MediumText>Admin notes</MediumText>
      <StyledCustomTextarea
        label="Enter your notes that available only to you"
        placeholder="Free text"
        value={note}
        changeHandler={setProviderAdminNote}
      />
      <CustomButton className="g-mt-35" disabled={!note} clickHandler={createProviderAdminNote}>
        Update
      </CustomButton>
    </ProviderTemplate>
  );
}

const StyledCustomTextarea = styled(CustomTextarea)`
  textarea {
    min-height: 40rem;
  }
`;
