import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ChatContent from 'components/appointmentsProvider/consultation/ChatContent';
import HitHandler from 'components/appointmentsProvider/consultation/HitHandler';
import InteractHandler from 'components/appointmentsProvider/consultation/InteractHandler';
import VideoContent from 'components/appointmentsProvider/consultation/VideoContent';
import { useConsultation, useSetConsultation, useHandleDisconnect } from 'hooks/useAppointments';
import { globalContext } from 'hooks/useGlobalState';
import onbeforeunloadHandler from 'utils/onbeforeunloadHandler';
import request from 'utils/request';

export default function Consultation() {
  const { appointmentId } = useParams();
  const { consultation } = React.useContext(globalContext);
  const [isInteracted, setIsInteracted] = React.useState(false);
  const [isChatHidden, setIsChatHidden] = React.useState(false);
  const respondData = useConsultation((current) => get(current, 'respondData'));
  const setRespondData = useSetConsultation((prev, respondData) => ({ ...prev, respondData }), []);
  const setAppointmentId = useSetConsultation(
    (prev) => ({ ...prev, appointmentId }),
    [appointmentId],
  );
  const handleDisconnect = useHandleDisconnect();

  React.useEffect(() => {
    setAppointmentId();
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!respondData) {
      request({
        method: 'get',
        url: `/appointments/${appointmentId}/video-token`,
      })
        .then(({ data: { data } }) => {
          setRespondData(data);
        })
        .catch((error) => console.info(error));
    }
    // eslint-disable-next-line
  }, [respondData])

  React.useEffect(() => {
    return () => {
      if (consultation.value.participants.length === 0) {
        handleDisconnect();
      }
      onbeforeunloadHandler({ key: 'video', func: handleDisconnect });
    };
    // eslint-disable-next-line
  }, [consultation])

  return (
    <StyledWrapper>
      <HitHandler />

      {isInteracted ? (
        <VideoContent setIsChatHidden={setIsChatHidden} />
      ) : (
        <InteractHandler setIsInteracted={setIsInteracted} />
      )}

      <div className="separator" />

      {!isChatHidden && <ChatContent isChatHidden={isChatHidden} />}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
  .separator {
    min-width: 3rem;
    @media only screen and (max-width: 1024px) {
      min-height: 3rem;
    }
  }
`;
