import React from 'react';
import styled from 'styled-components';

import { collorsConfig } from 'constants/withings-tabs';
import toRelative from 'utils/toRelative';

import Bar from './Bar';

export default React.memo(function Bars({ currentTab, dates }) {
  const maxValue = Math.max(...dates.map(({ value }) => value));
  return (
    <StyledWrapper className="g-mt-10">
      {dates.map(({ value }, index) => {
        return (
          <Bar
            key={index}
            index={index}
            height={toRelative(value, maxValue)}
            numCells={dates.length}
            color={collorsConfig[currentTab]}
          />
        );
      })}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 20rem;
  border-bottom: solid 1px #e9ebef;
`;
