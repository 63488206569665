import React from 'react';
import styled from 'styled-components';

import { ReactComponent as HipaaIcon } from 'assets/HIPAA.svg';
import { ReactComponent as SslIcon } from 'assets/SSL.svg';

export default React.memo(function SecureInfo() {
  return (
    <StylesWrapper className="g-d-flex g-mt-15">
      <HipaaIcon className="icon-hipaa g-mr-15" />
      <SslIcon className="icon-ssl g-mr-15" />
      <p className="text">
        The video call use the end-to-end encryption protocols to secure all communications. Video
        and chat are protected and HIPPA compliant.
      </p>
    </StylesWrapper>
  );
});

const StylesWrapper = styled.div`
  align-items: center;
  padding-left: 3rem;
  .icon-hipaa {
    max-width: 6rem;
  }
  .icon-ssl {
    max-width: 5.7rem;
  }
  .text {
    max-width: 31.6rem;
    font-size: 0.9rem;
    color: #979797;
  }
`;
