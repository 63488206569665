import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButoon from 'common/CustomButton';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import { MediumText } from 'common/texts';

export default function Finish() {
  const history = useHistory();

  const completeTesting = React.useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <WideRowPlate>
      <PlateHeader>Step 4 of 4 - Finish</PlateHeader>

      <PlateContent>
        <MediumText className="g-mt-20">
          Your connection is good to have a quality Video Session.
        </MediumText>

        <CustomButoon className="g-mt-40" clickHandler={completeTesting}>
          Good, completing testing
        </CustomButoon>
      </PlateContent>
    </WideRowPlate>
  );
}
