import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';

import Info from '../common/Info';
import MedicationHeader from '../common/MedicationHeader';
import Note from '../common/Note';

export default React.memo(function MedicationPreview({ medication }) {
  const icon = get(medication, 'icon');
  const medicationName = get(medication, 'medication');
  const category = get(medication, 'category');
  const complete_days = get(medication, 'complete_days');
  const missed_days = get(medication, 'missed_days');
  const marked = get(medication, 'marked');
  const total = get(medication, 'total');
  const duration = get(medication, 'duration');
  const note = get(medication, 'note');

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleNote = React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  return (
    <StyledWrapper className="g-mb-25">
      <div className="top g-d-flex g-align-center">
        <MedicationHeader
          icon={icon}
          medicationName={medicationName}
          description={`${category} / Duration: ${duration} ${duration === 1 ? 'day' : 'days'}`}
        />
        <Info className="info" label="Complete days" value={complete_days} />
        <Info
          className="info"
          label="Missed days"
          value={`${missed_days} ${missed_days === 1 ? 'Day' : 'Days'}`}
        />
        <Info className="info" label="Course" value={`${marked} / ${total} Taken`} />
        <LinkButton className="button" disabled={!note} onClick={toggleNote}>
          {isOpen ? 'Hide' : 'View note'}
        </LinkButton>
      </div>
      <Note isOpen={isOpen} note={note} />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  background: #fafbfd;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .top {
    padding: 2.5rem 2rem 2.5rem 2.5rem;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .info {
    padding: 0 1rem;
    @media screen and (max-width: 1024px) {
      padding: 0 0.5rem;
    }
    @media screen and (max-width: 768px) {
      width: 33%;
      margin-bottom: 1.5rem;
      padding: 0 0.1rem;
    }
  }
  .button {
    min-width: 10rem;
    margin-left: auto;
    white-space: nowrap;
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      margin-top: 1rem;
    }
  }
`;
