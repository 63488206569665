import React from 'react';
import { useParams } from 'react-router-dom';

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import { useQuizzesData } from 'hooks/patient/useQuestionnaires';

import QuizPreview from './quiz-preview';

export default React.memo(function QuizzesList() {
  const { type } = useParams();
  const quizzesList = useQuizzesData(
    (current) => {
      if (!current) {
        return null;
      }
      return current[type].questionnaires;
    },
    [type],
  );

  const parsedQuizzesList = React.useMemo(() => {
    if (!quizzesList) {
      return null;
    }
    return quizzesList.map((quiz) => {
      return <QuizPreview key={quiz.id} quiz={quiz} />;
    });
  }, [quizzesList]);

  if (!quizzesList) {
    return <ListPreviewPlaceholder className="g-mt-30" />;
  }

  return <div className="g-mt-30">{parsedQuizzesList}</div>;
});
