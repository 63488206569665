import React, { useCallback } from 'react';
import styled from 'styled-components';

import getInitials from 'utils/getInitials';

/**
 *
 * @param {*} p
 * @param {string=} p.className
 * @param {number=} p.size
 * @param {string=} p.avatar
 * @param {string=} p.firstName
 * @param {string=} p.lastName
 * @param {function=} p.onClick
 * @returns
 */
export default function UserAvatar({ className, size = 44, avatar, firstName, lastName, onClick }) {
  const [isDefault, setIsDefault] = React.useState(false);

  const makeDefault = useCallback(() => {
    setIsDefault(true);
  }, []);

  if (isDefault || !avatar) {
    const initials = getInitials(firstName, lastName);
    return (
      <StyledPlaceholder size={size} className={className} onClick={onClick}>
        {initials}
      </StyledPlaceholder>
    );
  }
  return (
    <StyledAvatar
      size={size}
      onError={makeDefault}
      className={className}
      onClick={onClick}
      src={avatar}
      alt="avatar"
    />
  );
}

const StyledPlaceholder = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: 50%;
  background: #d8d8d8;
  font-size: 1.4rem;
  font-weight: 700;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
`;

const StyledAvatar = styled.img`
  display: block;
  flex-shrink: 0;
  width: ${({ size }) => size + 'px'};
  height: ${({ size }) => size + 'px'};
  border-radius: 50%;
  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')};
`;
