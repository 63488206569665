import React from 'react';

import ChatInput from 'common/chat/ChatInput';
import { ColumnWidePlate } from 'common/plate/plates';

import DirectHeader from './DirectHeader';

export default React.memo(function CreateDirectContent() {
  return (
    <ColumnWidePlate>
      <DirectHeader />

      <ChatInput />
    </ColumnWidePlate>
  );
});
