import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import DateSeparator from 'common/DateSeparator';
import { useAppointments } from 'hooks/useAppointments';

import AppointmentsPreview from './appointments-preview';

const today = moment().format('MM.DD.YYYY');

export default React.memo(function AppointmentsList() {
  const appointments = useAppointments();

  const parsedAppointments = React.useMemo(() => {
    const appointmentsWithDayBreack = [];
    let lastDate = null;
    appointments.forEach((appointment) => {
      const timestamp = get(appointment, 'started_at');
      const parsedTimestamp = moment.utc(timestamp).local().format('MM.DD.YYYY');
      if (lastDate !== parsedTimestamp) {
        lastDate = parsedTimestamp;
        const data = {
          type: 'timeSeparator',
          body: parsedTimestamp === today ? 'TODAY' : parsedTimestamp,
        };
        appointmentsWithDayBreack.push(data);
      }
      appointmentsWithDayBreack.push(appointment);
    });
    return appointmentsWithDayBreack.map((appointment) => {
      const type = get(appointment, 'type');
      const body = get(appointment, 'body');
      if (type === 'timeSeparator') {
        return <DateSeparator key={body} value={body} />;
      }
      const id = get(appointment, 'id');
      const startedAt = get(appointment, 'started_at');
      const patient = get(appointment, 'patient') || {};
      const provider = get(appointment, 'provider') || {};
      const status = get(appointment, 'status', '');
      return (
        <AppointmentsPreview
          key={id}
          id={id}
          startedAt={startedAt}
          provider={provider}
          patient={patient}
          status={status}
        />
      );
    });
  }, [appointments]);

  return parsedAppointments;
});
