import debounce from 'lodash/debounce';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CloseIcon from 'common/CloseIcon';
import { UppercaseText } from 'common/texts';
import { useProvidersListFilters, useSetProvidersListFilters } from 'hooks/useProviders';

export default React.memo(function SearchInput() {
  const search = useProvidersListFilters((current) => get(current, 'search', ''), []);
  const setSearch = useSetProvidersListFilters((prev, search) => ({ ...prev, search }), []);
  const [localSearch, setLocalSearch] = React.useState(search);
  const isMounted = React.useRef(false);

  const debounced = React.useRef(
    debounce((searchText) => {
      setSearch(searchText);
    }, 500),
  );

  React.useEffect(() => {
    isMounted.current = true;
    debounced.current(localSearch);
    return () => (isMounted.current = false);
  }, [localSearch]);

  const setHandler = React.useCallback(
    (e) => {
      setLocalSearch(e.target.value);
    },
    [setLocalSearch],
  );

  const clearHandler = React.useCallback(() => {
    setSearch('');
    setLocalSearch('');
  }, [setSearch, setLocalSearch]);

  return (
    <div>
      <UppercaseText className="g-mb-10">Search</UppercaseText>
      <StyledWrapper>
        <input
          className="input"
          placeholder="Enter the name of the provider or patient"
          value={localSearch}
          onChange={setHandler}
        />
        {localSearch && <CloseIcon className="close" closeHandler={clearHandler} />}
      </StyledWrapper>
    </div>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  width: 27rem;
  .input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    line-height: 3rem;
    padding: 0.8rem 1.6rem;
    border: solid 1px #e9ebef;
    border-radius: 2px;
    outline: none;
    &::placeholder {
      font-size: 1.2rem;
      font-weight: 300;
    }
    &:hover {
      border: solid 1px #ccc;
    }
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: 0.5rem;
    bottom: 0;
    margin: auto;
  }
`;
