import React, { FC, useCallback, useMemo } from 'react';

import { ActivitySection } from './sections/ActivitySection/ActivitySection';
import { DietSection } from './sections/DietSection/DietSection';
import { LabsSection } from './sections/LabsSection/LabsSection';
import { MedicationsSection } from './sections/MedicationsSection/MedicationsSection';
import { SummarySection } from './sections/SummarySection/SummarySection';
import { PatientDetails, ProviderDetails } from './sections/SummarySection/SummarySectionView';
import BackButton from '../../../../common/plate/BackButton';
import { DETAILS, PATIENTS, TREATMENT_PLANS } from '../../../../constants/routes';
import {
  TaskType,
  TaskTypeName,
  TreatmentPlanType,
} from '../../../../requests/graphql/my-health/queries/getTreatmentPlan';

type TreatmentPlanViewProps = {
  plan?: Partial<TreatmentPlanType>;
  patientId?: string;
  provider?: ProviderDetails;
  patient: PatientDetails;
  loading?: boolean;
};

export const TreatmentPlanView: FC<TreatmentPlanViewProps> = ({
  plan,
  loading,
  patientId,
  patient,
  provider,
}) => {
  const filterTasks = useCallback(
    <N extends TaskTypeName>(name: N) =>
      () =>
        (plan?.tasks || []).filter((t) => t.type === name) as TaskType<N>[],
    [plan?.tasks],
  );

  const dietTasks = useMemo(filterTasks('diet'), [plan?.tasks]);
  const labsTasks = useMemo(filterTasks('labs'), [plan?.tasks]);
  const medicationsTasks = useMemo(filterTasks('medications'), [plan?.tasks]);
  const activityTasks = useMemo(filterTasks('activity'), [plan?.tasks]);
  return (
    <div className="bg-alabaster px-6 pb-6 m-4 rounded-main shadow-row">
      <div className="pt-4">
        <BackButton href={`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`} />
      </div>
      <div className="flex justify-center">
        <div className="flex-1">
          <div className="">
            <SummarySection
              title={plan?.title}
              createdAt={plan?.createdAt}
              provider={plan?.createdBy?.profile || provider}
              patient={patient}
              isPreview={true}
              loading={loading}
              summary={plan?.summary}
            />
            {!loading && dietTasks.length > 0 && <DietSection isPreview={true} tasks={dietTasks} />}
            {!loading && medicationsTasks.length > 0 && (
              <MedicationsSection isPreview={true} tasks={medicationsTasks} />
            )}
            {!loading && labsTasks.length > 0 && <LabsSection isPreview={true} tasks={labsTasks} />}
            {!loading && activityTasks.length > 0 && (
              <ActivitySection isPreview={true} tasks={activityTasks} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
