import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PRE_ASSESSMENT } from 'constants/quiz-types';
import { PATIENTS, DETAILS, QUESTIONNAIRES } from 'constants/routes';
import {
  questionnairesContext,
  useSetQuizzesData,
  useSetQuiz,
} from 'hooks/patient/useQuestionnaires';
import { useSetOverlayLoading } from 'hooks/useUtils';
import request from 'utils/request';

export function useFetchQuizesData() {
  const { patientId } = useParams();
  const setQuizzesData = useSetQuizzesData((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/patients/${patientId}/questionnaires`,
    })
      .then(({ data: { data } }) => {
        setQuizzesData(data);
      })
      .catch((error) => console.info(error));
  }, [patientId, setQuizzesData]);
}

export function useCheckQuizAvailability() {
  const { patientId } = useParams();
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    (id) => {
      setOverlayLoading(true);
      return request({
        method: 'get',
        url: `/patients/${patientId}/json-forms/${id}/check`,
      })
        .then(({ data: { message } }) => {
          return message;
        })
        .catch((error) => console.info(error))
        .finally(() => setOverlayLoading(false));
    },
    [patientId, setOverlayLoading],
  );
}

export function useFetchQuiz() {
  const { patientId } = useParams();
  const history = useHistory();
  const setQuiz = useSetQuiz((prev, next) => next);
  return React.useCallback(
    (quizId) => {
      return request({
        method: 'get',
        url: `/patients/${patientId}/json-forms/${quizId}`,
      })
        .then(
          ({
            data: {
              data: { form },
            },
          }) => {
            setQuiz({
              ...form,
              prevContent: JSON.parse(form.content),
              content: JSON.parse(form.content),
            });
            return { ...form, content: JSON.parse(form.content) };
          },
        )
        .catch((error) => {
          console.info(error);
          if (error.response.status === 403) {
            history.push(
              `/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${PRE_ASSESSMENT}`,
            );
          }
        });
    },
    [patientId, setQuiz, history],
  );
}

export function useSaveQuiz() {
  const { patientId } = useParams();
  const { quiz, quizStep } = React.useContext(questionnairesContext);
  const setPrevQuiz = useSetQuiz((prev) => ({
    ...prev,
    prevContent: JSON.parse(JSON.stringify(prev.content)),
  }));
  const setOverlayLoading = useSetOverlayLoading((prev, next) => next);
  return React.useCallback(
    ({ quizId }) => {
      const answer = JSON.stringify(quiz.value.content);
      const lastStep = quiz.value.step;
      const step = quizStep.value;
      setOverlayLoading(true);
      return request({
        method: 'post',
        url: `/patients/${patientId}/json-forms/${quizId}`,
        data: {
          answer,
          step: step > lastStep ? step : lastStep,
        },
      })
        .then(() => {
          setPrevQuiz();
          return true;
        })
        .catch((error) => console.info(error))
        .finally(() => setOverlayLoading(false));
    },
    [patientId, quiz, quizStep, setPrevQuiz, setOverlayLoading],
  );
}
