import React, { useState } from 'react';
import styled from 'styled-components';

import { FlexLine } from '../../../common/plate/styles';
import { BoldBigText } from '../../../common/texts';
import { useSetProgramForm } from '../../../hooks/usePrograms';
import getResponsibilityIconComponent from '../../../utils/getResponsibilityIconComponent';
import request from '../../../utils/request';
import { Button } from '../../ui2.0/Button';

export default React.memo(function ProgramVisits() {
  const setBilled = useSetProgramForm(
    (prev, next) => ({ ...prev, qualification_visits: next }),
    [],
  );

  const [visits, setVisits] = useState(null);

  React.useEffect(() => {
    request({
      method: 'get',
      url: '/options/qualifications',
    })
      .then(({ data }) => {
        const updatedData = data.map((data) => {
          return { ...data, amount: 0, id: data.value }; // Spread the existing object and add the new field with its value
        });
        setVisits(updatedData);
      })
      .catch((error) => {
        console.info(error);
      });
  }, []);

  React.useEffect(() => {
    setBilled(visits);
  }, [visits]);

  const changeVisitAmountById = React.useCallback(
    (id, increment = true) => {
      setVisits((currentVisits) => {
        return currentVisits.map((visit) => {
          if (visit.value === id) {
            const newAmount = increment ? visit.amount + 1 : visit.amount - 1;
            return { ...visit, amount: Math.max(0, newAmount) };
          }
          return visit;
        });
      });
    },
    [setVisits],
  );

  const parsedVisits = React.useMemo(() => {
    console.info(visits);
    if (visits === null) {
      return <div>Loading...</div>;
    }
    return visits.map((visit) => {
      const IconComponent = getResponsibilityIconComponent(visit.icon);
      return (
        <FlexLine
          key={visit.icon}
          className="flex w-full items-center gap-[1rem] pt-[3rem] pb-[3rem] pr-[10rem] first:border-t-2 border-b-2"
        >
          <div className="g-d-flex g-justify-center min-w-[7rem]">
            <IconComponent className="min-w-16" />
          </div>

          <StyledP>{visit.label}</StyledP>

          <StyledButton
            className="ml-auto"
            onClick={() => changeVisitAmountById(visit.value, false)}
          >
            -
          </StyledButton>
          <StyledPNum>{visit.amount}</StyledPNum>
          <StyledButton onClick={() => changeVisitAmountById(visit.value)}>+</StyledButton>
        </FlexLine>
      );
    });
  });

  return (
    <>
      <BoldBigText className="g-mt-20 g-mb-20 pt-[5rem]">Available Visits</BoldBigText>
      <div>{parsedVisits}</div>
    </>
  );
});

const StyledP = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
`;
const StyledPNum = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3rem;
  width: 25px;
  display: flex;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  min-width: 36px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #e9ebef;
  background: #f9fafc;
  color: #000;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #e9ebef;
  }
`;
