export const ADMIN = 'admin';
export const GROUP = 'group';
export const PRIVATE = 'private';

export const TIME_SEPARATOR = 'time-separator';
export const OWN_MESSAGE = 'own-message';
export const OPPONENT_MESSAGE = 'opponent-message';
export const SYSTEM_MESSAGE = 'system-message';

export const WELCOME_MESSAGE = 'welcome-message';
export const PATIENT_FEELING = 'patient-feeling';
export const PATIENT_FEELING_GOOD = 'patient-feeling-good';
export const PATIENT_FEELING_NEUTRAL = 'patient-feeling-neutral';
export const PATIENT_FEELING_SICK = 'patient-feeling-sick';

export const JOIN_TO_CHANNEL = 'join-to-channel';
export const LEFT_FROM_CHANNEL = 'left-from-channel';

export const JOIN_APPOINTMENT = 'join-appointment';
export const FEEDBACK_MESSAGE = 'feedback-message';

export const PROMOTER_SCORE = 'promoter-score';
export const REPLY_PROMOTER_SCORE = 'reply-promoter-score';
export const FIRST_APPOINTMENT_FEEDBACK = 'first-appointment-feedback';
export const LAST_APPOINTMENT_FEEDBACK = 'last-appointment-feedback';

export const CONSENT_FORM_COMPLETED = 'consent_form_completed';

export const SIX_MONTH_AFTER_PROGRAM_WAS_STARTED = 'six_month_after_program_was_started';
