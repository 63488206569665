import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/close-modal-icon.svg';

interface IconButtonProps {
  onClick: () => void;
  text: string;
}

const IconButton: React.FC<IconButtonProps> = ({ onClick, text }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center p-2 justify-center hover:bg-slate-50 rounded-full focus:outline-none focus:shadow-outline"
    >
      <CloseIcon />
      <span>{text}</span>
    </button>
  );
};

export default IconButton;
