import React from 'react';
import styled from 'styled-components';

import ChatIconComponent from './ChatIconComponent';
import MainVideoComponentLoader from './MainVideoComponentLoader';
import SubVideoComponentLoader from './SubVideoComponentLoader';
import VideoControls from './VideoControls';

export default React.memo(function VideoComponent({ placeholder, setIsChatHidden }) {
  return (
    <StyledWrapper id="video-wrapper">
      <ChatIconComponent setIsChatHidden={setIsChatHidden} />
      <MainVideoComponentLoader placeholder={placeholder} />
      <SubVideoComponentLoader />
      <VideoControls />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  position: relative;
  background: #242424;
  flex-grow: 1;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    z-index: 0;
    min-height: 100%;
  }
  .video {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 45rem;
    transform: scaleX(-1) !important;
    @media screen and (max-width: 480px) {
      max-height: initial;
    }
  }
  .video-placeholder {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 10rem;
    background: #6c6a6a;
    z-index: 1;
  }
  .sub-video {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-height: 100%;
    z-index: 1;
    transform: scaleX(-1) !important;
  }
  .name {
    position: absolute;
    left: 1.5rem;
    bottom: 0.5rem;
    padding: 0.2rem 1.5rem;
    background: rgba(0, 0, 0, 0.67);
    color: #fff;
    font-size: 1.3rem;
    line-height: 2.2rem;
    z-index: 2;
  }
  .not-connected {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 600;
    z-index: 0;
    @media screen and (max-width: 480px) {
      transform: translate(0, -4rem);
    }
  }
  &:-webkit-full-screen {
    width: 100%;
    .video {
      height: 100%;
      max-height: 100vh;
    }
  }
  &:-moz-full-screen {
    width: 100%;
    .video {
      height: 100%;
      max-height: 100vh;
    }
  }
  &:-ms-fullscreen {
    width: 100%;
    .video {
      height: 100%;
      max-height: 100vh;
    }
  }
  &:fullscreen {
    width: 100%;
    .video {
      height: 100%;
      max-height: 100vh;
    }
  }
`;
