import React from 'react';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { MediumText, UppercaseText } from 'common/texts';
import UserAvatar from 'common/UserAvatar';

export default React.memo(function ProviderPreview({
  id,
  isSelected,
  avatar,
  firstName,
  lastName,
  fullName,
  qualificationTitle,
  setSelectedId,
}) {
  const selectProvider = React.useCallback(() => {
    setSelectedId(id);
  }, [setSelectedId, id]);

  return (
    <StyledWrapper>
      <div className="g-d-flex">
        {isSelected ? (
          <StyledSelectedAvatar className="g-mr-15">
            <div className="check" />
          </StyledSelectedAvatar>
        ) : (
          <UserAvatar
            className="avatar g-mr-15"
            firstName={firstName}
            lastName={lastName}
            avatar={avatar}
          />
        )}

        <div>
          <MediumText>{fullName}</MediumText>
          <UppercaseText>{qualificationTitle}</UppercaseText>
        </div>
      </div>

      <LinkButton onClick={selectProvider}>Select</LinkButton>
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-bottom: solid 1px #e9ebef;
  background: #fafbfd;
  .avatar {
    width: 5.3rem;
    height: 5.3rem;
  }
`;

const StyledSelectedAvatar = styled.div`
  position: relative;
  background: #fab005;
  width: 5.3rem;
  height: 5.3rem;
  border-radius: 50%;
  .check {
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    margin: auto;
    height: 2rem;
    width: 1.3rem;
    border-bottom: solid 0.4rem #fff;
    border-right: solid 0.4rem #fff;
    border-radius: 0.2rem;
    transform: rotate(45deg);
  }
`;
