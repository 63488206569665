import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import CompanyHeader from 'components/companies/company/CompanyHeader';
import PatientSelect from 'components/companies/company/PatientSelect';
import { useSetCompany } from 'hooks/useCompanies';
import { useFetchCompany } from 'requests/companies';

import PatientsLoader from '../../components/companies/company/patients-loader';

export default function Company() {
  const fetchCompany = useFetchCompany();
  const clearCompany = useSetCompany(() => null, []);

  React.useEffect(() => {
    fetchCompany();
    return clearCompany;
    // eslint-disable-next-line
  }, [])

  return (
    <WideRowPlate>
      <CompanyHeader />

      <PlateContent>
        {/*<PlansLoader />*/}
        <PatientSelect />
        <PatientsLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
