import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { useUser } from 'hooks/useGlobalState';
import { useRemoveMember, useChangeChatOwner } from 'requests/messages';

export default React.memo(function Menu({ channelType, type, owner, member }) {
  const isOwner = useUser((current) => get(current, 'id') === owner, [owner]);
  const removeMember = useRemoveMember(channelType);
  const changeChatOwner = useChangeChatOwner(channelType);
  const ref = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOptions = React.useCallback(() => setIsOpen((current) => !current), [setIsOpen]);
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  React.useEffect(() => {
    function handleClick(e) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      closeOptions();
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [closeOptions]);

  return (
    <StyledWrapper ref={ref} className="g-ml-auto">
      <div className="handler-wrapper" onClick={toggleOptions}>
        <span className="point" />
        <span className="point" />
        <span className="point" />
      </div>
      {isOpen && (
        <div className="menu-wrapper">
          <div className="option" onClick={() => removeMember([member])}>
            Delete
          </div>
          {isOwner && type !== 'patient' && (
            <div className="option" onClick={() => changeChatOwner(member.id)}>
              Make owner
            </div>
          )}
        </div>
      )}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  position: relative;
  .handler-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 0.6rem;
    height: 2.2rem;
    cursor: pointer;
    :hover {
      .point {
        opacity: 0.5;
      }
    }
    :active {
      .point {
        opacity: 0.75;
      }
    }
    .point {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #bbc0c0;
      margin: 1px 0;
      transition: opacity 0.2s;
    }
  }
  .menu-wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0.5rem 0;
    background: #fafbfd;
    border: 1px solid #e9ebef;
    z-index: 10;
    .option {
      padding: 0.2rem 1.5rem;
      font-size: 1.2rem;
      white-space: nowrap;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    :last-child {
      bottom: 0;
      top: auto;
    }
  }
`;
