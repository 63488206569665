import React from 'react';
import styled from 'styled-components';

export default React.memo(function CustomInput({
  value,
  changeHandler,
  className = '',
  placeholder = '',
  type = '',
  onEnter,
}) {
  return (
    <StyledWrapper
      className={className}
      placeholder={placeholder}
      type={type}
      value={value}
      onKeyUp={(event) => {
        if (onEnter && event.keyCode === 13) {
          onEnter();
        }
      }}
      onChange={React.useCallback((e) => changeHandler(e.target.value), [changeHandler])}
    />
  );
});

const StyledWrapper = styled.input`
  display: block;
  width: 100%;
  border: solid 1px #979797;
  border-radius: 2px;
  font-size: 1.3rem;
  padding: 1.3rem 1.6rem 1.3rem;
  line-height: 2rem;
  outline: none;
  &::placeholder {
    font-weight: 300;
  }
  &:focus {
    box-shadow: 0 0 0 0.3rem rgba(22, 18, 63, 0.25);
  }
`;
