import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import ProvidersFilters from 'components/providers/providers/providers-filters';
import ProvidersHeader from 'components/providers/providers/ProvidersHeader';
import ProvidersLoader from 'components/providers/providers/ProvidersLoader';

export default function Providers() {
  return (
    <WideRowPlate>
      <ProvidersHeader />

      <PlateContent>
        <ProvidersFilters />

        <ProvidersLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
