import React from 'react';

import CustomButton from 'common/CustomButton';
import { useCreateCompany } from 'requests/companies';

export default function CompanySubmit({ setValidate }) {
  const [isSended, setIsSended] = React.useState(false);
  const createCompany = useCreateCompany();
  const handleCreateCompany = React.useCallback(() => {
    setValidate(true);
    createCompany(setIsSended);
  }, [setValidate, createCompany, setIsSended]);
  return (
    <div className="g-mt-20">
      <CustomButton disabled={isSended} clickHandler={handleCreateCompany}>
        Create
      </CustomButton>
    </div>
  );
}
