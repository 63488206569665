import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { useWidgetFilters, useSetWidgetFilters } from 'hooks/patient/widgets/useWidgets';

const DaySelector = React.memo(function DaySelector({ date }) {
  const isSelected = useWidgetFilters((current) => current.selectedDay === date, [date]);
  const selectDate = useSetWidgetFilters(
    (prev) => ({
      ...prev,
      selectedDay: date,
      start: moment(date).subtract(30, 'days').format('YYYY-MM-DD'),
      end: moment(date).add(7, 'days').format('YYYY-MM-DD'),
    }),
    [date],
  );
  return (
    <div className={`selector ${isSelected ? 'selected' : ''}`} onClick={selectDate}>
      {moment(date).format('D')}
    </div>
  );
});

export default React.memo(function DaysSelectors({ daysList }) {
  return (
    <StyledWrapper className="g-mt-15">
      {daysList.map((date) => {
        return <DaySelector key={date} date={date} />;
      })}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  justify-content: center;
  .selector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    margin: auto;
    border-radius: 50%;
    font-size: 1.3rem;
    font-weight: 600;
    color: #16123f;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.1rem;
    }
  }
  .selector.selected {
    background: #16123f;
    color: #fff;
  }
`;
