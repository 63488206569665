import React from 'react';

import { useMedicationsList, useSetMedicationsList } from 'hooks/patient/useMedications';
import { useFetchPatientMedicationsProgress } from 'requests/patients/medication';

import MedicationsList from './MedicationsList';
import EmptyMedications from '../common/EmptyMedications';
import MedicationsPlaceholder from '../common/MedicationsPlaceholder';

export default function MedicationsLoader() {
  const fetchPatientMedicationsProgress = useFetchPatientMedicationsProgress();
  const medicationsList = useMedicationsList();
  const cleartMedicationsList = useSetMedicationsList(() => null);

  React.useEffect(() => {
    fetchPatientMedicationsProgress();
    return cleartMedicationsList;
    // eslint-disable-next-line
  }, [])

  if (!medicationsList) {
    return <MedicationsPlaceholder />;
  }

  if (medicationsList.length === 0) {
    return <EmptyMedications />;
  }

  return <MedicationsList />;
}
