import React from 'react';

import MealCalendar from './MealCalendar';
import DetailsTemplate from '../../../../components/patients/DetailsTemplate';
import { FOOD_JOURNAL } from '../../../../constants/routes';
import { useFoodJournal } from '../../../../hooks/patient/useFoodJournal';
import { useFetchFoodJournal } from '../../../../requests/patients/food-journal';

export default function FoodJournal() {
  const fetchFoodJournal = useFetchFoodJournal();
  const foodJournal = useFoodJournal();

  React.useEffect(() => {
    fetchFoodJournal();
  }, []);

  return (
    <DetailsTemplate currentTab={FOOD_JOURNAL}>
      {foodJournal && <MealCalendar data={foodJournal} />}
    </DetailsTemplate>
  );
}
