import React from 'react';
import styled from 'styled-components';

import AppointmentsOverview from './appointments-overview';
import QuestionnairesOverview from './questionnaires-overview';

export default React.memo(function OverviewList() {
  return (
    <StyledWrapper>
      <AppointmentsOverview />
      <QuestionnairesOverview />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  column-count: 3;
  column-gap: 1rem;
  @media screen and (max-width: 1024px) {
    column-count: 2;
  }
  @media screen and (max-width: 768px) {
    column-count: 1;
  }
`;
