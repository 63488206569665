import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useBookedAppointment } from 'hooks/useAppointments';

import DataAndTime from './DataAndTime';
import DoctorInfo from './DoctorInfo';
import JoinButton from './JoinButton';
import PatientInfo from './PatientInfo';
import PatientProblems from './PatientProblems';

export default React.memo(function AppointmentInfoContent() {
  const { appointmentId } = useParams();
  const appointment = useBookedAppointment();

  const reason = get(appointment, 'reason', []);
  const date = get(appointment, 'started_at');

  return (
    <div>
      <PatientInfo appointment={appointment} />

      <PatientProblems reason={reason} />

      <DoctorInfo appointment={appointment} />

      <DataAndTime appointmentId={appointmentId} date={date} />

      <JoinButton date={date} />
    </div>
  );
});
