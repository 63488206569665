export default function eventPath(e) {
  if (!e) {
    return [];
  }
  if (e.path) {
    return e.path;
  }
  if (e.composedPath) {
    return e.composedPath();
  }
  return [];
}
