import React from 'react';

import { WideRowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { useSetCompanyPlansMembers } from 'hooks/useCompanies';
import { useFetchCompanyPlansMembers } from 'requests/companies';

import CompanyPlansHeader from '../../components/companies/company/CompanyPlansHeader';
import CompanyPlansLoader from '../../components/companies/company-plan';

export default function CompanyPlans() {
  const fetchCompanyPlanMember = useFetchCompanyPlansMembers();
  const clearCompanyPlansMembers = useSetCompanyPlansMembers(() => null, []);

  React.useEffect(() => {
    fetchCompanyPlanMember();
    return clearCompanyPlansMembers;
    // eslint-disable-next-line
  }, [])

  return (
    <WideRowPlate>
      <CompanyPlansHeader />

      <PlateContent>
        {/*<PatientSelect />*/}
        <CompanyPlansLoader />
      </PlateContent>
    </WideRowPlate>
  );
}
