import React from 'react';

import { useElementsResultMapper } from 'hooks/patient/useQuestionnaires';

import ScaleResults from './ScaleResults';

export default React.memo(function SurveyResults() {
  const parsedElements = useElementsResultMapper();
  return (
    <>
      {parsedElements}
      <ScaleResults />
    </>
  );
});
