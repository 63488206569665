import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import BackButton from 'common/plate/BackButton';
import { PlateHeader } from 'common/plate/styles';
import { DASHBOARD, COMPANIES, CREATE } from 'constants/routes';

import { useUserContext } from '../../../context/userContext';
import { permissions } from '../../../permissions';

export default function CompaniesHeader() {
  const history = useHistory();
  const { can } = useUserContext();
  const navigateToCreating = React.useCallback(() => {
    history.push(`/${COMPANIES}/${CREATE}`);
  }, [history]);
  return (
    <StyledPlateHeader>
      <BackButton href={`/${DASHBOARD}`} />
      Companies
      {can(permissions.companies.canAddNewCompany) && (
        <CustomButton className="add-new" clickHandler={navigateToCreating}>
          Add new
        </CustomButton>
      )}
    </StyledPlateHeader>
  );
}

const StyledPlateHeader = styled(PlateHeader)`
  padding: 1.5rem 2.5rem;
  .add-new {
    margin-left: auto;
    padding: 1rem 2rem;
  }
`;
