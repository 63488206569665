import React from 'react';
import { useHistory } from 'react-router-dom';

import { APPOINTMENTS, APPOINTMENT_INFO } from 'constants/routes';

export default function ViewButton({ id, status }) {
  const history = useHistory();
  if (status === 'CANCELLED') {
    return null;
  }

  return (
    <>
      <div
        onClick={() => history.push(`/${APPOINTMENTS}/${APPOINTMENT_INFO}/${id}`)}
        className="hover:bg-white cursor-pointer transition-all outline-none leading-[2.4rem] flex justify-center bg-[#f9fafc] border rounded-[2.4rem] text-[1.3rem] px-4 py-1 border-[#e9ebef] text-[#0000007f] md:mr-4"
      >
        View
      </div>
    </>
  );
}
