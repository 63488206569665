import classNames from 'clsx';
import React, { FC } from 'react';

import { BasicProps, Switch } from '../../common/Switch';

export const Filters: FC<BasicProps & { loading: boolean }> = ({
  items,
  activeItem,
  onChange,
  loading,
}) => {
  if (loading) {
    return (
      <div className="flex items-end mt-2">
        <div className="animate-pulse cursor-pointer bg-manatee h-3 w-7 font-semibold font-ambit text-1220 py-1 rounded-[.4rem]"></div>
        <div className="animate-pulse cursor-pointer bg-manatee h-3 ml-6 w-12 font-semibold font-ambit text-1220 py-1 rounded-[.4rem]"></div>
        <div className="animate-pulse cursor-pointer bg-manatee h-3 ml-6 w-9 font-semibold font-ambit text-1220 py-1 rounded-[.4rem]"></div>
        <div className="animate-pulse cursor-pointer bg-manatee h-3 ml-6 w-7 font-semibold font-ambit text-1220 py-1 rounded-[.4rem]"></div>
        <div className="animate-pulse cursor-pointer bg-manatee h-3 ml-6 w-7 font-semibold font-ambit text-1220 py-1 rounded-[.4rem]"></div>
      </div>
    );
  }
  return (
    <Switch
      className="flex"
      items={items}
      activeItem={activeItem}
      onChange={onChange}
      element={({ option, isActive, index }) => (
        <div
          className={classNames(
            'cursor-pointer font-semibold font-ambit text-1220 py-1 w-fit rounded-[.4rem]',
            {
              'text-main': isActive,
              'text-manatee': !isActive,
              'ml-6': index !== 0,
            },
          )}
        >
          {option.label}
        </div>
      )}
    />
  );
};
