import React from 'react';
import { useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { RowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import TabsSwitcher from 'common/TabsSwitcher';
import {
  PROVIDERS,
  SUMMARY,
  INFORMATION,
  QUALIFICATION,
  AVAILABILITY,
  REFERRAL,
  ADMIN_NOTES,
} from 'constants/routes';

/**
 *
 * @param {*} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.currentTab
 * @returns
 */
export default function ProviderTemplate({ children, currentTab }) {
  const { providerId } = useParams();

  const tabsConfig = React.useMemo(() => {
    return [
      { id: SUMMARY, label: 'Summary', href: `/${PROVIDERS}/${providerId}/${SUMMARY}` },
      { id: INFORMATION, label: 'Information', href: `/${PROVIDERS}/${providerId}/${INFORMATION}` },
      {
        id: QUALIFICATION,
        label: 'Qualification',
        href: `/${PROVIDERS}/${providerId}/${QUALIFICATION}`,
      },
      {
        id: AVAILABILITY,
        label: 'Availability',
        href: `/${PROVIDERS}/${providerId}/${AVAILABILITY}`,
      },
      { id: REFERRAL, label: 'Referral', href: `/${PROVIDERS}/${providerId}/${REFERRAL}` },
      { id: ADMIN_NOTES, label: 'Admin notes', href: `/${PROVIDERS}/${providerId}/${ADMIN_NOTES}` },
    ];
  }, [providerId]);

  return (
    <RowPlate>
      <PlateHeader>
        <BackButton href={`/${PROVIDERS}`} />
        Provider profile
      </PlateHeader>

      <PlateContent>
        <TabsSwitcher className="g-mt-20 g-mb-30" tabsConfig={tabsConfig} currentTab={currentTab} />

        {children}
      </PlateContent>
    </RowPlate>
  );
}
