import React from 'react';
import { BehaviorSubject } from 'rxjs';

import { createUseGlobalState, createUseSetGlobalState } from 'utils/globalStateHandlers';

export const utilsContext = React.createContext({
  requestsLoading: new BehaviorSubject(false),
  overlayLoading: new BehaviorSubject(false),
  screenWidth: new BehaviorSubject(document.body.offsetWidth),
});

const globalState = createUseGlobalState(utilsContext);
const setGlobalState = createUseSetGlobalState(utilsContext);

export const useRequestsLoading = globalState('requestsLoading');
export const useSetRequestsLoading = setGlobalState('requestsLoading');

export const useOverlayLoading = globalState('overlayLoading');
export const useSetOverlayLoading = setGlobalState('overlayLoading');

export const useScreenWidth = globalState('screenWidth');
export const useSetScreenWidth = setGlobalState('screenWidth');

export function useModalHandler() {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);
  return React.useMemo(() => [isOpen, openModal, closeModal], [isOpen, openModal, closeModal]);
}
