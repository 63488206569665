import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { useMissedFields } from 'hooks/useProfile';
import { useUpdateProfile } from 'requests/profile';

export default React.memo(function UpdateButton({ isLoading }) {
  const missedFields = useMissedFields();
  const updateProfile = useUpdateProfile();
  return (
    <StyledCustomButton
      disabled={missedFields.length !== 0}
      clickHandler={updateProfile}
      isLoading={isLoading}
    >
      Update
    </StyledCustomButton>
  );
});

const StyledCustomButton = styled(CustomButton)`
  margin-top: 3.6rem;
`;
