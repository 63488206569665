import React from 'react';
import styled from 'styled-components';

import PatientCard from 'components/patients/patients/PatientCard';
import { usePatinets } from 'hooks/usePatients';

const PatientsPlaceholder = React.memo(function PatientsPlaceholder() {
  return (
    <>
      <div className="card-placeholder" />
      <div className="card-placeholder" />
    </>
  );
});

export default function PatientsList() {
  const patinets = usePatinets();

  const content = React.useMemo(() => {
    if (!patinets) {
      return <PatientsPlaceholder />;
    }
    return patinets.map((patinet) => {
      return <PatientCard key={patinet.id} patinet={patinet} />;
    });
  }, [patinets]);

  return <StyledContent>{content}</StyledContent>;
}

const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  .card-placeholder {
    background: #fafbfd;
    padding: 1.5rem 2rem 2rem 2rem;
    border-radius: 0.8rem;
    margin: 1rem;
    width: calc(33.3% - 2rem);
    height: 24rem;
    @media screen and (max-width: 768px) {
      width: calc(50% - 2rem);
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
`;
