import React from 'react';
import styled from 'styled-components';

import InputWithLabel from 'common/InputWithLabel';
import LinkButton from 'common/LinkButton';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export default function ShareByEmail() {
  const [value, setValue] = React.useState('');

  const handleSubmit = React.useCallback(() => {
    request({
      method: 'post',
      url: '/referrer/share/email',
      data: {
        email: value,
      },
    })
      .then(() => {
        createNotification({ message: 'Email successfully sended', type: 'success' });
        setValue('');
      })
      .catch((error) => console.info(error));
  }, [value, setValue]);

  return (
    <StyledWrapper className="g-mt-20">
      <InputWithLabel
        label="Share as Email"
        placeholder="Enter email"
        value={value}
        changeHandler={setValue}
      />
      <LinkButton
        className="invite-button g-mt-auto g-ml-25"
        disabled={!value}
        onClick={handleSubmit}
      >
        Invite
      </LinkButton>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  .invite-button {
    padding: 1.1rem 2rem;
    width: 100%;
    max-width: 12rem;
  }
`;
