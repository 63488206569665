import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { useProviderAppointments } from 'hooks/useProviders';

import AppointmentsPreview from './AppointmentsPreview';

export default React.memo(function AppointmentsList() {
  const appointments = useProviderAppointments();

  const parsedAppointments = React.useMemo(() => {
    return appointments.map((appointment) => {
      const id = get(appointment, 'id');
      return <AppointmentsPreview key={id} appointment={appointment} />;
    });
  }, [appointments]);

  return <StyledWrapper>{parsedAppointments}</StyledWrapper>;
});

const StyledWrapper = styled.div`
  overflow-y: auto;
  max-height: 36rem;
`;
