import React from 'react';

export default React.memo(function VideoListener({ selectedCamera, currentStream, videoRef }) {
  React.useEffect(() => {
    // change video stream on initial step and when select another device in select
    if (selectedCamera) {
      localStorage.setItem('cameraId', selectedCamera); // store select device for appointment
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: { exact: selectedCamera } } }) // get media stream
        .then((stream) => {
          currentStream.current = stream; // save current stream for stoping on willUnmount

          videoRef.current.srcObject = stream;
          videoRef.current.play();
        });
    }
  }, [selectedCamera, currentStream, videoRef]);

  return null;
});
