import get from 'lodash/get';
import React from 'react';

import ProviderTemplate from 'components/providers/ProviderTemplate';
import { QUALIFICATION } from 'constants/routes';
import { useFetchQualifications } from 'requests/options';
import { useFetchProviderQualification, useUpdateProviderQualification } from 'requests/providers';

import CustomButton from '../../common/CustomButton';
import Speciality from '../../components/profile/qualification/speciality';
import StateLicenses from '../../components/profile/qualification/StateLicenses';
import { useLocalProfile } from '../../hooks/useProfile';
import { useDictionaryStore } from '../../stores/dictionaryStore';
import { useProviderQualificationsStore } from '../../stores/provider/qualificationStores';

export default function ProviderQualification() {
  const qualificationsOptions = useDictionaryStore((s) => s.qualificationsOptions);
  const providerQualifications = useProviderQualificationsStore((s) => s.providerQualifications);
  const setProviderQualifications = useProviderQualificationsStore(
    (s) => s.setProviderQualifications,
  );
  const setQualificationIds = useProviderQualificationsStore((s) => s.setQualificationIds);
  const updateQualification = useUpdateProviderQualification();

  const fetchQualifications = useFetchQualifications();
  const fetchProviderQualification = useFetchProviderQualification();

  const country = useLocalProfile((current) => get(current, 'country'));
  const qualificationIds = get(providerQualifications, 'qualifications', []);
  const stateLicenses = get(providerQualifications, 'state_licenses', []);

  const changeTitle = (qualification_title: string) =>
    setProviderQualifications({ qualification_title });
  const changeLicenses = (state_licenses: string[]) =>
    setProviderQualifications({ state_licenses });

  React.useEffect(() => {
    fetchQualifications();
    fetchProviderQualification();

    return () => {
      setProviderQualifications(null);
    };
  }, []);

  const isLoading = React.useMemo(() => {
    return !qualificationsOptions.length || !providerQualifications;
  }, [qualificationsOptions, providerQualifications]);

  return (
    <ProviderTemplate currentTab={QUALIFICATION}>
      <Speciality
        label="Specialization"
        setQualificationIds={(q) => setQualificationIds(qualificationsOptions, q)}
        qualifications={qualificationsOptions}
        qualificationIds={qualificationIds}
        onTitleChange={changeTitle}
        title={providerQualifications?.qualification_title || ''}
        isLoading={isLoading}
      />
      <StateLicenses
        isLoading={isLoading}
        country={country}
        onChangeLicense={changeLicenses}
        qualificationIds={qualificationIds}
        stateLicenses={stateLicenses}
      />
      <CustomButton className="mt-14" clickHandler={updateQualification} isLoading={isLoading}>
        Update
      </CustomButton>
    </ProviderTemplate>
  );
}
