import React from 'react';
import styled from 'styled-components';

import { HintText } from 'common/texts';

export default function PrescriptionInfo({ label = '', value = '' }) {
  return (
    <StyledWrapper className="col g-d-flex g-mb-10">
      <HintText className="label">{label}:</HintText>
      <p className="value g-ml-20">{value}</p>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  &:nth-of-type(n + 2) {
    margin-left: 3rem;
    @media screen and (max-width: 1024px) {
      margin-left: 2rem;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
  .label {
    text-transform: uppercase;
  }
  .value {
    text-transform: uppercase;
    font-size: 1.5rem;
  }
`;
