import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { useWidget } from 'hooks/patient/widgets/useWidgets';
import getBoundaryValues from 'utils/widgets/getBoundaryValues';

import BeatsPerMinute from './BeatsPerMinute';

export default function PulseData() {
  const pulseBoundary = useWidget((current) => {
    const data = get(current, 'data');
    return getBoundaryValues(data, 'pulse');
  });

  return (
    <StyledWrapper className="g-d-flex g-fw-w">
      <BeatsPerMinute className="col" label="Avg HR" value={pulseBoundary.avg} />
      <BeatsPerMinute className="col" label="High" value={pulseBoundary.max} />
      <BeatsPerMinute className="col" label="Low" value={pulseBoundary.min} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-bottom: 6rem;
  .col {
    max-width: 33%;
    margin-right: auto;
    margin-left: auto;
  }
`;
