import classNames from 'clsx';
import React, { FC, useCallback, JSX } from 'react';

import { ReactComponent as ActivityIcon } from 'assets/activity.svg';
import { ReactComponent as DietIcon } from 'assets/diet.svg';
import { ReactComponent as GoalsIcon } from 'assets/goals.svg';
import { ReactComponent as LabsIcon } from 'assets/labs.svg';
import { ReactComponent as MedicationsIcon } from 'assets/medications.svg';
import { ReactComponent as SupplementsIcon } from 'assets/supplements.svg';

import { TaskTypeName } from '../../../../requests/graphql/my-health/queries/getTreatmentPlan';

type TasksSticksProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  onSelectTaskItem: (val: TaskTypeName) => void;
};

type TaskItemProps = {
  label: string;
  disabled?: boolean;
  value: TaskTypeName;
  icon: JSX.Element;
  onSelect: (val: TaskTypeName) => void;
};

export const TaskItem: FC<TaskItemProps> = ({ label, value, icon, onSelect, disabled }) => {
  const selectItem = useCallback(() => {
    onSelect(value);
  }, [value]);

  return (
    <div
      className={classNames(
        'flex items-center justify-between p-4 bg-white rounded-main border my-2',
        {
          'opacity-50': disabled,
        },
      )}
    >
      <div className="flex items-center">
        <div className="">{icon}</div>
        <div className="text-1622 font-semibold ml-3">{label}</div>
      </div>
      <button
        disabled={disabled}
        onClick={selectItem}
        className={classNames(
          'w-6 h-6 bg-catskillWhite rounded-full  flex justify-center items-center font-light text-manatee text-2020',
          {
            'cursor-pointer': !disabled,
          },
        )}
      >
        +
      </button>
    </div>
  );
};

export const TasksSticks: FC<TasksSticksProps> = ({ onSelectTaskItem }) => {
  return (
    <div className="">
      <div className="mt-4">
        <div className="text-1214 font-ambit font-semibold text-lavenderGray uppercase">Intake</div>
        <div className="">
          <TaskItem onSelect={onSelectTaskItem} label="Diet" value="diet" icon={<DietIcon />} />
          <TaskItem
            onSelect={onSelectTaskItem}
            label="Medications"
            value="medications"
            icon={<MedicationsIcon />}
          />
          <TaskItem
            disabled
            onSelect={onSelectTaskItem}
            label="Supplements"
            value="supplements"
            icon={<SupplementsIcon />}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-1214 font-ambit font-semibold text-lavenderGray uppercase">Labs</div>
        <div className="">
          <TaskItem onSelect={onSelectTaskItem} label="Labs" value="labs" icon={<LabsIcon />} />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-1214 font-ambit font-semibold text-lavenderGray uppercase">
          Activity
        </div>
        <div className="">
          <TaskItem
            onSelect={onSelectTaskItem}
            label="Activity"
            value="activity"
            icon={<ActivityIcon />}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="text-1214 font-ambit font-semibold text-lavenderGray uppercase">Goals</div>
        <div className="">
          <TaskItem
            disabled
            onSelect={onSelectTaskItem}
            label="Goals"
            value="goals"
            icon={<GoalsIcon />}
          />
        </div>
      </div>
    </div>
  );
};
