import React from 'react';
import styled from 'styled-components';

import CustomCheckbox from 'common/CustomCheckbox';
import CustomSelect from 'common/CustomSelect';
import InputWithLabel from 'common/InputWithLabel';

export default function PrescriptionLeftCol() {
  return (
    <StyledWrapper>
      <InputWithLabel className="field" label="Drug" placeholder=" " value="" />
      <InputWithLabel
        className="field g-mt-20"
        label="Directions"
        placeholder="1 tablet(s) 1 to 2 times a day (11-2X/D)"
        value=""
      />
      <div className="fields-group g-d-flex g-justify-between g-mt-20">
        <InputWithLabel className="field" label="Dispence" placeholder=" " />
        <CustomSelect className="field" placeholder=" " />
        <CustomSelect className="field" label="Days" placeholder=" " />
        <CustomSelect className="field" label="Refills" placeholder=" " />
      </div>
      <CustomCheckbox className="g-mt-20" label="As Written" />
      <CustomCheckbox label="Substitution Aloowed" />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: 52%;
  @media screen and (max-width: 1024px) {
    width: 56%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
