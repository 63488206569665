import React from 'react';
import styled from 'styled-components';

import Tab from './Tab';

export default React.memo(function Tabs({ className = '', tabsConfig }) {
  const parsedTabsConfig = React.useMemo(() => {
    return tabsConfig.map((tab) => {
      return <Tab key={tab.id} tab={tab} />;
    });
  }, [tabsConfig]);

  return (
    <StyledWrapper className={`${className} g-d-flex g-justify-between`}>
      {parsedTabsConfig}
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  margin: 2rem 8rem 1.5rem;
  border-bottom: solid 2px #e9ebef;
  @media screen and (max-width: 480px) {
    margin: 2rem 0 1.5rem;
  }
`;
