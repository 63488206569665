import React from 'react';

import onlyPositive from 'utils/onlyPositive';
import valueToLabel from 'utils/widgets/valueToLabel';

import StatusLine from '../../common/StatusLine';

export default function ReadinessList({ data }) {
  const previousNight = onlyPositive(data.score_previous_night);
  const sleepBalance = onlyPositive(data.score_sleep_balance);
  const previousDay = onlyPositive(data.score_previous_day);
  const activityBalance = onlyPositive(data.score_activity_balance);
  const temperature = onlyPositive(data.score_temperature);
  const recoveryIndex = onlyPositive(data.score_recovery_index);

  return (
    <>
      <StatusLine
        label="Previous night"
        value={`Sleep score ${previousNight}`}
        percentageStatus={previousNight}
      />
      <StatusLine
        label="Sleep Balance"
        value={valueToLabel(sleepBalance)}
        percentageStatus={sleepBalance}
      />
      <StatusLine
        label="Previous day activity"
        value={valueToLabel(previousDay)}
        percentageStatus={previousDay}
      />
      <StatusLine
        label="Active balance"
        value={valueToLabel(activityBalance)}
        percentageStatus={activityBalance}
      />
      <StatusLine
        label="Body temperature"
        value={valueToLabel(temperature)}
        percentageStatus={temperature}
      />
      <StatusLine
        label="Recovery index"
        value={valueToLabel(recoveryIndex)}
        percentageStatus={recoveryIndex}
      />
    </>
  );
}
