import React, { FC, useCallback } from 'react';

import { ReactComponent as UploadIcon } from 'assets/upload.svg';

import { makeFiles } from '../../../ui2.0/DnDFiles';

const filesTypes = {
  PDF: 'application/pdf',
};

type AttachFileButtonProps = {
  onAttach: (file: File | File[]) => void;
  isMultiple?: boolean;
  types?: (keyof typeof filesTypes)[];
  size?: string;
};

export const AttachFileButton: FC<AttachFileButtonProps> = ({
  onAttach,
  types = ['PDF'],
  isMultiple,
  size = '10mb',
}) => {
  const accept = types?.map((type) => filesTypes[type]).join(',');

  const handleFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = makeFiles(e.target.files);
      if (files?.length) {
        onAttach(isMultiple ? files : files[0]);
      }
    }
  }, []);
  return (
    <label className="flex items-center cursor-pointer">
      <UploadIcon />
      <input
        multiple={isMultiple}
        type="file"
        onChange={handleFile}
        className="hidden"
        accept={accept}
      />
      <div className="text-main text-1420 font-ambit font-semibold ml-2">
        Attach a {types.join(', ')} file
      </div>
      <div className="text-manatee text-1420 font-ambit ml-2">({size} max)</div>
    </label>
  );
};
