import get from 'lodash/get';
import React from 'react';

import { useConsultation, useSetConsultation } from 'hooks/useAppointments';

const trackpubsToTracks = (trackMap) => {
  return Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);
};

export default React.memo(function MainVideoListeners() {
  const participant = useConsultation((current) => get(current, 'participants[0]'));

  const setMainVideoTracks = useSetConsultation(
    (prev, mainVideoTracks) => ({ ...prev, mainVideoTracks }),
    [],
  );
  const addMainVideoTracks = useSetConsultation(
    (prev, next) => ({ ...prev, mainVideoTracks: [...prev.mainVideoTracks, next] }),
    [],
  );
  const filterMainVideoTracks = useSetConsultation(
    (prev, next) => ({ ...prev, mainVideoTracks: prev.mainVideoTracks.filter((v) => v !== next) }),
    [],
  );

  const setMainAudioTracks = useSetConsultation(
    (prev, mainAudioTracks) => ({ ...prev, mainAudioTracks }),
    [],
  );
  const addMainAudioTracks = useSetConsultation(
    (prev, next) => ({ ...prev, mainAudioTracks: [...prev.mainAudioTracks, next] }),
    [],
  );
  const filterMainAudioTracks = useSetConsultation(
    (prev, next) => ({ ...prev, mainAudioTracks: prev.mainAudioTracks.filter((a) => a !== next) }),
    [],
  );

  React.useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        addMainVideoTracks(track);
      } else {
        addMainAudioTracks(track);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        filterMainVideoTracks(track);
      } else {
        filterMainAudioTracks(track);
      }
    };
    if (participant) {
      setMainVideoTracks(trackpubsToTracks(participant.videoTracks));
      setMainAudioTracks(trackpubsToTracks(participant.audioTracks));

      participant.on('trackSubscribed', trackSubscribed);
      participant.on('trackUnsubscribed', trackUnsubscribed);
    }
    // eslint-disable-next-line
  }, [participant])

  return null;
});
