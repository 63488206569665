import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButoon from 'common/CustomButton';
import LinkButton from 'common/LinkButton';
import { ENVIRONMENT_CHECK, MICROPHONE } from 'constants/routes';

import Hint from './Hint';

export default function ButtonGroup() {
  const history = useHistory();
  const [isHintOpen, setIsHintOpen] = React.useState(false);

  const navigateToMicrophoneCheck = React.useCallback(() => {
    history.push(`/${ENVIRONMENT_CHECK}/${MICROPHONE}`);
  }, [history]);

  const openHints = React.useCallback(() => {
    setIsHintOpen(true);
  }, [setIsHintOpen]);

  return (
    <>
      <div className="g-d-flex g-mt-30">
        <CustomButoon clickHandler={navigateToMicrophoneCheck}>Yes, camera is working</CustomButoon>
        <LinkButton className="g-ml-20" onClick={openHints}>
          No, camera is not working
        </LinkButton>
      </div>

      <Hint isOpen={isHintOpen} />
    </>
  );
}
