import React from 'react';

import { RegularMediumText } from 'common/texts';

export default React.memo(function EmptyAdminNotes() {
  return (
    <RegularMediumText>
      Provider currently does not have any administrative notes.
    </RegularMediumText>
  );
});
