import React from 'react';

import CustomCalendar from 'common/custom-calendar';
import { useLogsFilter, useSetLogsFilter } from 'hooks/useLogs';

export default React.memo(function FromSelect() {
  const from = useLogsFilter((current) => current.from, []);
  const setFrom = useSetLogsFilter((prev, from) => {
    const newFilter = { ...prev };
    newFilter.from = from;
    if (+newFilter.to - from < 0) {
      newFilter.to = null;
    }
    return newFilter;
  }, []);
  return (
    <CustomCalendar
      className="from"
      label="from"
      minDate={undefined}
      selectedDate={from}
      setSelectedDate={setFrom}
    />
  );
});
