import React from 'react';
import styled from 'styled-components';

export default function ReferralPlaceholder() {
  return (
    <StyledWrapper>
      <div className="placeholder" />
      <div className="placeholder" />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-top: 1.5rem;
  .placeholder {
    height: 9.6rem;
    margin-bottom: 2rem;
    border-radius: 0.8rem;
    background-color: #fafbfd;
    @media only screen and (max-width: 480px) {
      height: 14rem;
    }
  }
`;
