import React from 'react';

import { useChatChannels } from 'hooks/useMessages';

import ChannelAdded from './ChannelAdded';
import ChannelMemberLeft from './ChannelMemberLeft';
import ChannelRemoved from './ChannelRemoved';
import ChannelUpdated from './ChannelUpdated';
import CountHandler from './CountHandler';
import MessageAdded from './MessageAdded';
import MessageRemoved from './MessageRemoved';

export default React.memo(function ChatListeners() {
  const chatChannels = useChatChannels();

  if (!chatChannels) {
    return null;
  }

  return (
    <>
      <MessageAdded />
      <MessageRemoved />
      <ChannelUpdated />
      <ChannelAdded />
      <ChannelRemoved />
      <ChannelMemberLeft />
      <CountHandler />
    </>
  );
});
