import get from 'lodash/get';
import React from 'react';

import { UppercaseText, RegularText } from 'common/texts';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

import Scale from '../../../common/Scale';

export default React.memo(function ScaleResults() {
  const answers = useQuiz((current) => get(current, 'content.answers', {}));
  const labels = useQuiz((current) => get(current, 'content.withResults.lables', []));
  // const scaleCount = useQuiz((current) => get(current, 'content.withResults.scaleCount', []));
  const count = Object.values(answers)
    .map(({ value }) => value)
    .reduce(function (a, b) {
      return +a + +b;
    }, 0);

  const answersCount = Object.keys(answers).length;

  const agenda = useQuiz((current) => get(current, 'content.withResults.agenda', []));
  const parsedAgenda = React.useMemo(() => {
    return agenda.map((item, i) => {
      return <RegularText key={i}>{item}</RegularText>;
    });
  }, [agenda]);
  return (
    <>
      <UppercaseText className="g-mt-20 g-mb-10">Your score</UppercaseText>
      <Scale labels={labels} scaleCount={65} value={count - answersCount} />
      <div className="g-mt-70">{parsedAgenda}</div>
    </>
  );
});
