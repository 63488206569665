import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as HideIcon } from 'assets/hide-pass.svg';
import { ReactComponent as ShowIcon } from 'assets/show-pass.svg';
import CustomButton from 'common/CustomButton';
import InputWithLabel from 'common/InputWithLabel';
import Modal from 'common/Modal';
import createNotification from 'utils/createNotification';
import request from 'utils/request';
import validatePassword from 'utils/validatePassword';

export default function ChangePasswordModal({ closeModal }) {
  const { patientId } = useParams();
  const [isShow, setIsShow] = React.useState(false);
  const [newPass, setNewpass] = React.useState('');
  const [confirmation, setConfirmation] = React.useState('');

  const { isValid, message } = validatePassword(newPass);

  const changePassAttempt = React.useCallback(() => {
    request({
      method: 'put',
      url: '/admins/members/password/reset',
      data: {
        patient: patientId,
        password: newPass,
      },
    })
      .then(() => {
        closeModal();
        createNotification({ message: 'Password successfully updated', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [patientId, newPass, closeModal]);

  return (
    <Modal
      title="Change password"
      closeModal={closeModal}
      minWidth={48}
      maxWidth={48}
      withSeparator
    >
      <ModalContent>
        <div className="g-d-flex g-justify-between g-mt-15">
          <InputWithLabel
            className="input"
            label="New password"
            placeholder="Enter new password"
            type={isShow ? 'text' : 'password'}
            value={newPass}
            changeHandler={setNewpass}
            isRequred
            isValid={!newPass || isValid}
          />
          <div className="icon-wrapper g-d-flex g-justify-center g-align-center">
            {isShow ? (
              <HideIcon onClick={() => setIsShow(false)} />
            ) : (
              <ShowIcon onClick={() => setIsShow(true)} />
            )}
          </div>
        </div>
        {newPass && <div className="error">{message}</div>}
        <div className="g-d-flex g-justify-between g-mt-15">
          <InputWithLabel
            className="input"
            label="Confirm password"
            placeholder="Enter confirm password"
            type={isShow ? 'text' : 'password'}
            value={confirmation}
            changeHandler={setConfirmation}
            isRequred
            isValid={!confirmation || newPass === confirmation}
          />
          <div className="icon-wrapper" />
        </div>
        {confirmation && newPass !== confirmation && (
          <div className="error">Password not match</div>
        )}
        <CustomButton
          className="g-mt-20"
          disabled={!newPass || !confirmation || newPass !== confirmation}
          clickHandler={changePassAttempt}
        >
          Confirm
        </CustomButton>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .input {
    max-width: 100%;
  }
  .icon-wrapper {
    flex-shrink: 0;
    width: 4.8rem;
    height: 4.8rem;
    margin-top: auto;
    cursor: pointer;
    svg {
      transition: all 0.2s;
      :hover {
        opacity: 0.5;
      }
      :active {
        opacity: 1;
      }
    }
  }
  .error {
    color: #f86060;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
`;
