import get from 'lodash/get';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as LogoIcon } from 'assets/logo-cmpct.svg';
import { ReactComponent as Logo } from 'assets/logo-text.svg';
import IconAnchor from 'common/IconAnchor';
import IconLink from 'common/IconLink';
import {
  SETTINGS,
  TRAINING_AND_RESOURCES,
  PASSWORD,
  REFERRAL,
  FRIEND,
  LOGOUT,
  ENVIRONMENT_CHECK,
  INTRODUCTION,
  DASHBOARD,
} from 'constants/routes';
import useTranslations from 'translations';

export default withRouter(
  React.memo(function Menu({ location: { pathname } }) {
    const [isOpen, setIsOpen] = React.useState(false);

    const translations = useTranslations();
    const translatePath = 'footer';
    const settings = get(translations, `${translatePath}.settings`);
    const help = get(translations, `${translatePath}.help`);
    const refer = get(translations, `${translatePath}.refer`);
    const logout = get(translations, `${translatePath}.logout`);

    const logoutUrl = `/${LOGOUT}`;

    const closeMenu = React.useCallback(() => setIsOpen(false), [setIsOpen]);
    const openMenu = React.useCallback(() => setIsOpen(true), [setIsOpen]);
    const stopPropagation = React.useCallback((e) => e.stopPropagation(), []);

    return (
      <>
        <StyledMeny onClick={openMenu}>
          <div className="line" />
          <div className="line" />
          menu
        </StyledMeny>

        <StyledWrapper isOpen={isOpen} onClick={closeMenu}>
          <StyledContainer onClick={stopPropagation}>
            <div className="head items-center">
              <Link to={`/${DASHBOARD}`}>
                <div className="flex">
                  <LogoIcon className="w-8 mr-2" />
                  <Logo className="w-34" />
                </div>
              </Link>

              <StyledCloseButton onClick={closeMenu}>
                <div className="cross right" />
                <div className="cross left" />
              </StyledCloseButton>
            </div>

            <IconLink
              clickHandler={closeMenu}
              className="g-mt-20 mobile-link"
              value={settings}
              href={`/${SETTINGS}/${PASSWORD}`}
              isActive={pathname.includes(`/${SETTINGS}`)}
            />
            <IconLink
              clickHandler={closeMenu}
              className="mobile-link"
              value="Environment check"
              href={`/${ENVIRONMENT_CHECK}/${INTRODUCTION}`}
              isActive={pathname.includes(`/${ENVIRONMENT_CHECK}`)}
            />
            <IconLink
              clickHandler={closeMenu}
              className="mobile-link"
              value="Training and resources"
              href={`/${TRAINING_AND_RESOURCES}`}
              isActive={pathname.includes(TRAINING_AND_RESOURCES)}
            />
            <IconAnchor
              clickHandler={closeMenu}
              className="mobile-link"
              value={help}
              href="https://cibahealth.com/faq-page"
              target="_blank"
            />
            <IconLink
              clickHandler={closeMenu}
              className="mobile-link"
              value={refer}
              href={`/${REFERRAL}/${FRIEND}`}
              isActive={pathname.includes(`/${REFERRAL}`)}
            />
            <IconLink
              clickHandler={closeMenu}
              className="mobile-link"
              value={logout}
              href={logoutUrl}
              isActive={pathname.includes(logoutUrl)}
            />
          </StyledContainer>
        </StyledWrapper>
      </>
    );
  }),
);

const StyledMeny = styled.div`
  margin: auto 0;
  font-size: 1.2rem;
  line-height: 1.4rem;
  cursor: pointer;
  .line {
    height: 2px;
    background: #223232;
    margin: 2px 0;
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s;
  z-index: 15;
`;

const StyledContainer = styled.div`
  background: #fff;
  height: 100%;
  width: 33%;
  min-width: 250px;
  padding: 3rem;
  .head {
    display: flex;
    justify-content: space-between;
  }
  .mobile-link {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 480px) {
    .mobile-link {
      font-size: 2rem;
      padding: 1.5rem 0;
    }
  }
`;

const StyledCloseButton = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  .cross {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 0.3rem;
    background: #223232;
  }
  .right {
    transform: rotate(45deg);
  }
  .left {
    transform: rotate(-45deg);
  }
`;
