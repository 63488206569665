import get from 'lodash/get';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BackButton from 'common/plate/BackButton';
import { PATIENTS, DETAILS, OVERVIEW, MESSAGES } from 'constants/routes';
import { useAdminChannelData } from 'hooks/useMessages';
import { useScreenWidth } from 'hooks/useUtils';

import ChatSettings from './chat-settings';
import { StyledWrapper, StyledUserAvatar, StyledSubLogoChatIcon } from './styles';

export default React.memo(function AdminHeader({ channel }) {
  const { channelSID } = useParams();
  const history = useHistory();
  const isMobile = useScreenWidth((value) => value <= 480, []);
  const patientData = useAdminChannelData(channel);

  const id = get(patientData, 'id');
  const avatarUrl = get(patientData, 'avatar_url');
  const firstName = get(patientData, 'first_name');
  const lastName = get(patientData, 'last_name');
  const fullName = get(patientData, 'full_name');

  const ref = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOptions = React.useCallback(() => setIsOpen((current) => !current), [setIsOpen]);
  const closeOptions = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  React.useEffect(() => {
    function handleClick(e) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      closeOptions();
    }
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [closeOptions]);

  const navigateToPatient = React.useCallback(() => {
    return history.push({
      pathname: `/${PATIENTS}/${DETAILS}/${id}/${OVERVIEW}`,
      state: { referrer: `/${MESSAGES}/${channelSID}` },
    });
  }, [history, id, channelSID]);

  return (
    <StyledWrapper>
      {isMobile && <BackButton href={`/${MESSAGES}`} />}
      <StyledUserAvatar
        className="g-mr-10"
        firstName={firstName}
        lastName={lastName}
        avatar={avatarUrl}
        onClick={id && navigateToPatient}
      />
      <div ref={ref}>
        <div className="name">{fullName}</div>
        <ChatSettings
          channelType="admin"
          channel={channel}
          isOpen={isOpen}
          toggleOptions={toggleOptions}
        />
      </div>
      <div className="g-d-flex g-align-center g-ml-auto">
        <div className="sub-name g-mr-5">Support chat</div>
        <StyledSubLogoChatIcon />
      </div>
    </StyledWrapper>
  );
});
