import React from 'react';

import Biography from 'components/profile/information/Biography';
import ContactInformation from 'components/profile/information/ContactInformation';
import Hint from 'components/profile/information/Hint';
import PersonalInformation from 'components/profile/information/personal-information';
import ProfilePhoto from 'components/profile/information/profile-photo';
import UpdateButton from 'components/profile/information/UpdateButton';
import ProfileTemplate from 'components/profile/ProfileTemplate';
import { INFORMATION } from 'constants/routes';
import { useProfile, useGenders } from 'hooks/useProfile';
import { useFetchGenders, useFetchProfile } from 'requests/profile';

export default function Information() {
  const fetchProfile = useFetchProfile();
  const fetchGenders = useFetchGenders();

  const profile = useProfile();
  const genders = useGenders();

  React.useEffect(() => {
    fetchGenders();
    fetchProfile();
    // eslint-disable-next-line
  }, [])

  const isLoading = React.useMemo(() => {
    return !profile || !genders;
  }, [profile, genders]);

  return (
    <ProfileTemplate currentTab={INFORMATION}>
      <ProfilePhoto isLoading={isLoading} />
      {!isLoading && <Hint />}
      <PersonalInformation isLoading={isLoading} />
      <ContactInformation isLoading={isLoading} />
      <Biography isLoading={isLoading} />
      <UpdateButton />
    </ProfileTemplate>
  );
}
