import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

import { ReactComponent as LogoIcon } from 'assets/logo-icon.svg';
import { useOverlayLoading } from 'hooks/useUtils';

export default function OverlayLoader() {
  const overlayLoading = useOverlayLoading();
  if (!overlayLoading) {
    return null;
  }
  return ReactDOM.createPortal(
    <StyledWrapper>
      <LogoIcon className="icon" />
    </StyledWrapper>,
    document.getElementById('loader'),
  );
}

const rotateWrap = keyframes`
  0% {
    transform: perspective(500px) rotateY(0);
  }
  100% {
    transform: perspective(500px) rotateY(-360deg);
  }
`;

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(45, 49, 62, 0.25);
  z-index: 9999;
  .icon {
    display: block;
    width: 9rem;
    height: 9rem;
    animation: ${rotateWrap} 2s infinite linear;
  }
`;
