import moment from 'moment';

export const TODAY = moment().format('YYYY-MM-DD');

export const DAYS_OPTIONS = new Array(31).fill().map((item, i) => {
  const day = `${i + 1}`;
  if (day.length < 2) {
    return { value: `0${day}`, label: day };
  }
  return { value: day, label: day };
});

export const MONTH_OPTIONS = [
  { value: '01', label: 'Jan.' },
  { value: '02', label: 'Feb.' },
  { value: '03', label: 'Mar.' },
  { value: '04', label: 'Apr.' },
  { value: '05', label: 'May.' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'Aug.' },
  { value: '09', label: 'Sept.' },
  { value: '10', label: 'Oct.' },
  { value: '11', label: 'Nov.' },
  { value: '12', label: 'Dec.' },
];

const currentYear = new Date().getFullYear();

export const YEAR_OPTIONS = new Array(72)
  .fill()
  .map((item, i) => ({ value: `${currentYear - i - 18}`, label: `${currentYear - i - 18}` }));

export const PERIOD_SELECT = [
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Current week', value: 'currentWeek' },
  { label: 'Current month', value: 'currentMonth' },
  { label: 'Last week', value: 'lastWeek' },
  { label: 'Last month', value: 'lastMonth' },
  { label: 'Last 3 months', value: 'lastThreeMonths' },
  { label: 'Last year', value: 'lastYear' },
  { label: 'Next week', value: 'nextWeek' },
  { label: 'Next month', value: 'nextMonth' },
  { label: 'Next 3 months', value: 'nextThreeMonths' },
  { label: 'Next year', value: 'nextYear' },
];

const currentDate = moment();

export const PERIOD_CONFIG = {
  upcoming: {
    from: currentDate.clone().format('YYYY-MM-DD'),
    to: currentDate.clone().add(5, 'w').format('YYYY-MM-DD'),
  },
  currentWeek: {
    from: currentDate.clone().startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD'),
  },
  currentMonth: {
    from: currentDate.clone().startOf('month').format('YYYY-MM-DD'),
    to: currentDate.clone().format('YYYY-MM-DD'),
  },
  lastWeek: {
    from: currentDate.clone().add(-1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(-1, 'w').endOf('isoWeek').format('YYYY-MM-DD'),
  },
  lastMonth: {
    from: currentDate.clone().add(-1, 'M').startOf('month').format('YYYY-MM-DD'),
    to: currentDate.clone().add(-1, 'M').endOf('month').format('YYYY-MM-DD'),
  },
  lastThreeMonths: {
    from: currentDate.clone().add(-3, 'M').endOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(-1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
  },
  lastYear: {
    from: currentDate.clone().add(-1, 'y').endOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(-1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
  },
  nextWeek: {
    from: currentDate.clone().add(1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(1, 'w').endOf('isoWeek').format('YYYY-MM-DD'),
  },
  nextMonth: {
    from: currentDate.clone().add(1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(1, 'M').endOf('isoWeek').format('YYYY-MM-DD'),
  },
  nextThreeMonths: {
    from: currentDate.clone().add(1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(3, 'M').endOf('isoWeek').format('YYYY-MM-DD'),
  },
  nextYear: {
    from: currentDate.clone().add(1, 'w').startOf('isoWeek').format('YYYY-MM-DD'),
    to: currentDate.clone().add(1, 'y').endOf('isoWeek').format('YYYY-MM-DD'),
  },
};
