import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PATIENTS, DETAILS, MEDICATIONS } from 'constants/routes';
import { useSetMedicationsList, useSetMedicationsChart } from 'hooks/patient/useMedications';
import { globalContext } from 'hooks/useGlobalState';
import {
  useSetPatientMedicationsList,
  useSetPatientMedications,
  useDeleteMedicationRow,
} from 'hooks/usePatients';
import createNotification from 'utils/createNotification';
import request from 'utils/request';

export function useFetchPatientMedicationsList() {
  const { patientId } = useParams();
  const setPatientMedicationsList = useSetPatientMedicationsList((prev, next) => next);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/medications/${patientId}/lists`,
    })
      .then(
        ({
          data: {
            data: { medicationList },
          },
        }) => {
          setPatientMedicationsList(medicationList);
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setPatientMedicationsList]);
}

export function useDeletePatientMedications(id) {
  const { user } = React.useContext(globalContext);
  const fetchPatientMedicationsList = useFetchPatientMedicationsList();
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    return request({
      method: 'delete',
      url: `/medications/${providerId}/list/${id}`,
    })
      .then(() => {
        createNotification({ message: 'Medications list successfully deleted', type: 'success' });
        fetchPatientMedicationsList();
      })
      .catch((error) => console.info(error));
  }, [user, id, fetchPatientMedicationsList]);
}

export function useCreatePatientMedications() {
  const { patientId } = useParams();
  const history = useHistory();
  const { user, patientMedications } = React.useContext(globalContext);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    const patientMedicationSnap = patientMedications.value;
    patientMedicationSnap.medications = patientMedicationSnap.medications.map((medication) => {
      return {
        ...medication,
        started_at: moment(medication.started_at).format('YYYY-MM-DD'),
        ended_at: moment(medication.ended_at).format('YYYY-MM-DD'),
      };
    });
    request({
      method: 'post',
      url: `/medications/${providerId}/list`,
      data: {
        patient_id: patientId,
        ...patientMedicationSnap,
      },
    })
      .then(() => {
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`);
        createNotification({ message: 'Medications list successfully created', type: 'success' });
      })
      .catch((error) => console.info(error));
  }, [patientId, user, patientMedications, history]);
}

export function useFetchPatientMedications() {
  const { patientId, medicationsId } = useParams();
  const history = useHistory();
  const setPatientMedications = useSetPatientMedications((prev, next) => next);
  const { user } = React.useContext(globalContext);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    request({
      method: 'get',
      url: `/medications/${providerId}/list/${medicationsId}`,
    })
      .then(
        ({
          data: {
            data: { medicationList },
          },
        }) => {
          setPatientMedications(medicationList);
        },
      )
      .catch((error) => {
        console.info(error);
        history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}`);
      });
  }, [user, patientId, medicationsId, setPatientMedications, history]);
}

export function useUpdatePatientMedications() {
  const { patientId, medicationsId } = useParams();
  const { user, patientMedications } = React.useContext(globalContext);
  return React.useCallback(() => {
    const providerId = get(user, 'value.id');
    const patientMedicationSnap = patientMedications.value;
    patientMedicationSnap.medications = patientMedicationSnap.medications.map((medication) => {
      return {
        ...medication,
        started_at: moment(medication.started_at).format('YYYY-MM-DD'),
        ended_at: moment(medication.ended_at).format('YYYY-MM-DD'),
      };
    });
    return request({
      method: 'put',
      url: `/medications/${providerId}/list/${medicationsId}`,
      data: {
        patient_id: patientId,
        ...patientMedicationSnap,
      },
    })
      .then(() => {
        createNotification({ message: 'Medications list successfully updated', type: 'success' });
        return true;
      })
      .catch((error) => console.info(error));
  }, [patientId, medicationsId, user, patientMedications]);
}

export function useDeletePatientMedication(medicationId, index) {
  const { medicationsId } = useParams();
  const { user } = React.useContext(globalContext);
  const fetchPatientMedications = useFetchPatientMedications();
  const deleteMedicationRow = useDeleteMedicationRow(index);
  return React.useCallback(() => {
    if (!medicationId) {
      deleteMedicationRow();
      return true;
    }
    const providerId = get(user, 'value.id');
    return request({
      method: 'delete',
      url: `/medications/${providerId}/list/${medicationsId}/medication/${medicationId}`,
    })
      .then(() => {
        fetchPatientMedications();
      })
      .catch((error) => console.info(error));
  }, [user, deleteMedicationRow, medicationsId, medicationId, fetchPatientMedications]);
}

export function useFetchPatientMedicationsProgress() {
  const { patientId } = useParams();
  const setMedicationsList = useSetMedicationsList((prev, next) => next);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/medications/${patientId}/progress`,
    })
      .then(
        ({
          data: {
            data: { progress },
          },
        }) => {
          setMedicationsList(progress);
        },
      )
      .catch((error) => console.info(error));
  }, [patientId, setMedicationsList]);
}

export function useFetchPatientMedicationsChartProgress() {
  const { patientId } = useParams();
  const setMedicationsChart = useSetMedicationsChart((prev, next) => next);
  return React.useCallback(
    ({ from, to, medicationId }) => {
      request({
        method: 'get',
        url: `medications/${patientId}/medication-reduction`,
        params: {
          from,
          to,
          medication_id: medicationId === 'all' ? '' : medicationId,
        },
      })
        .then(
          ({
            data: {
              data: { medications },
            },
          }) => {
            setMedicationsChart(medications);
          },
        )
        .catch((error) => console.info(error));
    },
    [patientId, setMedicationsChart],
  );
}

export function useSetPatientMedicationsProgress() {
  const { patientId, medicationId } = useParams();
  const { medicationsTab } = React.useContext(globalContext);
  const setMedicationsChart = useSetMedicationsChart((prev, next) => next);
  return React.useCallback(
    ({ dosage, date }) => {
      const { from, to } = medicationsTab.value;
      return request({
        method: 'post',
        url: `medications/${patientId}/progress`,
        data: {
          medication_id: medicationId,
          dosage,
          date,
          from,
          to,
        },
      })
        .then(
          ({
            data: {
              data: { medications },
            },
          }) => {
            setMedicationsChart(medications);
          },
        )
        .catch((error) => console.info(error));
    },
    [patientId, medicationId, medicationsTab, setMedicationsChart],
  );
}
