import get from 'lodash/get';
import React from 'react';

import { useProvidersList } from 'hooks/useProviders';

import ProviderPreview from './ProviderPreview';

export default React.memo(function ProvidersList() {
  const providers = useProvidersList();

  const parsedDocuments = React.useMemo(() => {
    return providers.map((provider) => {
      const id = get(provider, 'id');
      const avatar = get(provider, 'avatar', '');
      const firstName = get(provider, 'first_name', '');
      const lastName = get(provider, 'last_name', '');
      const fullName = get(provider, 'full_name', '');
      const qualificationTitle = get(provider, 'qualification_title', '');
      const lastActivity = get(provider, 'last_activity', '');
      return (
        <ProviderPreview
          key={id}
          id={id}
          avatar={avatar}
          firstName={firstName}
          lastName={lastName}
          fullName={fullName}
          qualificationTitle={qualificationTitle}
          lastActivity={lastActivity}
        />
      );
    });
  }, [providers]);

  return parsedDocuments;
});
