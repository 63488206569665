import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { useQuiz, useSetQuizStep } from 'hooks/patient/useQuestionnaires';

export default React.memo(function NextButton({ className = '', metaPath }) {
  const nextHandler = useSetQuizStep((prev) => +prev + 1, []);

  const text = useQuiz((current) => get(current, `${metaPath}.text`, ''), [metaPath]);
  const styles = useQuiz((current) => get(current, `${metaPath}.styles`, ''), [metaPath]);

  return (
    <StyledCustomButton className={className} clickHandler={nextHandler} styles={styles}>
      {text}
    </StyledCustomButton>
  );
});

const StyledCustomButton = styled(CustomButton)`
  ${({ styles }) => styles || ''};
`;
