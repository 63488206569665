import get from 'lodash/get';
import React from 'react';

import CustomSelect from 'common/CustomSelect';
import { ALL } from 'constants/routes';
import { useProvidersListFilters, useSetProvidersListFilters } from 'hooks/useProviders';
import { useFetchQualifications } from 'requests/options';

import { useDictionaryStore } from '../../../../stores/dictionaryStore';

export default React.memo(function SpecializationSelect() {
  const qualifications = useDictionaryStore((state) => state.qualificationsOptions);
  const fetchQualifications = useFetchQualifications();
  const qualification = useProvidersListFilters((current) => get(current, 'qualification'), []);
  const setQualification = useSetProvidersListFilters(
    (prev, qualification) => ({ ...prev, qualification }),
    [],
  );

  React.useEffect(() => {
    fetchQualifications();
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (qualifications && !qualification) {
      setQualification(get(qualifications, '[0].value'));
    }
  }, [qualifications, qualification, setQualification]);

  const parsedOptions = React.useMemo(() => {
    if (!qualifications) {
      return [];
    }
    const parsedProviderResponsibilities = qualifications.map(({ value, label, type }) => {
      let parsedLabel = label;
      if (type === 'doctor') {
        parsedLabel = `${label} Doctor`;
      }
      return { label: parsedLabel, value };
    });
    parsedProviderResponsibilities.push({ label: 'All specialists', value: ALL });
    return parsedProviderResponsibilities;
  }, [qualifications]);

  const currentResponsibility = React.useMemo(() => {
    if (!parsedOptions) {
      return {};
    }
    return parsedOptions.find((item) => item.value === qualification) || {};
  }, [parsedOptions, qualification]);

  return (
    <CustomSelect
      className="g-mr-20"
      label="Qualifications"
      isLoading={!qualifications}
      options={parsedOptions}
      value={currentResponsibility.value}
      changeHandler={setQualification}
    />
  );
});
