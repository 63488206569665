import { createUseGlobalState, createUseSetGlobalState } from 'hooks/useGlobalState';

export const usePrograms = createUseGlobalState('programs');
export const useSetPrograms = createUseSetGlobalState('programs');

export const useProgram = createUseGlobalState('program');
export const useSetProgram = createUseSetGlobalState('program');

export const useLocalProgram = createUseGlobalState('localProgram');
export const useSetLocalProgram = createUseSetGlobalState('localProgram');

export const useOptions = createUseGlobalState('careOptions');
export const useSetOptions = createUseSetGlobalState('careOptions');

export const useLocalOptions = createUseGlobalState('localOptions');
export const useSetLocalOptions = createUseSetGlobalState('localOptions');

export const useVisits = createUseGlobalState('medicalVisits');
export const useSetVisits = createUseSetGlobalState('medicalVisits');

export const useLocalVisits = createUseGlobalState('localMedicalVisits');
export const useSetLocalVisits = createUseSetGlobalState('localMedicalVisits');

export const useProgramForm = createUseGlobalState('programForm');
export const useSetProgramForm = createUseSetGlobalState('programForm');
