import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';

import InvitePatientModal from './invitePatientModal';

export default function InvitePatientButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  const openModal = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const closeModal = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <>
      <StyledCustomButton clickHandler={openModal}>Invite Patient</StyledCustomButton>
      {isOpen && <InvitePatientModal closeModal={closeModal} />}
    </>
  );
}

const StyledCustomButton = styled(CustomButton)`
  padding: 1rem 2.5rem;
  margin-left: 2rem;
`;
