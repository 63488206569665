import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { DETAILS, PATIENTS, TREATMENT_PLANS } from 'constants/routes';

import { FullPlate } from '../../../common/plate/plates';
import { PageTitle } from '../../../components/Breadcrumbs';
import { PatientDetails } from '../../../components/patients/treatment-plans/TreatmentPlanEdit/sections/SummarySection/SummarySectionView';
import { TreatmentPlanEdit } from '../../../components/patients/treatment-plans/TreatmentPlanEdit/TreatmentPlanEdit';
import { usePatinetSummary, usePatinetProfile } from '../../../hooks/usePatients';
import {
  GET_TREATMENT_PLAN,
  GET_TREATMENT_PLANS,
} from '../../../requests/graphql/my-health/constants';
import {
  prepareToUpdateTreatmentPlan,
  useUpdateTreatmentPlan,
} from '../../../requests/graphql/my-health/mutations/updateTreatmentPlan';
import {
  TreatmentPlanType,
  useTreatmentPlan,
} from '../../../requests/graphql/my-health/queries/getTreatmentPlan';
import { useFetchPatinetSummary, useFetchPatinetProfile } from '../../../requests/patients';
import createNotification from '../../../utils/createNotification';
import { TreatmentPlanStatus } from '../../../zeus-graphql/my-health/zeus';

export default function TreatmentPlanEditContainer() {
  const { patientId, planId } = useParams<{ patientId: string; planId: string }>();
  const fetchPatinetSummary = useFetchPatinetSummary();
  const fetchPatinetProfile = useFetchPatinetProfile();
  const queryClient = useQueryClient();

  const history = useHistory();

  const patientSummary = usePatinetSummary();
  const patientProfile = usePatinetProfile();
  const [loading, setLoading] = useState(false);

  const { updateTreatmentPlan } = useUpdateTreatmentPlan();

  const { plan, isLoading } = useTreatmentPlan(planId);

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPatinetProfile(), fetchPatinetSummary()]).finally(() => setLoading(false));
  }, []);

  const saveDraft = useCallback(async (plan: Partial<TreatmentPlanType>) => {
    await updateTreatmentPlan({
      treatmentPlanId: plan.id,
      data: prepareToUpdateTreatmentPlan({
        summary: plan.summary as string,
        tasks: plan.tasks as [],
        title: plan.title as string,
        status: TreatmentPlanStatus.draft,
      }),
    });

    await queryClient.refetchQueries({
      predicate(query) {
        return [GET_TREATMENT_PLANS, GET_TREATMENT_PLAN].includes(query.queryKey[0] as string);
      },
    });

    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`);
    createNotification({
      type: 'success',
      message: 'Treatment plan saved as draft',
    });
  }, []);

  const saveAndPush = useCallback(async (planToPublish: Partial<TreatmentPlanType>) => {
    if (!planToPublish) {
      return;
    }
    await updateTreatmentPlan({
      treatmentPlanId: planToPublish.id,
      data: prepareToUpdateTreatmentPlan(planToPublish),
    });
    await queryClient.refetchQueries({
      predicate(query) {
        return [GET_TREATMENT_PLANS, GET_TREATMENT_PLAN].includes(query.queryKey[0] as string);
      },
    });
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/view/${planId}`);
    createNotification({
      type: 'success',
      message: 'Treatment plan saved and published',
    });
  }, []);

  const patientInfo: PatientDetails = {
    avatarUrl: patientProfile?.avatar_url,
    fName: patientProfile?.first_name,
    lName: patientProfile?.last_name,
    mrn: patientSummary?.patient.medical_record,
  };

  useEffect(() => {
    if (plan?.status === TreatmentPlanStatus.sent) {
      history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}/view/${planId}`);
    }
  }, [plan?.status]);

  return (
    <FullPlate>
      <PageTitle
        header={
          patientSummary?.patient.full_name
            ? `Edit plan for ${patientSummary?.patient.full_name}`
            : ''
        }
        crumbs={[
          {
            title: 'Treatment plans',
            url: `/${PATIENTS}/${DETAILS}/${patientId}/${TREATMENT_PLANS}`,
          },
        ]}
      />
      <TreatmentPlanEdit
        onSaveAndPush={saveAndPush}
        patient={patientInfo}
        patientId={patientId}
        loading={isLoading || loading}
        onSaveDraft={saveDraft}
        plan={plan as TreatmentPlanType}
      />
    </FullPlate>
  );
}
