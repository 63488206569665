import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import { PATIENTS, DETAILS, MEDICATIONS, CREATE } from 'constants/routes';

export default function NewMedicationsListButton() {
  const { patientId } = useParams();
  const history = useHistory();

  const navigateToCreateMedicationsList = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${CREATE}`);
  }, [history, patientId]);

  return (
    <CustomButton className="g-mt-20" clickHandler={navigateToCreateMedicationsList}>
      Add new medication list
    </CustomButton>
  );
}
