import React from 'react';

import AddTwoFaWrapper from 'components/settings/security/AddTwoFaWrapper';
import RemoveTwoFaWrapper from 'components/settings/security/RemoveTwoFaWrapper';
import SettingTemplate from 'components/settings/SettingTemplate';
import { SECURITY } from 'constants/routes';
import { Cognito } from 'libs/cognito';

const cognito = Cognito.getInstance();
export default function Security() {
  const [isTwoFaOn, setIsTwoFaOn] = React.useState(false);

  React.useEffect(() => {
    cognito.getMFAData().then((data) => {
      setIsTwoFaOn(data !== 'NOMFA');
    });
  }, []);

  const content = React.useMemo(() => {
    return isTwoFaOn ? <RemoveTwoFaWrapper /> : <AddTwoFaWrapper />;
  }, [isTwoFaOn]);

  return <SettingTemplate currentTab={SECURITY}>{content}</SettingTemplate>;
}
