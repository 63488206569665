import React from 'react';

import { ReactComponent as Arrow } from 'assets/arrow-down.svg';

export default React.memo(function NextButton({ page = 1, pagesCount = 1, navigateHandler }) {
  const navigateForward = React.useCallback(() => {
    if (page === pagesCount) {
      return;
    }
    navigateHandler(page + 1);
  }, [page, pagesCount, navigateHandler]);
  return (
    <div
      className={`pagination-button next ${page === pagesCount ? 'disabled' : ''}`}
      onClick={navigateForward}
    >
      Next
      <Arrow className="icon" />
    </div>
  );
});
