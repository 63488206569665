import _ from 'lodash';
import React from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SortImg from 'assets/sort.png';
import BackButton from 'common/plate/BackButton';
import { PlateHeader } from 'common/plate/styles';
import { DASHBOARD, PATIENTS } from 'constants/routes';
import { useSavedSearch } from 'hooks/useSavedSearch';

const sortLinks = ['Name', 'Last Appointment', 'Last Activity'];

export default React.memo(function PatientsHeader() {
  const { status } = useParams();
  const savedSearch = useSavedSearch();

  const history = useHistory();

  const params = new URLSearchParams(savedSearch);

  const sortDir = params.get('sort_dir');
  const sortBy = params.get('sort_by') || 'name';

  if (!sortBy) {
    params.set('sort_by', 'name');
  }

  return (
    <PlateHeader style={{ display: 'block' }}>
      <StyledWrapper>
        <div className="back-btn">
          <BackButton href={`/${DASHBOARD}`} />
          Patients
        </div>
        <div className={`sort ${sortDir === 'asc' ? 'asc' : ''}`}>
          <div
            onClick={() => {
              params.set('sort_dir', sortDir === 'asc' ? 'desc' : 'asc');
              history.replace({
                search: params.toString(),
              });
            }}
          >
            <StyledSort src={SortImg} alt="sort-img" />
          </div>
          <div className="dropdown">
            <div className="links">
              {sortLinks.map((sortByName) => {
                const sortName = _.snakeCase(sortByName);
                const current = sortBy === sortName;
                // eslint-disable-next-line no-nested-ternary
                const dir = current ? (sortDir === 'desc' ? 'asc' : 'desc') : 'asc';
                return (
                  <Link
                    key={sortName}
                    className={`link ${current ? 'active-link' : ''}`}
                    to={`/${PATIENTS}/${status}?sort_by=${sortName}&sort_dir=${dir}`}
                  >
                    <span className="sort-text">{sortByName}</span>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </StyledWrapper>
    </PlateHeader>
  );
});

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .back-btn {
    display: flex;
  }
  .metrics {
    padding: 1rem 2rem;
    margin: auto 1.5rem;
    white-space: nowrap;
  }
  .asc img {
    transform: scale(1, -1);
  }
  .sort:hover {
    .dropdown {
      display: block;
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    transform: translate(-82%, 0);
    padding-top: 1rem;
    z-index: 10;
    .links {
      border-radius: 0.8rem;
      padding: 1rem 0rem;
      background: #fff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      .link {
        background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.5rem 0rem;
        padding-left: 2rem;
        padding-right: 4rem;
        white-space: nowrap;
        &.active-link {
          background-color: #eeeeee;
        }
        :hover {
          text-decoration: underline;
        }
        .sort-text {
          margin-left: 1rem;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
        }
      }
    }
  }
  .dropdown:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, 1rem);
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 0.8rem solid #fff;
  }
  .info {
    margin-right: 1rem;
    cursor: pointer;
    .name {
      max-width: 20em;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.3rem;
      @media only screen and (max-width: 480px) {
        max-width: 10em;
      }
      @media only screen and (max-width: 768px) {
        max-width: 14em;
      }
    }
    .role {
      font-size: 1.2rem;
      line-height: 1rem;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }
`;

const StyledSort = styled.img`
  display: flex;
  justify-content: space-between;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  flex-shrink: 0;
  height: 18px;
  cursor: pointer;
`;
