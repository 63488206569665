import get from 'lodash/get';
import React from 'react';

import {
  PreFormatting,
  RegularMediumText,
  UppercaseText,
  RegularText,
  BoldText,
} from 'common/texts';
import DetailsTemplate from 'components/patients/DetailsTemplate';
import { DOCTOR_NOTES } from 'constants/routes';
import { usePatinetDoctorNote } from 'hooks/usePatients';
import { useFetchDoctorNote } from 'requests/patients';

const MedicalRecordNumber = React.memo(function MedicalRecordNumber() {
  const medicalRecord = usePatinetDoctorNote((current) => get(current, 'medicalRecord', ''), []);
  if (!medicalRecord) {
    return null;
  }
  return (
    <>
      <UppercaseText className="g-mt-20">medical record number</UppercaseText>
      <RegularText>{medicalRecord}</RegularText>
    </>
  );
});

const IllnessHistory = React.memo(function IllnessHistory() {
  const illnessHistory = usePatinetDoctorNote((current) => get(current, 'illnessHistory', ''), []);
  if (!illnessHistory) {
    return null;
  }
  return (
    <>
      <RegularMediumText className="g-mt-20">History of Present Illness</RegularMediumText>
      <PreFormatting>
        <RegularText>{illnessHistory}</RegularText>
      </PreFormatting>
    </>
  );
});

const ReviewOfSystems = React.memo(function ReviewOfSystems() {
  const general = usePatinetDoctorNote((current) => get(current, 'general', ''), []);
  const symptoms = usePatinetDoctorNote((current) => get(current, 'symptoms', []), []);

  const parsedGeneral = React.useMemo(() => {
    if (general) {
      return (
        <>
          <UppercaseText className="g-mt-5">General</UppercaseText>
          <RegularText>{general}</RegularText>
        </>
      );
    }
    return null;
  }, [general]);

  const parsedSymptoms = React.useMemo(() => {
    return symptoms.map(({ label, text }, i) => {
      return (
        <div key={i}>
          <BoldText className="g-mt-20">{label}</BoldText>
          <RegularText>{text}</RegularText>
        </div>
      );
    });
  }, [symptoms]);

  if (!parsedGeneral && parsedSymptoms.length === 0) {
    return null;
  }

  return (
    <>
      <RegularMediumText className="g-mt-20">Review of Systems</RegularMediumText>
      {parsedGeneral}
      {parsedSymptoms}
    </>
  );
});

const Assessment = React.memo(function Assessment() {
  const assessment = usePatinetDoctorNote((current) => get(current, 'assessment', ''), []);
  return (
    <>
      <UppercaseText className="g-mt-20">Patient assessment</UppercaseText>
      <PreFormatting>
        <RegularText className="g-mb-45">{assessment}</RegularText>
      </PreFormatting>
    </>
  );
});

export default function PatientNotePreview() {
  const fetchDoctorNote = useFetchDoctorNote();

  React.useEffect(() => {
    fetchDoctorNote();
    // eslint-disable-next-line
  }, [])

  return (
    <DetailsTemplate currentTab={DOCTOR_NOTES}>
      <RegularMediumText>Provider notes</RegularMediumText>

      <MedicalRecordNumber />

      <IllnessHistory />

      <ReviewOfSystems />

      <Assessment />
    </DetailsTemplate>
  );
}
