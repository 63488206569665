import moment from 'moment';
import React from 'react';

import AppointmentsTemplate from 'components/appointmentsProvider/AppointmentsTemplate';
import DayGrid from 'components/appointmentsProvider/day/DayGrid';
import DayHeader from 'components/appointmentsProvider/day/DayHeader';
import { DAY } from 'constants/routes';
import { useSetScheduledAppointments } from 'hooks/useAppointments';
import { useFetchScheduledAppointments } from 'requests/appointments';

export default function DayAppoitments() {
  const [date, setDate] = React.useState(moment());
  const fetchScheduledAppointments = useFetchScheduledAppointments();
  const clearScheduledAppointments = useSetScheduledAppointments(() => [], []);

  React.useEffect(() => {
    fetchScheduledAppointments({
      period: 'day',
      period_start: moment(date).format('YYYY-MM-DD'),
    });
    return clearScheduledAppointments;
    // eslint-disable-next-line
  }, [date])

  return (
    <AppointmentsTemplate currentTab={DAY}>
      <DayHeader date={date} setDate={setDate} />

      <DayGrid date={date} />
    </AppointmentsTemplate>
  );
}
