import React from 'react';

import { usePatientSymptomsList } from 'hooks/usePatients';

import SymptomsGroups from './SymptomsGroups';

export default React.memo(function PatientSymptoms() {
  const patientSymptomsList = usePatientSymptomsList();

  if (!patientSymptomsList) {
    return null;
  }

  return <SymptomsGroups />;
});
