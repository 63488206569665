import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomTextarea from 'common/CustomTextarea';
import InputWithLabel from 'common/InputWithLabel';
import { RegularBigText } from 'common/texts';
import { useLocalProfile, useSetLocalProfile, VALIDATION_RULES } from 'hooks/useProfile';

export default React.memo(function Biography({ isLoading }) {
  const summary = useLocalProfile((current) => get(current, 'summary', ''));
  const biography = useLocalProfile((current) => get(current, 'biography', ''));
  const experience = useLocalProfile((current) => get(current, 'experience', ''));
  const education = useLocalProfile((current) => get(current, 'education', ''));
  const certifications = useLocalProfile((current) => get(current, 'certifications', ''));
  const practices = useLocalProfile((current) => get(current, 'practices', ''));

  const isSummaryValid = VALIDATION_RULES['summary'](summary);
  const isBiographyValid = VALIDATION_RULES['biography'](biography);
  const isExperienceValid = VALIDATION_RULES['experience'](experience);
  const isEducationValid = VALIDATION_RULES['education'](education);
  const isCertificationsValid = VALIDATION_RULES['certifications'](certifications);
  const isPracticesValid = VALIDATION_RULES['practices'](practices);

  return (
    <div>
      <RegularBigText className="g-mt-15">Your experience</RegularBigText>

      <StyledSummary
        className="g-mt-15"
        label="Short Summary"
        placeholder="Write a short description with key facts about you. Limit is 180 symbols "
        isRequred
        isValid={isSummaryValid}
        value={summary}
        changeHandler={useSetLocalProfile((prev, summary) => ({ ...prev, summary }), [])}
        isLoading={isLoading}
      />

      <CustomTextarea
        className="g-mt-15"
        label="bio"
        placeholder="Your biography"
        isRequred
        isValid={isBiographyValid}
        value={biography}
        changeHandler={useSetLocalProfile((prev, biography) => ({ ...prev, biography }), [])}
        isLoading={isLoading}
      />

      <InputWithLabel
        className="g-mt-15"
        label="Years of Experience"
        isRequred
        isValid={isExperienceValid}
        value={experience}
        changeHandler={useSetLocalProfile((prev, experience) => ({ ...prev, experience }), [])}
      />

      <CustomTextarea
        className="g-mt-15"
        label="Education"
        placeholder="Write about your education"
        isRequred
        isValid={isEducationValid}
        value={education}
        changeHandler={useSetLocalProfile((prev, education) => ({ ...prev, education }), [])}
      />

      <CustomTextarea
        className="g-mt-15"
        label="Certifications & Licensure"
        placeholder="Write about your certficates & licenses"
        isRequred
        isValid={isCertificationsValid}
        value={certifications}
        changeHandler={useSetLocalProfile(
          (prev, certifications) => ({ ...prev, certifications }),
          [],
        )}
      />

      <CustomTextarea
        className="g-mt-15"
        label="Practices & training"
        placeholder="Write about your practices & trainings"
        isRequred
        isValid={isPracticesValid}
        value={practices}
        changeHandler={useSetLocalProfile((prev, practices) => ({ ...prev, practices }), [])}
      />
    </div>
  );
});

const StyledSummary = styled(CustomTextarea)`
  textarea {
    height: 6rem;
  }
`;
