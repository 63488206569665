import React from 'react';

import { usePatientAdminNotes } from 'hooks/usePatients';

import AdminNotePreview from './AdminNotePreview';

export default function AdminNotesList() {
  const patientAdminNotes = usePatientAdminNotes();
  return React.useMemo(() => {
    return patientAdminNotes.map((note) => {
      return <AdminNotePreview key={note.id} note={note} />;
    });
  }, [patientAdminNotes]);
}
