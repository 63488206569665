import React from 'react';

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import { usePatientAdminNotes } from 'hooks/usePatients';

import AdminNotesList from './AdminNotesList';
import EmptyAdminNotes from './EmptyAdminNotes';

export default function AdminNotesLoader() {
  const patientAdminNotes = usePatientAdminNotes();

  if (!patientAdminNotes) {
    return <ListPreviewPlaceholder />;
  }

  if (patientAdminNotes.length === 0) {
    return <EmptyAdminNotes />;
  }

  return <AdminNotesList />;
}
