import classNames from 'clsx';
import React, { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

type DropdownProps = {
  isOpen: boolean;
  className?: string;
  rootClassName?: string;
  button: React.ReactNode;
  onClose: () => void;
};

export const Dropdown: FC<DropdownProps & { children: React.ReactNode }> = ({
  isOpen,
  onClose,
  button,
  children,
  rootClassName,
  className = 'bg-white rounded-main min-w-[15.2rem] py-1 ',
}) => {
  const buttonRef = useRef<null | HTMLDivElement>(null);
  const elemRef = useRef<null | HTMLDivElement>(null);
  const { styles, attributes } = usePopper(buttonRef.current, elemRef.current, {
    placement: 'bottom-start',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onBodyClick = (event: any) => {
      if (elemRef?.current?.contains(event.target) || buttonRef?.current?.contains(event.target)) {
        return;
      }
      onClose();
    };
    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, []);

  return (
    <>
      <div ref={buttonRef} className="w-full">
        {button}
      </div>
      {ReactDOM.createPortal(
        <div className={rootClassName} ref={elemRef} style={styles.popper} {...attributes.popper}>
          <div
            className={classNames(
              'shadow-dropdown transition-all duration-300 ease-out',
              className,
              {
                block: isOpen,
                hidden: !isOpen,
              },
            )}
          >
            {children}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};
