import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import LinkButton from 'common/LinkButton';
import { CONSENT_FORM } from 'constants/quiz-types';
import { PATIENTS, DETAILS, QUESTIONNAIRES, QUIZ, EDIT } from 'constants/routes';
import { useModalHandler } from 'hooks/useUtils';
import { useCheckQuizAvailability } from 'requests/patients/questionnaires';

import NotAvailableQuizModal from './common/NotAvailableQuizModal';

export default React.memo(function SubButton({ id, type, total, step }) {
  const { patientId } = useParams();
  const history = useHistory();
  const checkQuizAvailability = useCheckQuizAvailability();
  const [isOpen, openModal, closeModal] = useModalHandler();
  const isCompleted = total === step;

  const handleClick = React.useCallback(async () => {
    const isAvailable = await checkQuizAvailability(id);
    if (!isAvailable) {
      return openModal();
    }
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${QUESTIONNAIRES}/${QUIZ}/${id}/${EDIT}`);
  }, [history, patientId, id, checkQuizAvailability, openModal]);

  if (!isCompleted || type === CONSENT_FORM) {
    return null;
  }

  return (
    <>
      <LinkButton className="g-w-100" onClick={handleClick}>
        Edit
      </LinkButton>
      {isOpen && <NotAvailableQuizModal closeModal={closeModal} />}
    </>
  );
});
