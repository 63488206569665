import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { Icon } from 'components/new/Icon/Icon';
import { PaginationProps } from 'components/new/Pagination/Pagination.types';

import styles from './Pagination.module.css';

export const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  onPageChange,
  initialPage = 1,
}) => {
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const handlePageChange = (selectedItem: { selected: number }): void => {
    const newPage = selectedItem.selected + 1; // Adjusting to one-based
    setCurrentPage(newPage);
    onPageChange({ selected: newPage });
  };

  return (
    <ReactPaginate
      renderOnZeroPageCount={null}
      previousLabel={<Icon name="arrow_back" strokeWeight="bold" />}
      nextLabel={<Icon name="arrow_forward" strokeWeight="bold" />}
      breakLabel="..."
      breakClassName="mx-2"
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handlePageChange}
      containerClassName={styles.pagination}
      pageClassName={styles.pageItem}
      pageLinkClassName={styles.pageLink}
      previousClassName={styles.previous}
      nextClassName={styles.next}
      activeClassName={styles.selected}
      disabledClassName={styles.disabled}
      forcePage={currentPage - 1} // Adjusting to zero-based for ReactPaginate
    />
  );
};
