import React from 'react';

import { FlexLine } from 'common/plate/styles';
import ChatsList from 'components/messages/ChatsList';
import InviteGroupContent from 'components/messages/invite-group-content';

export default function InviteGroup() {
  return (
    <FlexLine>
      <ChatsList withHidden />

      <InviteGroupContent />
    </FlexLine>
  );
}
