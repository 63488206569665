import React from 'react';
import styled from 'styled-components';

import { useMedicationsDayProgress } from 'hooks/patient/useMedications';

import { StyledCell } from '../styles';
import Tooltip from './Tooltip';

export default React.memo(function Bar({ time, chartOffsets }) {
  const progress = useMedicationsDayProgress(time);
  const [isLeftPosition, setIsLeftPosition] = React.useState(false);
  const tooltipEl = React.useRef(null);
  const [tooltipOffsets, setTooltipOffsets] = React.useState({
    right: 0,
    left: 0,
  });

  const setOffsets = function () {
    if (tooltipEl.current) {
      setTooltipOffsets({
        right: tooltipEl.current.getBoundingClientRect().right,
        left: tooltipEl.current.getBoundingClientRect().left,
      });
    }
  };
  const mouseOverHandler = React.useCallback(() => {
    setOffsets();
    let chartOffsetCenter = (chartOffsets.right - chartOffsets.left) / 2 + chartOffsets.left;
    let tooltipOffsetCenter =
      (tooltipOffsets.right - tooltipOffsets.left) / 2 + tooltipOffsets.left;
    if (chartOffsetCenter < tooltipOffsetCenter) {
      setIsLeftPosition(true);
    } else {
      setIsLeftPosition(false);
    }
  }, [chartOffsets, tooltipOffsets, setIsLeftPosition]);

  React.useEffect(() => {
    setOffsets();
  }, []);

  if (time.isAfter()) {
    return <StyledCell />;
  }
  return (
    <StyledWrapper progress={progress} isLeftPosition={isLeftPosition}>
      <div className="target-bar" onMouseOver={mouseOverHandler} ref={tooltipEl}>
        <div className="current-bar" />
        <div className="tooltip">
          <Tooltip time={time} />
        </div>
      </div>
    </StyledWrapper>
  );
});

const StyledWrapper = styled(StyledCell)`
  display: flex;
  justify-content: center;
  height: 100%;
  .target-bar {
    position: relative;
    display: flex;
    width: 1.6rem;
    height: 100%;
    background: #dce2e8;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    cursor: pointer;
    /* @media screen and (max-width: 768px) {
      position: static;
    } */
    .tooltip {
      z-index: 2;
      position: absolute;
      top: 0;
      ${({ isLeftPosition }) => (isLeftPosition ? 'right: 4.5rem;' : 'left: 100%;')}
      display: none;
      width: 33.5rem;
      max-height: 21rem;
      padding: 1.5rem;
      background: #fff;
      box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.18);
      transform: translate(1.5rem, 1.5rem);
      overflow-x: auto;
      /* @media screen and (max-width: 768px) {
        right: auto;
        left: 50%;
        width: 28rem;
        transform: translate(-50%);
      } */
    }
    :hover {
      .tooltip {
        display: block;
      }
    }
    .current-bar {
      width: 1.6rem;
      height: ${({ progress }) => progress}%;
      margin-top: auto;
      background: #96f500;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      transition: all 0.2s;
    }
  }
`;
