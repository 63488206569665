import React from 'react';

import { SquarePlate } from 'common/plate/plates';
import { PlateHeader } from 'common/plate/styles';
import { useBookedAppointments, useSetBookedAppointments } from 'hooks/useAppointments';
import { useFetchBookedAppointments } from 'requests/appointments';

import AppointmentsList from './AppointmentsList';
import EmptyUpcomingList from './EmptyUpcomingList';

export default React.memo(function UpcomingList({ className = '' }) {
  const bookedAppointments = useBookedAppointments();
  const clearBookedAppointments = useSetBookedAppointments(() => null, []);
  const fetchBookedAppointments = useFetchBookedAppointments();

  React.useEffect(() => {
    fetchBookedAppointments({ type: 'upcoming' });
    return clearBookedAppointments;
    // eslint-disable-next-line
  }, [])

  if (bookedAppointments && bookedAppointments.length === 0) {
    return <EmptyUpcomingList className={className} />;
  }

  return (
    <SquarePlate className={`g-ml-10 g-mr-10 ${className}`}>
      <PlateHeader>Upcoming</PlateHeader>

      <AppointmentsList />
    </SquarePlate>
  );
});
