import { $, GraphQLTypes } from 'zeus-graphql/my-health/zeus';

import { GQLArgs, useMutation } from '../client';
import { DELETE_TREATMENT_PLAN } from '../constants';

type Vars = {
  treatmentPlanId: string;
};
type MutationResponse = Record<
  'deleteTreatmentPlan',
  GraphQLTypes['TreatmentPlanDeletedType'] | GraphQLTypes['TreatmentIsSentError']
>;

type Args = GQLArgs<MutationResponse>;

export const useDeleteTreatmentPlan = (args?: Args) => {
  const instance = useMutation(
    [DELETE_TREATMENT_PLAN],
    {
      deleteTreatmentPlan: [
        {
          treatmentPlanId: $`treatmentPlanId`,
        },
        {
          '...on TreatmentIsSentError': {
            __typename: true,
            message: true,
          },
          '...on TreatmentPlanDeletedType': {
            deleted: true,
          },
        },
      ],
    },
    {
      operationName: DELETE_TREATMENT_PLAN,
    },
    args,
  );

  const deleteTreatmentPlan = instance.mutateAsync as (args: Vars) => Promise<MutationResponse>;

  return { ...instance, deleteTreatmentPlan };
};
