import get from 'lodash/get';
import React from 'react';
import { useParams } from 'react-router-dom';

import ChatHeader from 'common/chat/chat-header';
import ChatBody from 'common/chat/ChatBody';
import ChatInput from 'common/chat/ChatInput';
import ChatWrongHeader from 'common/chat/ChatWrongHeader';
import ChatHeaderPlaceholder from 'common/loaders/ChatHeaderPlaceholder';
import { ColumnWidePlate } from 'common/plate/plates';
import { MESSAGES } from 'constants/routes';
import { useChatTokenData, useChatChannels } from 'hooks/useMessages';
import { useScreenWidth } from 'hooks/useUtils';
import { parseMessages, addMessage, updateMessages, removeMessage } from 'utils/messages';

const ChatContent = React.memo(function ChatContent({ channel }) {
  const channelClass = channel.channelClass;
  const userIdentity = useChatTokenData((current) => get(current, 'chat_identity'), []);
  const [messages, setMessages] = React.useState(null);
  const [deliveredMessage] = React.useState(null);

  React.useEffect(() => {
    channelClass.getMessages().then(({ items }) => setMessages(parseMessages(items)));

    channelClass.setAllMessagesConsumed();

    function handleAddMessage(newMessage) {
      setMessages((current) => addMessage(current, newMessage));
      channelClass.setAllMessagesConsumed();
    }

    function handleUpdated({ message }) {
      setMessages((current) => updateMessages(current, message));
    }

    function handleRemoveMessage(removedMessage) {
      setMessages((current) => removeMessage(current, removedMessage));
      channelClass.setAllMessagesConsumed();
    }

    channelClass.on('messageAdded', handleAddMessage);
    channelClass.on('messageUpdated', handleUpdated);
    channelClass.on('messageRemoved', handleRemoveMessage);
    return () => {
      setMessages([]);
      channelClass.removeListener('messageAdded', handleAddMessage);
      channelClass.removeListener('messageUpdated', handleUpdated);
      channelClass.removeListener('messageRemoved', handleRemoveMessage);
    };
  }, [channelClass]);

  React.useEffect(() => {
    return () => setMessages([]);
  }, [setMessages]);

  return (
    <ColumnWidePlate>
      <ChatHeader />

      {messages && (
        <ChatBody
          channel={channel}
          userIdentity={userIdentity}
          messages={messages}
          deliveredMessage={deliveredMessage}
          lastConsumedMessageIndex={channel.lastConsumedMessageIndex}
        />
      )}

      <ChatInput channel={channel} />
    </ColumnWidePlate>
  );
});

export default React.memo(function ChatContentLoader() {
  const { channelSID } = useParams();
  const isMobile = useScreenWidth((value) => value <= 480, []);
  const isLoading = useChatChannels((current) => !current);
  const channel = useChatChannels(
    (current) => current && current.find(({ sid }) => sid === channelSID),
    [channelSID],
  );

  if (!channelSID) {
    return null;
  }

  if (isLoading) {
    return (
      <ColumnWidePlate>
        <ChatHeaderPlaceholder href={isMobile && `/${MESSAGES}`} />
      </ColumnWidePlate>
    );
  }

  if (!channel) {
    return (
      <ColumnWidePlate>
        <ChatWrongHeader href={isMobile && `/${MESSAGES}`} />
      </ColumnWidePlate>
    );
  }

  return <ChatContent channel={channel} />;
});
