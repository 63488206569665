import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import { MediumText, HintText, RegularText } from 'common/texts';
import { useReferral } from 'hooks/useReferral';

export default function Stats() {
  const activeUsers = useReferral((current) => get(current, 'meta.active_users', ''));
  const receivedAppointment = useReferral((current) =>
    get(current, 'meta.received_appointment', ''),
  );
  return (
    <StyledWrapper>
      <MediumText>Referral achievements</MediumText>

      <div className="properties-container g-mt-10">
        <HintText className="property">Active users</HintText>
        <RegularText className="value">{activeUsers}</RegularText>
        <HintText className="property">Received appointments</HintText>
        <RegularText className="value">{receivedAppointment}</RegularText>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .properties-container {
    display: inline-grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.5rem 2rem;
  }
`;
