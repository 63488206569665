import React, { FC } from 'react';
import ReactDOM from 'react-dom';

export const TitleContainer: FC = () => {
  return (
    <div>
      <span
        className="h-[3.4rem] text-2020 font-bold font-montserrat"
        id="header-title-container"
      />
    </div>
  );
};

interface TitleProps {
  pageTitle?: React.ReactNode;
}

export const Title: FC<TitleProps> = ({ pageTitle = '' }) => {
  const node = document.getElementById('header-title-container');

  return node ? ReactDOM.createPortal(pageTitle, node) : <></>;
};
