// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor as CKEditorLib } from '@ckeditor/ckeditor5-react';
import classNames from 'clsx';
import React, { FC, useRef } from 'react';

const config = {
  mediaEmbed: {
    previewsInData: true,
  },
  removePlugins: ['EasyImage', 'ImageUpload', 'FontFamily', 'Table', 'TableToolbar'],
  toolbar: {
    shouldNotGroupWhenFull: true,
  },
  heading: {
    options: [
      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    ],
  },
};

type CKEditorProps = {
  label?: string;
  error?: string;
  isRequired?: boolean;
  onChange: (data: string) => void;
  data: string;
};

export const CKEditor: FC<CKEditorProps> = ({ onChange, label, data, isRequired, error }) => {
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const hasError = !!error;
  return (
    <div className="">
      {label && (
        <div className="flex mb-1">
          <div className="text-1324 text-coil">{label}</div>
          {isRequired && <div className="text-error text-1324">*</div>}
        </div>
      )}
      <div ref={toolbarRef} className=""></div>
      <div className="bg-white border-b border-l border-r border-lavenderGray rounded-b-main">
        <CKEditorLib
          editor={DecoupledEditor}
          data={data}
          config={config}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onReady={(editor: any) => {
            toolbarRef?.current?.appendChild(editor.ui.view.toolbar.element);
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onBlur={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
      </div>
      <div
        className={classNames('text-error text-1214 px-2 py-1', {
          hidden: !hasError,
        })}
      >
        {error}
      </div>
    </div>
  );
};
