import React from 'react';

import ListPreviewPlaceholder from 'common/loaders/ListPreviewPlaceholder';
import { useProviderAdminNotes } from 'hooks/useProviders';

import AdminNotesList from './AdminNotesList';
import EmptyAdminNotes from './EmptyAdminNotes';

export default function AdminNotesLoader() {
  const providerAdminNotes = useProviderAdminNotes();

  if (!providerAdminNotes) {
    return <ListPreviewPlaceholder />;
  }

  if (providerAdminNotes.length === 0) {
    return <EmptyAdminNotes />;
  }

  return <AdminNotesList />;
}
