import React from 'react';

import { useProvidersListFilters, useProvidersList } from 'hooks/useProviders';
import { useFetchProviders } from 'requests/providers';

import EmptyProviders from './EmptyProviders';
import ProvidersList from './ProvidersList';
import ProvidersPlaceholder from './ProvidersPlaceholder';

export default React.memo(function ProvidersLoader() {
  const { qualification, search } = useProvidersListFilters();
  const fetchProviders = useFetchProviders();
  const providersList = useProvidersList();

  React.useEffect(() => {
    fetchProviders({ qualification, search });
    // eslint-disable-next-line
  }, [qualification, search])

  if (!providersList) {
    return <ProvidersPlaceholder />;
  }

  if (providersList && providersList.length === 0) {
    return <EmptyProviders />;
  }

  return <ProvidersList />;
});
