import React from 'react';
import { useHistory } from 'react-router-dom';

import { useLibraryMaterialTypes } from 'requests/graphql/patient/queries/getLibraryMaterialTypes';

import { PageTitle } from '../../components/Breadcrumbs';
import {
  LibraryMaterialEdit,
  MaterialData,
} from '../../components/library-material/LibraryMaterialEdit';
import { LIBRARY } from '../../constants/routes';
import { useCreateLibraryMaterial } from '../../requests/graphql/patient/mutations/createLibraryMaterial';
import { useLibraryMaterialCategories } from '../../requests/graphql/patient/queries/getLibraryMaterialCategories';

export const CreateMaterial = () => {
  const history = useHistory();

  const { mutateAsync: createLibrary } = useCreateLibraryMaterial();
  const { libraryMaterialCategoriesOptions } = useLibraryMaterialCategories();
  const { libraryMaterialTypesOptions } = useLibraryMaterialTypes();

  const saveMaterial = async (data: MaterialData) => {
    const result = await createLibrary({
      data,
    });

    if (result.createLibraryMaterial.__typename === 'LibraryMaterialType') {
      history.push(`/${LIBRARY}`);
    }
  };

  return (
    <>
      <PageTitle header="Add a material" crumbs={[{ title: 'Library', url: `/${LIBRARY}` }]} />
      <LibraryMaterialEdit
        onCancel={() => history.push(`/${LIBRARY}`)}
        libraryMaterialCategoriesOptions={libraryMaterialCategoriesOptions}
        libraryMaterialTypesOptions={libraryMaterialTypesOptions}
        onSave={saveMaterial}
      />
    </>
  );
};
