import React from 'react';
import styled from 'styled-components';

import LogoIcon from 'common/LogoIcon';

export default function AuthTemplate({ children, title }) {
  return (
    <StyledContainer>
      <StyledWrapper>
        <LogoIcon className="g-m-auto" />
        <StyledContent>
          <StyledTitle>{title}</StyledTitle>
          {children}
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 5vh 0;
`;

const StyledWrapper = styled.div`
  width: 34.5rem;
  margin: auto;
`;

const StyledTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

const StyledContent = styled.div`
  margin: 4.8rem 0 0 0;
  padding: 3rem 3.2rem;
  background: #fff;
  box-shadow: 0 0 1.6rem 0 rgba(116, 116, 116, 0.2);
  border-radius: 2px;
`;
