import styled from 'styled-components';

const LinkButton = styled.button`
  display: inline-block;
  background: #f9fafc;
  border: solid 1px #e9ebef;
  border-radius: 2.4rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.3rem;
  text-align: center;
  line-height: 2.4rem;
  padding: 0.7rem 1.5rem;
  transition: all 0.2s;
  outline: none;
  white-space: nowrap;
  &:hover:not([disabled]) {
    background: #fff;
    cursor: pointer;
  }
  &:active:not([disabled]) {
    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export default LinkButton;
