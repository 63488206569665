import React from 'react';
import styled from 'styled-components';

import { ReactComponent as DoneIcon } from 'assets/medication/done.svg';
import { ReactComponent as EmptyIcon } from 'assets/medication/empty.svg';
import { useMedicationsDayProgress } from 'hooks/patient/useMedications';

import { StyledCell } from '../styles';

export default React.memo(function Status({ time }) {
  const progress = useMedicationsDayProgress(time);

  if (time.isAfter()) {
    return <StyledCell />;
  }

  if (time.isSame(new Date(), 'day')) {
    return <StyledWrapper>TODAY</StyledWrapper>;
  }

  if (progress === 0) {
    return (
      <StyledWrapper>
        <EmptyIcon />
      </StyledWrapper>
    );
  }
  if (progress === 100) {
    return (
      <StyledWrapper>
        <DoneIcon />
      </StyledWrapper>
    );
  }

  return <StyledCell />;
});

const StyledWrapper = styled(StyledCell)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
