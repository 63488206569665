import Checkbox from 'components/patients/questionnaires/quiz/quiz-body/elements/Checkbox';
import Comment from 'components/patients/questionnaires/quiz/quiz-body/elements/Comment';
import Dropdown from 'components/patients/questionnaires/quiz/quiz-body/elements/Dropdown';
import Expression from 'components/patients/questionnaires/quiz/quiz-body/elements/Expression';
import Html from 'components/patients/questionnaires/quiz/quiz-body/elements/Html';
import Matrixdynamic from 'components/patients/questionnaires/quiz/quiz-body/elements/Matrixdynamic';
import MultipleText from 'components/patients/questionnaires/quiz/quiz-body/elements/MultipleText';
import NextButton from 'components/patients/questionnaires/quiz/quiz-body/elements/NextButton';
import RadioGroup from 'components/patients/questionnaires/quiz/quiz-body/elements/RadioGroup';
import SubmitButton from 'components/patients/questionnaires/quiz/quiz-body/elements/SubmitButton';
import SwitchGroup from 'components/patients/questionnaires/quiz/quiz-body/elements/SwitchGroup';
import Text from 'components/patients/questionnaires/quiz/quiz-body/elements/Text';

const config = {
  matrixdynamic: Matrixdynamic,
  comment: Comment,
  dropdown: Dropdown,
  text: Text,
  radiogroup: RadioGroup,
  switchgroup: SwitchGroup,
  expression: Expression,
  checkbox: Checkbox,
  nextbutton: NextButton,
  submitbutton: SubmitButton,
  multipletext: MultipleText,
  html: Html,
};

export default config;
