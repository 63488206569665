import classNames from 'clsx';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { DatePicker } from 'components/ui2.0/DatePicker';
import { DnDFiles } from 'components/ui2.0/DnDFiles';
import { InputField } from 'components/ui2.0/InputField';
import { FilesResult, useAttachFiles } from 'hooks/useAttachFiles';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';
import createNotification from 'utils/createNotification';

import { TreatmentPlanFiles } from '../../../Files/TreatmentPlanFiles';
import { LibraryArticleList } from '../../LibraryArticle/LibraryArticleList';

type LabsEditProps = {
  task: TaskType<'labs'>;
  hasDeleteSection?: boolean;
  errors: Partial<Record<keyof TaskType<'labs'>['details'] | 'endDate', string>>;
  onChange: (task: TaskType<'labs'>) => void;
  onDeleteSection: () => void;
};

export const LabsEditItem: FC<LabsEditProps> = ({
  task,
  errors,
  onChange,
  onDeleteSection,
  hasDeleteSection,
}) => {
  const [currentTask, setCurrentTask] = useState(task);

  const handleAddFile = useCallback((files: FilesResult) => {
    const newFiles = files.uploaded.map((file) => ({
      id: file.id || '',
      fileName: file.originalFileName || '',
      fileType: file.fileType || 'application/pdf',
    }));

    if (files.failed.length > 0) {
      files.failed.forEach((file) => {
        createNotification({
          message: `${file.originalFileName} - ${file.message}`,
          type: 'error',
        });
      });
    }

    setCurrentTask((p) => ({ ...p, files: [...(p.files || []), ...newFiles] }));
  }, []);

  const { onAttachFiles, uploadingFiles } = useAttachFiles({
    onSuccess: handleAddFile,
  });

  const handleDeleteFile = useCallback((id) => {
    setCurrentTask((p) => ({ ...p, files: p.files?.filter((file) => file.id !== id) }));
  }, []);

  const handleAddMaterial = useCallback((material: TaskType['materials']['0']) => {
    setCurrentTask((p) => ({
      ...p,
      materials: [...(p.materials || []).filter((m) => m.id !== material.id), material],
    }));
  }, []);

  const handleDeleteMaterial = useCallback((id) => {
    setCurrentTask((p) => ({
      ...p,
      materials: p.materials?.filter((material) => material.id !== id),
    }));
  }, []);

  const handleChangeStringVal = useCallback(
    (name: 'summary') => (value: string) => {
      setCurrentTask((p) => ({ ...p, details: { ...p.details, [name]: value } }));
    },
    [],
  );

  const handleChangeDueDate = useCallback((value: Date | null) => {
    setCurrentTask((p) => ({ ...p, endDate: moment(value).toISOString() }));
  }, []);

  useEffect(() => {
    if (JSON.stringify(currentTask) !== JSON.stringify(task)) {
      onChange(currentTask as TaskType<'labs'>);
    }
  }, [JSON.stringify(currentTask), JSON.stringify(task)]);

  return (
    <DnDFiles onFilesAdded={onAttachFiles}>
      {(isDragActive) => (
        <div className="relative">
          <div
            className={classNames(
              'text-persianRose text-2020 font-ambit font-semibold top-[50%] left-[50%] -mt-8 -ml-[7rem] absolute z-10',
              {
                flex: isDragActive,
                hidden: !isDragActive,
              },
            )}
          >
            Drop Files Here
          </div>
          <div
            className={classNames('border-2 border-persianRose rounded-main p-4 mb-6', {
              'border-dashed': isDragActive,
            })}
          >
            <div className={classNames('w-full', { 'opacity-30': isDragActive })}>
              {hasDeleteSection && (
                <div className="flex justify-end">
                  <div
                    onClick={onDeleteSection}
                    className="ml-3 cursor-pointer h-6 w-6 rounded-full bg-catskillWhite flex justify-center items-center"
                  >
                    <TrashIcon className="stroke-manatee" />
                  </div>
                </div>
              )}
              <div className="flex justify-between mb-4">
                <div className="w-[50%] mr-2.5">
                  <InputField
                    label="Summary"
                    isRequired
                    value={currentTask.details.summary}
                    error={errors.summary}
                    onChange={handleChangeStringVal('summary')}
                  />
                </div>
                <div className="flex justify-between w-[50%]">
                  <div className="w-full mr-2.5">
                    <DatePicker
                      error={errors.endDate}
                      value={currentTask.endDate}
                      label="Due date"
                      minDate={new Date()}
                      isRequired
                      onChange={handleChangeDueDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <LibraryArticleList
              color="text-persianRose"
              onAdd={handleAddMaterial}
              materials={currentTask.materials}
              onDelete={handleDeleteMaterial}
            />
          </div>
          <TreatmentPlanFiles
            uploadingFiles={uploadingFiles}
            onAttach={onAttachFiles}
            files={currentTask.files}
            onDelete={handleDeleteFile}
          />
        </div>
      )}
    </DnDFiles>
  );
};
