// QUERIES
export const GET_TREATMENT_PLANS = 'MY_HEALTH__GET_TREATMENT_PLANS';
export const GET_TREATMENT_PLAN = 'MY_HEALTH__GET_TREATMENT_PLAN';
export const GET_TASK_FILE_URL = 'MY_HEALTH__GET_TASK_FILE_URL';
export const GET_MEDICATION_CATEGORIES = 'MY_HEALTH__GET_MEDICATION_CATEGORIES';
export const GET_MEDICATION_FREQUENCIES = 'MY_HEALTH__GET_MEDICATION_FREQUENCIES';
export const GET_MEDICATION_MEASURES = 'MY_HEALTH__GET_MEDICATION_MEASURES';

// MUTATIONS
export const UPLOAD_TASK_FILES = 'MY_HEALTH__UPLOAD_TASK_FILES';
export const CREATE_TREATMENT_PLAN = 'MY_HEALTH__CREATE_TREATMENT_PLAN';
export const UPDATE_TREATMENT_PLAN = 'MY_HEALTH__UPDATE_TREATMENT_PLAN';
export const DELETE_TREATMENT_PLAN = 'MY_HEALTH__DELETE_TREATMENT_PLAN';
