import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Redirect } from 'react-router-dom';

import CustomButton from 'common/CustomButton';
import CustomTextarea from 'common/CustomTextarea';
import BackButton from 'common/plate/BackButton';
import { ColumnWidePlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import CustomCalendar from 'common/time-calendar';
import QualificationSelect from 'components/appointmentsProvider/appointments-create/QualificationSelect';
import { APPOINTMENTS_ADMIN, CREATE } from 'constants/routes';
import { useAppointmentCreate, useSetAppointmentCreate } from 'hooks/useAppointments';
import { useCreateAppointment } from 'requests/appointments';
import request from 'utils/request';

export default function AppointmentConfigurateAdmin() {
  const createAppointment = useCreateAppointment();
  const setSelectedDate = useSetAppointmentCreate((prev, selectedDate) => ({
    ...prev,
    selectedDate,
  }));
  const setReason = useSetAppointmentCreate((prev, reason) => ({
    ...prev,
    reason,
  }));
  const [isSended, setIsSended] = React.useState();
  const [allAvailableTime, setAllAvailableTime] = React.useState([]);

  const patientId = useAppointmentCreate((current) => get(current, 'patient.id'));
  const doctorId = useAppointmentCreate((current) => get(current, 'provider.id'));
  const selectedDate = useAppointmentCreate((current) => get(current, 'selectedDate'));
  const reason = useAppointmentCreate((current) => get(current, 'reason'));
  const qualification = useAppointmentCreate((current) => get(current, 'qualification'));

  const handleCreateAppointment = React.useCallback(() => {
    createAppointment(setIsSended);
  }, [createAppointment, setIsSended]);

  React.useEffect(() => {
    if (!doctorId) {
      return;
    }
    const timezone = moment().format('ZZ');
    request({
      method: 'get',
      url: `/providers/${doctorId}/all-available-slots`,
      params: {
        timezone,
      },
    })
      .then(({ data: { data } }) => setAllAvailableTime(data))
      .catch((error) => {
        setAllAvailableTime([]);
        console.info(error);
      });
  }, [doctorId]);

  if (!doctorId || !patientId) {
    return <Redirect to={`/${APPOINTMENTS_ADMIN}/${CREATE}`} />;
  }

  return (
    <ColumnWidePlate>
      <PlateHeader>
        <BackButton href={`/${APPOINTMENTS_ADMIN}/${CREATE}`} />
        Book an appointment
      </PlateHeader>

      <PlateContent>
        <QualificationSelect providerId={doctorId} />

        <CustomCalendar
          className="g-mt-15"
          label=" Select an available date and time"
          allAvailableTime={allAvailableTime}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <CustomTextarea
          className="g-mt-25 g-mb-25"
          label="Indicate the reason"
          value={reason}
          changeHandler={setReason}
        />

        <CustomButton
          className="g-mt-auto"
          disabled={!doctorId || !selectedDate || !reason || !qualification || isSended}
          clickHandler={handleCreateAppointment}
        >
          Confirm
        </CustomButton>
      </PlateContent>
    </ColumnWidePlate>
  );
}
