import React from 'react';
import toast from 'react-hot-toast';

import { ReactComponent as IconInfo } from 'assets/logo-icon.svg';
import { ReactComponent as IconError } from 'assets/toaster/error.svg';
import { ReactComponent as IconSuccess } from 'assets/toaster/success.svg';
import { ReactComponent as IconWarning } from 'assets/toaster/warning.svg';
import Notification from 'common/Notification';

const defaultOptions = {
  duration: 4000,
  position: 'top-right',
  style: {
    position: 'relative',
    width: '28.8rem',
    maxWidth: '100%',
    padding: '1.4rem 2.4rem 1.4rem 1.6rem',
    background: '#FFFFFF',
    boxShadow: '0.2rem 0.6rem 1.1rem rgba(0, 0, 0, 0.15)',
    borderRadius: '1.8rem',
  },
  ariaProps: {
    role: 'alert',
  },
};

const defaultIconStyles = {
  flexShrink: '0',
  width: '3.2rem',
  height: '3.2rem',
};

const toastConfig = {
  success: {
    ...defaultOptions,
    icon: <IconSuccess style={defaultIconStyles} />,
  },
  warning: {
    ...defaultOptions,
    icon: <IconWarning style={defaultIconStyles} />,
  },
  error: {
    ...defaultOptions,
    icon: <IconError style={defaultIconStyles} />,
  },
  info: {
    ...defaultOptions,
    icon: <IconInfo style={defaultIconStyles} />,
  },
};

// Type: info, success, warning, error
export default function createNotification({ title = '', message, type = 'success' }) {
  const options = toastConfig[type];
  if (!options) {
    return console.warn(`Notification type: ${type} not implemented.`);
  }
  return toast(
    (t) => (
      <Notification
        title={title}
        message={message}
        type={type}
        closeHandler={() => toast.dismiss(t.id)}
      />
    ),
    options,
  );
}
