import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import LinkButton from 'common/LinkButton';
import BackButton from 'common/plate/BackButton';
import { WideRowPlate } from 'common/plate/plates';
import { PlateHeader, PlateContent } from 'common/plate/styles';
import PrescriptionPreviewContent from 'components/patients/prescriptions/prescription-preview/PrescriptionPreviewContent';
import { DASHBOARD } from 'constants/routes';

export default function PatientPrescriptionPreview() {
  return (
    <WideRowPlate>
      <PlateHeader>
        <BackButton href={`/${DASHBOARD}`} />
        Prescription Preview
      </PlateHeader>
      <PlateContent>
        <PrescriptionPreviewContent />
        <StyledBtnsWrapper className="g-d-flex">
          <CustomButton>Confirm</CustomButton>
          <LinkButton className="button g-ml-20">Return</LinkButton>
        </StyledBtnsWrapper>
      </PlateContent>
    </WideRowPlate>
  );
}

const StyledBtnsWrapper = styled.div`
  margin-top: 2rem;
  .button {
    min-width: 13rem;
    &:nth-child(n + 2) {
      @media screen and (max-width: 1024px) {
        margin-left: 0.5rem;
      }
    }
  }
`;
