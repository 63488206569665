import React from 'react';

import CustomCalendar from 'common/custom-calendar';
import { useSetMedicationProperty } from 'hooks/usePatients';

export default function EndDate({ className = '', index, startedAt, endedAt, validate }) {
  const setMedicationEndedAt = useSetMedicationProperty({ index, field: 'ended_at' });
  return (
    <CustomCalendar
      className={className}
      label="End intake date"
      placeholder="mm.dd.yy"
      isRequred
      isValid={!validate || (endedAt && +new Date(endedAt) > +new Date(startedAt))}
      minDate={typeof startedAt === 'string' ? new Date(startedAt) : startedAt}
      selectedDate={endedAt}
      setSelectedDate={setMedicationEndedAt}
    />
  );
}
