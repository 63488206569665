import React from 'react';

import CustomCalendar from 'common/custom-calendar';
import { useSetMedicationProperty } from 'hooks/usePatients';

export default function StartDate({ className = '', index, startedAt, validate }) {
  const setMedicationStartedAt = useSetMedicationProperty({ index, field: 'started_at' });
  return (
    <CustomCalendar
      className={className}
      label="Start intake date"
      placeholder="mm.dd.yy"
      isRequred
      isValid={!validate || startedAt}
      selectedDate={startedAt}
      setSelectedDate={setMedicationStartedAt}
    />
  );
}
