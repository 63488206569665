import React from 'react';

import CustomCalendar from 'common/custom-calendar';
import { useLogsFilter, useSetLogsFilter } from 'hooks/useLogs';

export default React.memo(function ToSelect() {
  const from = useLogsFilter((current) => current.from || undefined, []);
  const to = useLogsFilter((current) => current.to, []);
  const setTo = useSetLogsFilter((prev, to) => ({ ...prev, to }), []);
  return (
    <CustomCalendar
      className="to"
      label="to"
      minDate={from}
      selectedDate={to}
      setSelectedDate={setTo}
    />
  );
});
