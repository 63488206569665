import get from 'lodash/get';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { PATIENTS, DETAILS, MEDICATIONS, PROGRESS } from 'constants/routes';

import Info from '../../common/Info';
import MedicationHeader from '../../common/MedicationHeader';
import Note from '../../common/Note';

export default React.memo(function MedicationPreview({ medication }) {
  const { patientId } = useParams();
  const history = useHistory();
  const id = get(medication, 'id');
  const icon = get(medication, 'icon');
  const medicationName = get(medication, 'medication');
  const dosage = get(medication, 'dosage');
  const measure = get(medication, 'measure');
  const frequency = get(medication, 'frequency');
  const duration = get(medication, 'duration');
  const note = get(medication, 'note');

  const navigateToProgress = React.useCallback(() => {
    history.push(`/${PATIENTS}/${DETAILS}/${patientId}/${MEDICATIONS}/${PROGRESS}/${id}`);
  }, [history, patientId, id]);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleNote = React.useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

  return (
    <StyledWrapper className="g-mb-25">
      <div className="top g-d-flex g-align-center">
        <MedicationHeader icon={icon} medicationName={medicationName} />
        <Info className="info" label="Dosage" value={`${dosage} ${measure} / ${frequency}`} />
        <Info
          className="info"
          label="Duration"
          value={`${duration} ${duration === 1 ? 'Day' : 'Days'}`}
        />
        <div className="g-d-flex g-ml-auto">
          <LinkButton className="button g-mr-10" onClick={navigateToProgress}>
            Progress
          </LinkButton>
          <LinkButton className="button" disabled={!note} onClick={toggleNote}>
            {isOpen ? 'Hide' : 'View note'}
          </LinkButton>
        </div>
      </div>
      <Note isOpen={isOpen} note={note} />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  background: #f5f9ff;
  box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  .top {
    padding: 2.5rem 2rem 2.5rem 2.5rem;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
  }
  .info {
    padding: 0 1.5rem;
    @media screen and (max-width: 1024px) {
      padding: 0 0.5rem;
    }
    @media screen and (max-width: 768px) {
      width: 49%;
      margin-bottom: 1.5rem;
      padding: 0 0.1rem;
    }
  }
  .button {
    min-width: 10rem;
    white-space: nowrap;
    @media screen and (max-width: 480px) {
      width: 100%;
      margin-top: 1rem;
      margin-left: 0;
    }
  }
  .progress {
    margin-left: auto;
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
`;
