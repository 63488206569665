import React from 'react';

import Status from './Status';

export default React.memo(function StatusesList({ config }) {
  const parsedStatuses = React.useMemo(() => {
    return config.map((time, i) => {
      return <Status key={i} time={time} />;
    });
  }, [config]);
  return <div className="g-d-flex g-mb-10">{parsedStatuses}</div>;
});
