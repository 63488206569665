import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import CustomButton from 'common/CustomButton';
import { RowPlate } from 'common/plate/plates';
import { PlateContent } from 'common/plate/styles';
import { useConsultation } from 'hooks/useAppointments';

import SecureInfo from './SecureInfo';
import VideoFooter from './VideoFooter';
import VideoHeader from './VideoHeader';

export default React.memo(function InteractHandler({ setIsInteracted }) {
  const respondData = useConsultation((current) => get(current, 'respondData'));
  return (
    <StylesWrapper>
      <RowPlate>
        <VideoHeader
          patientId={get(respondData, 'partisipiants.patient.id')}
          avatar={get(respondData, 'partisipiants.patient.avatar_url')}
          firstName={get(respondData, 'partisipiants.patient.first_name')}
          lastName={get(respondData, 'partisipiants.patient.last_name')}
          fullName={get(respondData, 'partisipiants.patient.full_name')}
        />
        <PlateContent>
          <CustomButton className="g-m-auto" clickHandler={setIsInteracted}>
            Join to appointment
          </CustomButton>
          <VideoFooter />
        </PlateContent>
      </RowPlate>
      <SecureInfo />
    </StylesWrapper>
  );
});

const StylesWrapper = styled.div`
  width: 100%;
  max-width: 87rem;
`;
