import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { RegularText } from 'common/texts';
import { typesConfig, periodTabsConfig, dimensionConfig } from 'constants/withings-tabs';
import { useDaysData } from 'hooks/patient/widgets/useWidgets';

import Bars from './bars';
import DatesList from './dates-list';
import LabelsList from './labels-list';

export default function WeekView({ currentTab, period }) {
  const type = typesConfig[currentTab];
  const { start, end } = periodTabsConfig.find((item) => item.id === period);

  const daysData = useDaysData(type);

  const dates = React.useMemo(() => {
    return new Array(7).fill().map((item, i) => {
      const day = moment(start).add(i, 'days');
      const { value = 0 } = daysData.find(({ date }) => date === day.format('YYYY-MM-DD')) || {};
      return {
        label: day.format('D MMM'),
        value,
      };
    });
  }, [start, daysData]);

  return (
    <StyledWrapper>
      <RegularText>
        {moment(start).format('dddd, D MMMM YYYY')} - {moment(end).format('dddd, D MMMM YYYY')}{' '}
        <b>(in {dimensionConfig[currentTab]})</b>
      </RegularText>
      <LabelsList dates={dates} />
      <Bars currentTab={currentTab} dates={dates} />
      <DatesList dates={dates} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div``;
