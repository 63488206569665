import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { COMPANIES } from 'constants/routes';
import {
  useSetCompanies,
  useSetCompany,
  useSetCompanyPatients,
  useSetCompanyPlans,
  useSetCompanyPlansMembers,
} from 'hooks/useCompanies';
import { globalContext } from 'hooks/useGlobalState';
import createNotification from 'utils/createNotification';
import normalizeParams from 'utils/normalizeParams';
import request from 'utils/request';

import { useSetPatinets, useSetPatinetsPagination } from '../hooks/usePatients';

export function useFetchCompanies() {
  const { companies } = React.useContext(globalContext);
  const setCompanies = useSetCompanies((prev, next) => next, []);
  return React.useCallback(() => {
    if (!companies.value) {
      request({
        method: 'get',
        url: '/companies',
      })
        .then(
          ({
            data: {
              data: { companies },
            },
          }) => setCompanies(companies),
        )
        .catch((error) => console.info(error));
    }
  }, [companies, setCompanies]);
}

export function useFetchCompany() {
  const { companyId } = useParams();
  const setCompany = useSetCompany((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/companies/${companyId}`,
    })
      .then(
        ({
          data: {
            data: { company },
          },
        }) => setCompany(company),
      )
      .catch((error) => console.info(error));
  }, [companyId, setCompany]);
}

export function useFetchCompanyPatients() {
  const { companyId } = useParams();
  const setCompanyPatients = useSetCompanyPatients((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/companies/${companyId}/patients`,
        params: normalizeParams(params),
      })
        .then(
          ({
            data: {
              data: { patients },
            },
          }) => setCompanyPatients(patients),
        )
        .catch((error) => console.info(error));
    },
    [companyId, setCompanyPatients],
  );
}

export function useFetchAllPatients() {
  const { status } = 'active';
  const setPatinets = useSetPatinets((prev, next) => next, []);
  const setPatinetsPagination = useSetPatinetsPagination((prev, next) => next, []);
  const per_page = '500';
  return React.useCallback(() => {
    const page = 1;
    request({
      method: 'get',
      url: '/patients',
      params: normalizeParams({
        status,
        page,
        per_page,
      }),
    })
      .then(({ data: { data } }) => {
        setPatinets(data.patients);
        setPatinetsPagination(data.meta);
      })
      .catch((error) => console.info(error));
  }, [status, setPatinets, setPatinetsPagination]);
}

export function usePreFetchCompanyPatients(companyId, signal) {
  const setCompanyPatients = useSetCompanyPatients((prev, next) => next, []);
  return React.useCallback(
    (params = {}) => {
      request({
        method: 'get',
        url: `/companies/${companyId}/patients`,
        params: normalizeParams(params),
        signal: signal,
      })
        .then(() => {
          // setCompanyPatients(patients)
        })
        .catch((error) => console.info(error));
    },
    [companyId, setCompanyPatients],
  );
}

export function useCreateCompany() {
  const history = useHistory();
  const { companyForm } = React.useContext(globalContext);
  return React.useCallback(
    (setIsSended) => {
      setIsSended(true);
      request({
        method: 'post',
        url: '/companies',
        data: companyForm.value,
      })
        .then(() => {
          createNotification({ message: 'Company successfully created', type: 'success' });
          history.push(`/${COMPANIES}`);
        })
        .catch((error) => console.info(error))
        .finally(() => setIsSended(false));
    },
    [companyForm, history],
  );
}

export function useFetchCompanyPlans() {
  const { companyId } = useParams();
  const setCompanyPlans = useSetCompanyPlans((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: `/companies/${companyId}/subscriptions`,
    })
      .then(
        ({
          data: {
            data: { subscriptions },
          },
        }) => setCompanyPlans(subscriptions),
      )
      .catch((error) => console.info(error));
  }, [companyId, setCompanyPlans]);
}

export function useFetchCompanyPlansMembers() {
  const { subscriptionId } = useParams();
  const body = { subscription_id: subscriptionId };
  const setCompanyPlansMembers = useSetCompanyPlansMembers((prev, next) => next, []);
  return React.useCallback(() => {
    request({
      method: 'get',
      url: '/company/memberships',
      params: body,
    })
      .then(
        ({
          data: {
            data: { memberships },
          },
        }) => setCompanyPlansMembers(memberships),
      )
      .catch((error) => console.info(error));
  }, [subscriptionId, setCompanyPlansMembers]);
}
