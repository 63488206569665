import get from 'lodash/get';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Cognito } from 'libs/cognito';

import PasswordChange from './PasswordChange';

const cognito = Cognito.getInstance();

export default React.memo(function PasswordChangeWrapper() {
  const { pathname } = useLocation();

  const [credentialsExpired, setCredentialsExpired] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('passwordHasBeenChanged', '1');
  };

  React.useEffect(() => {
    const passwordHasBeenChanged = Number(localStorage.getItem('passwordHasBeenChanged'));
    if (passwordHasBeenChanged) {
      setCredentialsExpired(false);
      return;
    }
    cognito.getSession().then((session) => {
      const maybeExpiredDate = get(session, 'idToken.payload["custom:passwordExpiredAt"]', '');
      const maybeGoogle = get(session, 'accessToken.payload.scope', '').includes('openid');
      if (!maybeExpiredDate || maybeGoogle) {
        return;
      }
      const expirationDate = new Date(maybeExpiredDate);
      const isExpired = expirationDate.getTime() < new Date().getTime();
      setCredentialsExpired(isExpired);
      if (isExpired) {
        setIsModalOpen(true);
      }
    });
  }, [pathname]);

  if (credentialsExpired && isModalOpen) {
    return <PasswordChange closeModal={closeModal} />;
  }

  return null;
});
