import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import React from 'react';
import styled from 'styled-components';

import LinkButton from 'common/LinkButton';
import { useSetQuiz } from 'hooks/patient/useQuestionnaires';

export default React.memo(function RemoveRow({ className = '', metaPath, rowIndex }) {
  const removeRowHandler = useSetQuiz(
    (prev) => {
      const currentRowCount = get(prev, `${metaPath}.rowCount`);
      const updatedCount = set(cloneDeep(prev), `${metaPath}.rowCount`, currentRowCount - 1);
      const answerField = get(prev, `${metaPath}.name`);
      const currentAnswers = get(prev, `content.answers.${answerField}`);
      if (currentAnswers) {
        return set(
          updatedCount,
          `content.answers.${answerField}`,
          currentAnswers.filter((item, i) => i !== rowIndex),
        );
      }
      return updatedCount;
    },
    [metaPath, rowIndex],
  );
  return (
    <StyledLinkButton className={className} onClick={removeRowHandler}>
      &#10005;
    </StyledLinkButton>
  );
});

const StyledLinkButton = styled(LinkButton)`
  width: 4.8rem;
  min-width: 4.8rem;
  height: 4.8rem;
  align-self: flex-end;
  font-size: 1.6rem;
  line-height: 4.8rem;
  padding: 0;
  margin: 1rem 0 0 2rem;
  @media screen and (max-width: 480px) {
    margin-left: auto;
  }
`;
