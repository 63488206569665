import React from 'react';
import styled from 'styled-components';

import { StyledWrapper } from 'common/chat/chat-list-item/styles';
import InputWithLabel from 'common/InputWithLabel';
import { MediumText } from 'common/texts';

export default React.memo(function GlobalMentalHealthResult({ rawScore, tScore }) {
  return (
    <>
      <MediumText>Global mental health</MediumText>
      <StyledWrapper>
        <StyledInputWithLabel
          className="raw-score"
          label="Raw Score"
          value={rawScore}
          styles=""
          readOnly
        />
        <StyledInputWithLabel
          className="t-score"
          label="T-Score"
          value={tScore}
          styles="margin-left:1rem"
          readOnly
        />
      </StyledWrapper>
    </>
  );
});

const StyledInputWithLabel = styled(InputWithLabel)`
  ${({ styles }) => styles || ''};
`;
