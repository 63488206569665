import get from 'lodash/get';
import React from 'react';

import {
  WELCOME_MESSAGE,
  PATIENT_FEELING,
  PATIENT_FEELING_GOOD,
  PATIENT_FEELING_NEUTRAL,
  PATIENT_FEELING_SICK,
  JOIN_TO_CHANNEL,
  LEFT_FROM_CHANNEL,
  JOIN_APPOINTMENT,
  FEEDBACK_MESSAGE,
  PROMOTER_SCORE,
  REPLY_PROMOTER_SCORE,
  FIRST_APPOINTMENT_FEEDBACK,
  LAST_APPOINTMENT_FEEDBACK,
  CONSENT_FORM_COMPLETED,
  SIX_MONTH_AFTER_PROGRAM_WAS_STARTED,
} from 'constants/messages';

import ConsentFormCompleted from './ConsentFormCompleted';
import FeedbackMessage from './FeedbackMessage';
import JoinAppointment from './JoinAppointment';
import JoinToChannel from './JoinToChannel';
import LeftFromChannel from './LeftFromChannel';
import PatientFeeling from './PatientFeeling';
import PatientFeelingGood from './PatientFeelingGood';
import PatientFeelingNeutral from './PatientFeelingNeutral';
import PatientFeelingSick from './PatientFeelingSick';
import SixMonthAfterProgramWasStarted from './SixMonthAfterProgramWasStarted';
import SystemMessageTemplate from './SystemMessageTemplate';
import TextMessage from './TextMessage';

const systemMessagesConfig = {
  [WELCOME_MESSAGE]: TextMessage,
  [PATIENT_FEELING]: PatientFeeling,
  [PATIENT_FEELING_GOOD]: PatientFeelingGood,
  [PATIENT_FEELING_NEUTRAL]: PatientFeelingNeutral,
  [PATIENT_FEELING_SICK]: PatientFeelingSick,
  [JOIN_TO_CHANNEL]: JoinToChannel,
  [LEFT_FROM_CHANNEL]: LeftFromChannel,
  [JOIN_APPOINTMENT]: JoinAppointment,
  [FEEDBACK_MESSAGE]: FeedbackMessage,
  [PROMOTER_SCORE]: TextMessage,
  [REPLY_PROMOTER_SCORE]: TextMessage,
  [FIRST_APPOINTMENT_FEEDBACK]: TextMessage,
  [LAST_APPOINTMENT_FEEDBACK]: TextMessage,
  [CONSENT_FORM_COMPLETED]: ConsentFormCompleted,
  [SIX_MONTH_AFTER_PROGRAM_WAS_STARTED]: SixMonthAfterProgramWasStarted,
};

export default React.memo(function SystemMessage({ message }) {
  const type = get(message, 'state.attributes.type');

  const parsedMessage = React.useMemo(() => {
    const Component = systemMessagesConfig[type];
    if (!Component) {
      console.warn(`System message with type: ${type}, not implemented`);
      return null;
    }
    const sid = get(message, 'sid');
    const timestamp = get(message, 'state.timestamp', '');
    return (
      <SystemMessageTemplate timestamp={timestamp}>
        <Component key={sid} message={message} />
      </SystemMessageTemplate>
    );
  }, [type, message]);

  return parsedMessage;
});
