import moment from 'moment';
import React, { FC, useMemo } from 'react';

import { ReactComponent as DateIcon } from 'assets/date.svg';
import { ReactComponent as MedicationsIcon } from 'assets/medications.svg';
import { TaskType } from 'requests/graphql/my-health/queries/getTreatmentPlan';

import { SelectOption } from '../../../../../../utils/sortOptions';
import { AttachedFile } from '../../AttachedFile';
import { LibraryArticle } from '../../LibraryArticle/LibraryArticle';
import { SectionHeader } from '../../SectionHeader';

type MedicationsViewProps = {
  tasks?: TaskType<'medications'>[];
  medicationMeasuresOptions: SelectOption[];
  medicationCategoriesOptions: SelectOption[];
  medicationFrequenciesOptions: SelectOption[];
  isPreview?: boolean;
  onEdit: () => void;
  onDelete: () => void;
};

const foundFunc =
  (
    tasks: TaskType<'medications'>[] | undefined,
    arr: SelectOption[],
    val: keyof TaskType<'medications'>['details'],
  ) =>
  () => {
    const found =
      tasks?.map((t) => arr.find((o) => o.value + '' === t?.details[val] + '')).filter(Boolean) ||
      [];

    return found.reduce((acc, curr) => {
      return { ...acc, [curr?.value || '']: curr?.label };
    }, {}) as Record<string, string>;
  };

export const MedicationsView: FC<MedicationsViewProps> = ({
  tasks,
  isPreview,
  onEdit,
  onDelete,
  medicationMeasuresOptions,
  medicationCategoriesOptions,
  medicationFrequenciesOptions,
}) => {
  const medications = useMemo(foundFunc(tasks, medicationCategoriesOptions, 'categoryId'), [
    medicationCategoriesOptions,
    tasks,
  ]);
  const measures = useMemo(foundFunc(tasks, medicationMeasuresOptions, 'measureId'), [
    medicationMeasuresOptions,
    tasks,
  ]);
  const frequencies = useMemo(foundFunc(tasks, medicationFrequenciesOptions, 'frequencyId'), [
    medicationFrequenciesOptions,
    tasks,
  ]);

  return (
    <div className="shadow-row p-6 border rounded-main mb-6">
      <div className="pb-6">
        <SectionHeader
          title="Medications"
          icon={<MedicationsIcon />}
          onEdit={onEdit}
          isPreview={isPreview}
          onDelete={onDelete}
        />
      </div>
      {tasks?.map((task, di) => {
        return (
          <React.Fragment key={task.id || 'medication' + di}>
            {di > 0 && <div className="border-t w-full h-px my-6"></div>}
            <div className="">
              <div className="">
                <div className="flex justify-between mb-2">
                  <div className="text-1822 font-ambit text-main font-semibold">
                    {task.details.name}
                  </div>
                  {task.startDate && task.endDate && (
                    <div className="flex ">
                      <DateIcon className="w-[2rem] h-[2rem]" />
                      <div className="ml-2 text-1420 font-ambit text-main font-semibold">
                        {moment(task.startDate).format('MM.DD.YYYY')} -{' '}
                        {moment(task.endDate).format('MM.DD.YYYY')}
                      </div>
                    </div>
                  )}
                </div>
                {task.details && (
                  <div
                    className="ck-editor-view"
                    dangerouslySetInnerHTML={{ __html: task.details.description }}
                  ></div>
                )}
              </div>
              {!!task.materials.length && <div className="border-t w-full h-px my-6"></div>}
              <div className="">
                {task.materials?.map((m, mi) => (
                  <LibraryArticle
                    key={m.id || 'material' + mi}
                    materialId={m.materialVersion.materialId}
                    isPreview={true}
                    title={m.materialVersion.title}
                    category="Diet"
                  />
                ))}
              </div>
              {!!task.files.length && <div className="border-t w-full h-px my-6"></div>}
              <div className="flex">
                {task.files?.map((file, fi) => (
                  <AttachedFile
                    id={file.id}
                    key={file.id || 'file' + fi}
                    className="mr-4"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    type={file.fileType as any}
                    filename={file.fileName}
                  />
                ))}
              </div>
              <div className="border-t w-full h-px my-6"></div>
              <div className="">
                <div className="flex">
                  <div className="text-1220 uppercase text-manatee w-[35%]">Name</div>
                  <div className="text-1220 uppercase text-manatee w-[15%]">Measure</div>
                  <div className="text-1220 uppercase text-manatee w-[15%]">Quantity</div>
                  <div className="text-1220 uppercase text-manatee w-[25%]">Frequency</div>
                </div>
                <div className="flex">
                  <div className="text-1420 font-semibold font-ambit w-[35%]">
                    {medications[task.details.categoryId] || task.details?.category?.title}
                  </div>
                  <div className="text-1420 font-semibold font-ambit w-[15%]">
                    {measures[task.details.measureId] || task.details?.measure?.measure}
                  </div>
                  <div className="text-1420 font-semibold font-ambit w-[15%]">
                    {task.details.quantity}
                  </div>
                  <div className="text-1420 font-semibold font-ambit w-[25%]">
                    {frequencies[task.details.frequencyId] || task.details?.frequency?.title}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
