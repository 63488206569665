import moment from 'moment';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import DayLabel from 'common/widgets/DayLabel';
import ViewData from 'common/widgets/ViewData';
import { PATIENTS, DETAILS, WIDGETS, OURA, SLEEP_CONTRIBUTORS } from 'constants/routes';
import normalizePercentageValue from 'utils/normalizePercentageValue';
import onlyPositive from 'utils/onlyPositive';
import toHM from 'utils/toHM';

import SleepPreview from './SleepPreview';

export default function OuraSleepWidget({ widgetId, lastResult }) {
  const { patientId } = useParams();
  const history = useHistory();
  const {
    summaryDate,
    total,
    bedtime_start: bedtimeStart,
    bedtime_end: bedtimeEnd,
    hr_average: hrAverage,
    efficiency,
  } = lastResult;

  const sleepEfficiency = Math.round(normalizePercentageValue(efficiency));
  const totalSleepTime = toHM(onlyPositive(total));
  const totalBedTime = toHM(moment(bedtimeEnd).diff(moment(bedtimeStart), 'minutes'), 'minutes');
  const restingHeartRate = `${Math.round(onlyPositive(hrAverage))} bpm`;

  const navigateToSleepContributors = React.useCallback(() => {
    history.push(
      `/${PATIENTS}/${DETAILS}/${patientId}/${WIDGETS}/${OURA}/${widgetId}/${SLEEP_CONTRIBUTORS}`,
    );
  }, [history, patientId, widgetId]);

  return (
    <>
      <DayLabel date={summaryDate} />
      <SleepPreview
        widgetId={widgetId}
        sleepEfficiency={sleepEfficiency}
        totalSleepTime={totalSleepTime}
        totalBedTime={totalBedTime}
        restingHeartRate={restingHeartRate}
      />
      <ViewData className="g-mt-45" clickHandler={navigateToSleepContributors} />
    </>
  );
}
