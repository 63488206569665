import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import InputWithLabel from 'common/InputWithLabel';
import { useQuiz } from 'hooks/patient/useQuestionnaires';

export default function Text({ className = '', metaPath, answerField }) {
  const label = useQuiz((current) => get(current, `${metaPath}.title`, ''), [metaPath]);
  const value = useQuiz(
    (current) => get(current, `content.answers.${answerField}.value`, ''),
    [answerField],
  );
  const styles = useQuiz((current) => get(current, `${metaPath}.styles`, ''), [metaPath]);

  return (
    <StyledInputWithLabel
      className={className}
      label={label}
      value={value}
      styles={styles}
      readOnly
    />
  );
}

const StyledInputWithLabel = styled(InputWithLabel)`
  ${({ styles }) => styles || ''};
`;
